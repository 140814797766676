import styled from "styled-components";
import { Button } from "@mui/material";

export const InfoBlock = styled.div`
  background: #f2f2fc;
  padding: 16px 16px 0;
  border-radius: 4px;
  display: flex;
  margin-bottom: 24px;
  flex-wrap: wrap;
`;
export const Col = styled.div`
  width: 50%;
  margin-bottom: 16px;
`;
export const SubHeading = styled.p`
  color: #383874;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  opacity: 0.5;
`;
export const Text = styled.p`
  color: #010101;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
export const Paragraph = styled.p`
  color: #010101;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
`;
export const StyledTabButton = styled(Button)`
  &.MuiButton-root {
    padding: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: none;
    padding: 10px 33px;
  }

  &.MuiButton-contained {
    background-color: var(--secondary);
    color: (--white);
  }

  &.MuiButton-outlined {
    background-color: var(--white);
    color: var(--secondary);
    border-color: var(--secondary);
    border-style: solid;
  }

  &:hover {
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
    color: var(--white) !important;
  }
`;
