import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";

import FormGroup from "../../FormComponents/FormGroup";
import Label from "../../FormComponents/Label";
import CustomSelect from "../../FormComponents/Select";
import Modal from "../StyledModal";
import Button from "shared/ui/Button";

import { Subtitle, Form, Block, StyledAlert } from "./styles";

import { alertActions } from "entities/alerts";

interface AddNewUserProps {
  open: boolean;
  onClose: () => void;
}
interface ErrorsProps {
  firstName?: boolean;
  lastName?: boolean;
  role?: boolean;
  email?: boolean;
}

const ResendInvitation = ({ open, onClose }: AddNewUserProps) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("John");
  const [lastName, setLastName] = useState("Smith");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("user.email@email.com");
  const [phone, setPhone] = useState("+1-202-555-0156");
  const [role, setRole] = useState<string[]>(["Role 1"]);
  const [group, setGroup] = useState<string[]>(["Group 1"]);
  const [errors, setErrors] = useState<ErrorsProps>({});

  const validateForm = () => {
    if (firstName && lastName && email && role) {
      return true;
    } else {
      const err: ErrorsProps = {};
      if (!firstName) err.firstName = true;
      if (!lastName) err.lastName = true;
      if (!role.length) err.role = true;
      setErrors({ ...errors, ...err });
    }
    return false;
  };

  const onSubmit = () => {
    const valid = validateForm();

    if (valid) {
      dispatch({
        type: alertActions.SHOW_ALERT,
        data: {
          type: "invitation",
        },
      });
      onClose();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, id },
    } = event;
    setErrors({
      ...errors,
      [id]: false,
    });
    if (id === "firstName") setFirstName(value);
    if (id === "lastName") setLastName(value);
    if (id === "title") setTitle(value);
    if (id === "phone") setPhone(value);
    if (id === "email") setEmail(value);
  };

  const selectRole = (event: any) => {
    const {
      target: { value },
    } = event;
    setRole(typeof value === "string" ? value.split(",") : value);
    setErrors({
      ...errors,
      role: false,
    });
  };

  const selectGroup = (event: any) => {
    const {
      target: { value },
    } = event;
    setGroup(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Modal
      id="resendInvitationModal"
      onClose={onClose}
      open={open}
      title="Resend an invitation"
      submitButton={
        <Button
          variant="contained"
          onClick={onSubmit}
          data-testid="resendInvitationModal_button"
        >
          Resend Invitation
        </Button>
      }
    >
      <Form dividers>
        <StyledAlert severity="info" data-testid="resendInvitationModal_alert">
          Invitation will be sent to email <b>[useremail@email.com]</b>. You can
          update it is needed.{" "}
        </StyledAlert>
        <Subtitle>General Information</Subtitle>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormGroup
              danger
              placeholder="John"
              value={firstName}
              id="firstName"
              onChange={handleChange}
              error={errors?.firstName}
              label="First Name"
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup
              danger
              placeholder="Smith"
              value={lastName}
              id="lastName"
              onChange={handleChange}
              error={errors?.lastName}
              label="Last Name"
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup
              disabled
              value={(firstName || lastName) && `${firstName} ${lastName}`}
              placeholder="Full Name"
              id="fullName"
              label="Full Name"
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup
              placeholder="Enter Title"
              value={title}
              id="title"
              onChange={handleChange}
              label="Title"
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <Label label="Role" danger />
            <CustomSelect
              value={role}
              id="role"
              options={["Role 1", "Role 2", "Role 3"]}
              onChange={selectRole}
              title="Role"
              error={errors?.role}
            />
          </Grid>
          <Grid item xs={6}>
            <Label label="Groups" />
            <CustomSelect
              value={group}
              id="group"
              options={["Group 1", "Group 2", "Group 3"]}
              onChange={selectGroup}
              title="Group"
            />
          </Grid>
        </Grid>
        <Block>
          <Subtitle>Contacts</Subtitle>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormGroup
                danger
                label="Email"
                type="email"
                placeholder="Enter Email"
                value={email}
                id="email"
                onChange={handleChange}
                error={errors?.email}
              />
            </Grid>
            <Grid item xs={6}>
              <FormGroup
                label="Phone"
                placeholder="Enter Phone Number"
                value={phone}
                id="phone"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Block>
      </Form>
    </Modal>
  );
};

export default ResendInvitation;
