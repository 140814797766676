import { Column } from "shared/config/types/Table";

export const initialColumns: Column[] = [
  {
    id: "Name",
    label: "Product Name",
    minWidth: 250,
    filtering: true,
  },
  {
    id: "category",
    label: "Category",
    minWidth: 250,
    filtering: false,
  },
  {
    id: "statusName",
    label: "Status",
    minWidth: 250,
    filtering: false,
  },
  {
    id: "createdDateUtc",
    label: "Creation",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Update",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 200,
    filtering: false,
    align: "center",
  },
];
export const mockIncludedData: any = [
  {
    id: "ascascasc",
    status: "activated",
    name: "Personal Auto",
    category: "Leads",
    statusName: "Active",
    createdDateUtc: "08-24-2022",
    updatedDateUtc: "08-24-2022",
  },
];
