import { useAppDispatch, useAppSelector } from "redux/store/hooks/hooks";
import { useCallback } from "react";
import {
  createModuleAction,
  createModuleRuleAction,
  createModulesAction,
  deleteModuleRuleAction,
  editModuleRuleAction,
  getAllModulesAction,
  getModules,
  getModulesLoading,
  getModulesNames,
  getModuleOrder,
  getRulesLoading,
  Modules,
  deleteModuleAction,
  editModuleAction,
  getModulesTemplatesAction,
  getTemplatesIntegrations,
  getTemplatesEnrichment,
  getTemplatesLoading,
  getModulesObject,
  getEnrichedModules,
  getThirdPartyModules,
  setModulesOrderAction,
  ModuleBody,
} from "entities/modules";
import { getProduct } from "entities/products";
import { RulePayload, ModulesOrderPayload } from "shared/api/modules/types";

const useProductData = () => useAppSelector(getProduct);
const useLoading = () => useAppSelector(getModulesLoading);
const useModules = () => useAppSelector(getModules);
const useRuleLoading = () => useAppSelector(getRulesLoading);
const useModulesNames = () => useAppSelector(getModulesNames);
const useModuleOrder = () => useAppSelector(getModuleOrder);
const useDataModulesObject = () => useAppSelector(getModulesObject);
const useEnrichedModulesObject = () => useAppSelector(getEnrichedModules);
const useThirdPartyModulesObject = () => useAppSelector(getThirdPartyModules);

export const useModule = () => {
  const dispatch = useAppDispatch();
  const { id, version } = useProductData();
  const isLoading = useLoading();
  const modules = useModules();
  const modulesNames = useModulesNames();
  const moduleOrderQueue = useModuleOrder();
  const modulesDataObject = useDataModulesObject();
  const enrichedDataObject = useEnrichedModulesObject();
  const thirdPartyDataObject = useThirdPartyModulesObject();

  const createModules = useCallback(() => {
    dispatch(
      createModulesAction({
        version: version,
        id: id,
      })
    );
  }, [dispatch, id, version]);
  const setModulesOrder = useCallback(
    (data: ModulesOrderPayload, callback: () => void) => {
      dispatch(
        setModulesOrderAction({
          id: id,
          version: version,
          data,
          callback,
        })
      );
    },
    [dispatch, id, version]
  );
  const createModule = useCallback(
    (type: Modules, data?: ModuleBody) => {
      dispatch(
        createModuleAction({
          version: version,
          id: id,
          type,
          data,
        })
      );
    },
    [dispatch, id, version]
  );
  const deleteModule = useCallback(
    (moduleId: string) => {
      dispatch(
        deleteModuleAction({
          version: version,
          id: id,
          moduleId,
        })
      );
    },
    [dispatch, id, version]
  );

  const editModule = useCallback(
    (moduleId: string, payload: ModuleBody) => {
      dispatch(
        editModuleAction({
          version: version,
          id: id,
          moduleId,
          payload,
        })
      );
    },
    [dispatch, id, version]
  );

  const getModules = useCallback(() => {
    dispatch(
      getAllModulesAction({
        version: version,
        id: id,
      })
    );
  }, [dispatch]);

  return {
    createModules,
    isLoading,
    modules,
    modulesNames,
    moduleOrderQueue,
    getModules,
    createModule,
    setModulesOrder,
    deleteModule,
    editModule,
    modulesDataObject,
    enrichedDataObject,
    thirdPartyDataObject,
  };
};

export const useRule = () => {
  const isLoading = useRuleLoading();
  const dispatch = useAppDispatch();
  const { id, version } = useProductData();
  const creatRule = useCallback(
    (moduleId: string, data: RulePayload) => {
      dispatch(
        createModuleRuleAction({
          version: version,
          id: id,
          moduleId,
          data,
        })
      );
    },
    [dispatch, id, version]
  );
  const editRule = useCallback(
    (moduleId: string, ruleId: string, data: RulePayload) => {
      dispatch(
        editModuleRuleAction({
          version: version,
          id: id,
          moduleId,
          ruleId,
          data,
        })
      );
    },
    [dispatch, id, version]
  );

  const deleteRule = useCallback(
    (moduleId: string, ruleId: string) => {
      dispatch(
        deleteModuleRuleAction({
          version: version,
          id: id,
          moduleId,
          ruleId,
        })
      );
    },
    [dispatch, id, version]
  );
  return { isLoading, editRule, creatRule, deleteRule };
};

const useIntegrations = () => useAppSelector(getTemplatesIntegrations);
const useEnrichment = () => useAppSelector(getTemplatesEnrichment);
const useTemplatesLoading = () => useAppSelector(getTemplatesLoading);

export const useTemplates = () => {
  const dispatch = useAppDispatch();
  const { id, version } = useProductData();
  const integrations = useIntegrations();
  const enrichment = useEnrichment();
  const isLoading = useTemplatesLoading();
  const getTemplates = useCallback(() => {
    dispatch(
      getModulesTemplatesAction({
        CategoryId: "asd",
        Limit: 100,
        Offset: 0,
        TenantId: "Asds",
      })
    );
  }, [dispatch, id, version]);
  return { getTemplates, integrations, enrichment, isLoading };
};
