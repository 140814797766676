import React, { useState, ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Box,
  IconButton,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { Toaster } from "shared/ui/Toast/Toast";
import OutlinedCheckbox from "../../FormComponents/OutlinedCheckbox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";

import Button from "shared/ui/Button";
import Label from "../../FormComponents/Label";
import CustomSelect from "shared/ui/Select";
import DatePicker from "shared/ui/DatePicker";
import TextArea from "../../FormComponents/Textarea";
import FormGroup from "../../FormComponents/FormGroup";
import { useKpis } from "features/kpis/model/hooks/useKpis";
import { getCurrentKpiItem } from "entities/kpis";
import { useAppSelector } from "redux/store/hooks/hooks";

import {
  Wrapper,
  StyledTypography,
  ButtonWrapper,
  StyledDialog,
  StyledActions,
  StyledTitle,
  List,
} from "./styles";

// actions
import {
  programsActions,
  getProgram,
  getProducts,
  unlinkProductFromProgramAction,
  updateProgramStatusAction,
} from "entities/programs";

import { ProductResponse } from "shared/api/programs/types";

interface CreateProgromProps {
  open: boolean;
  edit?: boolean;
  handleClose: () => void;
}
interface ErrorsProps {
  name?: boolean;
  description?: boolean;
  startDate?: boolean;
  endDate?: boolean;
  budget?: boolean;
  budgetType?: boolean;
  budgetDuration?: boolean;
  currency?: boolean;
  pacing?: boolean;
}

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  sidepadding: string;
  closeIconRightSpacing: number;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, closeIconRightSpacing, ...other } = props;

  return (
    <StyledTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: closeIconRightSpacing,
            top: 25,
            color: "var(--black)",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyledTitle>
  );
};

const CreateProgram = ({ open, handleClose, edit }: CreateProgromProps) => {
  const [name, setName] = useState("");
  const [programkpi, setProgramkpi] = useState<number | undefined>(undefined);
  const [description, setDescription] = useState("");
  const [budget, setBudget] = useState<number | undefined>(undefined);
  const [budgetType, setBudgetType] = useState("");
  const [budgetDuration, setBudgetDuration] = useState("");
  const [currency, setCurrency] = useState("");
  const [pacing, setPacing] = useState("");
  const [startDate, setStartDate] = useState<Dayjs | null>();
  const [endDate, setEndDate] = useState<Dayjs | null>();
  const [created, setCreated] = useState(false);
  const [errors, setErrors] = useState<ErrorsProps>({});
  const [status, setStatus] = useState("");
  const [showLinks, setShowLinks] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const programKpi = useAppSelector(getCurrentKpiItem);

  const program = useSelector(getProgram);
  const products = useSelector(getProducts);

  const { getKpiItem, pushKpi } = useKpis();

  useEffect(() => {
    if (program.id) {
      if (edit) {
        setName(program.name);
        setStartDate(dayjs(program.startDate));
        if (program?.endDate) setEndDate(dayjs(program.endDate));
        setBudget(program.budget);
        setBudgetType(program.budgetType);
        setBudgetDuration(program.budgetDuration);
        setCurrency(program.currency);
        setPacing(program.pacing);
        setStatus(program.status);
        getKpi(program.id);
        setDescription(program.description);
        setIsActive(program.isActive);
      } else {
        setCreated(true);
      }
    }
  }, [program]);

  useEffect(() => {
    if (program?.id && programKpi?.value) {
      setProgramkpi(programKpi.value);
    }
    if (programKpi && !programKpi.value) setProgramkpi(undefined);
  }, [programKpi]);

  const getKpi = (id: string) => {
    const payload = {
      EntityIds: [id],
      KPIType: "Program",
    };
    getKpiItem(payload);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    action: any,
    id: string
  ) => {
    const {
      target: { value },
    } = event;

    setErrors({
      ...errors,
      [id]: false,
    });
    if (id === "description") {
      if (value.length <= 250) action(value);
    } else if (id === "programkpi" || id === "budget") {
      if (!isNaN(Number(value))) action(Number(value));
    } else {
      action(value);
    }
  };

  const handleDateChange = (value: any, action: any, id: string) => {
    setErrors({
      ...errors,
      [id]: false,
    });
    action(value);
  };

  const handleSubmit = () => {
    const valid = validateForm();

    if (valid) {
      dispatch({
        type: programsActions.CREATE_PROGRAM,
        data: programPayload(),
        callback,
      });
    }
  };

  const callback = (data: any) => {
    if (data.id && programkpi) {
      const payload = kpiPayload(data.id, Number(programkpi));
      pushKpi(payload);
    }
    if (data.id && isActive) {
      toasterMessage();
    }
  };

  const toasterMessage = () => {
    Toaster.infoMessage(
      `Program will be activated on ${startDate?.format(
        "YYYY-MM-DD"
      )} which was chosen`
    );
  };

  const programPayload = () => {
    const payload = {
      name,
      description,
      budget,
      budgetType,
      budgetDuration,
      currency,
      pacing,
      startDate: startDate?.format("YYYY-MM-DD"),
      endDate: endDate?.format("YYYY-MM-DD") || null,
      status,
      isActive,
    };
    return payload;
  };

  const validateForm = () => {
    const err: ErrorsProps = {};
    if (!name) err.name = true;
    if (!description) err.description = true;
    if (!startDate) err.startDate = true;
    if (!budget) err.budget = true;
    if (!budgetType) err.budgetType = true;
    if (!budgetDuration) err.budgetDuration = true;
    if (!currency) err.currency = true;
    if (!pacing) err.pacing = true;

    if (Object.keys(err).length) {
      setErrors({ ...errors, ...err });
      return false;
    }
    return true;
  };

  const checkIfChanged = () => {
    if (
      name === program.name &&
      budget === program.budget &&
      budgetType === program.budgetType &&
      budgetDuration === program.budgetDuration &&
      currency === program.currency &&
      pacing === program.pacing &&
      description === program.description &&
      startDate?.format("YYYY-MM-DD") ===
        dayjs(program.startDate).format("YYYY-MM-DD") &&
      endDate?.format("YYYY-MM-DD") ===
        dayjs(program.endDate).format("YYYY-MM-DD")
    ) {
      return false;
    } else {
      return true;
    }
  };

  const updateProgram = () => {
    const changed = checkIfChanged();
    const valid = validateForm();

    if (status === "Active" && edit && valid) {
      updateActiveProgram();
      return;
    }
    if (program.isActive != isActive && !changed) {
      updateProgramStatus(program.id);
    }
    if (changed && valid) {
      dispatch({
        type: programsActions.UPDATE_PROGRAM,
        data: {
          id: program.id,
          data: programPayload(),
        },
        callback: (data: any) => handlePostUpdate(data),
      });
    }
    if (program && programkpi && programKpi.value !== programkpi) {
      updateProgramKpi(program.id);
    }
    handleClose();
  };

  const statusCallback = () => {
    if (isActive) toasterMessage();
  };

  const updateProgramStatus = (id: string) => {
    if (program.isActive === isActive) return;
    dispatch(
      updateProgramStatusAction({
        programId: id,
        isActive,
        cb: () => statusCallback(),
      })
    );
    handleClose();
  };

  const updateActiveProgram = () => {
    const changed = checkIfChanged();
    if (changed) {
      updateProgramEndDate();
    }
    if (program.isActive != isActive) {
      updateProgramStatus(program.id);
      return;
    }
    handleClose();
  };

  // If program is active, then only endDate can be changed.
  const updateProgramEndDate = () => {
    dispatch({
      type: programsActions.UPDATE_PROGRAM,
      data: {
        id: program.id,
        data: {
          endDate: endDate?.format("YYYY-MM-DD"),
        },
      },
      callback: () => handleClose(),
    });
  };

  const handlePostUpdate = (data: any) => {
    updateProgramStatus(program.id);

    if (data.id && programkpi && programKpi.value !== programkpi) {
      updateProgramKpi(data.id);
    }
  };

  const updateProgramKpi = (id: string) => {
    const payload = kpiPayload(id, Number(programkpi));
    pushKpi(payload);
  };

  const kpiPayload = (programId: string, value: number) => {
    const payload = {
      entityId: programId,
      kpiType: "Program",
      value: value,
    };
    return payload;
  };

  const renderProductActions = () => (
    <>
      {edit && !showLinks && (
        <>
          <Button onClick={() => updateProgram()} variant="outlined">
            {"Save"}
          </Button>
          <Button onClick={() => setShowLinks(true)} variant="contained">
            {"Link Product"}
          </Button>
        </>
      )}
      {edit && showLinks && renderLinkProductActions()}
      {!edit && renderLinkProductActions()}
    </>
  );

  const renderLinkProductActions = () => (
    <>
      <Button onClick={() => navigate("/products/list")} variant="outlined">
        {"Select Existing Products"}
      </Button>
      <Button
        onClick={() => navigate("/create_new_product")}
        variant="contained"
      >
        {"Create New Product"}
      </Button>
    </>
  );

  const onProgramUnlink = (product: ProductResponse) => {
    dispatch(
      unlinkProductFromProgramAction({
        programId: program.id,
        productIds: [product.productId],
      })
    );
  };

  const title = created ? "Create a Product" : "New Program";

  return (
    <StyledDialog onClose={handleClose} open={open}>
      <BootstrapDialogTitle
        id={"request-title"}
        onClose={handleClose}
        sidepadding={"50"}
        closeIconRightSpacing={47}
      >
        {edit ? "Edit Program" : title}
      </BootstrapDialogTitle>
      <Wrapper data-testid="create-program-modal">
        {!created ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: "center", marginTop: "0" }}
            >
              <Grid item xs={10}>
                <FormGroup
                  value={name}
                  id="name"
                  onChange={(e) => handleChange(e, setName, "name")}
                  label="Program Name"
                  error={errors?.name}
                  disabled={status === "Active" && edit}
                  danger
                />
              </Grid>
              {products.length ? (
                <Grid item xs={10}>
                  <Label label="Products" />
                  <List>
                    {products.map((item: ProductResponse) => (
                      <li key={item.productId}>
                        {item.productName}
                        <IconButton
                          aria-label="remove"
                          onClick={() => onProgramUnlink(item)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </li>
                    ))}
                  </List>
                </Grid>
              ) : null}
              <Grid item xs={10}>
                <FormGroup
                  value={programkpi}
                  id="programkpi"
                  onChange={(e) => handleChange(e, setProgramkpi, "programkpi")}
                  label="Add KPI"
                  disabled={status === "Active" && edit}
                />
              </Grid>
              <Grid item xs={5}>
                <Label label="Start Date" danger />
                <DatePicker
                  onChange={(e) =>
                    handleDateChange(e, setStartDate, "startDate")
                  }
                  disablePast={!edit}
                  value={startDate}
                  error={errors?.startDate}
                  disabled={status === "Active" && edit}
                />
              </Grid>
              <Grid item xs={5}>
                <Label label="End Date" />
                <DatePicker
                  onChange={(e) => handleDateChange(e, setEndDate, "endDate")}
                  disablePast={true}
                  value={endDate}
                  error={errors?.endDate}
                />
              </Grid>
              {startDate && (
                <Grid item xs={10}>
                  <FormControl
                    fullWidth
                    disabled={false}
                    sx={{
                      display: "flex",
                      paddingLeft: "3px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <OutlinedCheckbox
                          checked={isActive}
                          name="isActive"
                          onChange={() => setIsActive(!isActive)}
                        />
                      }
                      label="Active"
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={5}>
                <FormGroup
                  value={budget}
                  id="budget"
                  onChange={(e) => handleChange(e, setBudget, "budget")}
                  label="Budget"
                  error={errors?.budget}
                  disabled={status === "Active" && edit}
                  danger
                />
              </Grid>
              <Grid item xs={5}>
                <Label label="Budget Type" danger />
                <CustomSelect
                  value={budgetType}
                  id="budgetType"
                  options={["Money", "Impressions"]}
                  onChange={(e) => handleChange(e, setBudgetType, "budgetType")}
                  error={errors?.budgetType}
                  disabled={status === "Active" && edit}
                />
              </Grid>
              <Grid item xs={10}>
                <Label label="Budget Duration" danger />
                <CustomSelect
                  value={budgetDuration}
                  id="budgetDuration"
                  options={["Range", "Daily", "Monthly", "Quarterly", "Yearly"]}
                  onChange={(e) =>
                    handleChange(e, setBudgetDuration, "budgetDuration")
                  }
                  error={errors?.budgetDuration}
                  disabled={status === "Active" && edit}
                />
              </Grid>
              <Grid item xs={5}>
                <Label label="Currency" danger />
                <CustomSelect
                  value={currency}
                  id="currency"
                  options={["USD", "CAD", "EUR"]}
                  onChange={(e) => handleChange(e, setCurrency, "currency")}
                  error={errors?.currency}
                  disabled={status === "Active" && edit}
                />
              </Grid>
              <Grid item xs={5}>
                <Label label="Pacing" danger />
                <CustomSelect
                  value={pacing}
                  id="pacing"
                  options={["Even", "Ahead", "Behind", "Fastest"]}
                  onChange={(e) => handleChange(e, setPacing, "pacing")}
                  error={errors?.pacing}
                  disabled={status === "Active" && edit}
                />
              </Grid>
              <Grid item xs={10}>
                <Label label={"Description"} danger />
                <TextArea
                  value={description}
                  id="description"
                  onChange={(e) =>
                    handleChange(e, setDescription, "description")
                  }
                  placeholder="Enter a Description for your Program"
                  minRows={4}
                  error={errors?.description}
                  disabled={status === "Active" && edit}
                />
                <Box sx={{ textAlign: "right", marginTop: "6px" }}>
                  <StyledTypography className="warnings">
                    {description?.length}/250 characters
                  </StyledTypography>
                </Box>
              </Grid>
            </Grid>
          </LocalizationProvider>
        ) : (
          <>
            <StyledTypography className="created">
              <InfoOutlinedIcon />
              Congrats! Your Program has been created successfully.
            </StyledTypography>
            <StyledTypography className="centered">
              {`Now, let’s create a product for ${name}`}
            </StyledTypography>
            <ButtonWrapper>{renderProductActions()}</ButtonWrapper>
          </>
        )}
      </Wrapper>
      <StyledActions centered={1}>
        {edit ? (
          renderProductActions()
        ) : (
          <>
            <Button onClick={handleClose} variant="outlined">
              {"Cancel"}
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              {"Create"}
            </Button>
          </>
        )}
      </StyledActions>
    </StyledDialog>
  );
};

export default CreateProgram;
