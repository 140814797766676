import { PlatformNotificationsRespose } from "./types";
import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";

export default class PlatformNotificationsApi {
  static async getPlatformNotifications(
    params: any
  ): Promise<AxiosResponse<PlatformNotificationsRespose>> {
    return IXPortalApiPrivate.get("/api/PlatformNotifications", params);
  }

  static async getPlatformNotificationsCount(): Promise<
    AxiosResponse<PlatformNotificationsRespose>
  > {
    return IXPortalApiPrivate.get(
      "/api/PlatformNotifications/Count?isRead=false"
    );
  }

  static async getPlatformNotification(
    id: string
  ): Promise<AxiosResponse<PlatformNotificationsRespose>> {
    return IXPortalApiPrivate.get(`/api/PlatformNotifications/${id}`);
  }

  static async deletePlatformNotification(
    id: string
  ): Promise<AxiosResponse<PlatformNotificationsRespose>> {
    return IXPortalApiPrivate.delete(`/api/PlatformNotifications/${id}`);
  }

  static async readPlatformNotification(
    id: string
  ): Promise<AxiosResponse<PlatformNotificationsRespose>> {
    return IXPortalApiPrivate.patch(`/api/PlatformNotifications/${id}`);
  }

  static async getAlertNotifications(): Promise<
    AxiosResponse<PlatformNotificationsRespose>
  > {
    return IXPortalApiPrivate.get(
      "/api/PlatformNotifications?NotificationType=Alert&isRead=false"
    );
  }
}
