import { put, takeEvery } from "redux-saga/effects";
import { openModal, closeModal } from "entities/modals/model/slice/modalsSlice";
import { modalsActions } from "entities/modals";

export function* openModalWorker(payload: any) {
  try {
    yield put(openModal(payload.data));
  } catch (e) {
    yield put({ type: "OPEN_MODAL_FAILED" });
  }
}

export function* closeModalWorker(payload: any) {
  try {
    yield put(closeModal(payload.data));
  } catch (e) {
    yield put({ type: "CLOSE_MODAL_FAILED" });
  }
}

export function* ModalsWatcher() {
  yield takeEvery(modalsActions.OPEN_MODAL, openModalWorker);
  yield takeEvery(modalsActions.CLOSE_MODAL, closeModalWorker);
}
