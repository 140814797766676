import { ProductCategoriesResponse, ProductCategoryResponse } from "./types";
import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";

export default class ProductCategoriesApi {
  static async getProductCategories(): Promise<
    AxiosResponse<ProductCategoriesResponse>
  > {
    return IXPortalApiPrivate.get("/api/ProductCategories");
  }

  static async getProductCategory(
    id: string
  ): Promise<AxiosResponse<ProductCategoryResponse>> {
    return IXPortalApiPrivate.get(`/api/ProductCategories/${id}`);
  }
}
