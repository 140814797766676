import { styled } from "@mui/system";
import { Box, Skeleton } from "@mui/material";

export const LoaderContainer = styled(Box)({
  padding: 5,
});

export const StyledSkeleton = styled(Skeleton)<{ lineHeight: string }>`
  height: ${(props) => props.lineHeight};
`;
