import { createSlice } from "@reduxjs/toolkit";
import {
  RequestDetailSchema,
  CommentSchema,
  RequestType,
  RequestSchema,
} from "shared/api/requests/types";

export const requestsSlice = createSlice({
  name: "requests",
  initialState: {
    types: <RequestType[]>[],
    new: <RequestSchema[]>[],
    completed: <RequestSchema[]>[],
    archived: <RequestSchema[]>[],
    totalCount: 0,
    request: <RequestDetailSchema>{},
    comments: <CommentSchema[]>[],
    newPendingCount: 0,
    newCount: 0,
    completedCount: 0,
    archivedCount: 0,
    requestDeleted: <RequestSchema>{},
  },
  reducers: {
    setRequestToDeleteSlice: (state, action) => {
      return {
        ...state,
        requestDeleted: action.payload,
      };
    },
    clearupDeleteRequestSlice: (state, action) => {
      return {
        ...state,
        requestDeleted: action.payload,
      };
    },
    increaseNewPendingCountSlice: (state, action) => {
      return {
        ...state,
        newPendingCount: action.payload + state.newPendingCount,
      };
    },
    decreaseNewPendingCountSlice: (state, action) => {
      return {
        ...state,
        newPendingCount: state.newPendingCount - action.payload,
      };
    },
    loadNewPendingCountSlice: (state, action) => {
      return {
        ...state,
        newPendingCount: action.payload.count,
      };
    },
    loadNewCountSlice: (state, action) => {
      return {
        ...state,
        newCount: action.payload.count,
      };
    },
    loadCompletedCountSlice: (state, action) => {
      return {
        ...state,
        completedCount: action.payload.count,
      };
    },
    getRequestsSlice: (state, action) => {
      return {
        ...state,
        new: action.payload.items,
        totalCount: action.payload.totalCount,
      };
    },
    getCompletedSlice: (state, action) => {
      return {
        ...state,
        completed: action.payload.items,
      };
    },
    getArchivedSlice: (state, action) => {
      return {
        ...state,
        archived: action.payload.items,
        archivedCount: action.payload.totalCount,
      };
    },
    getRequestSlice: (state, action) => {
      return {
        ...state,
        request: action.payload,
      };
    },
    loadRequestComments: (state, action) => {
      return {
        ...state,
        comments: action.payload.requestComments,
      };
    },
    loadRequestTypes: (state, action) => {
      return {
        ...state,
        types: action.payload.requestTypes,
      };
    },
    addRequestComment: (state, action) => {
      return {
        ...state,
        comments: [...state.comments, action.payload],
      };
    },
  },
});

export const {
  getRequestsSlice,
  getRequestSlice,
  loadRequestComments,
  addRequestComment,
  loadRequestTypes,
  loadNewCountSlice,
  loadNewPendingCountSlice,
  loadCompletedCountSlice,
  getCompletedSlice,
  getArchivedSlice,
  increaseNewPendingCountSlice,
  decreaseNewPendingCountSlice,
  setRequestToDeleteSlice,
  clearupDeleteRequestSlice,
} = requestsSlice.actions;
