import React, { memo, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/store/hooks/hooks";
import {
  AttributesPayloadWithRoot,
  getProduct,
  getProductAttributesNames,
} from "../../model/selectors";
import { MultipleSelectChip } from "shared/ui/MultipleSelectChip";
import SelectAllButton from "shared/ui/SelectAllButton";
import {
  ButtonWrapper,
  ErrorWrapper,
  SelectWrapper,
  StyledWrapper,
} from "./styles";
import {
  getAttributesLoading,
  updateModulesAttributes,
} from "../../../modules";
import { Spinner } from "shared/ui/Spinner";
import { Toaster } from "shared/ui/Toast/Toast";
import { FormattedAttributePayload } from "shared/api/products/types";
import {
  buildResponse,
  defaultSelectedAttributes,
  filterByLabel,
  getAllItems,
} from "./helpers";

interface Props {
  responseAttributes: FormattedAttributePayload[];
  moduleId: string;
  resultEmptyAttributesError: boolean;
}

export const DataAttributesList = memo(function DataAttributesList({
  moduleId,
  responseAttributes,
  resultEmptyAttributesError,
}: Props) {
  const dispatch = useAppDispatch();
  const { id, version } = useAppSelector(getProduct);
  const isLoading = useAppSelector(getAttributesLoading);
  const attributes: AttributesPayloadWithRoot[] = useAppSelector(
    getProductAttributesNames
  );
  const [selected, setSelected] = useState<AttributesPayloadWithRoot[]>(() =>
    defaultSelectedAttributes(responseAttributes)
  );

  const handleSelect = (attribute: AttributesPayloadWithRoot) => {
    const existedItem = selected.find(
      (item) =>
        item.name === attribute.name &&
        item.parentConcat === attribute.parentConcat
    );
    if (attribute.subAttributes.length > 0) {
      const newArray = [] as AttributesPayloadWithRoot[];
      const newAttributesData = filterByLabel(attribute, newArray);
      if (!existedItem) {
        setSelected((prevState) => [...prevState, ...newAttributesData]);
      } else {
        const filterState = (item: any) => {
          return newAttributesData.every(
            (attr) =>
              item.name !== attr.name ||
              (item.name === attr.name &&
                item.parentConcat !== attr.parentConcat)
          );
        };
        setSelected((prevState) => prevState.filter(filterState));
      }
    } else if (existedItem) {
      setSelected((prevState) =>
        prevState.filter(
          (item) =>
            item.name !== attribute.name ||
            (item.name === attribute.name &&
              item.parentConcat !== attribute.parentConcat)
        )
      );
    } else {
      setSelected((prevState) => [...prevState, attribute]);
    }
  };
  const handleDelete = (attribute: AttributesPayloadWithRoot) => {
    if (attribute.subAttributes.length > 0) {
      const newArray = [] as AttributesPayloadWithRoot[];
      const newAttributesData = filterByLabel(attribute, newArray);
      const filterState = (item: any) =>
        newAttributesData.every(
          (attr) =>
            item.name !== attr.name ||
            (item.name === attr.name && item.parentConcat !== attr.parentConcat)
        );
      setSelected((prevState) => prevState.filter(filterState));
    } else {
      setSelected((prevState) =>
        prevState.filter(
          (item) =>
            item.name !== attribute.name ||
            (item.name === attribute.name &&
              item.parentConcat !== attribute.parentConcat)
        )
      );
    }
  };

  useEffect(() => {
    if (selected.length) {
      for (const attribute of selected) {
        if (attribute && attribute.fillmentType === "PostfillmentSensitive") {
          Toaster.error(
            "Sensitive data can’t be added as part of the Result Module"
          );
          handleDelete(attribute);
        }
      }
    }
  }, [selected]);

  const handleSelectAllClick = () => {
    const newArray = [] as AttributesPayloadWithRoot[];
    attributes.forEach((item) => {
      filterByLabel(item, newArray);
    });
    if (selected.length === newArray.length) {
      setSelected([]);
    } else {
      setSelected(newArray);
    }
  };

  const handleSave = () => {
    const items = getAllItems(attributes);

    const formattedSelect = selected.reduce<Record<string, string>>(
      (accum, item) => {
        if (item.parentConcat) {
          accum[item.parentConcat + "." + item.name] = "true";
        } else {
          accum[item.name] = "true";
        }
        return accum;
      },
      {}
    );
    const requestData = buildResponse(formattedSelect, items);
    dispatch(
      updateModulesAttributes({
        attributes: requestData,
        version,
        moduleId,
        id,
      })
    );
  };

  return (
    <StyledWrapper>
      {isLoading && <Spinner />}
      {resultEmptyAttributesError && (
        <ErrorWrapper>
          Result module have no attributes configured. Please add at least one
          attribute
        </ErrorWrapper>
      )}
      <SelectWrapper>
        <MultipleSelectChip
          data={attributes}
          selected={selected}
          value={selected}
          handleChange={handleSelect}
          deleteHandler={handleDelete}
          error={resultEmptyAttributesError}
        />
      </SelectWrapper>
      <ButtonWrapper>
        <SelectAllButton
          text={
            selected.length === Object.keys(attributes).length
              ? "Clear All"
              : "Select All"
          }
          onClick={handleSelectAllClick}
          sx={{
            marginBottom: "0px",
            marginLeft: "10px",
            minWidth: "100px",
          }}
        />
        <SelectAllButton
          text={"Save"}
          onClick={handleSave}
          sx={{
            marginBottom: "0px",
            marginLeft: "10px",
            minWidth: "100px",
          }}
        />
      </ButtonWrapper>
    </StyledWrapper>
  );
});
