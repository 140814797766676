import React, { FC, useCallback, useState } from "react";
import { Menu } from "@mui/material";
import { IconWrap, MenuWrapper, SortLabel } from "./styles";

import MultiSelect from "shared/ui/MultiSelect";
import FilterSelect from "shared/ui/FilterSelect";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";

import { Column } from "shared/config/types/Table";
import FilterButton from "shared/ui/FilterButton/FilterButton";
import { SORT } from "shared/config/enums/Sorting";
import { OrderTypes } from "shared/config/types/Sorting";

interface Props {
  headCell: Column;
  filterParams?: any;
  setFilterParams?: (params: any) => void;
  setSortOrder?: (params: any) => void;
}

const sortOrders: OrderTypes[] = [
  {
    type: SORT.ASC,
  },
  {
    type: SORT.DESC,
  },
];

export const FilterTableModal: FC<Props> = ({
  headCell,
  filterParams,
  setFilterParams,
  setSortOrder,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [order, setOrder] = useState<OrderTypes | null>(null);
  const [isApplied, setIsApplied] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleOrder = useCallback(
    (sortOrder: OrderTypes) => () => {
      if (setSortOrder) setSortOrder(sortOrder);
      setOrder(sortOrder);
    },
    []
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderFilters = (headCell: any) => {
    switch (headCell.id) {
      case "username": {
        const labels = ["First Name", "Last Name"];
        const customSortOrders: OrderTypes[] = [
          {
            type: SORT.ASC,
            label: labels[0],
          },
          {
            type: SORT.DESC,
            label: labels[0],
          },
          {
            type: SORT.ASC,
            label: labels[1],
          },
          {
            type: SORT.DESC,
            label: labels[1],
          },
        ];

        return (
          <MenuWrapper data-testid="filterModal_menu">
            {customSortOrders.map((item: OrderTypes) => (
              <FilterButton
                key={`${item.type}_${item.label}`}
                type={item.type}
                label={item.label}
                setOrder={handleOrder({ type: item.type, label: item.label })}
                order={order}
              />
            ))}
          </MenuWrapper>
        );
      }
      case "provider":
      case "productName":
      case "requestType":
      case "requestTypeName":
      case "name":
      case "id":
        return (
          <MenuWrapper data-testid="filterModal_menu">
            {sortOrders.map((item: OrderTypes) => (
              <FilterButton
                key={`${item.type}_${item.label}`}
                type={item.type}
                setOrder={handleOrder({ type: item.type, label: headCell.id })}
                order={order}
              />
            ))}
          </MenuWrapper>
        );
      case "status": {
        const options = ["New", "InProgress", "Pending"];
        return (
          <MenuWrapper data-testid="filterModal_menu">
            <MultiSelect
              options={options}
              handleClose={handleClose}
              filterKey={headCell.id}
              id={headCell.id}
              filterParams={filterParams}
              setApplied={setIsApplied}
              setFilterParams={setFilterParams}
            />
          </MenuWrapper>
        );
      }
      case "requestStatus": {
        const options = filterParams.states;
        return (
          <MenuWrapper data-testid="filterModal_menu">
            <MultiSelect
              options={options}
              handleClose={handleClose}
              filterKey={headCell.id}
              id={headCell.id}
              filterParams={filterParams}
              setApplied={setIsApplied}
              setFilterParams={setFilterParams}
            />
          </MenuWrapper>
        );
      }
      case "notificationType": {
        const options = ["Alert", "General", "Emergency", "Scheduled"];
        return (
          <MenuWrapper data-testid="filterModal_menu">
            <FilterSelect
              options={options}
              handleClose={handleClose}
              filterKey={headCell.id}
              id={headCell.id}
              filterParams={filterParams}
              setApplied={setIsApplied}
              setFilterParams={setFilterParams}
            />
          </MenuWrapper>
        );
      }
    }
  };

  return (
    <div data-testid="filterModal">
      <SortLabel data-testid="filterModal_label">
        {headCell.label}
        <IconWrap
          key={headCell.id}
          open={open}
          applied={isApplied}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <FilterIcon />
        </IconWrap>
      </SortLabel>
      <Menu
        keepMounted={true}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        data-testid="filterModal_menu"
      >
        {renderFilters(headCell)}
      </Menu>
    </div>
  );
};
