import { takeLatest, put, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import {
  programsActions,
  programActions,
  unlinkProductFromProgramAction,
  updateProgramStatusAction,
} from "entities/programs";

import { ReduxAction } from "redux/sagas/types";
import { alertActions } from "entities/alerts";

import ProgramsApi from "shared/api/programs/programsApi";
import {
  ProgramsResponse,
  CreateProgramResponse,
  ProgramFullResponse,
} from "shared/api/programs/types";

import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

import historyBr from "../../../../history-br";
import { productActions } from "entities/products";

export function* getProgramsWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<ProgramsResponse> = yield call(
      ProgramsApi.getPrograms,
      action.data
    );
    yield put(programActions.loadPrograms(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}
export function* getProductProgramWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<ProgramsResponse> = yield call(
      ProgramsApi.getPrograms,
      action.data
    );
    yield put(productActions.loadProductProgram(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* createProgramWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<CreateProgramResponse> = yield call(
      ProgramsApi.createProgram,
      action.data
    );
    yield put(programActions.loadProgram(response.data));
    yield put({
      type: programsActions.GET_PROGRAMS,
      data: {
        params: {
          ShowArchived: false,
          limit: 10,
          offset: 0,
        },
      },
    });
    action.callback(response.data);
  } catch (e) {
    sagaErrorBoundary(e);
  }
}
export function* updateProgramWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<CreateProgramResponse> = yield call(
      ProgramsApi.updateProgram,
      action.data
    );
    yield put(programActions.loadProgram(response.data));
    action.callback(response.data);
  } catch (e) {
    sagaErrorBoundary(e);
  }
}
export function* linkProductsToProgramWorker(action: ReduxAction) {
  try {
    yield call(ProgramsApi.linkProductsToProgram, action.data);
    yield put(programActions.loadProgram({}));
    yield call(historyBr.push as any, "/programs/list");
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* updateProgramStatusWorker(
  action: ReturnType<typeof updateProgramStatusAction>
) {
  try {
    const response: AxiosResponse<CreateProgramResponse> = yield call(
      ProgramsApi.updateProgramStatus,
      action.payload
    );
    if (response) {
      yield put({
        type: programsActions.GET_PROGRAMS,
        data: {
          params: {
            ShowArchived: false,
            limit: 10,
            offset: 0,
          },
        },
      });
      if (response.data?.id && action.payload.cb) action.payload.cb();
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* unLinkProductsFromProgram(
  action: ReturnType<typeof unlinkProductFromProgramAction>
) {
  try {
    const response: AxiosResponse<CreateProgramResponse> = yield call(
      ProgramsApi.unlinkProductsToProgram,
      action.payload
    );
    if (response.data?.id) {
      if (action.payload.cb) action.payload.cb();
      yield put(programActions.deleteProducts(action.payload.productIds));
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
}
export function* getProgramWorker(action: ReduxAction) {
  try {
    yield put(programActions.setProgramLoading());
    const response: AxiosResponse<ProgramFullResponse> = yield call(
      ProgramsApi.getProgram,
      action.data
    );
    yield put({
      type: programsActions.GET_PROGRAM_PRODUCTS,
      data: action.data,
    });
    yield put(programActions.loadProgram(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}
export function* getProgramProductsListWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<ProgramFullResponse> = yield call(
      ProgramsApi.getProgramProducts,
      action.data
    );
    yield put(programActions.loadProducts(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}
export function* deleteProgramWorker(action: ReduxAction) {
  try {
    yield call(ProgramsApi.deleteProgram, action.data);
    yield put({
      type: programsActions.GET_PROGRAMS,
      data: {
        params: {
          ShowArchived: false,
          limit: 10,
          offset: 0,
        },
      },
    });
    if (action.data.callback) {
      action.data.callback();
    }
    yield put({
      type: alertActions.SHOW_ALERT,
      data: {
        type: "deleteProgram",
        data: { name: action.data.name },
      },
    });
  } catch (e) {
    sagaErrorBoundary(e);
  }
}
export function* undeleteProgramWorker(action: ReduxAction) {
  try {
    yield call(ProgramsApi.undeleteProgram, action.data);
    yield put({
      type: programsActions.GET_PROGRAMS,
      data: {
        params: {
          ShowArchived: true,
          limit: 10,
          offset: 0,
        },
      },
    });
    yield put({
      type: alertActions.SHOW_ALERT,
      data: {
        type: "programRestored",
        data: { name: action.data.name },
      },
    });
  } catch (e) {
    sagaErrorBoundary(e);
  }
}
export function* cloneProgramWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<CreateProgramResponse> = yield call(
      ProgramsApi.cloneProgram,
      action.data
    );
    yield put({
      type: programsActions.GET_PROGRAM,
      data: response.data,
    });
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* ProgramsWatcher() {
  yield takeLatest(programsActions.GET_PROGRAMS, getProgramsWorker);
  yield takeLatest(programsActions.CREATE_PROGRAM, createProgramWorker);
  yield takeLatest(
    programsActions.LINK_PRODUCTS_TO_PROGRAM,
    linkProductsToProgramWorker
  );
  yield takeLatest(programsActions.GET_PROGRAM, getProgramWorker);
  yield takeLatest(programsActions.UPDATE_PROGRAM, updateProgramWorker);
  yield takeLatest(
    programsActions.GET_PROGRAM_PRODUCTS,
    getProgramProductsListWorker
  );
  yield takeLatest(
    programsActions.GET_PRODUCT_PROGRAM,
    getProductProgramWorker
  );
  yield takeLatest(unlinkProductFromProgramAction, unLinkProductsFromProgram);
  yield takeLatest(updateProgramStatusAction, updateProgramStatusWorker);
  yield takeLatest(programsActions.DELETE_PROGRAM, deleteProgramWorker);
  yield takeLatest(programsActions.UNDELETE_PROGRAM, undeleteProgramWorker);
  yield takeLatest(programsActions.CLONE_PROGRAM, cloneProgramWorker);
}
