import React, { FC, ReactElement, useCallback, useState } from "react";
import { SideModal } from "../styles";
import { Application } from "shared/api/widgets/types";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getActiveWidgetsProductIdsById } from "../../model/selectors/getWidgets";
import WidgetConfigModal from "./WidgetConfigModal";
import { Box } from "@mui/material";

interface Props {
  widgetId: string;
  productId: string;
  children: ReactElement;
  name: string;
  distributions: Application[];
}
export const WidgetConfig: FC<Props> = ({
  productId,
  widgetId,
  children,
  name,
  distributions,
}) => {
  const activeWidget = useAppSelector(
    getActiveWidgetsProductIdsById(widgetId, productId)
  );
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div>
      {React.cloneElement(children, {
        onClick: openModal,
        disabled: !activeWidget || distributions.length <= 0,
      })}
      {open && (
        <SideModal
          open={open}
          onClose={handleClose}
          id={"modal"}
          hideBackdrop={false}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflow: "hidden",
              overflowY: "scroll",
            }}
          >
            <WidgetConfigModal
              widgetId={widgetId}
              distributions={distributions}
              handleClose={handleClose}
              productId={productId}
              name={name}
            />
          </Box>
        </SideModal>
      )}
    </div>
  );
};
