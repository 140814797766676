import { put, takeLatest } from "redux-saga/effects";

import { alertActions } from "entities/alerts";
import { productsActions } from "entities/products";
import { productCategoriesActions } from "entities/productCategories";
import { moduleTemplatesActions } from "entities/moduleTemplates";
import { usersActions } from "entities/users";

export const actionsList = [
  productsActions.GET_PRODUCTS_FAILURE,
  productsActions.GET_PRODUCT_FAILURE,
  productsActions.DELETE_PRODUCT_FAILURE,
  productsActions.CREATE_PRODUCT_FAILURE,
  productsActions.EDIT_PRODUCT_FAILURE,
  productsActions.GET_PRODUCT_MODULES_FAILURE,
  productsActions.ADD_ATTRIBUTES_TO_PRODUCT_FAILURE,
  productsActions.GET_PRODUCT_VERSIONS_FAILURE,
  productsActions.PUBLISH_PRODUCT_FAILURE,
  productsActions.RESTORE_PRODUCT_FAILURE,
  productsActions.CLONE_PRODUCT_FAILURE,
  productsActions.CREATE_NEW_PRODUCT_VERSION_FAILURE,
  productsActions.UPDATE_PRODUCT_ATTRIBUTE_FAILURE,
  productCategoriesActions.GET_PRODUCT_CATEGORIES_FAILURE,
  productCategoriesActions.GET_PRODUCT_CATEGORY_FAILURE,
  usersActions.GET_USERS_FAILURE,
  usersActions.INVITE_USER_FAILURE,
  moduleTemplatesActions.GET_MODULE_TEMPLATES_FAILURE,
  moduleTemplatesActions.ADD_MODULE_TEMPLATE_FAILURE,
  moduleTemplatesActions.REMOVE_MODULE_TEMPLATE_FAILURE,
];

export function* AppExceptionsWorker({ data }: any) {
  try {
    let alertData;
    if (data?.status > 399 && data?.status < 500) {
      alertData = {
        type: "requestError",
        data: {
          message: data?.errors?.Validations.join(", ") || data?.detail,
        },
      };
    } else {
      alertData = {
        type: "serverError",
        reload: true,
      };
    }
    yield put({
      type: alertActions.SHOW_ALERT,
      data: alertData,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* AppExceptionsWatcher() {
  yield takeLatest(actionsList, AppExceptionsWorker as any);
}
