import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Chip } from "@mui/material";

import { ReactComponent as ConnectIcon } from "assets/icons/connect.svg";

import {
  StyledTableRow,
  StyledTableCell,
  Connect,
  GreyText,
  Bold,
} from "./styles";

import OutlinedCheckbox from "../../FormComponents/OutlinedCheckbox";

import { TableRowProps } from "./types";

const TableRowComponent = ({
  selected,
  row,
  handleClick,
  small,
  onEdit,
  hideCheckbox,
  parentId,
}: TableRowProps) => {
  return (
    <StyledTableRow>
      <StyledTableCell padding="checkbox" className="noWidth">
        {!hideCheckbox && (
          <OutlinedCheckbox
            checked={selected}
            disabled={!handleClick}
            onClick={() => handleClick && handleClick(row)}
          />
        )}
      </StyledTableCell>
      {small ? (
        <>
          <StyledTableCell padding="checkbox" className="noWidth">
            <Connect>
              <ConnectIcon />
            </Connect>
          </StyledTableCell>
          <StyledTableCell
            sx={{ width: small ? "90% !important" : "20%" }}
            colSpan={4}
          >
            <Bold>{row.displayName}</Bold>
          </StyledTableCell>
          <StyledTableCell className="collapsePadding right" colSpan={2}>
            <Chip
              sx={{
                backgroundColor: "#F2F2FC",
                height: "28px",
                color: "#010101",
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "Inter",
              }}
              label={row.defaultFillmentType}
            />
          </StyledTableCell>
        </>
      ) : (
        <>
          <StyledTableCell padding="checkbox" className="noWidth">
            <Connect>
              <ConnectIcon />
            </Connect>
          </StyledTableCell>
          <StyledTableCell sx={{ width: "25%" }}>
            <Bold>{row.displayName}</Bold>
          </StyledTableCell>
          <StyledTableCell>
            <GreyText>{row.isRequired ? "mandatory" : "not required"}</GreyText>
          </StyledTableCell>
          <StyledTableCell>
            <GreyText>{row.valueType}</GreyText>
          </StyledTableCell>
          <StyledTableCell>{row.attributeDescription}</StyledTableCell>
          <StyledTableCell className="collapsePadding right">
            <Chip
              sx={{
                backgroundColor: "#F2F2FC",
                height: "28px",
                color: "#010101",
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "Inter",
              }}
              label={row.fillmentType}
            />
          </StyledTableCell>
          <StyledTableCell className="right noWidth">
            <IconButton
              sx={{ color: "#383874", opacity: "0.5" }}
              onClick={() => {
                onEdit?.(row, parentId);
              }}
            >
              <EditIcon />
            </IconButton>
          </StyledTableCell>
        </>
      )}
    </StyledTableRow>
  );
};

export default TableRowComponent;
