import styled from "styled-components";

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
`;

export const InfoRow = styled.div`
  display: flex;
  border: 1px solid #dadae3;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 30px;
`;

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitleWrap = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  margin: 0 0 30px 0;
  font-size: 24px;
  line-height: 32px;
  color: #010101;
`;
