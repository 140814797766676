import { createSelector } from "@reduxjs/toolkit";
import { getWidgets } from "./getWidget";

export const getWidgetsData = createSelector(getWidgets, (state) => {
  return state.data;
});

export const getWidgetProducts = createSelector(getWidgets, (state) => {
  return state.widgetConfigProducts;
});
export const getWidgetsApplications = createSelector(getWidgets, (state) => {
  return state.widgetConfigApps;
});
export const getRowLoader = createSelector(getWidgets, (state) => {
  return state.rowLoader;
});
export const getWidgetsConfigAppsLoading = createSelector(
  getWidgets,
  (state) => {
    return state.widgetConfigAppLoader;
  }
);
export const getWidgetsConfigApps = createSelector(getWidgets, (state) => {
  if (!state.widgetConfigApps) return null;
  return state.widgetConfigApps.map((item) => ({
    applicationId: item.id,
    clientId: item.clientId,
    name: item.name,
  }));
});
export const getWidgetsTotal = createSelector(getWidgets, (state) => {
  return state.total;
});
export const getWidgetsLoading = createSelector(getWidgets, (state) => {
  return state.loading;
});

export const getWidgetsConfigData = createSelector(getWidgets, (state) => {
  return state.configWidget;
});

export const getWidgetsConfigID = createSelector(getWidgets, (state) => {
  return state.configWidgetId;
});
export const getWidgetsConfiguration = createSelector(
  getWidgetsConfigData,
  (state) => {
    return state?.configuration || null;
  }
);
export const getWidgetsNeedToRefresh = createSelector(getWidgets, (state) => {
  return state.needToRefreshWidgets;
});

export const getWidgetsActionLoader = createSelector(getWidgets, (state) => {
  return state.actionLoader;
});
export const getWidgetItemId = createSelector(getWidgets, (state) => {
  return state.configWidgetId;
});
export const getWidgetsByIdLoading = createSelector(getWidgets, (state) => {
  return state.configWidgetLoading;
});

export const getWidgetsArrayLoading = (widgetId: string) =>
  createSelector(getWidgets, (widgets) => {
    return widgets.widgetsArrayLoader.includes(widgetId);
  });

export const getActiveWidgetsProductIdsById = (
  widgetId: string,
  productId: string
) =>
  createSelector(getWidgets, (widgets) => {
    return widgets?.widgetIds[widgetId]?.includes(productId);
  });

export const getWidgetsProductIdsById = (widgetId: string) =>
  createSelector(getWidgets, (widgets) => {
    return widgets.widgetIds[widgetId];
  });
