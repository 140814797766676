import React, { useCallback, useState } from "react";
import { RequestWidget } from "features/request-widget-form";

import { DistributionWidgetTable } from "widgets/DistributionWidgetTable";
import TablePaper from "shared/ui/TablePaper";

export const DistributionWidgets = () => {
  const [openCreateRequest, setOpenCreateRequest] = useState(false);

  const openCreateRequestModal = useCallback(() => {
    setOpenCreateRequest((prev) => !prev);
  }, []);

  return (
    <TablePaper>
      <DistributionWidgetTable
        openCreateRequestModal={openCreateRequestModal}
      />
      {openCreateRequest && (
        <RequestWidget
          handleClose={openCreateRequestModal}
          open={openCreateRequest}
        />
      )}
    </TablePaper>
  );
};
