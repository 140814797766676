import styled from "styled-components";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const StyledSearchIcon = styled(SearchIcon)`
  &.MuiSvgIcon-root {
    font-size: 40px;
    color: var(--secondary);
  }
`;
export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    font-family: "Inter";
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 6px;
    outline: none;
    font-weight: 600;
    letter-spacing: 0.02em;
  }
  .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
  }
  .MuiInputBase-input {
    padding: 14.5px 6px;
  }

  [data-testid="LocationOnOutlinedIcon"] {
    color: var(--secondary);
  }
  .MuiInputBase-adornedStart {
    .MuiInputBase-input {
      padding-left: 5px;
    }
  }
`;
