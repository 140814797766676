import styled from "styled-components";

export const OutcomesTableWrapper = styled.div`
  background-color: #ffffff;
  width: 100%;
`;

export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  align-items: baseline;
`;
