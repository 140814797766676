/* eslint-disable quotes */
import React, { useState, useCallback, useEffect } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";

import { Grid, Typography } from "@mui/material";
import { Container, Footer, Subtitle } from "./styles";
import { ErrorText } from "features/events/ui";

interface JsonConfigurationProps {
  title: string;
  body?: boolean;
  value: any;
  onChange: (ev: any) => void;
  error: any;
}

export const JsonConfiguration = ({
  title,
  body,
  value,
  onChange,
  error,
}: JsonConfigurationProps) => {
  const [code, setCode] = useState<string>("");

  const handleChange = useCallback((val: string) => {
    setCode(val);
    onChange(val);
  }, []);

  useEffect(() => {
    if (value) setCode(value);
  }, [value]);

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Subtitle>
              {title} <span className="thin">(Optional)</span>
            </Subtitle>
            <CodeMirror
              value={code}
              theme={vscodeDark}
              onChange={handleChange}
            />
            {error && <ErrorText error={error} />}
          </Grid>
        </Grid>
      </Container>
      <Footer>
        <Typography
          sx={{
            color: "#494A63",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
          }}
        >
          Example:
        </Typography>
        <Typography
          sx={{
            color: "#494A63",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            wordWrap: "break-word",
          }}
        >
          {body
            ? '{"responseType": "C","person": {"firstName": "{{attributesData.FirstName}}","lastName": "{{attributesData.LastName}}"}}'
            : `[
              {
                "name": "Authorization",
                "value": "Basic MG9hMXg2eXc4cGZxd0hXRXYwaDg6eEI5UUlvYXN5d1dibl9fNWdjb1J2VzFiNkpXT1duTGZFQTZTbUtKMnpZNmVSRkZUVDdITTFrUDlyNl9hLXZiZA=="
              },
              {
                "name": "Content-Type",
                "value": "application/x-www-form-urlencoded"
              }
            ]`}
        </Typography>
      </Footer>
    </>
  );
};
