import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/material";
import { StyledTab, StyledTabs, TabContent } from "./styles";
import AccordionSection from "shared/ui/AccordionSection";

import { useAppSelector } from "redux/store/hooks/hooks";
import { moduleTemplatesActions } from "entities/moduleTemplates";
import { productsActions } from "entities/products";

import { RootState } from "redux/store";
import { getProduct } from "entities/products/model/selectors";
import { AssociatedProductsContext } from "pages/IntegrationHub";
import { OrderTypes } from "shared/config/types/Sorting";
import DataEnrichTable from "components/Table";

import { Module, ModuleTemplate } from "api/types";

import { initialColumns, enrichmentsColumns } from "./types";

import { sortByOrder } from "utils/array";
import { useProducts } from "features/associatedProducts/model/hooks/useProducts";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <TabContent>{children}</TabContent>}
    </div>
  );
}
interface IntegrationsProps {
  editable?: boolean;
}

const Integrations = ({ editable }: IntegrationsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateProductVersion } = useProducts();
  const [value, setValue] = useState(0);
  const [order, setOrder] = useState<OrderTypes | null>(null);
  const [includedThirdParties, setIncludedThirdParties] = useState<
    ModuleTemplate[]
  >([]);
  const [includedEnrichments, setIncludedEnrichments] = useState<
    ModuleTemplate[]
  >([]);

  const product = useAppSelector(getProduct);

  const productModules = useSelector(
    (state: RootState) => state.products.modules
  );
  const moduleTemplates = useSelector(
    (state: RootState) => state.moduleTemplates.items
  );

  useEffect(() => {
    dispatch({
      type: productsActions.GET_PRODUCT_MODULES,
      data: {
        productId: product?.id,
        version: product?.version,
      },
    });
    dispatch({ type: moduleTemplatesActions.GET_MODULE_TEMPLATES });
  }, []);

  useEffect(() => {
    if (product?.name) {
      processThirdParties();
      processEnrichments();
    }
  }, [product, moduleTemplates]);

  useEffect(() => {
    if (order) {
      const filters = {
        name: order.label,
        sortKey: order.type,
      };
      const sorted = sortByOrder([...includedThirdParties], filters);
      if (sorted.length > 0) setIncludedThirdParties(sorted);
      const sortedEnrichments = sortByOrder([...includedEnrichments], filters);
      if (sortedEnrichments.length > 0)
        setIncludedEnrichments(sortedEnrichments);
    }
  }, [order]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleEdit = () => {
    if (product.state === "Published") {
      updateProductVersion(product);
    }
    navigate(`/create_new_product/${product.id}?step=Integrations`);
  };

  const viewProducts = (id: string) => {
    console.log("list all associated products", id);
  };

  const processModules = (moduleType: string) => {
    const categoryModules = moduleTemplates.filter(
      (item: ModuleTemplate) =>
        item.moduleConfiguration.moduleType === moduleType
    );
    const included = productModules.map(
      (item: Module) => item.moduleTemplateId
    );
    return categoryModules.filter((item: ModuleTemplate) =>
      included.includes(item.id)
    );
  };

  const processThirdParties = () => {
    const productThirdParties = processModules("Integration");
    setIncludedThirdParties([...productThirdParties]);
  };

  const processEnrichments = () => {
    const productEnrichments = processModules("Enrichment");
    setIncludedEnrichments([...productEnrichments]);
  };

  const sortData = (order: OrderTypes) => {
    setOrder(order);
  };

  const associatedProductsValue = useMemo(
    () => ({
      viewProducts,
      currentProduct: product,
    }),
    [viewProducts, product]
  );

  return (
    <AssociatedProductsContext.Provider value={associatedProductsValue}>
      <AccordionSection
        title="Integration Hub"
        editable={editable}
        handleEdit={handleEdit}
      >
        <Box sx={{ width: "100%" }}>
          <StyledTabs value={value} onChange={handleChange}>
            <StyledTab value={0} label="Third Party" />
            <StyledTab value={1} label="Data Enrichment" />
          </StyledTabs>
          <TabPanel value={value} index={0}>
            <DataEnrichTable
              rows={includedThirdParties}
              columns={initialColumns}
              rowHasChip={true}
              heightAuto={true}
              hideCheckbox={true}
              setSortOrder={(order: OrderTypes) => sortData(order)}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DataEnrichTable
              rows={includedEnrichments}
              columns={enrichmentsColumns}
              rowHasChip={true}
              isCompact={true}
              hideCheckbox={true}
              heightAuto={true}
              setSortOrder={(order: OrderTypes) => sortData(order)}
            />
          </TabPanel>
        </Box>
      </AccordionSection>
    </AssociatedProductsContext.Provider>
  );
};

export default Integrations;
