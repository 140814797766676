import React from "react";
import { WidgetConfigItem, WidgetConfigWrapper } from "../styles";
import { CopyBlock, dracula } from "react-code-blocks";
import { WIDGET_PATH } from "../../../../constants";

interface Props {
  widgetId: string;
  productId: string;
  tenantId: string;
}

export const ConfigListItem = ({ widgetId, productId, tenantId }: Props) => {
  const string = `<script type="text/javascript">
      !function(){
          if (window.IXWidget) {
              window.IXWidget(
                  'ix-platform-widget', 
                  {
                    widgetId: "${widgetId}",
                    clientId: "${tenantId}",
                    productId: "${productId}",
                  }
              );
          }
      }();
  </script>
`;
  return (
    <WidgetConfigWrapper>
      <WidgetConfigItem>
        <h3>Widget library link</h3>
        <p>
          {
            "To insert this widget on your site, place the <script> tag for referencing IX-Widget JavaScript bundle"
          }
        </p>
        <CopyBlock
          codeContainerStyle={{ style: { "font-size": "12px" } }}
          language={"javascript"}
          text={`<script type="text/javascript" src="${WIDGET_PATH}"></script>`}
          showLineNumbers={false}
          theme={dracula as any}
          onCopy={() => ({})}
          codeBlock
          copied={false}
          wrapLongLines={false}
        />
        <h3>Widget placement</h3>
        <p>
          {
            "Decide where you want to place the widget, and then put there <b></div></b> with unique id, like this:"
          }
        </p>
        <CopyBlock
          text={"<div id='ix-platform-widget'></div>"}
          language={"javascript"}
          showLineNumbers={false}
          theme={dracula as any}
          onCopy={() => ({})}
          codeBlock
          copied={false}
          wrapLongLines={false}
        />
        <h3>Widget configuration</h3>
        <p>
          {
            "Place a <script> tag with a widget initialization before the closing </body>."
          }
        </p>
        <CopyBlock
          language={"javascript"}
          text={string}
          showLineNumbers={false}
          theme={dracula as any}
          codeBlock
          onCopy={() => ({})}
          copied={false}
          wrapLongLines={false}
        />
      </WidgetConfigItem>
    </WidgetConfigWrapper>
  );
};
