import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "shared/ui/Modal";
import Button from "shared/ui/Button";
import FormGroup from "components/FormComponents/FormGroup";
import Select from "components/FormComponents/Select";
import { InputBlock } from "./styles";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getProduct } from "entities/products/model/selectors";
import { RequestType } from "shared/api/requests/types";
import { loadTypes } from "entities/requests";
import { useRequests } from "features/requests/model/hooks/useRequests";
import { Application } from "shared/api/applications/types";
import { getDirectData, getNetworkData } from "entities/applications";
import { getCurrentTenantInfo } from "entities/users";

interface ModalProps {
  handleClose: () => void;
  open: boolean;
  productName?: string;
}

interface RequestData {
  program_name: string;
  product_name: string;
  widget_type: string;
  distributions: string[];
  widget_name: string;
  description: string;
}

const defaultValues: RequestData = {
  program_name: "",
  product_name: "Personal Auto",
  widget_type: "",
  distributions: [],
  widget_name: "",
  description: "",
};

export const RequestWidget = (props: ModalProps) => {
  const { handleClose, open, productName } = props;
  const product = useAppSelector(getProduct);
  const requestTypes: RequestType[] = useAppSelector(loadTypes);
  const directApplications: Application[] = useAppSelector(getDirectData);
  const networkApplications: Application[] = useAppSelector(getNetworkData);
  const [widgetNames, setWidgetNames] = useState<string[]>([]);
  const [directNames, setDirectNames] = useState<string[]>([]);
  const [networkNames, setNetworkNames] = useState<string[]>([]);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { ...defaultValues, product_name: product.name || "" },
  });
  const tenantInfo: any = useAppSelector(getCurrentTenantInfo);
  const { createRequest } = useRequests();

  useEffect(() => {
    if (directApplications.length) {
      const dirNames = directApplications.map((app: Application) => app.name);
      if (dirNames.length) setDirectNames(dirNames);
    }
  }, [directApplications]);

  useEffect(() => {
    if (networkApplications.length) {
      const netNames = networkApplications.map((app: Application) => app.name);
      if (netNames.length) setNetworkNames(netNames);
    }
  }, [networkApplications]);

  useEffect(() => {
    const names = directNames.concat(networkNames);
    setWidgetNames(names);
  }, [directNames, networkNames]);

  const onSubmit = () => {
    handleSubmit((data: RequestData) => {
      const typeId = loadRequestType()?.id;
      if (typeId) {
        const payload = requestPayload(typeId, data);
        createRequest(payload, callback);
      }
    })();
  };

  const callback = () => {
    reset({
      program_name: "",
      product_name: "",
      widget_type: "",
      distributions: [],
      widget_name: "",
      description: "",
    });
    handleClose();
  };

  const loadRequestType = () => {
    const type = requestTypes.filter(
      (type: RequestType) => type.displayName === "Widget"
    );
    if (type.length === 1) return type[0];
  };

  // TODO: Removed id field once tenant API connected.
  const requestPayload = (requestId: string, data: RequestData) => {
    const payload = {
      requestTypeId: requestId,
      tenantId: tenantInfo.id,
      description: JSON.stringify(data),
      title: "Widget request",
    };
    return payload;
  };

  return (
    <Modal
      title="Request a widget"
      open={open}
      handleClose={handleClose}
      id="addAttributesModal"
      footer={
        <>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              marginRight: "24px",
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit}>
            Request
          </Button>
        </>
      }
    >
      <form>
        <InputBlock>
          <Controller
            name="program_name"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormGroup
                  id="program_name"
                  disabled={true}
                  value={value}
                  label="Program Name"
                  placeholder=""
                  onChange={onChange}
                  error={!!error}
                />
              );
            }}
          />
        </InputBlock>
        <InputBlock>
          <Controller
            name="product_name"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormGroup
                  id="product_name"
                  disabled={!!productName}
                  value={productName ?? value}
                  label="Product Name"
                  placeholder="Product Name"
                  onChange={onChange}
                  error={!!error}
                />
              );
            }}
          />
        </InputBlock>
        <InputBlock>
          <Controller
            name="widget_type"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormGroup
                  id="widget_type"
                  value={value}
                  label="Widget type"
                  placeholder="Widget type"
                  onChange={onChange}
                  error={!!error}
                  danger={true}
                />
              );
            }}
          />
        </InputBlock>
        <InputBlock>
          <Controller
            name="distributions"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Select
                  multiple
                  onChange={onChange}
                  options={widgetNames}
                  id={"distributions"}
                  title={""}
                  error={!!error}
                  value={value}
                  label="Distributions"
                  hideCreate={true}
                  required={true}
                />
              );
            }}
          />
        </InputBlock>
        <InputBlock>
          <Controller
            name="widget_name"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormGroup
                id="widget_name"
                label="Widget Name"
                placeholder="Widget Name"
                value={value}
                danger={true}
                onChange={onChange}
                error={!!error}
              />
            )}
          />
        </InputBlock>
        <InputBlock>
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormGroup
                id="description"
                type="textarea"
                label="Customer Journey / Description:"
                placeholder="Customer Journey / Description:"
                value={value}
                onChange={onChange}
                rows={4}
                danger={true}
                multiline={true}
                error={!!error}
              />
            )}
          />
        </InputBlock>
      </form>
    </Modal>
  );
};
