import { createAction } from "@reduxjs/toolkit";
import {
  ModulesTemplatesPayload,
  RulePayload,
  ModulesOrderPayload,
} from "shared/api/modules/types";
import { ModuleAttributesPayload, ModuleBody } from "../types/types";
import { Modules } from "../types/enum";

export const createModulesAction = createAction<{
  id: string;
  version: number;
}>("modules/create");

export const createModuleAction = createAction<{
  id: string;
  version: number;
  type: Modules;
  data?: ModuleBody;
}>("module/create");

export const setModulesOrderAction = createAction<{
  id: string;
  version: number;
  data: ModulesOrderPayload;
  callback: () => void;
}>("modulesOrder/set");

export const createModuleLoadingAction = createAction<boolean>(
  "module/create/loading"
);

export const ruleCreationLoadingAction = createAction<boolean>(
  "modules/rule/loadingCreate"
);

export const getModulesTemplatesAction = createAction<ModulesTemplatesPayload>(
  "modules/templates/get"
);

export const getAllModulesAction = createAction<{
  id: string;
  version: number;
}>("modules/getAll");

export const editModuleAction = createAction<{
  id: string;
  version: number;
  moduleId: string;
  payload: ModuleBody;
}>("modules/edit");

export const deleteModuleAction = createAction<{
  id: string;
  version: number;
  moduleId: string;
}>("modules/delete");

export const createModuleRuleAction = createAction<{
  id: string;
  version: number;
  moduleId: string;
  data: RulePayload;
}>("modules/rule/create");

export const editModuleRuleAction = createAction<{
  id: string;
  version: number;
  moduleId: string;
  ruleId: string;
  data: RulePayload;
}>("modules/rule/edit");

export const deleteModuleRuleAction = createAction<{
  id: string;
  version: number;
  moduleId: string;
  ruleId: string;
}>("modules/rule/delete");

export const updateModulesAttributes = createAction<ModuleAttributesPayload>(
  "modules/attributes/update"
);
