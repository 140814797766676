import React from "react";

const AddUser = () => {
  return (
    <p>New User has been added! An invitation to Sign Up has been sent.</p>
  );
};
const EditUser = () => {
  return (
    <p>
      Profile of <b>[Username]</b> has been updated.
    </p>
  );
};
const Invitation = () => {
  return (
    <p>
      An invitation to Sign In has been sent to <b>[username@email.com]</b>.{" "}
    </p>
  );
};
const DeleteUser = ({ notification }: any) => {
  return (
    <p>
      User <b>{notification?.data?.name}</b> has been removed. You can find the
      user in the Archived tab.
    </p>
  );
};
const DeleteUsers = ({ items }: any) => {
  return (
    <p>
      {items.length} users have been removed. You can find them in Archive tab.
    </p>
  );
};
const DeleteProduct = ({ notification }: any) => {
  return (
    <p>
      Product <b>{notification?.data?.name}</b> has been removed. You can find
      it in Archive tab.
    </p>
  );
};
const DeleteProgram = ({ notification }: any) => {
  return (
    <p>
      Program <b>{notification?.data?.name}</b> has been removed. You can find
      it in Archive tab.
    </p>
  );
};
const RequestSent = () => {
  return <p>Your Request has been sent.</p>;
};
const RequestCreated = ({ notification }: any) => {
  return (
    <p>
      Request <b>{notification?.data?.name}</b> has been created.
    </p>
  );
};
const RequestDeleted = ({ notification }: any) => {
  return (
    <p>
      Request <b>{notification?.data?.name}</b> is deleted.
    </p>
  );
};
const RequestRestored = ({ notification }: any) => {
  return (
    <p>
      Request <b>{notification?.data?.name}</b> is restored.
    </p>
  );
};
const ProductRestored = ({ notification }: any) => {
  return (
    <p>
      The Product <b>{notification?.data?.name}</b> is restored.
    </p>
  );
};
const ProgramRestored = ({ notification }: any) => {
  return (
    <p>
      The Program <b>{notification?.data?.name}</b> is restored.
    </p>
  );
};
const UserRestored = ({ notification }: any) => {
  return (
    <p>
      The User <b>{notification?.data?.email}</b> is restored.
    </p>
  );
};
const RequestError = ({ notification }: any) => {
  return (
    <p>
      Request Failed: Error: <b>{notification?.data?.message}</b>
    </p>
  );
};
const ServerError = () => {
  return <p>Unable to load data. Please try again later or reload webpage.</p>;
};
const DataElementsAdded = () => {
  return <p>Data Elements have been added</p>;
};
const DataElementsRemoved = () => {
  return <p>Data Elements have been removed</p>;
};

export const messages: any = {
  addUser: AddUser,
  editUser: EditUser,
  invitation: Invitation,
  deleteUser: DeleteUser,
  deleteUsers: DeleteUsers,
  deleteProduct: DeleteProduct,
  deleteProgram: DeleteProgram,
  requestSent: RequestSent,
  requestCreated: RequestCreated,
  requestDeleted: RequestDeleted,
  requestRestored: RequestRestored,
  productRestored: ProductRestored,
  programRestored: ProgramRestored,
  userRestored: UserRestored,
  requestError: RequestError,
  serverError: ServerError,
  dataElementsAdded: DataElementsAdded,
  dataElementsRemoved: DataElementsRemoved,
};
