import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EventsResponse,
  TriggerDefinitionsResponse,
} from "shared/api/events/types";
import { InitialState } from "../types/types";

const initialState: InitialState = {
  loading: false,
  actionLoading: false,
  items: [],
  totalCount: 0,
  eventTriggers: [],
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setEvents: (state, action: PayloadAction<EventsResponse>) => {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    },
    setEventsLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setEventActionLoading: (state, action) => {
      return {
        ...state,
        actionLoading: action.payload,
      };
    },
    setEventTriggers: (
      state,
      action: PayloadAction<TriggerDefinitionsResponse>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { reducer: eventsReducer, actions: eventsActions } = eventsSlice;
