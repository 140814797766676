import styled from "styled-components";
import { TableCell } from "@mui/material";

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-bottom: none;
    text-align: left;
    padding: 7px 8px;
  }
`;

export const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
  border: 1px solid var(--silver);
  border-radius: 10px;
  background-color: var(--ghost);
  max-height: 700px;
  overflow: auto;
`;

export const AttrName = styled.div`
  font-size: 14px;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;
