import React, { useEffect, useRef, useState } from "react";
import {
  FieldBlock,
  FieldName,
  FieldTitle,
  FieldValue,
  RowItem,
} from "../styles";
import { ReactComponent as RuleType } from "assets/icons/ruletype.svg";
import { ReactComponent as ActionDots } from "assets/icons/action-dots.svg";
import { ActionButton } from "shared/ui/ActionButton";
import { ActionButtonTypes } from "components/ActionButton/types";
import { Rule } from "shared/api/modules/types";
import { useRule } from "features/modules";
import { styled, Tooltip, TooltipProps } from "@mui/material";

const WhiteTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }}>
    {props.children}
  </Tooltip>
))(`
    background-color: #fff;
    color: #494a63;
    font-size: 16px;
    padding: 5px;
    box-shadow: 10px 10px 40px rgba(31,60,100,0.16), 0px 4px 20px rgba(0,0,0,0.16);
    max-width: 500px;
`);
interface Props {
  data: Rule;
  editable: boolean;
  name: string;
  moduleId: string;
  editRuleHandler?: (item?: Rule, name?: string) => void;
}
export const RuleRow = ({
  data,
  editable,
  editRuleHandler,
  name,
  moduleId,
}: Props) => {
  const { deleteRule } = useRule();
  const [overflow, setOverflow] = useState(false);
  const refFieldContainer: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null);
  const refFieldValue: React.MutableRefObject<HTMLSpanElement | null> =
    useRef(null);
  const handleEdit = () => {
    if (editRuleHandler) editRuleHandler(data, name);
  };

  const handleDelete = () => {
    deleteRule(moduleId, data.id);
  };
  useEffect(() => {
    if (
      refFieldValue.current?.clientWidth &&
      refFieldContainer.current?.clientWidth &&
      refFieldValue.current?.clientWidth >
        refFieldContainer.current?.clientWidth
    ) {
      setOverflow(true);
    }
  }, []);
  return (
    <RowItem>
      <FieldBlock>
        <FieldBlock>
          <FieldTitle>
            <ActionDots />
            <RuleType />
            {data.name}
          </FieldTitle>
        </FieldBlock>
        <FieldBlock>
          <FieldName>Expression:</FieldName>
          <WhiteTooltip
            title={data.expression}
            placement="top"
            disableHoverListener={!overflow}
          >
            <FieldValue ref={refFieldContainer} overflow={overflow}>
              <span ref={refFieldValue}>{data.expression}</span>
            </FieldValue>
          </WhiteTooltip>
        </FieldBlock>
        <FieldBlock>
          <FieldName>Output Type:</FieldName>
          <FieldValue>{data.outputType}</FieldValue>
        </FieldBlock>
      </FieldBlock>
      <div>
        {editable && (
          <>
            <ActionButton
              title={ActionButtonTypes.EDIT}
              type={ActionButtonTypes.EDIT}
              onClick={handleEdit}
            />
            <ActionButton
              title={ActionButtonTypes.DELETE}
              type={ActionButtonTypes.DELETE}
              onClick={handleDelete}
            />
          </>
        )}
      </div>
    </RowItem>
  );
};
