import styled from "styled-components";
import { Button } from "@mui/material";

export const StyledProfilePicture = styled.div`
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  background-color: var(--silver);
`;

export const StyledButton = styled(Button)<{ component: string }>`
  &.MuiButtonBase-root {
    border-radius: 100px;
    padding: 3px 23px;
    color: var(--secondary);
    border: 1px solid var(--secondary);
    text-transform: none;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;
