import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import AccordionSection from "shared/ui/AccordionSection";
import NoData from "../NoData";
import { Wrapper } from "./styles";
import { useAppSelector } from "redux/store/hooks/hooks";

import { getProduct } from "entities/products/model/selectors";
import { useProducts } from "features/associatedProducts/model/hooks/useProducts";
import Table from "./Table";

interface AttributesProps {
  editable?: boolean;
}

const Attributes = ({ editable }: AttributesProps) => {
  const product = useAppSelector(getProduct);
  const attributes = product?.attributes;

  const navigate = useNavigate();
  const { updateProductVersion } = useProducts();

  const handleEdit = () => {
    if (product.state === "Published") {
      updateProductVersion(product);
    }
    navigate(`/create_new_product/${product.id}?step=DataElements`);
  };

  return (
    <AccordionSection
      title="Data Elements"
      editable={editable}
      handleEdit={handleEdit}
    >
      <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
        {attributes?.length ? (
          <Wrapper>
            {attributes.map((attr: any) => (
              <Table item={attr} key={attr.id} />
            ))}
          </Wrapper>
        ) : (
          <NoData />
        )}
      </Box>
    </AccordionSection>
  );
};

export default Attributes;
