import styled from "styled-components";
import { MenuItem, Select, FormHelperText } from "@mui/material";
import OutlinedCheckbox from "../OutlinedCheckbox";

export const StyledSelect = styled(Select)`
  &.MuiInputBase-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    border: 1px solid var(--primary);
    border-radius: 4px;
    outline: none;
    text-align: left;
    &.Mui-error {
      background: var(--lightred);
      border: 1px solid var(--darkred);
      color: var(--headings);
    }
  }
  &.Mui-focused {
    border: 1px solid var(--secondary);
    box-shadow: 0px 0px 10px rgba(98, 102, 234, 0.5);
    outline: none;
  }
  .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
  }
  .MuiSelect-select {
    height: 52px;
    box-sizing: border-box;
    padding: 14px 40px 14px 14px;
  }
  .MuiSvgIcon-root {
    color: var(--black);
  }
  &.Mui-disabled {
    background: var(--lightsilver);
    border-color: var(--lightsilver);
  }
`;
export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    .MuiTypography-root {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--black);
    }
    &.Mui-selected {
      background-color: var(--white);
      .MuiTypography-root,
      .MuiCheckbox-root {
        color: var(--secondary);
      }
      &:hover {
        background: var(--ghost);
      }
    }
    &:hover {
      background: var(--ghost);
      .MuiTypography-root,
      .MuiCheckbox-root {
        color: var(--secondary);
      }
      path {
        fill: var(--secondary);
      }
    }
  }
`;
export const StyledCheckbox = styled(OutlinedCheckbox)`
  &.MuiCheckbox-root {
    margin-left: -9px;
    margin-right: 5px;
    color: var(--primary);
    &.Mui-checked {
      color: var(--secondary);
    }
  }
`;
export const StyledPlaceholder = styled.span`
  color: var(--darkred);
`;
export const StyledHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    color: #d32f2f;
    margin-left: 14px;
  }
`;
