import { createSelector } from "@reduxjs/toolkit";
import { getEvents } from "./getEvents";

export const getEventItems = createSelector(getEvents, (events) => {
  return events.items;
});

export const getTotalCount = createSelector(getEvents, (events) => {
  return events.totalCount;
});

export const getEventsLoading = createSelector(getEvents, (events) => {
  return events.loading;
});

export const getEventActionLoading = createSelector(getEvents, (events) => {
  return events.actionLoading;
});

export const getEventTriggers = createSelector(getEvents, (events) => {
  return events.eventTriggers;
});
