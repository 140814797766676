import React, { useState, memo, useEffect } from "react";
import {
  getDirectProductIdsByApp,
  getNetworkProductIdsByApp,
  productActiveOnApplication,
} from "entities/applications";
import { getActiveWidgetsProductIdsById } from "entities/widgets";
import { ChipWrapper } from "./styles";
import FormGroup from "components/FormComponents/FormGroup";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getEntityKPI } from "entities/kpis";
import { KpiPayload } from "shared/api/kpis/types";
import { useDebounce } from "use-debounce";
import { Toaster } from "shared/ui/Toast/Toast";

interface Props {
  productId: string;
  appId: string;
  type: string;
  handleKpi?: (payload: KpiPayload) => void;
}

export const Kpi = memo(function Kpi({
  productId,
  appId,
  type,
  handleKpi,
}: Props) {
  const entityKpi = useAppSelector(getEntityKPI(appId, productId));
  const [kpi, setKpi] = useState(entityKpi?.toString());
  const directActive = useAppSelector(
    getDirectProductIdsByApp(appId, productId)
  );
  const networkActive = useAppSelector(
    getNetworkProductIdsByApp(appId, productId)
  );
  const widgetActive = useAppSelector(
    getActiveWidgetsProductIdsById(appId, productId)
  );
  const distributionActive = useAppSelector(
    productActiveOnApplication(appId, productId)
  );

  const [userInputKpi] = useDebounce(kpi, 500);

  const isActivated = (type: string) => {
    switch (type) {
      case "direct":
        return directActive;
      case "network":
        return networkActive;
      case "distribution":
        return distributionActive;
      case "widget":
        return widgetActive;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (entityKpi && Number(kpi) !== entityKpi) setKpi(entityKpi.toString());
  }, [entityKpi]);

  useEffect(() => {
    if (!isNaN(Number(userInputKpi))) {
      if (handleKpi && userInputKpi !== entityKpi?.toString()) {
        handleKpi(kpiPayload(Number(userInputKpi)));
      }
    }
    if (userInputKpi && isNaN(Number(userInputKpi))) {
      Toaster.error("Please provide a valid number");
      setKpi(entityKpi?.toString());
    }
  }, [userInputKpi]);

  const kpiPayload = (kpiValue: number) => {
    return {
      entityId: appId,
      productId,
      kpiType: kpiType(),
      value: kpiValue === 0 && userInputKpi === "" ? null : kpiValue,
    };
  };

  const kpiType = () => {
    switch (type) {
      case "direct":
      case "network":
      case "distribution":
        return "Application";
      case "widget":
        return "Widget";
      default:
        return "Product";
    }
  };

  return (
    <ChipWrapper>
      {isActivated(type) && (
        <FormGroup
          value={kpi}
          id={`kpi-${appId}`}
          onChange={(e) => setKpi(e.target.value)}
          placeholder="Type KPI"
        />
      )}
    </ChipWrapper>
  );
});
