import styled from "styled-components";
import { Typography, DialogContent, Alert } from "@mui/material";

export const Subtitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--secondary);
    margin-bottom: 24px;
  }
`;
export const Form = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 40px 51px;
    border-bottom: none;
  }
`;
export const Block = styled.div`
  padding: 40px 0;
  margin: 40px 0 0;
  border-top: 1px solid var(--silver);
`;
export const StyledAlert = styled(Alert)`
  &.MuiAlert-root {
    margin-bottom: 40px;
    background: #e5f6fd;
    .MuiAlert-message {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: var(--darkblue);
      b {
        font-weight: 700;
      }
    }
    .MuiAlert-icon {
      color: var(--darkblue);
    }
  }
`;
