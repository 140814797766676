import { Column } from "../Table/types";

export const initialColumns: Column[] = [
  {
    id: "fullName",
    label: "Fullname",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "role",
    label: "Role",
    minWidth: 100,
    filtering: false,
  },
  {
    id: "state",
    label: "Status",
    minWidth: 100,
    filtering: false,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 150,
    align: "center",
    filtering: false,
  },
];
