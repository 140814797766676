import React, { useEffect } from "react";

const WidgetView = () => {
  useEffect(() => {
    const data = localStorage.getItem("widgetConfig");

    if (data) {
      const parsedData = JSON.parse(data);
      const { productId, clientID, ...other } = parsedData;
      window.IXWidget("ix-widget-preview", {
        productId: productId,
        clientID: clientID,
        ...other,
      });
    }
  }, []);
  return <div id="ix-widget-preview"></div>;
};

export default WidgetView;
