import { styled } from "@mui/material";

export const LoaderContainer = styled("div")({
  display: "flex",
  flex: 1,
  width: "100%",
  height: "100%",
  alignItems: "center",
  position: "absolute",
  backgroundColor: "rgb(255 255 255 / 39%)",
  justifyContent: "center",
  zIndex: 9,
  top: 0,
  left: 0,
});
