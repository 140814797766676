import styled from "styled-components";

export const Title = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #383874;
`;

export const Block = styled.div`
  width: 100%;
  text-align: center;
  padding: 30px;
  border: 1px solid #dadae3;
  border-radius: 10px;
`;
