import { useAppDispatch, useAppSelector } from "redux/store/hooks/hooks";
import { useCallback, useMemo } from "react";
import {
  actions,
  getApplicationsAction,
  getApplicationsLoading,
  getApplicationsMapData,
  getApplicationsTotal,
  getApplicationWidgetConfigILoading,
  getApplicationClientId,
  getLinkedProductsArrayDataMap,
  getLinkedProductsList,
  getLinkedProductsLoading,
  getLinkedProductsTotal,
  getLoadingLinkedApps,
  getSearchExpressions,
  linkToProductAction,
  unlinkNToProductAction,
  getApplicationWidgetConfigItem,
} from "entities/applications";
import {
  ApplicationsLinkPayload,
  ApplicationsPayload,
} from "shared/api/applications/types";
import { useDispatch } from "react-redux";
import { LinkedProductsPayload } from "entities/products/model/types";
import { getLinkedProductsAction } from "entities/products";
import { getCategories } from "entities/productCategories";

const useApplicationsMapData = () => useAppSelector(getApplicationsMapData);
const useApplicationsLoading = () => useAppSelector(getApplicationsLoading);
const useApplicationsTotal = () => useAppSelector(getApplicationsTotal);
const useSearchExpressions = () => useAppSelector(getSearchExpressions);

export const useApplicationsData = () => {
  const data = useApplicationsMapData();
  const isLoading = useApplicationsLoading();
  const total = useApplicationsTotal();
  const search = useSearchExpressions();

  return {
    isLoading,
    data,
    total,
    search,
  };
};

export const useApplicationsNetworkData = () => {
  const data = useApplicationsMapData();
  const isLoading = useApplicationsLoading();
  const total = useApplicationsTotal();

  return {
    isLoading,
    data,
    total,
  };
};

export const useApplicationActions = () => {
  const dispatch = useAppDispatch();

  const getApplications = useCallback(
    (data: ApplicationsPayload) => {
      dispatch(getApplicationsAction(data));
    },
    [dispatch]
  );

  const setSearchExpressions = useCallback(
    (search: string) => {
      dispatch(actions.setSearchExpression(search));
    },
    [dispatch]
  );

  return {
    getApplications,
    setSearchExpressions,
  };
};

export const useGetLoadingLinkedApps = () =>
  useAppSelector(getLoadingLinkedApps);

export const useLinkedApps = (id: string) => {
  const data = useGetLoadingLinkedApps();
  return useMemo(() => {
    return data.includes(id);
  }, [data, id]);
};

export const useLinkedProductsAction = () => {
  const dispatch = useDispatch();
  const getLinkedProducts = useCallback(
    (data: LinkedProductsPayload) => {
      dispatch(getLinkedProductsAction(data));
    },
    [dispatch]
  );

  return {
    getLinkedProducts,
  };
};

export const useGetLinkedProductsList = () =>
  useAppSelector(getLinkedProductsList);

export const useGetLinkedProductsTotal = () =>
  useAppSelector(getLinkedProductsTotal);

export const useGetLinkedProductsLoading = () =>
  useAppSelector(getLinkedProductsLoading);

export const useGetCategories = () => useAppSelector(getCategories);
export const useGetLinkedProductsArrayDataMap = () =>
  useAppSelector(getLinkedProductsArrayDataMap);

export const useLinkedProductsData = () => {
  const data = useGetLinkedProductsList();
  const dataMap = useGetLinkedProductsArrayDataMap();
  const isLoading = useGetLinkedProductsLoading();
  const categories = useGetCategories();
  const total = useGetLinkedProductsTotal();

  return {
    data,
    isLoading,
    categories,
    dataMap,
    total,
  };
};

export const useLinkedProductsActions = () => {
  const dispatch = useDispatch();
  const linkToProduct = useCallback(
    (data: ApplicationsLinkPayload) => {
      dispatch(linkToProductAction(data));
    },
    [dispatch]
  );

  const unlinkToProduct = useCallback(
    (data: ApplicationsLinkPayload) => {
      dispatch(unlinkNToProductAction(data));
    },
    [dispatch]
  );

  return {
    unlinkToProduct,
    linkToProduct,
  };
};
const useGetApplicationClientId = (productId: string) =>
  useAppSelector(getApplicationClientId(productId));
const useGetApplicationWidgetConfigILoading = () =>
  useAppSelector(getApplicationWidgetConfigILoading);
const useGetApplicationWidgetConfigItem = () =>
  useAppSelector(getApplicationWidgetConfigItem);

export const useWidgetConfigApplications = (productId: string) => {
  const clientId = useGetApplicationClientId(productId);
  const loading = useGetApplicationWidgetConfigILoading();
  const applicationItem = useGetApplicationWidgetConfigItem();

  return {
    clientId,
    loading,
    applicationItem,
  };
};
