import styled from "styled-components";

export const Basic = styled.div`
  border-top: 1px solid #e0e0ea;
  border-bottom: 1px solid #e0e0ea;
  margin: 48px 0;
  padding: 48px 0;
`;

export const Wrapper = styled.div`
  max-width: 952px;
  margin: auto;
  .MuiInputBase-root {
    background: #ffffff;
    .Mui-disabled {
      -webkit-text-fill-color: #9696a9;
    }
    &.Mui-disabled {
      background: var(--lightsilver);
    }
  }
  .Mui-error {
    background: transparent;
  }
`;

export const Description = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #190a3a;
  margin: 0 0 34px;
`;
