import styled from "styled-components";
import { Button } from "@mui/material";
import OutlinedCheckbox from "shared/ui/OutlinedCheckbox";

export const MenuWrapper = styled.div`
  display: flex;
  min-width: 250px;
  flex-direction: column;
`;

export const OptionsWrap = styled.div`
  max-height: 184px;
  min-height: 50px;
  overflow-y: scroll;
`;

export const ButtonsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NoItemsWrap = styled.div`
  padding: 10px 15px;
  text-align: center;
  color: var(--grey);
  font-size: 16px;
`;

export const StyledButton = styled(Button)<{ isActive?: boolean }>`
  &.MuiButton-root {
    justify-content: flex-start;
    padding: 5px;
    text-transform: none;
    color: ${(props) => (props.isActive ? "var(--secondary)" : "var(--black)")};
    :hover {
      color: ${(props) =>
        props.isActive ? "var(--secondary)" : "var(--black)"};
      background: var(--ghost);
    }
    .Mui-checked + p {
      color: var(--secondary);
    }
  }
`;

export const StyledCheckbox = styled(OutlinedCheckbox)`
  &.MuiCheckbox-root {
    margin-left: -9px;
    margin-right: 5px;
    color: var(--primary);
    &.Mui-checked {
      color: var(--secondary);
    }
  }
`;

export const ControlsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  button {
    cursor: pointer;
    font-size: 12px;
    border: none;
    background: transparent;
    font-size: 16px;
    letter-spacing: 2px;
    color: var(--primary);
  }

  button + button {
    color: var(--secondary);
  }
`;
