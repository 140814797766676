import { Column } from "components/Table/types";

export const initialColumns: Column[] = [
  {
    id: "name",
    label: "Name",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "provider",
    label: "Provider",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "version",
    label: "Version",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "type",
    label: "Type",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "createdDateUtc",
    label: "Creation",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Update",
    minWidth: 150,
    filtering: false,
  },
];

export const enrichmentsColumns: Column[] = [
  {
    id: "name",
    label: "Name",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "provider",
    label: "Provider",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "actualPrice",
    label: "Price",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "createdDateUtc",
    label: "Creation",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Update",
    minWidth: 150,
    filtering: false,
  },
];
