import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";
import {
  WidgetLocationsResponse,
  WidgetDetailsResponse,
  WidgetDeploysResponse,
} from "./types";

export default class AnalyticsApi {
  static async getWidgetDeploys(
    params: any
  ): Promise<AxiosResponse<WidgetDeploysResponse>> {
    return IXPortalApiPrivate.get("/api/Analytics/WidgetDeploys", params);
  }
  static async getWidgetDetails(
    params: any
  ): Promise<AxiosResponse<WidgetDetailsResponse>> {
    return IXPortalApiPrivate.get("/api/Analytics/WidgetDetails", params);
  }
  static async getWidgetLocations(
    params: any
  ): Promise<AxiosResponse<WidgetLocationsResponse>> {
    return IXPortalApiPrivate.get("/api/Analytics/WidgetLocations", params);
  }
}
