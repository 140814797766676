import { useAppDispatch, useAppSelector } from "redux/store/hooks/hooks";
import { useCallback } from "react";
import {
  getWidgetDeploysAction,
  getWidgetDetailsAction,
  getWidgetLocationsAction,
  AnalyticsParams,
  getLocationDetailsState,
  getWidgetDeploysState,
  getStepDetailsState,
  getDeploysLoading,
} from "entities/analytics";

export const useAnalytics = () => {
  const dispatch = useAppDispatch();
  const locationDetails = useAppSelector(getLocationDetailsState);
  const widgetDeploys = useAppSelector(getWidgetDeploysState);
  const stepDetails = useAppSelector(getStepDetailsState);
  const loading = useAppSelector(getDeploysLoading);

  const getWidgetDeploys = useCallback(
    (params?: AnalyticsParams) => dispatch(getWidgetDeploysAction({ params })),
    [dispatch]
  );
  const getWidgetDetails = useCallback(
    (params?: AnalyticsParams) => dispatch(getWidgetDetailsAction({ params })),
    [dispatch]
  );
  const getWidgetLocations = useCallback(
    (params?: AnalyticsParams) =>
      dispatch(getWidgetLocationsAction({ params })),
    [dispatch]
  );

  return {
    getWidgetDeploys,
    getWidgetDetails,
    getWidgetLocations,
    locationDetails,
    widgetDeploys,
    stepDetails,
    loading,
  };
};
