import styled from "styled-components";
import { Menu } from "@mui/material";

export const StyledHeaderDiv = styled.div`
  display: flex;
  border-bottom: 1px solid var(--silver);
  padding: 20px 2%;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledProfilePicture = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  background-color: var(--secondary);
  opacity: 0.7;
`;

export const StyledProfileButton = styled.div`
  background: var(--white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-left: 25px;
  cursor: pointer;
  :hover {
    background: rgba(98, 102, 234, 0.2);
    box-shadow: 10px 10px 40px rgba(111, 138, 231, 0.25);
    path {
      opacity: 1;
    }
  }
`;

export const StyledIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  position: relative;
`;

export const Ellipse = styled.div`
  position: absolute;
  right: 0px;
  top: 0;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--ix-platform-accent, #fca22b); ;
`;

export const StyledMenu = styled(Menu)`
  .MuiPaper-root.MuiPaper-elevation {
    border-radius: 20px;
    box-shadow: 10px 10px 40px rgba(111, 138, 231, 0.25);
    min-width: 180px;
  }
`;

export const StyledChevron = styled.div<{ open: boolean }>`
  svg {
    transition: 0.2s;
    transform: ${(props) => (props.open ? "rotateX(180deg)" : "rotateX(0deg)")};
    position: relative;
    top: 5px;
    path {
      fill: var(--primary);
    }
  }
`;
