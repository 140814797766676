import { createAction } from "@reduxjs/toolkit";
import { LinkedProductsPayload } from "../types";

export const getLinkedProductsAction = createAction<LinkedProductsPayload>(
  "products/linked/get"
);

export const productsActions = {
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",
  GET_PRODUCT: "GET_PRODUCT",
  GET_PRODUCT_FAILURE: "GET_PRODUCT_FAILURE",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",
  GET_PRODUCT_MODULES: "GET_PRODUCT_MODULES",
  GET_PRODUCT_MODULES_FAILURE: "GET_PRODUCT_MODULES_FAILURE",
  CREATE_PRODUCT: "CREATE_PRODUCT",
  CREATE_PRODUCT_FAILURE: "CREATE_PRODUCT_FAILURE",
  EDIT_PRODUCT: "EDIT_PRODUCT",
  EDIT_PRODUCT_FAILURE: "EDIT_PRODUCT_FAILURE",
  PUBLISH_PRODUCT: "PUBLISH_PRODUCT",
  PUBLISH_PRODUCT_FAILURE: "PUBLISH_PRODUCT_FAILURE",
  ADD_ATTRIBUTES_TO_PRODUCT: "ADD_ATTRIBUTES_TO_PRODUCT",
  ADD_ATTRIBUTES_TO_PRODUCT_FAILURE: "ADD_ATTRIBUTES_TO_PRODUCT_FAILURE",
  GET_PRODUCT_VERSIONS: "GET_PRODUCT_VERSIONS",
  GET_PRODUCT_VERSIONS_FAILURE: "GET_PRODUCT_VERSIONS_FAILURE",
  UPDATE_PRODUCT_ATTRIBUTE: "UPDATE_PRODUCT_ATTRIBUTE",
  UPDATE_PRODUCT_ATTRIBUTE_FAILURE: "UPDATE_PRODUCT_ATTRIBUTE_FAILURE",
  GET_PRODUCTS_BY_CATEGORY: "GET_PRODUCTS_BY_CATEGORY",
  GET_PRODUCTS_BY_MODULE_TEMPLATE: "GET_PRODUCTS_BY_MODULE_TEMPLATE",
  RESTORE_PRODUCT: "RESTORE_PRODUCT",
  RESTORE_PRODUCT_FAILURE: "RESTORE_PRODUCT_FAILURE",
  CLONE_PRODUCT: "CLONE_PRODUCT",
  CLONE_PRODUCT_FAILURE: "CLONE_PRODUCT_FAILURE",
  CREATE_NEW_PRODUCT_VERSION: "CREATE_NEW_PRODUCT_VERSION",
  CREATE_NEW_PRODUCT_VERSION_FAILURE: "CREATE_NEW_PRODUCT_VERSION_FAILURE",
};
