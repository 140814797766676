import styled from "styled-components";

export const RowItem = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  justify-content: space-between;
  margin: 5px 0;
  border-radius: 10px;
`;
export const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 1 0px;
`;

export const FieldTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #010101;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FieldName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #383874;
  opacity: 0.5;
  text-transform: uppercase;
`;

export const FieldValue = styled("div").withConfig({
  shouldForwardProp: (prop) => !["overflow"].includes(prop),
})<{ overflow?: boolean }>(({ overflow }) => {
  return `
  display: ${overflow ? "inline-block" : "flex"};
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  gap: 5px;
  background: #f2f2f5;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #494a63;
  cursor: ${overflow ? "pointer" : "auto "};
  width: 253px;
  text-overflow: ${overflow ? "ellipsis" : "initial"};
  overflow: ${overflow ? "hidden" : "visible"};
  white-space: ${overflow ? "nowrap" : "inherit"};
  `;
});
