import {
  EventPayload,
  EventsResponse,
  SingleEventsResponse,
  TriggerDefinitionsResponse,
} from "./types";
import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";

export default class EventsApi {
  static async getEvents(params: any): Promise<AxiosResponse<EventsResponse>> {
    return IXPortalApiPrivate.get("/api/Events", {
      params,
    });
  }

  static async createEvent(
    data: EventPayload
  ): Promise<AxiosResponse<SingleEventsResponse>> {
    return IXPortalApiPrivate.post("/api/Events", data);
  }

  static async editEvent(
    eventId: string,
    data: EventPayload
  ): Promise<AxiosResponse<SingleEventsResponse>> {
    return IXPortalApiPrivate.put(`/api/Events/${eventId}`, data);
  }

  static async deleteEvent(
    eventId: string
  ): Promise<AxiosResponse<SingleEventsResponse>> {
    return IXPortalApiPrivate.delete(`/api/Events/${eventId}`);
  }

  static async getTriggerDefinitions(): Promise<
    AxiosResponse<TriggerDefinitionsResponse>
  > {
    return IXPortalApiPrivate.get("/api/Events/trigger-definitions");
  }

  static async cloneEvent(
    eventId: string
  ): Promise<AxiosResponse<SingleEventsResponse>> {
    return IXPortalApiPrivate.post(`/api/Events/${eventId}/clone`);
  }

  static async getEvent(eventId: string): Promise<AxiosResponse<Event>> {
    return IXPortalApiPrivate.get(`/api/Events/${eventId}`);
  }
}
