export const addOrRemove = (array: string[], item: string): string[] => {
  const exists = array.includes(item);
  if (exists) {
    return array.filter((c) => {
      return c !== item;
    });
  } else {
    return [...array, item];
  }
};
