import React from "react";
import { Typography } from "@mui/material";

export const Title = ({ children }: any) => {
  return (
    <Typography
      sx={{
        color: "#383874",
        fontSize: "24px",
        fontWeight: "600",
        lineHeight: "32px",
      }}
    >
      {children}
    </Typography>
  );
};
