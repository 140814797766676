import styled from "styled-components";
import { Typography } from "@mui/material";

export const StyledTitle = styled(Typography)`
  &.MuiTypography-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 48px;
    letter-spacing: 0.01em;
    color: #190a3a;
  }
`;

export const BackDiv = styled.div`
  padding: 20px 0 0 0;
  display: flex;
  align-items: center;
`;
