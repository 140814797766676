import React, { useState, useEffect, useContext } from "react";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";

import { StyledTypography } from "./styles";

import TableControls from "../TableControls";
import DataEnrichTable from "../Table";
import CreateRequestModal from "../Modals/CreateRequest";
import { AssociatedProductsModal } from "features/associatedProducts";
import TablePaper from "shared/ui/TablePaper";

import { RootState } from "redux/store";
import { initialColumns, DataEnrichmentData } from "./types";
import { Column } from "../Table/types";
import { ProductResponse } from "shared/api/products/types";
import { OrderTypes } from "shared/config/types/Sorting";
import { Module, ModuleTemplate } from "api/types";

import { AssociatedProductsContext } from "pages/IntegrationHub";

import { moduleTemplatesActions } from "entities/moduleTemplates";
import { productsActions } from "entities/products";
import { getProduct } from "entities/products/model/selectors";
import { searchByValue, sortByOrder } from "utils/array";
import { useAppSelector } from "redux/store/hooks/hooks";
import { useModule } from "features/modules/model/hooks/useModule";

interface DataEnrichmentProps {
  dataType?: string;
  listProduct: boolean;
  product?: ProductResponse;
}

const DataEnrichment = ({
  dataType,
  listProduct,
  product,
}: DataEnrichmentProps) => {
  const { viewProducts }: any = useContext(AssociatedProductsContext);
  const [openCreateRequest, setOpenCreateRequest] = useState(false);
  const [openListProductModal, setOpenListProductModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [columns, setColumns] = useState<Column[]>(initialColumns);
  const [includedEnrichments, setIncludedEnrichments] = useState<
    ModuleTemplate[]
  >([]);
  const [currentIncluded, setCurrentIncluded] = useState<ModuleTemplate[]>([]);
  const [currentAddon, setCurrentAddon] = useState<ModuleTemplate[]>([]);
  const [addOnEnrichments, setAddOnEnrichments] = useState<ModuleTemplate[]>(
    []
  );
  const [categoryId, setCategoryId] = useState("");
  const [enrichId, setEnrichId] = useState("");
  const [sortType, setSortType] = useState("");
  const [order, setOrder] = useState<OrderTypes | null>(null);
  const isConfig = location.pathname.includes("create_new_product");
  const currentProduct = useAppSelector(getProduct);
  const moduleTemplates = useSelector(
    (state: RootState) => state.moduleTemplates.items
  );
  const productModules = useSelector(
    (state: RootState) => state.products.modules
  );

  const { moduleOrderQueue } = useModule();
  const dispatch = useDispatch();

  const handleSearch = (searchQuery: string) => {
    if (searchQuery) {
      const searchOutcome = searchByKey("name");
      const { searchResult, addOnSearchResult } = searchOutcome;
      if (searchResult) {
        setIncludedEnrichments([...searchResult]);
      }
      if (addOnSearchResult) {
        setAddOnEnrichments([...addOnSearchResult]);
      }
      if (!searchResult.length && !addOnSearchResult.length) {
        searchProvider();
      }
    }
  };

  const searchProvider = () => {
    const searchOutcome = searchByKey("provider");
    const { searchResult, addOnSearchResult } = searchOutcome;
    if (searchResult) {
      setIncludedEnrichments([...searchResult]);
    }
    if (addOnSearchResult) {
      setAddOnEnrichments([...addOnSearchResult]);
    }
  };

  const searchByKey = (key: string) => {
    const searchResult = searchByValue(currentIncluded, key, searchQuery);
    const addOnSearchResult = searchByValue(currentAddon, key, searchQuery);
    return { searchResult, addOnSearchResult };
  };

  const openCreateRequestModal = () => {
    setOpenCreateRequest(!openCreateRequest);
  };

  const [searchQuery] = useDebounce(searchValue, 2000);

  useEffect(() => {
    if (currentProduct?.name) {
      processEnrichments(currentProduct);
    }
  }, [currentProduct]);

  useEffect(() => {
    if (product) getProductModules();
    if (!moduleTemplates.length) {
      dispatch({ type: moduleTemplatesActions.GET_MODULE_TEMPLATES });
    }
  }, []);

  useEffect(() => {
    if (moduleTemplates.length) {
      if (!product && !isConfig) {
        const includedData = getEnrichments([...moduleTemplates]).filter(
          (item: ModuleTemplate) => item.isIncluded === true
        );
        const addOnData = getEnrichments([...moduleTemplates]).filter(
          (item: ModuleTemplate) => item.isIncluded === false
        );
        setCurrentIncluded([...includedData]);
        setIncludedEnrichments([...includedData]);
        setCurrentAddon([...addOnData]);
        setAddOnEnrichments([...addOnData]);
      } else if (product) {
        processEnrichments(product);
      }
    }
  }, [moduleTemplates]);

  const getEnrichments = (data: ModuleTemplate[]) => {
    return data.filter(
      (item: ModuleTemplate) =>
        item.moduleConfiguration.moduleType === "Enrichment"
    );
  };

  useEffect(() => {
    if (searchQuery) {
      handleSearch(searchQuery);
    } else {
      if (currentIncluded.length) setIncludedEnrichments([...currentIncluded]);
      if (currentAddon.length) setAddOnEnrichments([...currentAddon]);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!listProduct) {
      const filteredColumns = [...columns].filter(
        (col: Column) => col.id !== "products"
      );
      setColumns(filteredColumns);
    }
  }, [listProduct]);

  useEffect(() => {
    if (order) {
      const filters = {
        name: order.label,
        sortKey: order.type,
      };
      if (sortType === "addOn") {
        const sorted = sortByOrder([...addOnEnrichments], filters);
        if (sorted.length > 0) setAddOnEnrichments(sorted);
      }
      if (sortType === "included") {
        const sorted = sortByOrder([...includedEnrichments], filters);
        if (sorted.length > 0) setIncludedEnrichments(sorted);
      }
    }
  }, [order, sortType]);

  const getProductModules = () => {
    dispatch({
      type: productsActions.GET_PRODUCT_MODULES,
      data: {
        productId: product?.id,
        version: product?.version,
      },
    });
  };

  const processEnrichments = (currentProduct: ProductResponse) => {
    const categoryEnrichments = moduleTemplates.filter(
      (item: ModuleTemplate) =>
        item.categoryId === currentProduct.categoryId &&
        item.moduleConfiguration.moduleType === "Enrichment"
    );
    const included = productModules.map(
      (item: Module) => item.moduleTemplateId
    );
    const productEnrichments = categoryEnrichments.filter(
      (item: ModuleTemplate) => included.includes(item.id)
    );
    const addOnEnrichments = categoryEnrichments.filter(
      (item: ModuleTemplate) => !included.includes(item.id)
    );
    setIncludedEnrichments([...productEnrichments]);
    setCurrentIncluded([...productEnrichments]);
    setAddOnEnrichments([...addOnEnrichments]);
    setCurrentAddon([...addOnEnrichments]);
  };

  const hanldeEdit = (record: ModuleTemplate, editName?: string) => {
    if (!product && listProduct) setOpenListProductModal(!openListProductModal);
    setCategoryId(record.categoryId);
    setEnrichId(record.id);
    // TODO: if (record.isIncluded) {
    if (editName === "Deactivate") handleDeactivate(record);
    else handleActivate(record);
  };

  const handleActivate = (record: ModuleTemplate) => {
    if (product) {
      dispatch({
        type: moduleTemplatesActions.ADD_MODULE_TEMPLATE,
        data: {
          version: product.version,
          templateId: record.id,
          id: product.id,
          moduleOrderQueue: moduleOrderQueue + 1,
          outcomeType: "Lead",
        },
      });
      const newAddOnEnrichments = [...currentAddon].filter(
        (item: ModuleTemplate) => item.id !== record.id
      );
      const newIncludes = [...currentIncluded, record];
      setCurrentAddon(newAddOnEnrichments);
      setAddOnEnrichments(newAddOnEnrichments);
      setCurrentIncluded(newIncludes);
      setIncludedEnrichments(newIncludes);
    }
  };

  const handleDeactivate = (record: ModuleTemplate) => {
    if (product) {
      dispatch({
        type: moduleTemplatesActions.REMOVE_MODULE_TEMPLATE,
        data: {
          version: product.version,
          templateId: record.id,
          id: product.id,
        },
      });
      const newIncludedEnrichments = [...currentIncluded].filter(
        (item: ModuleTemplate) => item.id !== record.id
      );
      const newAddOns = [...currentAddon, record];
      setIncludedEnrichments(newIncludedEnrichments);
      setCurrentIncluded(newIncludedEnrichments);
      setCurrentAddon(newAddOns);
      setAddOnEnrichments(newAddOns);
    }
  };

  const handleViewAll = (record: DataEnrichmentData) => {
    viewProducts(record.id);
  };

  const sortData = (order: OrderTypes, type: string) => {
    setOrder(order);
    setSortType(type);
  };

  return (
    <TablePaper>
      <TableControls
        activeTab={"Data Enrichment"}
        handleOpen={openCreateRequestModal}
        searchValue={searchValue}
        handleSearch={(e: any) => setSearchValue(e.target.value)}
      />
      <StyledTypography>INCLUDED</StyledTypography>
      <DataEnrichTable
        rows={includedEnrichments}
        columns={columns}
        rowHasChip={true}
        chipIndex={0}
        chipName={listProduct ? "Add to product" : "Deactivate"}
        isCompact={true}
        onEdit={hanldeEdit}
        onView={handleViewAll}
        hideCheckbox={true}
        heightAuto={true}
        setSortOrder={(order: OrderTypes) => sortData(order, "included")}
      />
      <StyledTypography>ADD-ON</StyledTypography>
      <DataEnrichTable
        rows={addOnEnrichments}
        columns={columns}
        rowHasChip={true}
        chipIndex={0}
        chipName={listProduct ? "Add to product" : "Activate"}
        onEdit={hanldeEdit}
        onView={handleViewAll}
        hideCheckbox={true}
        heightAuto={true}
        setSortOrder={(order: OrderTypes) => sortData(order, "addOn")}
      />
      <CreateRequestModal
        open={openCreateRequest}
        handleClose={() => setOpenCreateRequest(!openCreateRequest)}
        requestCategory={dataType}
      />
      <AssociatedProductsModal
        open={openListProductModal}
        categoryId={categoryId}
        enrichId={enrichId}
        handleClose={() => setOpenListProductModal(!openListProductModal)}
        type={"dataEnrichment"}
      />
    </TablePaper>
  );
};

export default DataEnrichment;
