import React, { useState } from "react";
import { Column } from "shared/config/types/Table";
import { OrderTypes } from "shared/config/types/Sorting";
import { IconWrap, MenuWrapper, SortLabel } from "./styles";
import FilterButton from "./FilterButton";
import { SORT } from "shared/config/enums/Sorting";
import { Menu, Divider } from "@mui/material";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import SearchMultiSelect from "./SearchMultiSelect";

const sortOrders: OrderTypes[] = [
  {
    type: SORT.ASC,
  },
  {
    type: SORT.DESC,
  },
];

export interface TableSortingProps {
  headCell?: Column;
  rows: any;
  order: OrderTypes;
  setOrder: (data: OrderTypes) => void;
  setProduct: (data: any) => void;
}

export interface SortRenderProps {
  headCell: Column;
}
const TableSorting = ({
  headCell = undefined as never,
  setOrder,
  order,
  rows,
  setProduct,
}: TableSortingProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOrder = (data: OrderTypes) => {
    setOrder && setOrder(data);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SortLabel data-testid="filterModal_label">
        {headCell.label}
        <IconWrap
          key={headCell.id}
          open={open}
          applied={order.label === headCell.id}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <FilterIcon />
        </IconWrap>
      </SortLabel>
      <Menu
        keepMounted={true}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        data-testid="filterModal_menu"
      >
        <MenuWrapper data-testid="filterModal_menu">
          {sortOrders.map((item: OrderTypes) => (
            <FilterButton
              key={item.type}
              type={item.type}
              label={headCell.id}
              setOrder={() =>
                handleOrder({ type: item.type, label: headCell.id })
              }
              order={order}
            />
          ))}
          {headCell.id === "ProductName" && (
            <>
              <Divider sx={{ marginBottom: "15px" }} />
              <SearchMultiSelect
                id={`${headCell.id}_search`}
                rows={rows}
                handleClose={handleClose}
                onChange={setProduct}
              />
            </>
          )}
        </MenuWrapper>
      </Menu>
    </>
  );
};

export default TableSorting;
