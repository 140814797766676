import styled from "styled-components";
import { Typography, Dialog, DialogActions, DialogTitle } from "@mui/material";

export const Wrapper = styled.div`
  height: calc(100% - 196px);
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  .MuiButton-outlined {
    margin-bottom: 16px;
  }
`;

export const StyledTypography = styled(Typography)`
  &.warnings {
    color: "var(--grey)";
    font-size: 0.75rem !important;
  }

  &.centered {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  &.created {
    font-weight: 500 !important;
    background-color: rgba(0, 156, 16, 0.12);
    color: #009c10;
    margin: 40px 32px;
    padding: 12px;
    display: flex;
    font-size: 14px;
    align-items: center;
    .MuiSvgIcon-root {
      margin-right: 6px;
    }
  }
`;
export const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    justify-content: flex-end;
    font-family: "Inter";
  }
  .MuiDialog-paper {
    max-height: 100%;
    height: 100%;
    max-width: 640px;
    width: 640px;
    margin: inherit;
    position: relative;
    overflow-x: hidden;
  }
`;
export const StyledActions = styled(DialogActions)<{ centered: number }>`
  &.MuiDialogActions-root {
    padding: ${(props) =>
      props.centered == 1 ? "20px 20px" : "20px 20px 13px 0px"};
    justify-content: ${(props) => (props.centered == 1 ? "center" : "left")};
    position: absolute;
    bottom: 0;
    width: ${(props) => (props.centered == 1 ? "100%" : "84%")};
    left: ${(props) => (props.centered == 1 ? "0" : "8%")};
    border-top: 1px solid #dadae3;
    background: #ffffff;
  }
`;

export const StyledTitle = styled(DialogTitle)<{ sidepadding: string }>`
  &.MuiTypography-root {
    font-weight: 600;
    font-size: 24px;
    line-height: 48px;
    color: var(--headings);
    padding: ${(props) => `20px ${props.sidepadding}px`};
    border-bottom: 1px solid var(--silver);
  }
`;

export const List = styled.ul`
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  border-top: 1px solid #dadae3;

  li {
    color: var(--ix-platform-headings, #010101);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    padding: 10px;
    border-bottom: 1px solid #dadae3;
    justify-content: space-between;
    display: flex;
    align-items: center;

    &:nth-child(even) {
      background: #f9f9f9;
    }
  }
`;
