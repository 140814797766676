import React, { useState } from "react";
import { StyledActions, StyledDialog } from "shared/ui/IXDialog";
import { StyledMenuItem, StyledSelect } from "shared/ui/Select/styles";
import { SelectChangeEvent } from "@mui/material/Select";
import { Box, Typography } from "@mui/material";
import Button from "shared/ui/Button";
import { RoutesList } from "shared/routes";
import { Application, Product } from "shared/api/widgets/types";

interface Props {
  onClose: () => void;
  open: boolean;
  products: Product[] | null;
  apps: Application[] | null;
  widgetsConfigID?: string | null;
  configuration?: Record<string, unknown>;
}

const ProductsOptionsModal = ({
  open,
  onClose,
  products,
  apps,
  widgetsConfigID,
  configuration,
}: Props) => {
  const hostname = window.location.origin;
  const [selectValue, setSelectValue] = useState<Record<string, string>>({
    productId: "",
    productName: "",
  });
  const [selectValueApps, setSelectValueApps] = useState<
    Record<string, string>
  >({
    clientId: "",
    name: "",
  });

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setSelectValue(event.target.value as Record<string, string>);
  };
  const handleChangeApps = (event: SelectChangeEvent<unknown>) => {
    setSelectValueApps(event.target.value as Record<string, string>);
  };
  const onSubmit = () => {
    const newObject = {
      productId: selectValue.productId,
      clientId: selectValueApps.clientId,
      ...(widgetsConfigID
        ? { widgetId: widgetsConfigID }
        : { configuration: configuration }),
    };
    localStorage.setItem("widgetConfig", JSON.stringify(newObject));
    window.open(
      `${hostname}/${RoutesList.WIDGET_VIEW}`,
      "_blank",
      "noopener,noreferrer"
    );
    onClose();
  };

  return (
    <StyledDialog id="productOptions" onClose={onClose} open={open}>
      {!products && !apps && (
        <Typography variant="body1" sx={{ mb: 1 }} fontFamily="Inter">
          It looks like this widget is not linked to a published product and/or
          a distribution. If you are setting up the product with this widget,
          you can link it to a distribution, and activate this widget on the
          product. Once activated, publish the product and come back here to
          view the widget.
        </Typography>
      )}
      {!products && apps && (
        <Typography variant="body1" sx={{ mb: 1 }} fontFamily="Inter">
          It looks like this widget is not linked to a published product. If you
          are setting up the product with this widget you can activate this
          widget on the product
        </Typography>
      )}
      {products && (
        <Box sx={{ mb: 2, width: "100%" }}>
          <Typography
            variant="body1"
            sx={{ mb: 1 }}
            fontFamily="Inter"
            fontWeight={700}
          >
            Please select a product
          </Typography>
          <StyledSelect
            onChange={handleChange}
            value={selectValue}
            fullWidth={true}
            renderValue={(value: any) => {
              return value.productName;
            }}
          >
            {products.map((item) => {
              return (
                <StyledMenuItem key={item.productId} value={item as any}>
                  {item.productName}
                </StyledMenuItem>
              );
            })}
          </StyledSelect>
        </Box>
      )}
      {products && !apps && (
        <Typography variant="body1" sx={{ mb: 1 }} fontFamily="Inter">
          It looks like this widget is not linked to a distribution. If you are
          setting up the product with this widget, you can link it to a
          distribution
        </Typography>
      )}
      {apps && (
        <Box>
          <Typography
            variant="body1"
            sx={{ mb: 1 }}
            fontFamily="Inter"
            fontWeight={700}
          >
            Please select a distribution
          </Typography>
          <StyledSelect
            onChange={handleChangeApps}
            value={selectValueApps}
            fullWidth={true}
            renderValue={(value: any) => {
              return value.name;
            }}
          >
            {apps.map((item) => {
              return (
                <StyledMenuItem key={item.clientId} value={item as any}>
                  {item.name}
                </StyledMenuItem>
              );
            })}
          </StyledSelect>
        </Box>
      )}
      <StyledActions sx={{ mt: 3 }}>
        <Button
          onClick={onClose}
          variant="outlined"
          data-testid="view_cancel_button"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          data-testid="view_button"
          disabled={!selectValue.productId || !selectValueApps.clientId}
        >
          View
        </Button>
      </StyledActions>
    </StyledDialog>
  );
};

export default ProductsOptionsModal;
