import styled from "styled-components";
import { Button, Typography } from "@mui/material";
import OutlinedCheckbox from "components/FormComponents/OutlinedCheckbox";
export const OptionsWrap = styled.div`
  max-height: 200px;
  min-height: 50px;
  overflow-y: scroll;
`;

export const ButtonsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FieldWrap = styled.div`
  padding: 0 15px;
  > div.MuiTextField-root {
    width: 100%;
    margin-bottom: 10px;
    fieldset {
      border-color: var(--primary);
      transition: 0.2s;
    }
    input:focus + fieldset {
      border: 1px solid var(--secondary);
      box-shadow: 0px 0px 10px rgba(98, 102, 234, 0.5);
    }
  }
`;

export const NoItemsWrap = styled.div`
  padding: 10px 15px;
  text-align: center;
  color: var(--grey);
  font-size: 16px;
`;

export const ControlsWrap = styled.div`
  color: var(--primary);
  display: flex;
  justify-content: space-between;
  padding: 15px;
  button {
    letter-spacing: 2px;
    font-size: 16px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
  }

  button + button {
    color: var(--secondary);
  }
`;

export const StyledButton = styled(Button)<{ isActive?: boolean }>`
  &.MuiButton-root {
    justify-content: flex-start;
    padding: 0 10px;
    text-transform: none;
    color: ${(props) => (props.isActive ? "var(--secondary)" : "var(--black)")};
    :hover {
      color: ${(props) =>
        props.isActive ? "var(--secondary)" : "var(--black)"};
      background: var(--ghost);
    }
  }
`;

export const StyledCheckbox = styled(OutlinedCheckbox)`
  &.MuiCheckbox-root {
    margin-left: -9px;
    margin-right: 5px;
    color: var(--secondary);
    &.Mui-checked {
      color: var(--secondary);
    }
  }
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    padding: 8px 16px;
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`;
