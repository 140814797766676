import React, { FC, memo } from "react";
import { StyledButton } from "./styles";
import EastIcon from "@mui/icons-material/East";
import CheckIcon from "@mui/icons-material/Check";
import { OrderTypes } from "../../../config/types/Sorting";
import { SORT } from "../../../config/enums/Sorting";

interface Props extends OrderTypes {
  setOrder: () => void;
  order: OrderTypes | null;
}

const FilterButton: FC<Props> = ({ type, label, setOrder, order }) => {
  const isActive = order?.type === type && order?.label === label;
  return (
    <StyledButton
      disableRipple
      onClick={setOrder}
      isActive={isActive}
      data-testid="filterModal_button"
    >
      <div>
        {type === SORT.ASC ? (
          <>
            A <EastIcon width={20} height={20} /> Z
          </>
        ) : (
          <>
            Z <EastIcon width={20} height={20} /> A
          </>
        )}
      </div>
      {isActive && <CheckIcon />}
    </StyledButton>
  );
};

export default memo(FilterButton);
