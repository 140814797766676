import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import { Box, Divider, MenuItem, Tooltip, Typography } from "@mui/material";
import {
  Ellipse,
  StyledChevron,
  StyledHeaderDiv,
  StyledIconWrap,
  StyledMenu,
  StyledProfileButton,
  StyledProfilePicture,
} from "./styles";
import { UserProfileModal } from "features/users/ui";

import { useAppSelector } from "redux/store/hooks/hooks";

import { getCurrentUserProfile } from "entities/users";
import { getNewNotificationsCount } from "entities/platformNotifications";
import { RoutesList } from "shared/routes";

const settings = ["Profile", "Reset Password Request"];

const Header = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const anchorEl = document.getElementById("profile");

  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [openProfile, setOpenProfile] = useState<boolean>(false);

  const userProfile = useAppSelector(getCurrentUserProfile);
  const newNotificationCount = useAppSelector(getNewNotificationsCount);

  useEffect(() => {
    if (userProfile) {
      setUserName(userProfile.firstName + " " + userProfile.lastName);
    }
  }, [userProfile]);

  const logOut = (instance: any) => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
    };
    localStorage.removeItem("tenant-name");
    instance.logoutRedirect(logoutRequest);
  };

  const userAction = (action: string) => {
    if (action === "Profile") setOpenProfile(!openProfile);
    if (action === "Reset Password Request") console.log("reset password");
    setOpen(!open);
  };

  return (
    <StyledHeaderDiv data-testid="header">
      <StyledProfileButton
        data-testid="header_notificationButton"
        onClick={() => navigate(RoutesList.NOTIFICATIONS)}
      >
        <Tooltip title="Open notifications">
          <StyledIconWrap>
            <NotificationsOutlinedIcon />
            {newNotificationCount ? <Ellipse /> : null}
          </StyledIconWrap>
        </Tooltip>
      </StyledProfileButton>
      <StyledProfileButton
        onClick={() => setOpen(!open)}
        data-testid="header_profileButton"
      >
        <Tooltip title="Open settings">
          <StyledIconWrap id={"profile"}>
            <PersonOutlineOutlinedIcon />
          </StyledIconWrap>
        </Tooltip>
        <StyledMenu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          data-testid="header_profileMenu"
        >
          <Box>
            <Box
              sx={{
                color: "var(--secondary)",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: "10px",
                padding: "0 15px",
              }}
            >
              <StyledProfilePicture>
                <Tooltip title="Profile Picture">
                  <StyledIconWrap>
                    <PersonOutlineOutlinedIcon />
                  </StyledIconWrap>
                </Tooltip>
              </StyledProfilePicture>
              <Typography
                sx={{ marginBottom: 0, fontWeight: 700, marginLeft: "10px" }}
                paragraph
                data-testid="header_userName"
              >
                {userName}
              </Typography>
            </Box>
            <Divider />
            {settings.map((setting, index: number) => (
              <MenuItem
                sx={{
                  padding: "0 15px",
                  margin: "5px 0",
                  fontSize: "18px",
                  color: index === 1 ? "var(--silver)" : "default",
                }}
                key={setting}
                onClick={() => userAction(setting)}
                data-testid={`headerMenu_${setting}Button`}
              >
                <Typography>{setting}</Typography>
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              sx={{ padding: "0 15px", margin: "5px 0", fontSize: "18px" }}
              onClick={() => logOut(instance)}
              data-testid="headerMenu_logoutButton"
            >
              <Typography textAlign="center">Sign Out</Typography>
            </MenuItem>
          </Box>
        </StyledMenu>
      </StyledProfileButton>
      <StyledChevron
        open={open}
        onClick={() => setOpen(!open)}
        data-testid="header_expandMoreButton"
      >
        <ExpandMoreIcon />
      </StyledChevron>
      <UserProfileModal
        open={openProfile}
        handleClose={() => setOpenProfile(!openProfile)}
        profile={userProfile}
      />
    </StyledHeaderDiv>
  );
};

export default Header;
