import React, { useEffect, useState } from "react";
import { ListItemText, OutlinedInput } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { StyledCheckbox, StyledMenuItem, StyledSelect } from "./styles";
import Label from "components/FormComponents/Label";

interface SelectProps {
  options: any;
  id: string;
  title: string;
  value: any;
  onChange?: (e: any) => void;
  multiple?: boolean;
  error?: boolean;
  hideCreate?: boolean;
  disabled?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  label?: string;
  required?: boolean;
  subOptions?: Record<string, string[]>;
}
export const RuleSelect = ({
  options,
  id,
  onChange,
  multiple,
  value,
  error,
  disabled,
  placeholder,
  fullWidth = true,
  label,
  required,
}: SelectProps) => {
  const [selectedOptions, setSelectedOptions] = useState(options);
  useEffect(() => {
    setSelectedOptions(options);
  }, [options]);
  const renderSelectGroup = (option: any) => {
    const data = option.rules.map((item: any) => {
      const dot = JSON.stringify({
        name: item.name,
        value: item.id,
        module: option.id,
      });
      return (
        <StyledMenuItem
          key={item.id}
          value={dot}
          sx={{ marginLeft: "15px" }}
          data-testid={`select_${item}Option`}
        >
          <StyledCheckbox
            checked={
              !!value?.find((item: any) => {
                return JSON.parse(item).value === JSON.parse(dot).value;
              })
            }
          />
          <ListItemText primary={item.name} />
        </StyledMenuItem>
      );
    });

    return [
      option.rules.length > 0 && (
        <StyledMenuItem
          key={option.id}
          data-testid={`select_${option.i}Option`}
        >
          <ListItemText primary={option.name} />
        </StyledMenuItem>
      ),
      data,
    ];
  };
  const renderIconComponent = (props: any) => (
    <KeyboardArrowDownIcon {...props} />
  );

  return (
    <>
      {label && <Label label={label} disabled={disabled} danger={required} />}
      <StyledSelect
        multiple={multiple}
        fullWidth={fullWidth}
        id={id}
        value={value}
        onChange={onChange}
        input={<OutlinedInput placeholder={placeholder ?? "Please select"} />}
        renderValue={(selected: any) => {
          return Array.isArray(selected)
            ? selected.map((item) => JSON.parse(item).name).join(", ")
            : selected;
        }}
        error={error}
        disabled={disabled}
        label="test"
        IconComponent={renderIconComponent}
      >
        {selectedOptions.map((option: any) => {
          return renderSelectGroup(option);
        })}
      </StyledSelect>
    </>
  );
};
