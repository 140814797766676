import styled from "styled-components";
import { DateRangePicker } from "react-date-range";
import { Button } from "@mui/material";
import arrowIcon from "assets/icons/arrow.svg";
import arrowActiveIcon from "assets/icons/arrowActive.svg";

export const StyledDateRangePicker = styled(DateRangePicker)`
  .rdrCalendarWrapper {
    background: var(--Primary-Light, #f7f9fb);
    box-shadow: 0px 0px 10px 0px rgba(56, 56, 116, 0.25);
    z-index: 1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .rdrDefinedRangesWrapper {
    position: absolute;
    border-radius: 8px;
    background: var(--Shading-White, #fff);
    box-shadow: 0px 0px 10px 0px rgba(56, 56, 116, 0.25);
    padding: 16px 0px;
    right: calc(100% - 12px);
    top: -10px;
    padding-right: 20px;
    min-width: 300px;
    order: 1;
    .rdrInputRanges {
      display: none;
    }
  }

  // arrows
  .rdrMonthAndYearWrapper {
    padding: 0 10px;
    width: 100%;
    align-items: baseline;
  }
  .rdrNextButton,
  .rdrPprevButton {
    background: transparent;
  }
  .rdrWeekDay {
    color: var(--Text-black, #010101);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
  }

  .rdrDays {
    display: flex;
    font-size: 12px;
    color: var(--primary);
    flex-wrap: wrap;
  }

  .rdrDayStartOfMonth {
    .rdrInRange,
    .rdrDayInPreview {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    .rdrDayInPreview {
      border-left: 1px solid var(--secondary);
    }
  }

  .rdrDayEndOfMonth {
    .rdrInRange,
    .rdrDayInPreview {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
    .rdrDayInPreview {
      border-right: 1px solid var(--secondary);
    }
  }
  .rdrDayNumber span {
    color: var(--Text-black, #010101);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: #ffffff;
  }
  .rdrDay {
    padding: 0;
    border: none;
    background: transparent;
    position: relative;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rdrDayStartPreview {
    border: 1px solid var(--secondary);
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    border-right: none;
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 0;
    box-sizing: border-box;
  }
  .rdrDayEndPreview {
    border: 1px solid var(--secondary);
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-left: none;
    position: absolute;
    top: 4px;
    bottom: 4px;
    right: 4px;
    left: 0;
    box-sizing: border-box;
  }
  .rdrDayInPreview {
    border-top: 1px solid var(--secondary);
    border-bottom: 1px solid var(--secondary);
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 0;
    width: 100%;
  }
  .rdrDayStartPreview.rdrDayEndPreview {
    border-left: 1px solid var(--secondary);
    right: 4px;
    left: 4px;
  }
  .rdrDayPassive.rdrDay .rdrDayNumber {
    display: none;
  }
  .rdrDayPassive {
    pointer-events: none;
    > .rdrInRange,
    .rdrEndEdge {
      display: none;
    }
    &.rdrDay {
      .rdrDayNumber {
        border-radius: 0;
        span {
          color: transparent;
        }
      }
    }

    .rdrStartEdge,
    .rdrDayInPreview,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      display: none;
    }
  }
  .rdrStartEdge ~ .rdrDayNumber,
  .rdrEndEdge ~ .rdrDayNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: var(--secondary);
    span {
      color: var(--white);
    }
  }
  .rdrInRange {
    position: absolute;
    top: 4px;
    background: var(--Secondary-Indigo, #95a4fc);
    left: 0;
    width: 100%;
    height: 40px;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    width: 50%;
    position: absolute;
    background: var(--Secondary-Indigo, #95a4fc);
    height: 40px;
    top: 4px;
    z-index: 0;
  }
  .rdrStartEdge {
    right: 0;
  }
  .rdrEndEdge {
    left: 0;
  }
  .rdrEndEdge.rdrStartEdge {
    display: none;
  }

  .rdrNextPrevButton {
    display: block;
    width: 24px;
    height: 24px;
    border: none;
  }

  .rdrNextPrevButton i {
    display: block;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
  }

  .rdrPprevButton i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent #383874 transparent transparent;
    transform: translate(-3px, 0px);
  }
  .rdrNextButton i {
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent #383874;
    transform: translate(3px, 0px);
  }
  .rdrDateDisplay {
    margin: 0;
    position: relative;
    &:before {
      content: "-";
      position: absolute;
      left: 43%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: var(--Text-Dark-grey, #383874);
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
  }

  .rdrMonth {
    padding: 12px;
    width: 360px;
  }
  .rdrMonthName {
    padding: 16px 24px 12px 24px;
    color: var(--Text-Dark-grey, #383874);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .rdrMonthAndYearPickers {
    padding: 16px 24px 12px 24px;
    color: var(--Text-Dark-grey, #383874);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    justify-content: start;
  }
  .rdrWeekDays {
    display: flex;
  }

  // inputs
  .rdrDateDisplayWrapper {
    padding: 16px 12px 12px 24px;
    border-bottom: 1px solid var(--Neutral-Light-grey, #b9b9c9);

    .rdrDateInput {
      input {
        text-align: left;
        border: none;
        background: transparent;
        width: 100%;
        color: var(--Text-Dark-grey, #383874);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        padding: 0;
      }

      .rdrDateInput + .rdrDateInput {
        margin-left: 15px;
      }
    }
  }
  .rdrStaticRange {
    text-align: left;
    padding: 12px 16px;
    background: var(--white);
    border: none;
    cursor: pointer;
    position: relative;
    .rdrStaticRangeLabel {
      color: var(--Common-colors-Neutral-Black, #000);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    &:after {
      content: "";
      color: var(--Common-colors-Neutral-Black, #000);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      background: url(${arrowIcon});
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &.rdrStaticRangeSelected,
    &:hover {
      .rdrStaticRangeLabel {
        color: var(--secondary);
      }
      &:after {
        background: url(${arrowActiveIcon});
      }
    }
  }
  .rdrMonthsVertical {
    align-items: center;
  }
`;

export const StyledButtonsWrap = styled.div`
  border-top: 1px solid var(--Neutral-Silver, #dadae3);
  background: var(--Primary-Light, #f7f9fb);
  display: flex;
  padding: 16px var(--32, 32px);
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  position: relative;
  z-index: 1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const StyledContainer = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: calc(100% - 20px);
  border-radius: 8px;
  background: var(--Primary-Light, #f7f9fb);
  box-shadow: 0px 0px 10px 0px rgba(56, 56, 116, 0.25);
  z-index: 1;
`;

export const StyledButton = styled(Button)<{ color: string }>`
  &.MuiButton-root {
    width: 48%;
    font-size: 14px;
    text-transform: none;
    border: 1px solid var(--grey);
    background-color: var(--secondary);
    border-radius: 100px;
    :hover {
      background-color: var(--secondary);
    }
    :first-child {
      background-color: var(--white);
      color: var(--secondary);
    }
  }
`;
