import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "shared/ui/Modal";
import Button from "shared/ui/Button";
import FormGroup from "components/FormComponents/FormGroup";
import Select from "shared/ui/Select";
import { InputBlock } from "./styles";
import { Rule, RulePayload } from "shared/api/modules/types";
import { useModule, useRule } from "features/modules";
import { Outcomes, RuleSelect } from "entities/modules";
import Label from "components/FormComponents/Label";

interface ModalProps {
  handleClose: () => void;
  modelName: string;
  ruleData: Rule | null;
  open: boolean;
  moduleId: string;
}

type Data = Omit<RulePayload, "parameters"> & {
  parameters: string[];
  outcomeType?: Outcomes;
};

const options_rule_type = [
  "Validation",
  "Calculation",
  "ConditionalCalculation",
];

const options_type = ["String", "Integer", "Decimal", "Bool"];
const outcome_type = ["Bind", "Lead", "Quote"];

const defaultValues: Data = {
  name: "",
  type: "",
  outputType: "",
  parameters: [],
  expression: "",
  onSuccessExpression: "",
  onFailureExpression: "",
  message: "",
  link: "",
  outcomeType: "Bind",
  attributeName: "",
};

export const EditRuleForm = (props: ModalProps) => {
  const { handleClose, ruleData, modelName, open, moduleId } = props;
  const { modules } = useModule();
  const { isLoading, editRule } = useRule();
  const defaultParams = useMemo(() => {
    return ruleData?.parameters.reduce<string[]>((accum, param) => {
      const test = modules.find((module) => module.id === param.moduleId);
      if (test) {
        accum.push(JSON.stringify({ name: test.name, value: test.id }));
        if (test?.rules) {
          const ruleData = test.rules.find((data) => data.id === param.ruleId);
          if (ruleData) {
            accum.push(
              JSON.stringify({
                name: ruleData.name,
                value: ruleData.id,
                module: test.name,
              })
            );
          }
        }
      }
      return accum;
    }, []);
  }, [modules, ruleData]);
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      ...defaultValues,
      parameters: defaultParams ?? [],
      name: ruleData?.name ?? defaultValues.name,
      outputType: ruleData?.outputType ?? defaultValues.outputType,
      expression: ruleData?.expression ?? defaultValues.expression,
      link: ruleData?.link ?? defaultValues.link,
      message: ruleData?.message ?? defaultValues.message,
      type: ruleData?.type ?? defaultValues.type,
      outcomeType: ruleData?.outcomeType ?? defaultValues.outcomeType,
      onSuccessExpression:
        ruleData?.onSuccessExpression ?? defaultValues.onSuccessExpression,
      onFailureExpression:
        ruleData?.onFailureExpression ?? defaultValues.onFailureExpression,
      attributeName: ruleData?.attributeName ?? defaultValues.attributeName,
    },
  });
  const typeWatcher = watch("type");
  const dataModules = useMemo(() => {
    if (modelName !== "Result") {
      return modules.filter((module) => module.name !== "Result");
    }
    return modules;
  }, [modules, modelName]);

  const onSubmit = () => {
    handleSubmit((data: Data) => {
      const { parameters, ...otherData } = data;
      let newData: Omit<Data, "parameters"> = otherData;
      if (modelName !== "Result") {
        const { link, message, outcomeType, ...other } = newData;
        newData = other;
      }
      if (typeWatcher === "Validation" || typeWatcher === "Calculation") {
        const { onFailureExpression, onSuccessExpression, ...restData } =
          newData;
        newData = restData;
      }
      if (ruleData) editRule(moduleId, ruleData.id, newData);
    })();
  };

  return (
    <Modal
      title="Edit Rule"
      open={open}
      handleClose={handleClose}
      id="addAttributesModal"
      isLoading={isLoading}
      footer={
        <>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              marginRight: "24px",
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit}>
            Submit
          </Button>
        </>
      }
    >
      <form>
        <InputBlock>
          <Controller
            name="name"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormGroup
                  id="name"
                  disabled={true}
                  value={value}
                  label="Name"
                  placeholder="Name"
                  onChange={onChange}
                  error={!!error}
                />
              );
            }}
          />
        </InputBlock>
        <InputBlock>
          <Controller
            name="type"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Select
                  onChange={onChange}
                  options={options_rule_type}
                  id={"type"}
                  disabled={true}
                  error={!!error}
                  value={value || ""}
                  multiple={false}
                />
              );
            }}
          />
        </InputBlock>
        <InputBlock>
          <Controller
            name="outputType"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Select
                  onChange={onChange}
                  options={options_type}
                  id={"outputType"}
                  disabled={true}
                  value={value || ""}
                  error={!!error}
                  multiple={false}
                />
              );
            }}
          />
        </InputBlock>
        <InputBlock>
          <Controller
            name="parameters"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <RuleSelect
                  onChange={onChange}
                  disabled={true}
                  options={dataModules}
                  id={"parameters"}
                  title={""}
                  value={value || ""}
                  label="Parameters"
                  hideCreate={true}
                  multiple={true}
                />
              );
            }}
          />
        </InputBlock>
        <InputBlock>
          <Controller
            name="expression"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormGroup
                id="expression"
                label="Expression"
                placeholder="Expression"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </InputBlock>
        {typeWatcher !== "Validation" &&
          typeWatcher !== "Calculation" &&
          typeWatcher !== "ExecutionCondition" && (
            <>
              <InputBlock>
                <Controller
                  name="onSuccessExpression"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormGroup
                      id="onSuccessExpression"
                      label="On Success Expression"
                      placeholder="On Success Expression"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </InputBlock>
              <InputBlock>
                <Controller
                  name="onFailureExpression"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormGroup
                      id="onFailureExpression"
                      label="On Failure Expression"
                      placeholder="On Failure Expression"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </InputBlock>
            </>
          )}
        {modelName === "Result" && (
          <>
            <InputBlock>
              <Controller
                name="message"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormGroup
                    id="message"
                    label="Message"
                    placeholder="Message"
                    danger={true}
                    error={!!error}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </InputBlock>
            <InputBlock>
              <Controller
                name="link"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormGroup
                    id="link"
                    label="Link"
                    placeholder="Link"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </InputBlock>
            <InputBlock>
              <Controller
                name="outcomeType"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <Label label="Outcome Type" danger={false} />
                      <Select
                        onChange={onChange}
                        options={outcome_type}
                        id={"outcomeType"}
                        error={!!error}
                        value={value || ""}
                        multiple={false}
                      />
                    </>
                  );
                }}
              />
            </InputBlock>
          </>
        )}
        {(typeWatcher === "Validation" ||
          typeWatcher === "Calculation" ||
          typeWatcher === "ConditionalCalculation") && (
          <InputBlock>
            <Controller
              name="attributeName"
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormGroup
                  id="attributeName"
                  label="Attribute Name"
                  placeholder="Attribute Name"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </InputBlock>
        )}
      </form>
    </Modal>
  );
};
