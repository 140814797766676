import React from "react";

import { Grid } from "@mui/material";
import { AnalyticBlock, Title, Paragraph } from "features/analytics";

export const CompletionByDistribution = () => {
  return (
    <AnalyticBlock>
      <Grid container spacing={3}>
        <Grid item md={8}>
          <Title>Completion by Distribution</Title>
          <Paragraph>August, Tues 17 - Wed 23 </Paragraph>
        </Grid>
        <Grid item md={4} sx={{ textAlign: "right" }}>
          <Title>0000</Title>
          <Paragraph>Number Secondary</Paragraph>
        </Grid>
        <Grid item md={12}></Grid>
      </Grid>
    </AnalyticBlock>
  );
};
