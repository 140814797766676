import { takeLatest, put, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  pushKpiAction,
  getKpisAction,
  getKpiItemAction,
  getKpiItemsAction,
  clearKpiItemsAction,
} from "../actions";
import { kpiActions } from "entities/kpis";
import KpisApi from "shared/api/kpis/KpisApi";
import { KpiItemsResponse } from "shared/api/kpis/types";
import { SuccessResponse } from "api/types";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

export function* pushKpisWorker(action: ReturnType<typeof pushKpiAction>) {
  try {
    const response: AxiosResponse<SuccessResponse> = yield call(
      KpisApi.createKpi,
      action.payload
    );
    if (response.data && action.payload.callback) {
      action.payload.callback(response.data);
    }
    yield put(kpiActions.setKpi(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getKpisWorker(action: ReturnType<typeof getKpisAction>) {
  try {
    yield put(kpiActions.setKpisLoading(true));
    const response: AxiosResponse<KpiItemsResponse> = yield call(
      KpisApi.getProductKpis,
      action.payload.productId
    );
    if (response.data) {
      yield put(kpiActions.setKpisLoading(false));
      yield put(kpiActions.loadKpis(response.data));
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getKpiItemWorker(action: ReturnType<typeof getKpiItemAction>) {
  try {
    yield put(kpiActions.setKpisLoading(true));
    const response: AxiosResponse<KpiItemsResponse> = yield call(
      KpisApi.getKpiItem,
      action.payload
    );
    if (response?.data?.items?.length) {
      const kpiItem = response.data.items[0];
      yield put(kpiActions.setKpi(kpiItem));
      yield put(kpiActions.setKpisLoading(false));
    } else {
      yield put(kpiActions.setKpisLoading(false));
      yield put(kpiActions.setKpi({}));
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getKpiItemsWorker(
  action: ReturnType<typeof getKpiItemsAction>
) {
  yield put(kpiActions.setKpisLoading(true));
  try {
    const response: AxiosResponse<KpiItemsResponse> = yield call(
      KpisApi.getKpiItem,
      action.payload
    );
    if (response?.data?.items?.length) {
      const kpiItems = response.data.items;
      yield put(kpiActions.setKpiItems(kpiItems));
      yield put(kpiActions.setKpisLoading(false));
    } else {
      yield put(kpiActions.setKpiItems([]));
      yield put(kpiActions.setKpisLoading(false));
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* clearKpiItemsWorker() {
  yield put(kpiActions.setKpiItems([]));
}

export function* KpisWatcher() {
  yield takeLatest(pushKpiAction, pushKpisWorker);
  yield takeLatest(getKpisAction, getKpisWorker);
  yield takeLatest(getKpiItemAction, getKpiItemWorker);
  yield takeLatest(getKpiItemsAction, getKpiItemsWorker);
  yield takeLatest(clearKpiItemsAction, clearKpiItemsWorker);
}
