import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { UserRespose } from "shared/api/users/types";

export const getUsers = (state: RootState) => state.users;

export const getUsersList = createSelector(getUsers, (users) => {
  return users.items;
});

export const getActiveUsersList = createSelector(getUsers, (users) => {
  return users.items.filter((user: UserRespose) => user.state === "Active");
});

export const getCurrentUserProfile = createSelector(getUsers, (users) => {
  return users.profile;
});

export const getCurrentTenantInfo = createSelector(getUsers, (users) => {
  return users.tenantInfo;
});

export const getCurrentTenantLogo = createSelector(getUsers, (users) => {
  return users.tenantLogo;
});
