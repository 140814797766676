import React, { useState } from "react";

import { PageTitle } from "shared/ui/pageTitle";
import Tabs from "components/Tabs";
import { TabNames } from "../types";

import { Box } from "@mui/material";

import { RoutesList } from "shared/routes";
import EventsAndTriggersTable from "widgets/EventsAndTriggersTable";

const tabList = [
  {
    label: TabNames.EVENTS_AND_TRIGGERS,
    link: RoutesList.EVENTS_AND_TRIGGERS,
  },
];

const EventsAndTriggers: React.FC<any> = () => {
  const [activeTab, setActiveTab] = useState(TabNames.EVENTS_AND_TRIGGERS);
  return (
    <Box data-testid="events-and-triggers-page">
      <PageTitle title={"Events & Triggers"} />
      <Tabs
        setActiveTab={setActiveTab}
        tabList={tabList}
        activeTab={activeTab}
        basePath={RoutesList.EVENTS_AND_TRIGGERS}
      >
        <EventsAndTriggersTable />
      </Tabs>
    </Box>
  );
};

export default EventsAndTriggers;
