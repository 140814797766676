import React from "react";
import { Link, List, ListItem, Modal } from "./styles";

interface Props {
  items: string[];
}
export const DistributionsList = ({ items }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      {items.length > 1 && (
        <>
          <Link onClick={handleClick}>View All</Link>
          <Modal
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{ minWidth: "200px", width: "200px" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <List>
              {items.map((item, index) => (
                <ListItem key={`${item}_${index}`}>{item}</ListItem>
              ))}
            </List>
          </Modal>
        </>
      )}
      {items.length === 1 && items[0]}
    </>
  );
};
