import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { KpiItem } from "shared/api/kpis/types";

export const getKpis = (state: RootState) => state.kpiItems;

export const getKpiItems = createSelector(getKpis, (kpiItems) => {
  return kpiItems.items;
});

export const getCurrentKpiItem = createSelector(getKpis, (kpiItems) => {
  return kpiItems.kpiItem;
});

export const getCurrentKpiItems = createSelector(getKpis, (kpiItems) => {
  return kpiItems.kpiItems;
});

export const getKpiItemCount = createSelector(getKpis, (kpiItems) => {
  return kpiItems.totalCount;
});

export const getEntityKPI = (appId: string, productId: string) =>
  createSelector(getKpiItems, (items) => {
    const entity: KpiItem[] = items.filter(
      (item: KpiItem) => item.entityId === appId && item.productId === productId
    );
    if (entity.length) return entity[0].value;
    return "";
  });
