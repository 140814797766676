import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { ResponsiveLine } from "@nivo/line";
import { Grid } from "@mui/material";
import {
  AnalyticBlock,
  Title,
  Paragraph,
  useAnalytics,
} from "features/analytics";
import { WidgetDeploy } from "shared/api/analytics/types";
import Select from "shared/ui/Select";
import { Application } from "shared/api/applications/types";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getApplicationsData } from "entities/applications";

interface WidgetTrafficProps {
  ranges?: string;
  handleSelectDistribution: (item: any) => void;
  distribution: any;
}

export const WidgetTraffic = ({
  ranges,
  handleSelectDistribution,
  distribution,
}: WidgetTrafficProps) => {
  const { widgetDeploys } = useAnalytics();
  const applications = useAppSelector(getApplicationsData);

  const [activeApplications, setActiveApplications] = useState<Application[]>(
    []
  );
  const [widgetsApplications, setWidgetsApplications] = useState<string[]>([]);
  const [activeWidgets, setActiveWidgets] = useState<string[]>([]);

  const [chartData, setChartData] = useState<any>();

  useEffect(() => {
    const widgets_applications: Array<string> = [];
    const widgets: Array<string> = [];

    widgetDeploys.map((item: WidgetDeploy) => {
      const found_application: any = widgets_applications.find(
        (element: string) => element === item.applicationId
      );
      if (!found_application) {
        widgets_applications.push(item.applicationId);
      }
      const found_widget: any = widgets.find(
        (element: string) => element === item.widgetId
      );
      if (!found_widget) widgets.push(item.widgetId);
    });

    setWidgetsApplications(widgets_applications);
    setActiveWidgets(widgets);
  }, [widgetDeploys]);

  useEffect(() => {
    const active_applications: Array<Application> = [];
    applications.map((item: Application) => {
      widgetsApplications.map((el: string) => {
        if (item.id === el) {
          active_applications.push(item);
        }
      });
    });
    setActiveApplications(active_applications);
  }, [applications, widgetDeploys, widgetsApplications]);

  const handleSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const selectedApplications: Application = activeApplications.filter(
      (item: Application) => item.name === ev.target.value
    )[0];
    handleSelectDistribution(selectedApplications);
  };

  const getItemData = (id: string) => {
    const itemData: any = [];
    widgetDeploys.map((element: WidgetDeploy) => {
      if (element.widgetId === id) {
        const index = itemData.findIndex((el: any) => {
          return el.x === dayjs(element.createdDateUtc).format("DD/MM/YYYY");
        });

        if (index !== -1) {
          itemData[index] = {
            x: itemData[index].x,
            y: itemData[index].y + 1,
          };
        } else {
          itemData.push({
            x: dayjs(element.createdDateUtc).format("DD/MM/YYYY"),
            y: 1,
          });
        }
      }
    });
    return itemData;
  };

  useEffect(() => {
    const data = activeWidgets.map((item: string) => {
      return {
        id: item,
        data: getItemData(item),
      };
    });
    setChartData(data);
  }, [activeWidgets]);

  return (
    <AnalyticBlock>
      <Grid container spacing={3}>
        <Grid item md={8}>
          <Title>Widget Traffic</Title>
          <Paragraph>{ranges}</Paragraph>
        </Grid>
        <Grid item md={4} sx={{ textAlign: "right" }}>
          <Title>{widgetDeploys?.length}</Title>
          <Paragraph>Total display</Paragraph>
        </Grid>

        <Grid item md={4}>
          <Select
            id={"selectedDistribution"}
            value={distribution?.name || ""}
            onChange={handleSelect}
            options={activeApplications?.map((item: Application) => item.name)}
            placeholder={"Filter by distribution"}
            sx={{
              background: "#ffffff",
              ".MuiSelect-select": {
                color: "#383874",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "1px",
              },
            }}
          />
        </Grid>
        <Grid item md={12} sx={{ height: "500px" }}>
          {chartData && (
            <ResponsiveLine
              data={chartData}
              enableSlices="x"
              margin={{ top: 20, right: 20, bottom: 60, left: 80 }}
              colors={[
                "#010101",
                "#383874",
                "#6266EA",
                "#95A4FC",
                "#C6C7F8",
                "#A1E3CB",
              ]}
              axisBottom={{
                tickSize: 0,
                tickPadding: 10,
                legend: "Widgets",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                tickSize: 0,
                tickPadding: 15,
                legend: "Views",
                legendOffset: -45,
                legendPosition: "end",
              }}
            />
          )}
        </Grid>
      </Grid>
    </AnalyticBlock>
  );
};
