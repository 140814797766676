import React, { createContext, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getCategoriesList,
  productCategoriesActions,
} from "entities/productCategories";
import {
  getProduct,
  getProductAttributes,
  getProgram,
  productActions,
  productsActions,
} from "entities/products";

import CreateNewProduct from "components/CreateNewProduct";
import Alerts from "components/Alerts";

import { ProductResponse } from "shared/api/products/types";

import { RootState } from "redux/store";
import { useAppSelector } from "redux/store/hooks/hooks";

import { CategoryData } from "shared/api/productCategories/types";
import {
  getModulesObject,
  ModuleTypes,
  setResultsAttributesError,
} from "entities/modules";
import { Toaster } from "shared/ui/Toast/Toast";
import { useKpis } from "features/kpis/model/hooks/useKpis";
import { getKpiItems } from "entities/kpis";
import { KpiItem } from "shared/api/kpis/types";
import { programsActions } from "entities/programs";

export const CreateProductContext: any = createContext(null);

const CreateProduct: React.FC<any> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const productCategories: CategoryData[] = useAppSelector(getCategoriesList);
  const product = useAppSelector(getProduct);
  const program = useAppSelector(getProgram);
  const products = useSelector((state: RootState) => state.products?.products);
  const dataElements = useAppSelector(getProductAttributes);
  const modules = useAppSelector(getModulesObject);
  const productKpis: KpiItem[] = useAppSelector(getKpiItems);
  const productId = location.pathname
    .replace("/create_new_product/", "")
    .replace(/\/$/, "");
  const [currentProduct, setCurrentProduct] = useState<ProductResponse>();
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [productKpi, setProductKpi] = useState("");
  const [currentProductKpi, setCurrentProductKpi] = useState("");
  const [isFormApprovalRequired, setIsFormApprovalRequired] = useState(false);
  const [categories, setCategories] = useState(productCategories);
  const [category, setCategory]: any = useState();
  const [errors, setErrors]: any = useState();
  const [tab, setTab] = useState("");
  const { pushKpi, getKpisByProduct, getKpiItem } = useKpis();

  useEffect(() => {
    if (!currentProduct || currentProduct.id !== productId) {
      const editProduct = products.find(
        (product: ProductResponse) => product.id === productId
      );
      if (editProduct) setCurrentProduct(editProduct);
    }
  }, [productId]);

  useEffect(() => {
    if (productKpis.length) {
      const productKpiItems = productKpis.filter(
        (item) => item.kpiType === "Product" && item.productId === productId
      );
      if (productKpiItems.length) {
        const kpiValue = productKpiItems[0]?.value?.toString();
        setProductKpi(kpiValue);
        setCurrentProductKpi(kpiValue);
      }
    }
  }, [productKpis]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const presetStep = queryParameters.get("step");
    const presetTab = queryParameters.get("tab");
    if (presetStep?.match(/^-?\d+$/)) setStep(Number(presetStep));
    if (presetTab) setTab(presetTab);
  }, []);

  useEffect(() => {
    if (location.pathname === "/create_new_product") {
      // Reset product forms if creating new product
      dispatch(productActions.loadProduct({}));
      dispatch(productActions.getModules({ modules: [] }));
      dispatch(productActions.loadProductVersions({}));
    }
    if (location.pathname !== "/create_new_product" && productId) {
      dispatch({
        type: productsActions.GET_PRODUCT_VERSIONS,
        data: { id: productId },
      });
    }

    return () => {
      dispatch(productActions.loadProduct({}));
    };
  }, []);

  useEffect(() => {
    if (product?.categoryId) {
      setCurrentProduct(product);
      setName(product.name);
      setIsFormApprovalRequired(product.isFormApprovalRequired);
      dispatch({
        type: productCategoriesActions.GET_PRODUCT_CATEGORY,
        data: product.categoryId,
      });
      getProductProgram();
      getKpiByProductId();

      const urlParams = new URLSearchParams(location.search);
      const step = urlParams.get("step");
      switch (step) {
        case "ProductSetup":
          setStep(1);
          break;
        case "DataElements":
          setStep(2);
          break;
        case "Integrations":
          setStep(3);
          break;
        case "ServicesAndRules":
          setStep(4);
          break;
        case "EventsAndTriggers":
          setStep(5);
          break;
        case "Distribution":
          setStep(6);
          break;
        case "Engagement":
          setStep(7);
          break;
        case "Confirmation":
          setStep(8);
          break;
        default:
          break;
      }
    } else {
      // Reset product form once store cleared previous product.
      setCurrentProduct(undefined);
      setName("");
      setIsFormApprovalRequired(false);
      setCategory(undefined);
    }
  }, [product]);

  useEffect(() => {
    if (Object.keys(program).length) getProgramKpi();
  }, [program]);

  useEffect(() => {
    if (!productCategories.length) {
      dispatch({ type: productCategoriesActions.GET_PRODUCT_CATEGORIES });
    } else {
      const data: Array<any> = productCategories.map((item: any) => {
        return { label: item.displayName, id: item.id };
      });
      setCategories(data);
    }
  }, [productCategories]);

  useEffect(() => {
    if (currentProduct?.categoryId && categories.length) {
      const currentCategory = categories.find(
        (category: any) => category.id === currentProduct.categoryId
      );
      if (currentCategory) setCategory(currentCategory);
    }
  }, [currentProduct, categories]);

  useEffect(() => {
    if (category) {
      if (errors?.category) setErrors({ ...errors, category: false });
    }
    if (name) {
      if (errors?.name) setErrors({ ...errors, name: false });
    }
  }, [category, name]);

  const saveAndQuit = () => {
    if (step === 1 && currentProduct?.id && name) updateProductBasic();
    if (step === 1 && !product.id) {
      if (category) {
        if (name) {
          const data = {
            categoryId: category?.id,
            name,
            isFormApprovalRequired,
          };
          dispatch({ type: productsActions.CREATE_PRODUCT, data });
          navigate("/products/list");
        } else {
          setErrors({ name: true });
        }
      } else {
        setErrors({ category: true });
      }
    } else {
      navigate("/products/list");
    }
  };

  const getProductProgram = () => {
    if (product) {
      dispatch({
        type: programsActions.GET_PRODUCT_PROGRAM,
        data: {
          params: {
            ProductId: product.id,
          },
        },
      });
    }
  };

  const getProgramKpi = () => {
    if (Object.keys(program).length) {
      const payload = {
        EntityIds: [program.id],
        KPIType: "Program",
      };
      getKpiItem(payload);
    }
  };

  const createProduct = () => {
    if (name) {
      const data = {
        categoryId: category?.id,
        name,
        isFormApprovalRequired,
      };
      dispatch({ type: productsActions.CREATE_PRODUCT, data });
      setStep(2);
    } else {
      setErrors({ name: true });
    }
  };

  const updateProductBasic = () => {
    if (
      name !== product.name ||
      isFormApprovalRequired !== product.isFormApprovalRequired
    ) {
      const data = {
        name,
        isFormApprovalRequired,
      };
      dispatch({
        type: productsActions.EDIT_PRODUCT,
        data: { id: product.id, version: product.version, data },
      });
    }
  };

  const setKpi = () => {
    if (product?.id) {
      const payload = {
        productId: product.id,
        entityId: product.id,
        kpiType: "Product",
        value: productKpi ? Number(productKpi) : null,
      };
      if (currentProductKpi !== productKpi) pushKpi(payload);
    }
  };

  const updateProduct = () => {
    if (name) {
      setKpi();
      updateProductBasic();
      setStep(2);
    } else {
      setErrors({ name: true });
    }
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const modulesStepValidation = () => {
    for (const key in modules) {
      if (key.includes("result")) {
        if (modules[key]?.responseAttributes.length === 0) {
          dispatch(setResultsAttributesError(true));
          Toaster.error(
            "Result module has no attributes configured. Please add at least one attribute or remove module."
          );
          return;
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Unreachable code error
      if (
        modules[key]?.rules.length === 0 &&
        modules[key].moduleType !== ModuleTypes.FULFILLMENT &&
        modules[key].moduleType !== ModuleTypes.ENRICHMENT &&
        modules[key].moduleType !== ModuleTypes.INTEGRATION
      ) {
        const moduleName = key.charAt(0).toUpperCase() + key.slice(1);
        Toaster.error(
          `${moduleName} module has no rules configured. Please add at least one rule.`
        );
        return;
      }
    }
    if (modules && !Object.keys(modules).length) {
      Toaster.error("Atleast one outcome is needed to proceed further");
    } else setStep(step + 1);
  };

  const getKpiByProductId = () => {
    const id = product?.id || currentProduct?.id;
    if (id) getKpisByProduct(id);
  };

  const handleNext = () => {
    if (step === 4) {
      getProductProgram();
      modulesStepValidation();
      getKpiByProductId();
      return;
    }
    if (step === 6 || step === 7) {
      getProgramKpi();
      getKpiByProductId();
    }
    if (step === 2 && Object.keys(dataElements).length <= 0) {
      Toaster.error("At least one data element should be added");
      return;
    }
    if (step === 1) {
      if (currentProduct?.id) updateProduct();
      else createProduct();
    } else if (step === 8) {
      dispatch({
        type: productsActions.PUBLISH_PRODUCT,
        data: { id: product.id, version: product.version },
      });
      return;
    }
    setStep(step + 1);
  };

  const removeSelectedAttributes = (selected: string[]) => {
    if (currentProduct) {
      dispatch({
        type: productsActions.ADD_ATTRIBUTES_TO_PRODUCT,
        data: {
          attributes: { attributes: selected },
          productId: currentProduct.id,
          version: currentProduct.version,
        },
        alertType: "dataElementsRemoved",
      });
    }
  };

  const productValue = useMemo(
    () => ({
      handleBack,
      handleNext,
      saveAndQuit,
      step,
      name,
      setName,
      productKpi,
      setProductKpi,
      categories,
      category,
      setCategory,
      errors,
      isFormApprovalRequired,
      setApprovalRequired: setIsFormApprovalRequired,
      removeSelectedAttributes,
      currentProduct,
      tab,
      product,
      productKpis,
    }),
    [
      step,
      name,
      productKpi,
      categories,
      category,
      setCategory,
      errors,
      isFormApprovalRequired,
      currentProduct,
      tab,
      product,
      productKpis,
      modules,
    ]
  );
  return (
    <CreateProductContext.Provider value={productValue}>
      <CreateNewProduct />
      <Alerts />
    </CreateProductContext.Provider>
  );
};

export default CreateProduct;
