import { call, fork, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  addTenantInfo,
  loadUserProfile,
  loadUsers,
  setTenantImage,
} from "entities/users/model/slice/usersSlice";
import { usersActions } from "entities/users";

import { ReduxAction } from "redux/sagas/types";
import { alertActions } from "entities/alerts";

import UsersApi from "shared/api/users/usersApi";
import { TenantInfo, UsersRespose, UserProfile } from "shared/api/users/types";

import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

import FilesApi from "shared/api/files/filesApi";
import { FileDetails } from "shared/api/files/types";
import { logout } from "entities/auth/model/slice/authSlice";

export function* getUserProfileWorker() {
  try {
    const response: AxiosResponse<UserProfile> = yield call(
      UsersApi.getUserProfile
    );
    if (response.data.tenants.length > 0) {
      localStorage.setItem("tenant-name", response.data.tenants[0].companyName);
    }
    if (response.data?.email) {
      const tenantResponse: AxiosResponse<TenantInfo> = yield call(
        UsersApi.getTenantInfo
      );
      if (tenantResponse.data.logo) {
        const tenantLogo: AxiosResponse<FileDetails> = yield fork(
          FilesApi.retrieveFile,
          tenantResponse.data.logo
        );
        if (tenantLogo.data) {
          yield put(setTenantImage(tenantLogo.data));
        }
      }
      yield put(loadUserProfile(response.data));
      yield put(addTenantInfo(tenantResponse.data));
    } else {
      yield put(logout());
      localStorage.removeItem("tenant-name");
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getUsersWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<UsersRespose> = yield call(
      UsersApi.getUsers,
      action.data
    );
    yield put(loadUsers(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* deleteUserWorker(action: ReduxAction) {
  try {
    yield call(UsersApi.deleteUser, action.data);
    yield put({
      type: usersActions.GET_USERS,
    });
    yield put({
      type: alertActions.SHOW_ALERT,
      data: {
        type: "deleteUser",
        data: { name: action.data.name },
      },
    });
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* inviteUserWorker(action: any) {
  try {
    yield call(UsersApi.inviteUser, action.data);
    yield put({
      type: usersActions.GET_USERS,
      data: { params: { ShowArchived: action.restore } },
    });
    yield put({
      type: alertActions.SHOW_ALERT,
      data: {
        type: action.restore ? "userRestored" : "addUser",
        data: {
          email: action.data.recipientEmail,
        },
      },
    });
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* UsersWatcher() {
  yield takeLatest(usersActions.INVITE_USER, inviteUserWorker);
  yield takeLatest(usersActions.DELETE_USER, deleteUserWorker);
  yield takeLatest(usersActions.GET_USERS, getUsersWorker);
  yield takeLatest(usersActions.LOAD_USER_PROFILE, getUserProfileWorker);
}
