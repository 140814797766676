import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";

import {
  StyledDialog,
  StyledActions,
  StyledTitle,
  StyledIconButton,
} from "./styles";
import Button from "shared/ui/Button";

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  submitButton: React.ReactNode;
  id: string;
  title: string;
  fullsize?: boolean;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <StyledTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <StyledIconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "var(--black)",
          }}
        >
          <CloseIcon />
        </StyledIconButton>
      ) : null}
    </StyledTitle>
  );
};

const Modal = ({
  open,
  onClose,
  children,
  id,
  title,
  submitButton,
  fullsize,
}: ModalProps) => {
  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby={`${id}-title`}
      open={open}
      id={id}
      data-testid={id}
      fullsize={fullsize}
    >
      <BootstrapDialogTitle id={`${id}-title`} onClose={onClose}>
        {title}
      </BootstrapDialogTitle>
      {children}
      <StyledActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        {submitButton}
      </StyledActions>
    </StyledDialog>
  );
};

export default Modal;
