import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { TabButton, TabButtonWrap, TabPanelWrap, TabsHeader } from "./styles";
import { TabNames } from "pages/types";
import { useAppSelector } from "redux/store/hooks/hooks";
import { countNew } from "entities/requests";

const Tabs: React.FC<{
  children: any;
  tabList: any[];
  setActiveTab: (tab: TabNames) => void;
  basePath: string;
  activeTab: string;
  isNotRedirect?: boolean;
}> = ({
  children,
  setActiveTab,
  basePath,
  tabList,
  activeTab,
  isNotRedirect,
}) => {
  const navigate = useNavigate();
  const [newCount, setNewCount] = useState(0);
  const count = useAppSelector(countNew);

  useEffect(() => {
    if (newCount == 0 && count) {
      setNewCount(count);
    }
    if (newCount !== count) {
      setNewCount(count);
    }
  }, [count]);

  useEffect(() => {
    if (activeTab === TabNames.NEW) navigate("new");
  }, []);

  const tabNewLabel = (label: string) => {
    if (label !== "New") {
      return label;
    } else if (newCount == 0) {
      return label;
    } else {
      return `New (${newCount})`;
    }
  };

  const handleActiveTab = (tabLabel: TabNames) => {
    setActiveTab(tabLabel);
    if (
      tabLabel.toLowerCase() === "products" ||
      tabLabel.toLowerCase() === "programs"
    ) {
      return navigate(`${basePath}/list`);
    }
    if (!isNotRedirect) {
      switch (tabLabel) {
        case "Data Enrichment":
          return navigate(`${basePath}/data-enrich`);
        case "Third Party":
          return navigate(`${basePath}/third-party`);
        case "Events & Triggers":
          return navigate(`${basePath}`);
        default:
          navigate(`${basePath}/${tabLabel.toLowerCase()}`);
          break;
      }
    }
  };

  const tabs = tabList?.map((tab: any) => (
    <TabButton
      isActive={tab.label === activeTab}
      key={tab.label}
      onClick={() => handleActiveTab(tab.label)}
      disabled={tab?.disabled}
    >
      {tabNewLabel(tab.label)}
    </TabButton>
  ));

  return (
    <div>
      <TabsHeader>
        <TabButtonWrap>{tabs}</TabButtonWrap>
      </TabsHeader>
      <TabPanelWrap>{children}</TabPanelWrap>
    </div>
  );
};

export default Tabs;
