import { Column } from "shared/config/types/Table";

export const initialColumns: Column[] = [
  {
    id: "EventTrigger",
    label: "Trigger Name",
    minWidth: 180,
    filtering: true,
  },
  {
    id: "TriggerType",
    label: "Trigger TYPE",
    minWidth: 150,
    filtering: true,
  },
  {
    id: "ActionType",
    label: "Action Type",
    minWidth: 150,
    filtering: true,
  },
  {
    id: "templateName",
    label: "Notification Template Name",
    minWidth: 220,
    filtering: false,
  },
  {
    id: "CreatedDateUtc",
    label: "Creation",
    minWidth: 130,
    filtering: false,
  },
  {
    id: "UpdatedDateUtc",
    label: "Update",
    minWidth: 130,
    filtering: false,
  },
];
