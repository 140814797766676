import { createSlice } from "@reduxjs/toolkit";
import { CategoryData } from "shared/api/productCategories/types";

export const productCategoriesSlice = createSlice({
  name: "productCategories",
  initialState: {
    categories: {
      items: [] as CategoryData[],
      totalCount: 0,
    },
    productCategory: {
      attributes: [],
    },
  },
  reducers: {
    loadCategories: (state, action) => {
      return {
        ...state,
        categories: { ...action.payload },
      };
    },
    loadCategory: (state, action) => {
      return {
        ...state,
        productCategory: { ...action.payload },
      };
    },
  },
});

export const { loadCategories, loadCategory } = productCategoriesSlice.actions;
