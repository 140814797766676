import { takeLatest, put, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import {
  productCategoriesActions,
  loadCategories,
  loadCategory,
} from "entities/productCategories";
import { ReduxAction } from "redux/sagas/types";

import ProductCategoriesApi from "shared/api/productCategories/productCategoriesApi";
import {
  ProductCategoriesResponse,
  ProductCategoryResponse,
} from "shared/api/productCategories/types";

import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

export function* getProductCategoriesWorker() {
  try {
    const response: AxiosResponse<ProductCategoriesResponse> = yield call(
      ProductCategoriesApi.getProductCategories
    );
    yield put(loadCategories(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getProductCategoryWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<ProductCategoryResponse> = yield call(
      ProductCategoriesApi.getProductCategory,
      action.data
    );
    yield put(loadCategory(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* ProductCategoryWatcher() {
  yield takeLatest(
    productCategoriesActions.GET_PRODUCT_CATEGORY,
    getProductCategoryWorker
  );
  yield takeLatest(
    productCategoriesActions.GET_PRODUCT_CATEGORIES,
    getProductCategoriesWorker
  );
}
