import styled from "styled-components";
import { Card } from "@mui/material";
import Button from "shared/ui/Button";

export const CarrierCard = styled(Card)`
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  border-radius: 24px !important;
  box-shadow: none !important;
  height: 390px;
`;

export const SelectModuleWrapper = styled.div`
  max-width: 384px;
  margin: 0 32px 32px 0;
  width: 100%;
  .MuiInputBase-root {
    background-color: #ffffff;
  }
`;
export const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: #190a3a;
  border: none;
  font-size: 32px;
  margin: 10px 0;
  text-align: center;
`;

export const ContentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentIntegration = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const AdditionalWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  font-size: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
`;
export const ChannelsList = styled.ol`
  margin: 0;
`;

export const ContentIntegrationsWrapper = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
  background-color: ${(props) =>
    props.background || "rgba(98, 102, 234, 0.16)"};
  border-radius: 16px;
  height: 180px;
`;

export const NumberItem = styled.div`
  background-color: rgba(98, 102, 234, 0.16);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 114px;
  width: 114px;
  margin-bottom: 20px;
`;

export const CircleHeadingText = styled.div<{
  count: number;
}>`
  font-size: ${(props) => (props.count < 100 ? "60px" : "32px")};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: var(--secondary);
`;

export const HeadingText = styled.div`
  font-size: 56px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  color: var(--secondary);
`;

export const DescriptionItem = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
  color: var(--secondary);
`;

export const Description = styled.div`
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  margin-top: 40px;
  color: #383874;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin: auto 0 15px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
`;

export const FooterButton = styled(Button)<{ buttonActive?: boolean }>`
  flex: 2 1 auto;
  border-radius: 100px !important;
  background-color: ${(props) =>
    props.buttonActive ? "#6266EA !important" : "transparent"};
  color: ${(props) =>
    !props.buttonActive ? "#6266EA !important" : "#ffffff !important"};
`;
