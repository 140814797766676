import { Column } from "components/CreateNewProduct/AttributesTableCollapsed/types";
export const columns: Column[] = [
  {
    id: "checkbox",
    filtering: false,
  },
  {
    id: "icon",
    filtering: false,
  },
  {
    id: "displayName",
    label: "Data Element name",
    filtering: true,
  },
  {
    id: "isRequired",
    label: "Required",
    filtering: false,
  },
  {
    id: "valueType",
    label: "Type",
    filtering: false,
  },
  {
    id: "attributeDescription",
    label: "Description",
    filtering: false,
  },
  {
    id: "fillmentType",
    label: "fulfillment type",
    filtering: false,
    align: "right",
  },
  {
    id: "actions",
    label: "",
    filtering: false,
  },
];
