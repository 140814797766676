import axios, { AxiosError, AxiosPromise } from "axios";
import { defaultConfig } from "./headers";
import { getState } from "redux/store";
import {
  ModulesResponse,
  ModuleTemplatesResponse,
  RequestHeadersKeys,
} from "./types";

export const IXPortalApiPrivate = axios.create(defaultConfig);

IXPortalApiPrivate.interceptors.request.use(
  async (config) => {
    const state: any = getState();
    const token = state.auth.accessToken;
    config.headers = {
      ...defaultConfig.headers,
      ...(config.url !== "/api/Users/profile" && {
        [RequestHeadersKeys.IXPORTAL_TENANT]:
          localStorage.getItem("tenant-name") ?? "IX-Platform",
      }),
    };

    if (token && config.headers) {
      config.headers.authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

IXPortalApiPrivate.interceptors.response.use(
  (value) => value,
  async (err: AxiosError) => {
    if (err?.response?.status === 401) {
      location.reload();
    }
    return Promise.reject(err);
  }
);

export const getProductModules = (
  params: any
): AxiosPromise<ModulesResponse> => {
  return IXPortalApiPrivate.get(
    `/api/Products/${params.productId}/versions/${params.version}/modules`
  );
};

export const getProductModulesConnectors = (
  params: any
): AxiosPromise<ModulesResponse> => {
  return IXPortalApiPrivate.get(
    `/api/Products/${params.productId}/versions/${params.version}/modules/${params.moduleId}/Connectors`
  );
};

export const attachModuleTemplate = (
  params: any
): AxiosPromise<ModuleTemplatesResponse> => {
  const data = {
    moduleOrderQueue: params.moduleOrderQueue,
    outcomeType: params.outcomeType,
  };
  return IXPortalApiPrivate.post(
    `/api/Products/${params.id}/versions/${params.version}/modules/template/${params.templateId}`,
    data
  );
};

export const detachModuleTemplate = (
  params: any
): AxiosPromise<ModuleTemplatesResponse> => {
  return IXPortalApiPrivate.delete(
    `/api/Products/${params.id}/versions/${params.version}/modules/template/${params.templateId}`
  );
};
