import { createSelector } from "@reduxjs/toolkit";
import { getApplications } from "./getApplications";

export const getDirectData = createSelector(getApplications, (application) => {
  return application.directData;
});

export const getDirectLoading = createSelector(
  getApplications,
  (application) => {
    return application.directLoading;
  }
);

export const getDirectTotal = createSelector(getApplications, (application) => {
  return application.directTotal;
});

export const getDirectProductIds = createSelector(
  getApplications,
  (application) => {
    return application.directProductIds;
  }
);

export const getDirectProductIdsByApp = (appId: string, productId: string) =>
  createSelector(getApplications, (application) => {
    if (application.directProductIds[appId]) {
      return application.directProductIds[appId].includes(productId);
    }
    return false;
  });
