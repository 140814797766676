import React, { useState, useEffect, ChangeEvent } from "react";

import Modal from "./RequestModal";
import Button from "shared/ui/Button";
import { Wrapper } from "./styles";
import { Grid } from "@mui/material";
import CreateSection from "./CreateSection";
import CommentSection from "./CommentSection";
import FileUploadSection from "./FileUploadSection";
import ViewRequestSection from "./ViewRequestSection";
import {
  CommentSchema,
  RequestDetailSchema,
  RequestSchema,
  RequestType,
  RequestFile,
} from "shared/api/requests/types";
import { useRequests } from "features/requests/model/hooks/useRequests";
import { useFiles } from "features/files/model/hooks/useFiles";
import { useAppSelector } from "redux/store/hooks/hooks";
import {
  getCurrentRequest,
  loadComments,
  loadTypes,
  loadFileInfos,
} from "entities/requests";
import { getCurrentTenantInfo } from "entities/users";
interface CreateRequestProps {
  open: boolean;
  request?: RequestSchema;
  handleClose: () => void;
  requestCategory?: string;
  requestCreated?: (created: boolean) => void;
}
interface ErrorsProps {
  requestType?: boolean;
  requestStatus?: boolean;
  requestTitle?: boolean;
  description?: boolean;
}

const CreateRequest = ({
  open,
  request,
  handleClose,
  requestCategory,
  requestCreated,
}: CreateRequestProps) => {
  const [requestStatus, setRequestStatus] = useState("");
  const [requestType, setRequestType] = useState("");
  const [requestTitle, setRequestTitle] = useState("");
  const [description, setDescription] = useState("");
  const [userComment, setUserComment] = useState("");
  const [comments, setComments] = useState<CommentSchema[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [fileInfos, setFileInfos] = useState<RequestFile[]>([]);
  const [errors, setErrors] = useState<ErrorsProps>({});
  const [requestGroup, setRequestGroup] = useState("Request");
  const [modalTitle, setModalTitle] = useState("New Request");
  const [viewFiles, setViewFiles] = useState(false);
  const [requestDetail, setRequestDetail] = useState<RequestDetailSchema>();
  const currentRequest: RequestDetailSchema = useAppSelector(getCurrentRequest);
  const requestComments: CommentSchema[] = useAppSelector(loadComments);
  const fileDetails: RequestFile[] = useAppSelector(loadFileInfos);
  const requestTypes: RequestType[] = useAppSelector(loadTypes);
  const tenantInfo: any = useAppSelector(getCurrentTenantInfo);
  const [types, setTypes] = useState<RequestType[]>([]);

  const allowedFileTypes = [
    "text/plain",
    "image/png",
    "image/jpeg",
    "application/json",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const maxFileSize = 5120000;

  const {
    createRequest,
    getRequest,
    getRequestComments,
    addRequestComment,
    getRequestTypes,
  } = useRequests();
  const { uploadFiles } = useFiles();

  const resetForm = () => {
    setRequestStatus("");
    setRequestTitle("");
    setDescription("");
    setFileInfos([]);
    setFiles([]);
    setRequestDetail(undefined);
    setViewFiles(false);
    if (requestCategory === "Request") setRequestType("");
  };

  useEffect(() => {
    if (!requestTypes.length) getRequestTypes();
  }, []);

  useEffect(() => {
    if (request) {
      if (requestDetail?.id !== request.id) resetForm();
      getRequest(request.id);
      getRequestComments(request.id);
    }
    if (!request && requestDetail?.id) {
      setRequestDetail(undefined);
    }
  }, [request]);

  useEffect(() => {
    if (requestTypes?.length) {
      setTypes(requestTypes);
    }
  }, [requestTypes]);

  useEffect(() => {
    if (requestComments?.length) {
      setComments(requestComments);
    } else setComments([]);
  }, [requestComments]);

  useEffect(() => {
    if (currentRequest?.id) {
      setRequestDetail(currentRequest);
    }
  }, [currentRequest]);

  useEffect(() => {
    if (fileDetails?.length) {
      setFileInfos(fileDetails);
    }
  }, [fileDetails]);

  useEffect(() => {
    if (open && !request) resetForm();
  }, [open]);

  useEffect(() => {
    if (request?.status) {
      setRequestStatus(request.status);
      setComments([]);
    }
  }, [request]);

  useEffect(() => {
    if (requestGroup) {
      switch (requestGroup) {
        case "Data Elements":
          setRequestType("Attribute");
          setModalTitle("Request Data Element");
          break;
        case "Distribution":
          setRequestType("Distribution");
          setModalTitle("Request New Distribution");
          break;
        case "Third Party":
          setRequestType("API Integration");
          break;
        case "Data Enrichment":
          setRequestType("Data Enrichment");
          break;
        default:
          break;
      }
    }
  }, [requestGroup]);

  useEffect(() => {
    if (requestCategory) {
      setRequestGroup(requestCategory);
    }
  }, [requestCategory]);

  const loadRequestType = (name: string) => {
    const type = types.filter((type: RequestType) => type.displayName === name);
    if (type.length === 1) return type[0];
  };

  const handleTypeChange = (value: string) => {
    setErrors({
      ...errors,
      requestType: false,
    });
    setRequestType(value);
  };

  const handleStatusChange = (value: string) => {
    setErrors({
      ...errors,
      requestType: false,
    });
    setRequestStatus(value);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, id },
    } = event;

    setErrors({
      ...errors,
      [id]: false,
    });
    if (id === "requestTitle") setRequestTitle(value);
    if (id === "description" && value.length <= 250) setDescription(value);
    if (id === "userComment") setUserComment(value);
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedfiles = e.target.files;
    const validFiles = [];

    if (uploadedfiles && uploadedfiles.length > 0) {
      for (let i = 0; i < uploadedfiles.length; i++) {
        const uploadedFile = uploadedfiles[i];
        const fileType = uploadedFile.type;
        if (
          allowedFileTypes.includes(fileType) &&
          uploadedFile.size <= maxFileSize
        ) {
          validFiles.push(uploadedFile);
        }
        if (i === uploadedfiles.length - 1) setFiles(files?.concat(validFiles));
      }
    }
  };

  const validateForm = () => {
    if (requestTitle && description && requestType) {
      return true;
    } else {
      const err: ErrorsProps = {};
      if (!requestType) err.requestType = true;
      if (!requestTitle) err.requestTitle = true;
      if (!description) err.description = true;
      setErrors({ ...errors, ...err });
    }
    return false;
  };

  const submitRequestAction = () => {
    const typeId = loadRequestType(requestType)?.id;
    if (typeId && tenantInfo.id) {
      const payload = requestPayload(typeId);
      createRequest(payload, callback);
    }
  };

  const callback = (data: any) => {
    if (files.length && data.id) {
      const requestId = data.id.toString();
      if (request) {
        uploadFiles(requestId, "RequestComment", files);
      } else {
        uploadFiles(requestId, "Request", files);
      }
    }
    if (data.id && requestCreated) requestCreated(true);
  };

  const handleSubmit = () => {
    const valid = validateForm();
    if (valid) {
      submitRequestAction();
      resetForm();
      handleClose();
    }
  };

  const attachNewComment = () => {
    if (request) {
      let userCommentContent = userComment;
      if (files.length) {
        userCommentContent = `${files[0].name}`;
        setFiles([]);
      }
      if (userCommentContent || files.length) {
        const commentPayload = {
          id: request.id,
          data: {
            comment: userCommentContent,
            isPrivate: false,
          },
        };
        if (!viewFiles) addRequestComment(commentPayload, callback);
        else {
          const requestId = currentRequest.id.toString();
          uploadFiles(requestId, "Request", files);
        }
      }
    }
    setUserComment("");
  };

  const requestPayload = (requestId: string) => {
    const payload = {
      requestTypeId: requestId,
      tenantId: tenantInfo.id,
      description,
      title: requestTitle,
    };
    return payload;
  };

  // TODO: Connect to actual API if applicable.
  const removeUploadedFile = (file: RequestFile) => {
    console.log("remove", file);
  };

  const removeFile = (file: File) => {
    setFiles(files.filter((f: File) => f.name !== file.name));
  };

  return (
    <Modal
      onClose={handleClose}
      open={open}
      title={request ? `Request ${request.id}` : modalTitle}
      copying={!!request}
      hideBottom={false}
      submitButton={
        !request && (
          <Button
            variant="contained"
            onClick={handleSubmit}
            data-testid="createRequestModal_button"
          >
            {"Send Request"}
          </Button>
        )
      }
      sidepadding="50"
      closeIconRightSpacing={47}
    >
      <Wrapper>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: "center", marginTop: "0" }}
        >
          {!requestDetail ? (
            <CreateSection
              errors={errors}
              description={description}
              requestTitle={requestTitle}
              requestType={requestType}
              handleTypeChange={handleTypeChange}
              handleChange={handleChange}
              requestGroup={requestGroup}
            />
          ) : (
            <ViewRequestSection
              errors={errors}
              request={requestDetail}
              requestStatus={requestStatus}
              handleStatusChange={handleStatusChange}
              viewFiles={(value: boolean) => setViewFiles(value)}
            />
          )}
        </Grid>
        {!requestDetail ? (
          <FileUploadSection
            files={files}
            handleFileUpload={handleFileUpload}
            removeFile={removeFile}
          />
        ) : (
          <CommentSection
            renderFiles={viewFiles}
            comments={comments}
            userComment={userComment}
            handleChange={handleChange}
            attachNewComment={attachNewComment}
            handleFileUpload={handleFileUpload}
            file={files[0]}
            removeFile={() => setFiles([])}
            files={fileInfos}
            removeUploadedFile={removeUploadedFile}
          />
        )}
      </Wrapper>
    </Modal>
  );
};

export default CreateRequest;
