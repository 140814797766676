import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { requestActions } from "entities/requests";
import { CommentSchema } from "shared/api/requests/types";
import { PaginationParams } from "api/types";

export const useRequests = () => {
  const dispatch = useDispatch();

  const getRequests = useCallback(
    (pagingParams?: PaginationParams) => {
      dispatch({
        type: requestActions.GET_REQUESTS,
        data: {
          params: {
            ShowArchived: false,
            limit: pagingParams?.limit,
            offset: pagingParams?.offset,
            SearchExpression: pagingParams?.SearchExpression,
            filter: pagingParams?.filter,
          },
        },
      });
    },
    [dispatch]
  );

  const getCompletedRequests = useCallback(
    (pagingParams: PaginationParams) => {
      dispatch({
        type: requestActions.GET_COMPLETED,
        data: {
          params: {
            ShowArchived: false,
            limit: pagingParams.limit,
            offset: pagingParams.offset,
            SearchExpression: pagingParams.SearchExpression,
            filter: pagingParams?.filter,
          },
        },
      });
    },
    [dispatch]
  );

  const getArchivedRequests = useCallback(
    (pagingParams: PaginationParams) => {
      dispatch({
        type: requestActions.GET_ARCHIVED,
        data: {
          params: {
            ShowArchived: true,
            limit: pagingParams.limit,
            offset: pagingParams.offset,
            SearchExpression: pagingParams.SearchExpression,
            filter: pagingParams?.filter,
          },
        },
      });
    },
    [dispatch]
  );

  const getRequestTypes = useCallback(() => {
    dispatch({ type: requestActions.GET_TYPES });
  }, [dispatch]);

  const getRequest = useCallback(
    (id: number) => {
      dispatch({ type: requestActions.GET_REQUEST, data: id });
    },
    [dispatch]
  );

  const countNewRequest = useCallback(() => {
    dispatch({ type: requestActions.COUNT_NEW_REQUESTS });
  }, [dispatch]);

  const countActiveRequest = useCallback(() => {
    dispatch({ type: requestActions.COUNT_ACTIVE_REQUESTS });
  }, [dispatch]);

  const countCompletedRequest = useCallback(() => {
    dispatch({ type: requestActions.COUNT_COMPLETED_REQUESTS });
  }, [dispatch]);

  const countArchivedRequest = useCallback(() => {
    dispatch({ type: requestActions.COUNT_ARCHIVED_REQUESTS });
  }, [dispatch]);

  const deleteRequest = useCallback(
    (id: number) => {
      dispatch({ type: requestActions.DELETE_REQUEST, data: id });
    },
    [dispatch]
  );

  const restoreRequest = useCallback(
    (id: number, callback?: (data: any) => void) => {
      dispatch({ type: requestActions.RESTORE_REQUEST, data: id, callback });
    },
    [dispatch]
  );

  const createRequest = useCallback(
    (params: any, callback?: (data: any) => void) => {
      dispatch({ type: requestActions.CREATE_REQUEST, data: params, callback });
    },
    [dispatch]
  );

  const getRequestComments = useCallback(
    (id: number) => {
      dispatch({ type: requestActions.GET_COMMENTS, data: id });
    },
    [dispatch]
  );

  const addRequestComment = useCallback(
    (params: any, callback: (data: any) => void) => {
      dispatch({ type: requestActions.ADD_COMMENT, data: params, callback });
    },
    [dispatch]
  );

  const orderedComments = (comments: CommentSchema[]) => {
    const sorted = [...comments].sort((a: CommentSchema, b: CommentSchema) => {
      const itemA = a.createdDateUtc;
      const itemB = b.createdDateUtc;

      return new Date(itemA).valueOf() - new Date(itemB).valueOf();
    });
    return sorted;
  };

  return {
    getRequestTypes,
    createRequest,
    getRequests,
    getRequest,
    getRequestComments,
    addRequestComment,
    orderedComments,
    deleteRequest,
    restoreRequest,
    countNewRequest,
    countActiveRequest,
    getCompletedRequests,
    countCompletedRequest,
    countArchivedRequest,
    getArchivedRequests,
  };
};
