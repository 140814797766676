import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { alertActions } from "entities/alerts";
import { draftItemsActions } from "entities/draftItems";
import { NotificationProps } from "entities/alerts/model/types";

import { StyledAlert, Container, StyledButton } from "./styles";
import { messages } from "./Messages";

import { RootState } from "redux/store";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getDraftItems } from "entities/draftItems/model/selectors";

export interface AlertProps {
  notification: NotificationProps;
}
function Alert({ notification }: Readonly<AlertProps>) {
  const dispatch = useDispatch();
  const draftItems = useAppSelector(getDraftItems);

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch({ type: alertActions.CLOSE_ALERT });
    }, 3000);

    dispatch({ type: draftItemsActions.ADD_DRAFT_ITEM, data: [] });

    return () => clearInterval(timer);
  }, []);

  const handleClick = () => {
    return true;
  };

  const handleReload = () => {
    window.location.reload();
  };

  const severityType = () => {
    if (notification?.severity === "success") return "success";
    if (notification.type.toLowerCase().includes("error")) return "error";

    return "info";
  };

  const MessageComponent = messages[notification.type];

  return (
    <StyledAlert
      severity={severityType()}
      data-testid={`alert_${notification.type}`}
    >
      <MessageComponent notification={notification} items={draftItems} />{" "}
      {notification.undo && (
        <StyledButton onClick={handleClick} data-testid="alert-button">
          UNDO
        </StyledButton>
      )}
      {notification.reload && (
        <StyledButton onClick={handleReload} data-testid="alert-button">
          RELOAD
        </StyledButton>
      )}
    </StyledAlert>
  );
}

const BasicAlerts = () => {
  const alerts = useSelector((state: RootState) => state.alerts);

  return (
    <Container data-testid="alerts">
      {alerts.map((item: NotificationProps, i: number) => (
        <Alert notification={item} key={`${item.type}_${i}`} />
      ))}
    </Container>
  );
};

export default BasicAlerts;
