import React from "react";

import { StyledTextarea, StyledTypography } from "./styles";

interface TextareaProps {
  value: string;
  placeholder: string;
  id: string;
  onChange: (e: any) => void;
  minRows: number;
  error?: any;
  disabled?: boolean;
  className?: string;
}

const TextareaComponent = ({
  value,
  placeholder,
  id,
  onChange,
  minRows,
  error,
  disabled,
  className,
}: TextareaProps) => {
  return (
    <>
      <StyledTextarea
        placeholder={placeholder}
        value={value}
        id={id}
        onChange={onChange}
        rows={minRows}
        error={error}
        readOnly={disabled}
        className={className}
      />
      {error &&
        (error.message ? (
          <StyledTypography>{error.message}</StyledTypography>
        ) : (
          <StyledTypography>This field is required.</StyledTypography>
        ))}
    </>
  );
};

export default TextareaComponent;
