import React, { FC } from "react";
import { CardContent } from "shared/config/types/Cards";
import {
  CircleHeadingText,
  ContentInfoWrapper,
  ContentIntegrationsWrapper,
  DescriptionItem,
  NumberItem,
} from "./styles";

export const CardContentInfo: FC<CardContent> = ({ count, description }) => {
  return (
    <ContentIntegrationsWrapper background="transparent">
      <ContentInfoWrapper>
        <NumberItem>
          <CircleHeadingText count={Number(count)}>{count}</CircleHeadingText>
        </NumberItem>
        <DescriptionItem>{description}</DescriptionItem>
      </ContentInfoWrapper>
    </ContentIntegrationsWrapper>
  );
};
