import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";
import {
  RequestSchema,
  RequestDetailSchema,
  RequestCommentSchema,
  TypesResponse,
  CountSchema,
} from "./types";
import { SuccessResponse } from "api/types";

export default class RequestsApi {
  static async getRequestTypes(): Promise<AxiosResponse<TypesResponse>> {
    return IXPortalApiPrivate.get("/api/Requests/Types");
  }

  static async getRequests(params: any): Promise<AxiosResponse<RequestSchema>> {
    const data = params.params;
    let baseUrl = `/api/Requests?ShowArchived=${data.ShowArchived}&limit=${data.limit}&offset=${data.offset}&SearchExpression=${data.SearchExpression}`;
    if (data.filter) baseUrl = baseUrl.concat(`&${data.filter}`);
    return IXPortalApiPrivate.get(baseUrl);
  }

  static async createRequest(
    params: any
  ): Promise<AxiosResponse<SuccessResponse>> {
    return IXPortalApiPrivate.post("/api/Requests/", params);
  }

  static async getRequest(
    id: string
  ): Promise<AxiosResponse<RequestDetailSchema>> {
    return IXPortalApiPrivate.get(`/api/Requests/${id}`);
  }

  static async countNew(): Promise<AxiosResponse<CountSchema>> {
    return IXPortalApiPrivate.get(
      "/api/Requests/count?Statuses=New&Statuses=Pending"
    );
  }

  static async countActive(): Promise<AxiosResponse<CountSchema>> {
    return IXPortalApiPrivate.get(
      "/api/Requests/count?Statuses=New&Statuses=Pending&Statuses=InProgress"
    );
  }

  static async countCompleted(): Promise<AxiosResponse<CountSchema>> {
    return IXPortalApiPrivate.get("/api/Requests/count?Statuses=Completed");
  }

  static async getRequestComments(
    id: number
  ): Promise<AxiosResponse<RequestCommentSchema>> {
    return IXPortalApiPrivate.get(`/api/Requests/${id}/comments`);
  }

  static async attachRequestComments(
    params: any
  ): Promise<AxiosResponse<SuccessResponse>> {
    return IXPortalApiPrivate.post(
      `/api/Requests/${params.id}/comments`,
      params.data
    );
  }

  static async deleteRequest(
    id: string
  ): Promise<AxiosResponse<SuccessResponse>> {
    return IXPortalApiPrivate.delete(`/api/Requests/${id}`);
  }

  static async undeleteRequest(
    id: string
  ): Promise<AxiosResponse<SuccessResponse>> {
    return IXPortalApiPrivate.put(`/api/Requests/${id}/undelete`);
  }
}
