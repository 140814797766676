import styled from "styled-components";
import { DialogContent, Tabs, Typography, Avatar } from "@mui/material";

export const StyledTabs = styled(Tabs)`
  padding: 0 50px;
  .MuiTab-root {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--primary);
    padding: 19px 32px;
    &.Mui-selected {
      color: var(--secondary);
    }
  }
  .MuiTabs-indicator {
    background-color: var(--secondary);
  }
`;
export const Wrapper = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 40px 50px;
    border-bottom: none;
    .MuiButton-containedSecondary {
      padding: 5px 24px;
      height: 40px;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
export const StyledAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 32px;
    color: rgba(98, 102, 234, 0.5);
  }
`;
export const Text = styled(Typography)`
  &.MuiTypography-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--grey);
    margin-top: 10px;
  }
`;
export const Block = styled.div`
  padding: 40px 0;
  margin: 40px 0 0;
  border-top: 1px solid var(--silver);
  &:last-child {
    margin: 0;
  }
`;

export const WarningWrap = styled.div`
  background: #fff4e6;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 12px;
`;

export const WarningTextWrap = styled.div`
  color: var(--darkorange);
  font-size: 14px;
  width: 94%;
`;

export const WarningIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 5%;
  svg path {
    fill: var(--darkorange);
  }
`;
