import { Column } from "shared/config/types/Table";

export const initialColumns: Column[] = [
  {
    id: "Name",
    label: "Name",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "section",
    label: "Section",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "kpis",
    label: "KPI's",
    minWidth: 200,
    filtering: false,
  },
];
