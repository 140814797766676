import React from "react";
import { StyledImageWrap } from "./styles";

interface IconWrapProps {
  icon: JSX.Element;
  color?: "primary" | "secondary" | "ghost";
  fillColor?: "primary" | "secondary";
}

const IconWrap: React.FC<IconWrapProps> = ({
  icon,
  color = "primary",
  fillColor = "primary",
}) => {
  return (
    <StyledImageWrap
      className={"icon-wrap-override-class"}
      color={color}
      fillColor={fillColor}
    >
      {icon}
    </StyledImageWrap>
  );
};

export default IconWrap;
