import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// icons
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ReactComponent as RemoveIcon } from "assets/icons/folderRemove.svg";
// actions
import { draftItemsActions } from "entities/draftItems";
import { modalsActions } from "entities/modals";

import { Slide } from "@mui/material";
import {
  Container,
  DeleteItem,
  StyledButton,
  StyledItem,
  StyledMenu,
} from "./styles";
// types
import { TabNames } from "pages/types";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getDraftItems } from "entities/draftItems/model/selectors";

const BulkActions: React.FC<{ activeTab: TabNames }> = ({ activeTab }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [checked, setChecked] = React.useState(false);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();
  const draftItems = useAppSelector(getDraftItems);

  const handleClear = () => {
    dispatch({ type: draftItemsActions.ADD_DRAFT_ITEM, data: [] });
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (draftItems.length > 1) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [draftItems]);

  const handleDelete = () => {
    dispatch({
      type: modalsActions.OPEN_MODAL,
      data: { delete: { type: "users", isModalOpen: true } },
    });
  };

  return (
    <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
      <Container data-testid="bulkActions">
        {activeTab !== TabNames.PRODUCTS ? (
          <p data-testid="bulkActions_selected">
            {draftItems.length} users selected
          </p>
        ) : (
          <p data-testid="bulkActions_selected">
            {draftItems.length} products selected
          </p>
        )}

        <div>
          <StyledButton
            onClick={handleClear}
            variant="outlined"
            endIcon={<CloseIcon />}
            data-testid="bulkActions_clearSelection"
          >
            Clear selection
          </StyledButton>
          <StyledButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            variant="outlined"
            data-testid="bulkActions_menuButton"
            endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          >
            Bulk actions
          </StyledButton>
          <StyledMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            data-testid="bulkActions_menu"
          >
            {activeTab === TabNames.ARCHIVE && (
              <StyledItem data-testid="bulkAction_resetInvitations">
                <RemoveIcon /> Reset invitations
              </StyledItem>
            )}
            {activeTab !== TabNames.PRODUCTS ? (
              <DeleteItem
                onClick={handleDelete}
                data-testid="bulkAction_deleteUser"
              >
                <DeleteOutlineIcon /> Delete User(s)
              </DeleteItem>
            ) : (
              <DeleteItem
                onClick={() => console.log("delete products")}
                data-testid="bulkAction_deleteProduct"
              >
                <DeleteOutlineIcon /> Delete Product(s)
              </DeleteItem>
            )}
          </StyledMenu>
        </div>
      </Container>
    </Slide>
  );
};

export default BulkActions;
