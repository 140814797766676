import React, { FC, ReactNode } from "react";
import { StyledWrapper } from "./styles";
import { Spinner } from "../Spinner";

interface Props {
  children: ReactNode;
  isLoading?: boolean;
}
export const ActionButtonsWrapper: FC<Props> = ({ children, isLoading }) => (
  <StyledWrapper>
    {isLoading && <Spinner />}
    {children}
  </StyledWrapper>
);
