import styled from "styled-components";
import { Grid } from "@mui/material";

export const StyledGrid = styled(Grid)`
  &.MuiGrid-root {
    width: calc(100% + 60px);
    margin-left: -40px;
  }
`;

export const PaginationGrid = styled(Grid)`
  &.MuiGrid-root {
    position: relative;
    top: -50px;
    display: flex;
    justify-content: end;
  }
`;

export const Wrapper = styled.div`
  height: calc(100% - 156px);
`;
