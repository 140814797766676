import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { StyledTypography } from "./styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { IXTableRow, TableHeader } from "shared/ui/Table";
import { initialColumns, mockIncludedData } from "./types";
import { StyledChip } from "shared/ui/Table/styles";
import SimpleLink from "shared/ui/SimpleLink/SimpleLink";
import { ReactComponent as ActivateIcon } from "assets/icons/add.svg";
import {
  ActivateButtonDirect,
  useApplicationsData,
  useDirectActions,
  useDirectData,
  useLinkedProductsActions,
  Kpi,
} from "features/applications";
import { TableSkeleton } from "shared/ui/TableSkeleton";
import TablePlaceholder from "shared/ui/TablePlaceholder";
import { format } from "date-fns";
import { DateFormat } from "shared/config/enums/DateFormat";
import SortingDefault, {
  SortRenderProps,
} from "shared/ui/Table/SortingDefault/SortingDefault";
import { OrderTypes } from "shared/config/types/Sorting";
import { SORT } from "shared/config/enums/Sorting";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getProduct } from "entities/products";
import { AssociatedProductsModal } from "features/associatedProducts";
import { KpiPayload } from "shared/api/kpis/types";

interface Props {
  type?: string;
  handleKpi?: (payload: KpiPayload) => void;
}

export const DirectTable = memo(function DirectTable({
  type,
  handleKpi,
}: Props) {
  const { id: productId } = useAppSelector(getProduct);
  const { data, isLoading, total } = useDirectData();
  const { search } = useApplicationsData();
  const { getDirectApplications } = useDirectActions();
  const { linkToProduct } = useLinkedProductsActions();
  const [appId, setAppId] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [openListProductModal, setOpenListProductModal] = useState(false);
  const [order, setOrder] = useState<OrderTypes>({ type: SORT.ASC, label: "" });
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (search && page !== 0) setPage(0);
  }, [search]);

  useEffect(() => {
    const offset = page * rowsPerPage;
    getDirectApplications({
      offset: offset,
      limit: rowsPerPage,
      search: search,
      ...(order.label && { sortPreference: order.label }),
      ...(order.label && { sortKind: order.type }),
    });
  }, [getDirectApplications, page, rowsPerPage, search, order]);

  const handleAddToProduct = (id: string) => () => {
    setAppId(id);
    handleOpenAssociated();
  };

  const handleOpenAssociated = () => {
    setOpenListProductModal((prevState) => !prevState);
  };

  const activateProduct = useCallback(
    (productId: string) => {
      if (appId) {
        linkToProduct({ productId, id: appId });
      }
    },
    [linkToProduct, appId]
  );
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
  };
  const initialColumnsData = useMemo(() => {
    return type === "edit"
      ? initialColumns.filter((item) => item.id !== "associatedProducts")
      : initialColumns;
  }, [initialColumns]);
  return (
    <>
      <StyledTypography>Direct</StyledTypography>
      <TableContainer
        sx={{
          height: `calc(100vh - ${"660px"})`,
          position: "relative",
        }}
        data-testid="tableContainer"
      >
        <Table stickyHeader>
          <TableHeader
            hideCheckbox={true}
            columns={initialColumnsData}
            rowCount={mockIncludedData.length}
            numSelected={0}
          >
            {(props: SortRenderProps) => (
              <SortingDefault
                setOrder={(data: OrderTypes) => setOrder(data)}
                order={order}
                {...props}
              />
            )}
          </TableHeader>
          <TableBody data-testid="tableBody">
            {isLoading && (
              <TableSkeleton lines={3} columns={initialColumnsData} />
            )}
            {!isLoading && data.length === 0 && (
              <TablePlaceholder colSpan={initialColumnsData.length} />
            )}
            {!isLoading &&
              data.length > 0 &&
              data.map((row) => {
                return (
                  <IXTableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    data-testid={`tableRow_${row.id}`}
                  >
                    <TableCell sx={{ textAlign: "center" }}>
                      {type === "edit" && (
                        <ActivateButtonDirect
                          appId={row.id}
                          productId={productId}
                          handleKpi={handleKpi}
                        />
                      )}
                      {!type && (
                        <StyledChip
                          label="Add to product"
                          chiptype="activated"
                          icon={<ActivateIcon />}
                          onClick={handleAddToProduct(row.id)}
                        />
                      )}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>{row.name}</TableCell>
                    <TableCell>{row.provider}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>{row.channel}</TableCell>
                    {!type && (
                      <TableCell>
                        <SimpleLink
                          to={"associated-products"}
                          state={{ productIds: row.productIds, appId: row.id }}
                        >
                          View all
                        </SimpleLink>
                      </TableCell>
                    )}
                    <TableCell>
                      {format(new Date(row.createdDateUtc), DateFormat.DEFAULT)}
                    </TableCell>
                    <TableCell>
                      {format(new Date(row.updatedDateUtc), DateFormat.DEFAULT)}
                    </TableCell>
                    <TableCell>
                      <Kpi
                        appId={row.id}
                        productId={productId}
                        type="direct"
                        handleKpi={handleKpi}
                      />
                    </TableCell>
                  </IXTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ marginTop: "30px" }}
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        data-testid="table_pagination"
      />
      <AssociatedProductsModal
        open={openListProductModal}
        handleClose={handleOpenAssociated}
        step={5}
        activateProduct={activateProduct}
      />
    </>
  );
});
