import React, { useState, useEffect } from "react";

import { Grid, Box, Typography } from "@mui/material";
import {
  AnalyticBlock,
  Title,
  Paragraph,
  useAnalytics,
} from "features/analytics";
import { LocationDetail } from "shared/api/analytics/types";

import { ResponsiveChoropleth } from "@nivo/geo";
import countries from "./albers_usa.json";
import { states } from "./states";
import { ListText, StyledTab, StyledTabs } from "./styles";

export const WidgetLocation = () => {
  const { locationDetails } = useAnalytics();
  const [chartData, setChartData] = useState<any>();
  const [outcomeType, setOutcomeType] = React.useState("Lead");
  const [sortedLocation, setSortedLocation] = React.useState<LocationDetail[]>(
    []
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setOutcomeType(newValue);
  };

  const compare = (a: LocationDetail, b: LocationDetail) => {
    if (a.count < b.count) {
      return 1;
    }
    if (a.count > b.count) {
      return -1;
    }
    return 0;
  };

  useEffect(() => {
    const result = locationDetails.filter(
      (item: LocationDetail) => item.outcomeType === outcomeType
    );

    const data = result.map((item: LocationDetail) => {
      return {
        id: item.name,
        value: item.count,
      };
    });
    setChartData(data);

    result.sort(compare);
    setSortedLocation(result);
  }, [locationDetails, outcomeType]);

  const initialValue = 0;
  const numberSecondary = locationDetails.reduce(
    (accumulator, currentValue) => accumulator + currentValue.count,
    initialValue
  );

  const getPercentage = (count: number) => {
    return (count / (numberSecondary / 100)).toFixed(1);
  };

  const renderStates = () => {
    if (sortedLocation.length > 7) {
      let othersCount = 0;
      return (
        <>
          {sortedLocation.map((item: LocationDetail, index) => {
            if (index < 6) {
              return (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  key={item.name}
                >
                  <ListText>{states[item.name]}</ListText>
                  <ListText>{getPercentage(item.count)}%</ListText>
                </Box>
              );
            } else {
              othersCount = othersCount + item.count;
            }
          })}
          <Box display="flex" justifyContent="space-between">
            <ListText>Other</ListText>
            <ListText>{getPercentage(othersCount)}%</ListText>
          </Box>
        </>
      );
    } else {
      return sortedLocation.map((item: LocationDetail) => {
        return (
          <Box display="flex" justifyContent="space-between" key={item.name}>
            <Typography>{item.name}</Typography>
            <Typography>{getPercentage(item.count)}%</Typography>
          </Box>
        );
      });
    }
  };

  return (
    <AnalyticBlock>
      <Grid container spacing={3}>
        <Grid item md={8}>
          <Title>Widget Location</Title>
          <Paragraph>August, Tues 17 - Wed 23 </Paragraph>
        </Grid>
        <Grid item md={4} sx={{ textAlign: "right" }}>
          <Title>{numberSecondary}</Title>
          <Paragraph>Number Secondary</Paragraph>
        </Grid>
        <Grid item md={12}>
          <Box sx={{ margin: "auto", width: "max-content" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <StyledTabs
                value={outcomeType}
                onChange={handleChange}
                aria-label="basic tabs example"
                centered
              >
                <StyledTab label="Lead" value="Lead" />
                <StyledTab label="Quote" value="Quote" />
                <StyledTab label="Bind" value="Bind" />
              </StyledTabs>
            </Box>
          </Box>
        </Grid>
        <Grid item md={8} style={{ height: "420px" }}>
          <ResponsiveChoropleth
            data={chartData}
            features={countries.features}
            colors={["#383874", "#6266EA", "#95A4FC", "#C6C7F8", "#A1E3CB"]}
            unknownColor="#eeeeee"
            label="properties.state_name"
            projectionScale={200}
            projectionTranslation={[0.5, 0.4]}
            borderWidth={0.5}
            borderColor="#152538"
            domain={[0, numberSecondary]}
            legends={[
              {
                anchor: "bottom-left",
                direction: "row",
                justify: true,
                translateX: 70,
                translateY: -30,
                itemsSpacing: 10,
                itemWidth: 80,
                itemHeight: 40,
                itemDirection: "top-to-bottom",
                itemTextColor: "#444444",
                itemOpacity: 0.85,
                symbolSize: 18,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000000",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </Grid>
        <Grid item md={4}>
          {renderStates()}
        </Grid>
      </Grid>
    </AnalyticBlock>
  );
};
