import React, { memo, ReactNode, useState } from "react";
import { DataAttributesList, RuleRow } from "entities/products";
import { Accordion } from "shared/ui/Accordion";
import { FormattedAttributePayload } from "shared/api/products/types";
import { ActionButton } from "shared/ui/ActionButton";
import { ActionButtonTypes } from "components/ActionButton/types";
import { useModule } from "features/modules";
import { AddRuleForm } from "features/add-rule-form";
import { EditRuleForm } from "features/edit-rule-form";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { RuleButton } from "../../OutcomeConfiguration/ui/ModulesAccordion.styles";
import { Rule } from "shared/api/modules/types";
import { ModuleTypes } from "entities/modules";

type Props = {
  rules?: Rule[];
  heading: string;
  subHeading?: string;
  icon?: ReactNode;
  moduleId: string;
  editable: boolean;
  responseAttributes: FormattedAttributePayload[];
  onEdit: (id: string) => void;
  name: string;
  sortable: boolean;
  moduleType?: ModuleTypes;
};

export const FulfillmentCard = memo(function FulfillmentCard({
  rules,
  heading,
  subHeading,
  icon,
  moduleId,
  editable,
  responseAttributes,
  onEdit,
  name,
  sortable,
  moduleType,
}: Props) {
  const { deleteModule } = useModule();
  const [addRuleModal, setAddRuleModal] = useState(false);
  const [editRuleModal, setEditRuleModal] = useState(false);
  const [ruleData, setRuleData] = useState<Rule | null>(null);

  const deleteModuleHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    deleteModule(moduleId);
  };

  const addRuleHandler = () => {
    setAddRuleModal((prevState) => !prevState);
  };

  const editRuleHandler = (item?: Rule) => {
    setEditRuleModal((prevState) => !prevState);
    setRuleData(item ?? null);
  };

  return (
    <>
      <Accordion
        heading={heading}
        subHeading={subHeading}
        icon={icon}
        sortable={sortable}
        expandedProp={false}
        action={
          editable ? (
            <>
              <ActionButton
                title={ActionButtonTypes.EDIT}
                type={ActionButtonTypes.EDIT}
                onClick={() => onEdit(name)}
              />
              <ActionButton
                title={ActionButtonTypes.DELETE}
                type={ActionButtonTypes.DELETE}
                onClick={deleteModuleHandler}
              />
            </>
          ) : (
            <></>
          )
        }
      >
        {editable && (
          <DataAttributesList
            moduleId={moduleId}
            responseAttributes={responseAttributes}
            resultEmptyAttributesError={false}
          />
        )}
        {rules &&
          rules.length > 0 &&
          rules.map((subItem) => {
            return (
              <RuleRow
                data={subItem}
                key={subItem.id}
                name={heading}
                editable={editable}
                moduleId={moduleId}
                editRuleHandler={editRuleHandler}
              />
            );
          })}
        {editable && (
          <RuleButton
            variant="outlined"
            startIcon={<Plus />}
            onClick={addRuleHandler}
          >
            <span>Add Rule</span>
          </RuleButton>
        )}
      </Accordion>
      {addRuleModal && (
        <AddRuleForm
          handleClose={addRuleHandler}
          modelName={heading}
          open={addRuleModal}
          moduleId={moduleId}
          moduleType={moduleType}
        />
      )}
      {editRuleModal && (
        <EditRuleForm
          handleClose={editRuleHandler}
          ruleData={ruleData}
          modelName={heading}
          open={editRuleModal}
          moduleId={moduleId}
        />
      )}
    </>
  );
});
