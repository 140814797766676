import styled from "styled-components";

const handleColorType = (color: string) => {
  switch (color.toLowerCase()) {
    case "invited":
    case "pending":
    case "draft":
    case "inactive":
      return { light: "var(--lightorange)", medium: "var(--mediumorange)" };
    case "deleted":
      return { light: "var(--lightred)", medium: "var(--mediumred)" };
    default:
      return { light: "var(--lightgreen)", medium: "var(--mediumgreen)" };
  }
};

export const StatusDiv = styled.div<{ status: string }>`
  border-radius: 15px;
  font-size: 12px;
  padding: 5px 16px;
  height: 30px;
  color: ${({ status }) => {
    const { medium } = handleColorType(status);
    return medium;
  }};
  background-color: ${({ status }) => {
    const { light } = handleColorType(status);
    return light;
  }};
  font-weight: 700;
  display: table-caption;
  > div {
    display: flex;
    height: 100%;
    align-items: center;
    svg {
      transform: rotateY(180deg);
    }
  }
  svg {
    cursor: pointer;
  }
`;
