import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState } from "../types/types";
import { Widget, WidgetsResponse } from "shared/api/widgets/types";
import { addOrRemove } from "shared/utils/addOrRemove";
import { ApplicationResponse } from "shared/api/applications/types";
import { LinkedProductsResponse } from "shared/api/products/types";

const initialState: InitialState = {
  loading: false,
  data: [],
  total: 0,
  widgetIds: {},
  widgetsArrayLoader: [],
  configWidget: null,
  configWidgetId: null,
  configWidgetLoading: false,
  actionLoader: false,
  rowLoader: "",
  needToRefreshWidgets: false,
  widgetConfigApps: null,
  widgetConfigProducts: null,
  widgetConfigAppLoader: false,
  widgetConfigProductsLoader: false,
};

export const widgetsSlice = createSlice({
  name: "widgets",
  initialState,
  reducers: {
    setWidgetsData: (state, action: PayloadAction<WidgetsResponse>) => {
      const data = action.payload.items.reduce<Record<string, string[]>>(
        (accum, item) => {
          accum[item.id] = item.products.map((item) => item.productId);
          return accum;
        },
        {}
      );
      return {
        ...state,
        data: action.payload.items,
        total: action.payload.totalCount,
        loading: false,
        widgetIds: data,
      };
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setWidgetIds: (
      state,
      action: PayloadAction<{ widgetId: string; productId: string }>
    ) => {
      const productIds = state.widgetIds[action.payload.widgetId];
      const newProductsIds = addOrRemove(
        [...productIds],
        action.payload.productId
      );
      return {
        ...state,
        widgetIds: {
          ...state.widgetIds,
          [action.payload.widgetId]: newProductsIds,
        },
      };
    },
    setWidgetArrayLoading: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        widgetsArrayLoader: addOrRemove(
          [...state.widgetsArrayLoader],
          action.payload
        ),
      };
    },
    setConfigWidgetLoading: (state, action: PayloadAction<boolean>) => {
      state.configWidgetLoading = action.payload;
    },
    setActionLoader: (state, action: PayloadAction<boolean>) => {
      state.actionLoader = action.payload;
    },
    setRowLoader: (state, action: PayloadAction<string>) => {
      state.rowLoader = action.payload;
    },
    setNeedToRefreshWidgets: (state, action: PayloadAction<boolean>) => {
      state.needToRefreshWidgets = action.payload;
    },
    setWidgetItem: (state, action: PayloadAction<Widget>) => {
      state.configWidget = action.payload;
      state.configWidgetId = action.payload.id;
    },
    clearWidgetItem: (state) => {
      state.configWidget = null;
    },
    clearWidgetItemId: (state) => {
      state.configWidgetId = null;
      state.widgetConfigApps = null;
      state.widgetConfigProducts = null;
    },
    setWidgetConfigApps: (
      state,
      action: PayloadAction<ApplicationResponse>
    ) => {
      state.widgetConfigApps =
        action.payload.items.length > 0 ? action.payload.items : null;
      state.widgetConfigAppLoader = false;
    },
    setWidgetConfigAppsLoader: (state, action: PayloadAction<boolean>) => {
      state.widgetConfigAppLoader = action.payload;
    },
    setWidgetConfigProducts: (
      state,
      action: PayloadAction<LinkedProductsResponse>
    ) => {
      state.widgetConfigProducts =
        action.payload.items.length > 0 ? action.payload.items : null;
      state.widgetConfigProductsLoader = false;
    },
    setWidgetConfigProductsLoader: (state, action: PayloadAction<boolean>) => {
      state.widgetConfigProductsLoader = action.payload;
    },
  },
});

export const { reducer: widgetsReducer, actions: widgetsActions } =
  widgetsSlice;
