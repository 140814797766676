import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "../types/types";

export const initialState: InitialState = {
  programs: {
    items: [],
    totalCount: 0,
  },
  loading: false,
  program: {
    id: "",
    name: "",
    startDate: "",
    endDate: "",
    budgetType: "",
    budget: undefined,
    budgetDuration: "",
    currency: "",
    pacing: "",
    status: "",
    isDeleted: false,
    createdDateUtc: "",
    updatedDateUtc: "",
    createdByUserId: null,
    updatedByUserId: null,
    description: "",
    isActive: false,
  },
  isProgramLoading: false,
  products: [],
};

export const programsSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {
    loadPrograms: (state, action) => {
      return {
        ...state,
        programs: action.payload,
      };
    },
    loadProgram: (state, action) => {
      return {
        ...state,
        program: action.payload,
        isProgramLoading: false,
      };
    },
    setProgramLoading: (state) => {
      return {
        ...state,
        isProgramLoading: true,
      };
    },
    loadProducts: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    deleteProducts: (state, action) => {
      const newProducts = state.products.filter(
        (item) => !action.payload.includes(item.productId)
      );
      return {
        ...state,
        products: newProducts,
      };
    },
  },
});

export const { reducer: programReducer, actions: programActions } =
  programsSlice;
