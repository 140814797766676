import { takeLatest, put, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { attachModuleTemplate, detachModuleTemplate } from "api/IXPortalApi";
import { AxiosPromiseResponse } from "api/types";

import { moduleTemplatesActions } from "entities/moduleTemplates";
import { productsActions } from "entities/products";
import { ReduxAction } from "redux/sagas/types";
import { loadModuleTemplates } from "entities/moduleTemplates/model/slice/moduleTemplatesSlice";

import ModulesApi from "shared/api/modules/modulesApi";
import { ModuleTemplatesResponse } from "shared/api/modules/types";

import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

export function* getModuleTemplatesWorker() {
  try {
    const response: AxiosResponse<ModuleTemplatesResponse> = yield call(
      ModulesApi.getModulesTemplates
    );
    yield put(loadModuleTemplates(response.data));
    yield put({
      type: moduleTemplatesActions.GET_MODULE_TEMPLATES_SUCCESS,
    });
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* attachModuleTemplateWorker(action: ReduxAction) {
  let requestError: any;
  const response: AxiosPromiseResponse = yield call(
    async () =>
      await attachModuleTemplate(action.data)
        .then((response) => response)
        .catch((error: any) => {
          requestError = error;
        })
  );
  if (response) {
    yield put({
      type: moduleTemplatesActions.ADD_MODULE_TEMPLATE_SUCCESS,
    });
    yield put({
      type: productsActions.GET_PRODUCT_MODULES,
      data: {
        productId: action.data.id,
        version: action.data.version,
      },
    });
  } else if (requestError) {
    yield put({
      type: moduleTemplatesActions.ADD_MODULE_TEMPLATE_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* detachModuleTemplateWorker(action: ReduxAction) {
  let requestError: any;
  const response: AxiosPromiseResponse = yield call(
    async () =>
      await detachModuleTemplate(action.data)
        .then((response) => response)
        .catch((error: any) => {
          requestError = error;
        })
  );
  if (response) {
    yield put({
      type: moduleTemplatesActions.REMOVE_MODULE_TEMPLATE_SUCCESS,
    });
    yield put({
      type: productsActions.GET_PRODUCT_MODULES,
      data: {
        productId: action.data.id,
        version: action.data.version,
      },
    });
  } else if (requestError) {
    yield put({
      type: moduleTemplatesActions.REMOVE_MODULE_TEMPLATE_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* ModuleTemplatesWatcher() {
  yield takeLatest(
    moduleTemplatesActions.GET_MODULE_TEMPLATES,
    getModuleTemplatesWorker
  );
  yield takeLatest(
    moduleTemplatesActions.ADD_MODULE_TEMPLATE,
    attachModuleTemplateWorker
  );
  yield takeLatest(
    moduleTemplatesActions.REMOVE_MODULE_TEMPLATE,
    detachModuleTemplateWorker
  );
}
