import React, { useContext, useEffect, useState } from "react";

import { Title } from "../../styles";
import {
  AddAttrButton,
  AddAttrDiv,
  Block,
  Row,
  ScrollWrapper,
  TableActionsBlock,
  TableWrapper,
} from "./styles";
import Table from "../../AttributesTableCollapsed";

import { AttributesPayload } from "shared/api/products/types";

import { CreateProductContext } from "pages/CreateProduct";
import { AddAttributesModal } from "../../Modals";
import CreateRequestModal from "../../../Modals/CreateRequest";
import EditDataElements from "../../../Modals/EditDataElements";
import SelectAllButton from "shared/ui/SelectAllButton";

import ExpandAllButton from "shared/ui/ExpandAllButton";
import { SearchField } from "shared/ui/searchField";

import { columns } from "./types";
import { SORT } from "shared/config/enums/Sorting";

import { useAppSelector } from "redux/store/hooks/hooks";

import { getProduct } from "entities/products/model/selectors";
import {
  getAllSelectedAttributes,
  recursiveAttributesFormat,
  recursiveSearch,
} from "./helpers";
import { generateAttributeData } from "pages/CreateProduct/helpers";

const DataElements: React.FC<any> = () => {
  const { removeSelectedAttributes }: any = useContext(CreateProductContext);

  const [attributes, setAttributes] = useState<AttributesPayload[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [openning, setOpenning] = useState(false);
  const [openCreateRequest, setOpenCreateRequest] = useState(false);
  const [openEditDataElement, setOpenEditDataElement] = useState(false);
  const [attribute, setAttribute] = useState<AttributesPayload>();
  const [parentId, setParentId] = useState<string>("");

  const product = useAppSelector(getProduct);

  useEffect(() => {
    if (product?.attributes) {
      if (searchValue) {
        const data = recursiveSearch(product.attributes, searchValue);
        setAttributes(data);
        return;
      }
      setAttributes(recursiveAttributesFormat(product.attributes));
    }
  }, [product]);
  useEffect(() => {
    if (searchValue) {
      const data = recursiveSearch(attributes, searchValue);
      setAttributes(data);
    } else if (!searchValue && product?.attributes) {
      setAttributes(recursiveAttributesFormat(product.attributes));
    }
  }, [searchValue]);
  const removeAttributes = () => {
    if (selected.length) {
      const selectedAdapt = selected.reduce<Record<string, boolean>>(
        (accum, item) => {
          accum[item] = true;
          return accum;
        },
        {}
      );
      const newData = attributes.reduce<any>((accum, item) => {
        if (!selectedAdapt[item.parentConcat]) {
          const newItem = generateAttributeData(item);
          accum.push(newItem);
        }
        return accum;
      }, []);
      removeSelectedAttributes(newData);
      setSelected([]);
    }
  };

  const onEdit = (row: AttributesPayload, id?: string) => {
    setOpenEditDataElement(true);
    setAttribute(row);
    if (id) setParentId(id);
  };

  const handleSelectAllClick = () => {
    const newArray: string[] = [];
    attributes.forEach((attribute) => {
      getAllSelectedAttributes(attribute, newArray);
    });
    setSelected(newArray);
  };

  const filterByNames = (filter: string) => {
    const sortedRows: any = [...attributes];
    sortedRows.sort((a: AttributesPayload, b: AttributesPayload) => {
      const itemA = String(a.attributeName);
      const itemB = String(b.attributeName);

      return itemA.toLowerCase() > itemB.toLowerCase() ? 1 : -1;
    });

    switch (filter) {
      case SORT.ASC:
        setAttributes(sortedRows);
        break;
      case SORT.DESC:
        setAttributes(sortedRows.toReversed());
        break;
      default:
        break;
    }
  };
  return (
    <Block>
      <Title>Data Elements</Title>
      <TableWrapper>
        <TableActionsBlock>
          <Row>
            <SearchField
              value={searchValue}
              onChange={(e: any) => setSearchValue(e.target.value)}
              placeholder="Search Data Elements"
              id="search-data-element"
              sx={{ width: "384px" }}
            />
            <ExpandAllButton
              onClick={() => setOpenning(!openning)}
              sx={{
                marginLeft: "20px",
              }}
            />
            <SelectAllButton
              onClick={handleSelectAllClick}
              sx={{
                marginBottom: "0px",
                marginLeft: "10px",
              }}
            />
          </Row>
          <AddAttrButton
            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={() => setModalOpen(true)}
          >
            Data Element(s)
          </AddAttrButton>
        </TableActionsBlock>
        <ScrollWrapper>
          <Table
            rows={attributes}
            columns={columns}
            filterByNames={filterByNames}
            selected={selected}
            setSelected={setSelected}
            openning={openning}
            onEdit={onEdit}
          />
        </ScrollWrapper>
        <AddAttrDiv className="left">
          <AddAttrButton
            variant="outlined"
            onClick={removeAttributes}
            className="remove"
          >
            {`Remove ${selected.length} Data Element`}
          </AddAttrButton>
        </AddAttrDiv>
      </TableWrapper>
      {modalOpen && (
        <AddAttributesModal
          handleClose={() => setModalOpen(false)}
          openRequestDataElementModal={() =>
            setOpenCreateRequest(!openCreateRequest)
          }
        />
      )}
      <CreateRequestModal
        open={openCreateRequest}
        handleClose={() => setOpenCreateRequest(!openCreateRequest)}
        requestCategory={"Data Elements"}
      />
      {openEditDataElement && (
        <EditDataElements
          onClose={() => setOpenEditDataElement(false)}
          open={openEditDataElement}
          attribute={attribute}
          parentid={parentId}
        />
      )}
    </Block>
  );
};

export default DataElements;
