import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Box } from "@mui/material";

import { PageTitle } from "shared/ui/pageTitle";
import OverviewTab from "components/ProductOverview";

import { productsActions } from "entities/products";
import { useAppSelector } from "redux/store/hooks/hooks";
import { useKpis } from "features/kpis/model/hooks/useKpis";
import { getProduct } from "entities/products/model/selectors";

const ProductOverviewPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const product = useAppSelector(getProduct);
  const { getKpisByProduct } = useKpis();

  useEffect(() => {
    const productId = location.pathname.replace("/products/list/", "");
    dispatch({
      type: productsActions.GET_PRODUCT_VERSIONS,
      data: { id: productId },
    });
    getKpisByProduct(productId);
  }, []);
  const handleClick = () => {
    navigate("/programs/list");
  };

  return product?.id ? (
    <Box data-testid="product-overview-page">
      <PageTitle
        title={product.name}
        backLink={{ to: "/products/list", title: "Back to Products" }}
        handleClick={handleClick}
      />
      <OverviewTab />
    </Box>
  ) : null;
};

export default ProductOverviewPage;
