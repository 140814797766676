import { useAppDispatch, useAppSelector } from "redux/store/hooks/hooks";
import {
  getApplicationsDirectDataAction,
  getDirectData,
  getDirectLoading,
  getDirectProductIds,
  getDirectTotal,
  linkDirectToProductAction,
  unlinkDirectToProductAction,
} from "entities/applications";
import { useCallback } from "react";
import {
  ApplicationsLinkPayload,
  ApplicationsPayload,
} from "shared/api/applications/types";

const useDirectItems = () => useAppSelector(getDirectData);
const useDirectLoading = () => useAppSelector(getDirectLoading);
const useDirectTotal = () => useAppSelector(getDirectTotal);
const useGetDirectProductIds = () => useAppSelector(getDirectProductIds);

export const useDirectData = () => {
  const data = useDirectItems();
  const isLoading = useDirectLoading();
  const total = useDirectTotal();
  const productIds = useGetDirectProductIds();

  return {
    isLoading,
    data,
    total,
    productIds,
  };
};

export const useDirectActions = () => {
  const dispatch = useAppDispatch();
  const getDirectApplications = useCallback(
    (data: ApplicationsPayload) => {
      dispatch(getApplicationsDirectDataAction(data));
    },
    [dispatch]
  );
  const linkDirectToProduct = useCallback(
    (data: ApplicationsLinkPayload) => {
      dispatch(linkDirectToProductAction(data));
    },
    [dispatch]
  );

  const unlinkDirectToProduct = useCallback(
    (data: ApplicationsLinkPayload) => {
      dispatch(unlinkDirectToProductAction(data));
    },
    [dispatch]
  );
  return {
    getDirectApplications,
    linkDirectToProduct,
    unlinkDirectToProduct,
  };
};
