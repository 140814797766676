import { createSelector } from "@reduxjs/toolkit";
import { getApplications } from "./getApplications";
import { getDirectData } from "./getDirectApplications";
import { getNetworkData } from "./getNetworkApplications";

export const getApplicationsData = createSelector(
  getApplications,
  (application) => {
    return application.applications;
  }
);

export const getApplicationsLoading = createSelector(
  getApplications,
  (application) => {
    return application.loading;
  }
);

export const getApplicationsMapData = createSelector(
  getApplications,
  (application) => {
    return application.data;
  }
);

export const getApplicationsTotal = createSelector(
  getApplications,
  (application) => {
    return application.total;
  }
);

export const getSearchExpressions = createSelector(
  getApplications,
  (application) => {
    return application.searchExpression;
  }
);

export const getLoadingLinkedApps = createSelector(
  getApplications,
  (application) => {
    return application.linkedAppsLoading;
  }
);

export const getLinkedProductsList = createSelector(
  getApplications,
  (products) => {
    return products.linkedProducts;
  }
);

export const getLinkedProductsLoading = createSelector(
  getApplications,
  (products) => {
    return products.linkedLoading;
  }
);

export const getLinkedProductsTotal = createSelector(
  getApplications,
  (products) => {
    return products.linkedTotal;
  }
);

export const getLinkedProductsArrayLoading = (productId: string) =>
  createSelector(getApplications, (application) => {
    return application.linkedProductsArrayLoading.includes(productId);
  });

export const getLinkedProductsArrayData = (productId: string) =>
  createSelector(getApplications, (application) => {
    return application.linkedProductsArray.includes(productId);
  });

export const getLinkedProductsArrayDataMap = createSelector(
  getApplications,
  (application) => {
    return application.linkedProductsArray;
  }
);

export const getApplicationsById = (id: string) =>
  createSelector(getDirectData, getNetworkData, (direct, network) => {
    const newData = direct.concat(network);
    const item = newData.find((item) => item.id === id);
    if (item) return item.clientId;
    return "";
  });
export const getApplicationWidgetConfigData = createSelector(
  getApplications,
  (application) => {
    return application.applicationItem;
  }
);
export const getApplicationClientId = (productId: string) =>
  createSelector(getApplicationWidgetConfigData, (application) => {
    if (application?.item?.productIds.includes(productId)) {
      return application.item.clientId;
    }
    return null;
  });

export const getApplicationWidgetConfigItem = createSelector(
  getApplicationWidgetConfigData,
  (application) => {
    return application.item;
  }
);
export const getApplicationWidgetConfigILoading = createSelector(
  getApplicationWidgetConfigData,
  (application) => {
    return application.loading;
  }
);
