import React, { useState, KeyboardEvent } from "react";
import { Controller } from "react-hook-form";
import { useDebounce } from "use-debounce";

import { Grid, Chip, IconButton, InputAdornment, Tooltip } from "@mui/material";
import { HighlightedBlock, Subtitle, ErrorText } from "features/events/ui";
import { InputGroup, CssTextField, FieldGroup } from "../../styles";
import FormGroup from "components/FormComponents/FormGroup";
import Select from "shared/ui/Select";
import Label from "components/FormComponents/Label";
import TextArea from "components/FormComponents/Textarea";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { TenantNotificationTemplate } from "shared/api/templates/types";

export const EmailConfiguration = ({
  control,
  notificationTemplates,
  setValue,
  recipientEmailsWatcher,
  setError,
  clearErrors,
}: any) => {
  const [valueEntered, setValueEntered] = useState("");

  const [currentValue] = useDebounce(valueEntered, 100);

  const deleteValue = (valueToRemove: string) => {
    const newEmails = recipientEmailsWatcher.filter(
      (value: any) => value !== valueToRemove
    );
    setValue(
      "actionConfiguration.emailActionConfiguration.recipientEmails",
      newEmails
    );
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      const newValues = recipientEmailsWatcher
        ? [...recipientEmailsWatcher]
        : [];
      const validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (currentValue.match(validRegex)) {
        newValues.push(currentValue);
        setValueEntered("");
        setValue(
          "actionConfiguration.emailActionConfiguration.recipientEmails",
          newValues
        );
        clearErrors([
          "actionConfiguration.emailActionConfiguration.recipientEmails",
        ]);
      } else {
        setError(
          "actionConfiguration.emailActionConfiguration.recipientEmails",
          {
            type: "custom",
            message: "invalid email address",
          }
        );
      }
    }
  };

  const handleChange = (e: any) => {
    setValue(
      "actionConfiguration.emailActionConfiguration.tenantNotificationTemplateName",
      e.target.value
    );

    const tenantNotificationTemplateId = notificationTemplates.filter(
      (item: TenantNotificationTemplate) => item.displayName === e.target.value
    )[0].id;
    setValue(
      "actionConfiguration.emailActionConfiguration.tenantNotificationTemplateId",
      tenantNotificationTemplateId
    );
  };

  return (
    <HighlightedBlock>
      <Subtitle>EMAIL CONFIGURATION</Subtitle>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Controller
            name="actionConfiguration.emailActionConfiguration.senderEmail"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormGroup
                  id="senderEmail"
                  value={value}
                  label="Sender email"
                  placeholder="Enter sender email"
                  onChange={onChange}
                  error={!!error}
                  danger={true}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="actionConfiguration.emailActionConfiguration.senderName"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormGroup
                  id="senderName"
                  value={value}
                  label="Sender name"
                  placeholder="Enter sender name"
                  danger={true}
                  onChange={onChange}
                  error={!!error}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Controller
            name="actionConfiguration.emailActionConfiguration.recipientEmails"
            control={control}
            rules={{ required: true }}
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <>
                  <Label label="Recipient emails" danger />
                  <Tooltip
                    title="Please press Enter button after input the value"
                    classes={{ popper: "white-tooltip" }}
                  >
                    <InputGroup error={error}>
                      {value?.map((value: string) => (
                        <Chip
                          key={value}
                          label={value}
                          onDelete={() => deleteValue(value)}
                        />
                      ))}
                      <CssTextField
                        fullWidth={!recipientEmailsWatcher?.length}
                        type="text"
                        variant="outlined"
                        value={valueEntered}
                        id="values"
                        onChange={(e: any) => setValueEntered(e.target.value)}
                        onKeyDown={handleKeyPress}
                        sx={{ padding: 0, border: "none" }}
                        error={!!error}
                      />
                    </InputGroup>
                  </Tooltip>
                  {error && <ErrorText error={error} />}
                </>
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Controller
            name="actionConfiguration.emailActionConfiguration.tenantNotificationTemplateName"
            control={control}
            rules={{ required: true }}
            render={({ field: { value }, fieldState: { error } }) => {
              return (
                <>
                  <Label label="Notification template name" danger={true} />
                  <Select
                    onChange={handleChange}
                    options={notificationTemplates.map(
                      (item: TenantNotificationTemplate) => item.displayName
                    )}
                    id={"tenantNotificationTemplateId"}
                    error={!!error}
                    value={value || ""}
                  />
                </>
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Controller
            name="actionConfiguration.emailActionConfiguration.templateData"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <>
                  <Label label={"Template data"} />
                  <FieldGroup>
                    <TextArea
                      value={value}
                      id="templateData"
                      onChange={onChange}
                      placeholder="Enter template data"
                      minRows={4}
                      error={error}
                      className="has-tooltip"
                    />
                    <InputAdornment
                      position="end"
                      sx={{
                        position: "absolute",
                        top: "20px",
                        right: "15px",
                      }}
                    >
                      <Tooltip
                        title={`Specify parameters that will be used in email templates. In key-value format, 
                          where key is the name of the parameter expected in the email template and the value is 
                          what you would like to be substituted. Use JsonPath syntax and wrap it with ### to use dynamic data. 
                          For example: { "firstName": "###$.ApplicationFormAreaAttributesData.FirstName###", 
                          "lastName": "###$.ApplicationFormAreaAttributesData.LastName###" }. This means your product contains 
                          FirstName and LastName attributes and values from an application form will be substituted. 
                          Pay attention to the trigger selected as this manages what data areas are available`}
                        classes={{ popper: "white-tooltip lg-tooltip" }}
                      >
                        <IconButton aria-label="toggle visibility" edge="end">
                          <InfoOutlinedIcon sx={{ color: "#0671E0" }} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  </FieldGroup>
                </>
              );
            }}
          />
        </Grid>
      </Grid>
    </HighlightedBlock>
  );
};
