import React, { useState, useEffect, createContext, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import Tabs from "components/Tabs";
import { TabPanelWrap } from "components/Tabs/styles";
import AssociatedProducts from "components/AssociatedProducts";
import { BackDiv, StyledTitle } from "pages/EmbeddedHub/styles";

import { PageTitle } from "shared/ui/pageTitle";

import { TabNames } from "../types";
import { ProductResponse } from "shared/api/products/types";

import { CategoryData } from "shared/api/productCategories/types";

import { productsActions } from "entities/products";
import { useAppSelector } from "redux/store/hooks/hooks";

import { productCategoriesActions } from "entities/productCategories";
import { getCategoriesList } from "entities/productCategories/model/selectors";
import {
  getProductsList,
  getProductsByModuleTemplate,
  getIsLoading,
} from "entities/products/model/selectors";
import { Spinner } from "shared/ui/Spinner";
import { AssociateProductData } from "features/associatedProducts/ui/types";
import { RoutesList } from "shared/routes";

export const AssociatedProductsContext: any = createContext(null);

const tabList = [
  {
    label: TabNames.THIRDPARTY,
  },
  {
    label: TabNames.DATAENRICH,
  },
];

const IntegrationHub = () => {
  const [activeTab, setActiveTab] = useState(TabNames.THIRDPARTY);
  const products = useAppSelector(getProductsList);
  const productsByModuleTemplate = useAppSelector(getProductsByModuleTemplate);
  const isLoading = useAppSelector(getIsLoading);
  const productCategories: CategoryData[] = useAppSelector(getCategoriesList);

  const [associateProducts, setAssociateProducts] = useState<
    AssociateProductData[]
  >([]);
  const [viewAllProducts, setViewAllProducts] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!productCategories.length) {
      dispatch({ type: productCategoriesActions.GET_PRODUCT_CATEGORIES });
    }
    if (!products.length) {
      dispatch({
        type: productsActions.GET_PRODUCTS,
        data: {
          params: {
            ShowArchived: false,
            limit: 10,
            offset: 0,
            states: ["Draft"],
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (productsByModuleTemplate.length) {
      const result: AssociateProductData[] = [];
      productsByModuleTemplate.map((product: ProductResponse) => {
        const data = {
          id: product.id,
          productName: product.name,
          productStatus: product.state,
          categoryName: getCategoryName(product.categoryId),
          createdDateUtc: product.createdDateUtc,
          updatedDateUtc: product.updatedDateUtc,
          version: product.version,
        };
        result.push(data);
      });
      if (result.length) setAssociateProducts(result);
    } else setAssociateProducts([]);
  }, [productsByModuleTemplate]);

  const getCategoryName = (id: string) => {
    const category = productCategories.find(
      (category: CategoryData) => category.id === id
    );
    if (category) return category.categoryName;
    else return "Unknown";
  };

  const viewProducts = (id: string) => {
    setViewAllProducts(!viewAllProducts);

    dispatch({
      type: productsActions.GET_PRODUCTS_BY_MODULE_TEMPLATE,
      data: {
        params: {
          ShowArchived: false,
          ModuleTemplateId: id,
        },
      },
    });
  };

  useEffect(() => {
    if (location.pathname == RoutesList.INTEGRATIONS) {
      setActiveTab(TabNames.THIRDPARTY);
      navigate("third-party");
    }
  }, [location]);

  const listProduct = true;

  const backClick = () => {
    setViewAllProducts(!viewAllProducts);
    if (associateProducts.length) setAssociateProducts([]);
  };

  const productsValue = useMemo(
    () => ({
      associateProducts,
      viewProducts,
      listProduct,
    }),
    [associateProducts, listProduct, viewProducts]
  );

  return (
    <AssociatedProductsContext.Provider value={productsValue}>
      {viewAllProducts && (
        <BackDiv onClick={backClick}>
          <ArrowBackIosIcon />
          <StyledTitle>Back to Integration Hub</StyledTitle>
        </BackDiv>
      )}
      <PageTitle
        title={viewAllProducts ? "Associated Products" : "Integrations Hub"}
      />
      {!viewAllProducts ? (
        <Tabs
          setActiveTab={setActiveTab}
          tabList={tabList}
          activeTab={activeTab}
          basePath={RoutesList.INTEGRATIONS}
        >
          <Outlet />
        </Tabs>
      ) : (
        <TabPanelWrap>
          {isLoading ? (
            <Spinner />
          ) : (
            <AssociatedProducts products={associateProducts} />
          )}
        </TabPanelWrap>
      )}
    </AssociatedProductsContext.Provider>
  );
};

export default IntegrationHub;
