import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from "@mui/material";

import { StyledChip } from "./styles";

import TableHeader from "./TableHeader";
import TablePlaceholder from "shared/ui/TablePlaceholder";
import OutlinedCheckbox from "../FormComponents/OutlinedCheckbox";
import TableCellComponent from "./TableCell";
import { ReactComponent as ActivateIcon } from "assets/icons/add.svg";
import { ReactComponent as DeactivateIcon } from "assets/icons/remove.svg";

// actions
import { draftItemsActions } from "entities/draftItems";

// types
import { TableProps } from "./types";
import { IXTableRow } from "shared/ui/Table";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getDraftItems } from "entities/draftItems/model/selectors";

const DataTable: React.FC<TableProps> = ({
  rows,
  columns,
  pagingParams,
  setPagingParams,
  filterParams,
  setFilterParams,
  totalCount,
  activeTab,
  onView,
  onDelete,
  onRestore,
  resendInvitation,
  rowHasChip,
  chipIndex,
  isCompact,
  onEdit,
  chipName,
  hideCheckbox,
  setSortOrder,
  heightAuto,
  borderCollapse,
  onClone,
  navigateUrl,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState<string[]>([]);

  const dispatch = useDispatch();
  const draftItems = useAppSelector(getDraftItems);
  const tenant = localStorage.getItem("tenant-name") ?? "IX-Platform";

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (setPagingParams) {
      setPagingParams({
        ...pagingParams,
        page: newPage,
        offset: rowsPerPage * newPage,
      });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (setPagingParams) {
      setPagingParams({
        ...pagingParams,
        page: 0,
        limit: event.target.value,
        offset: 0,
      });
    }
  };

  const renderChip = (row: any) => {
    if (chipName) {
      const activating = chipName === "Activate";
      const addToProduct = chipName === "Add to product";
      return (
        <StyledChip
          label={chipName}
          chiptype="activated"
          icon={
            activating || addToProduct ? <ActivateIcon /> : <DeactivateIcon />
          }
          onClick={() => onEdit?.(row, chipName)}
        />
      );
    } else if (row.productName && row.categoryName) {
      const deactivating = onEdit?.name.includes("Deactivate");
      return (
        <StyledChip
          label={deactivating ? "Deactivate" : "Activate"}
          chiptype="ready"
          icon={deactivating ? <DeactivateIcon /> : <ActivateIcon />}
          onClick={() => onEdit?.(row)}
        />
      );
    } else {
      switch (row.status) {
        case "activated":
          return (
            <StyledChip
              label="Deactivate"
              chiptype="activated"
              icon={<DeactivateIcon />}
              onClick={() => onEdit?.(row)}
            />
          );
        case "ready":
          return (
            <StyledChip
              label="Activate"
              chiptype="ready"
              icon={<ActivateIcon />}
              onClick={() => onEdit?.(row)}
            />
          );
        default:
          return <StyledChip label="Pending" chiptype="pending" />;
      }
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected: any = rows.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedRows = [...selected];
    if (isSelected(id)) {
      const elementIndex = selectedRows.indexOf(id);
      selectedRows.splice(elementIndex, 1);
      setSelected(selectedRows);
    } else {
      setSelected([...selectedRows, id]);
    }
  };

  const isSelected = (id: string) => {
    return selected.some((x) => x === id);
  };

  useEffect(() => {
    if (pagingParams?.offset === 0) setPage(0);
  }, [pagingParams]);

  useEffect(() => {
    dispatch({ type: draftItemsActions.ADD_DRAFT_ITEM, data: selected });
  }, [selected]);

  useEffect(() => {
    if (draftItems.length !== selected.length) {
      setSelected([]);
    }
  }, [draftItems]);

  const calcHeight = isCompact ? "660px" : "540px";
  return (
    <>
      <TableContainer
        sx={{
          height: heightAuto ? "auto" : `calc(100vh - ${calcHeight})`,
          position: "relative",
          "&::-webkit-scrollbar": {
            height: "2px",
          },
        }}
        data-testid="tableContainer"
      >
        <Table
          stickyHeader
          sx={
            borderCollapse
              ? {
                  borderCollapse: "separate",
                  borderSpacing: "0 10px",
                }
              : {}
          }
        >
          <TableHeader
            hideCheckbox={rowHasChip || hideCheckbox}
            columns={columns}
            rowCount={rows.length}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            setSortOrder={setSortOrder}
          />
          <TableBody data-testid="tableBody">
            {rows.length ? (
              <>
                {rows.map((row: any) => (
                  <IXTableRow
                    selected={selected.indexOf(row.id) !== -1}
                    hover
                    tabIndex={-1}
                    key={row.id}
                    data-testid={`tableRow_${row.id}`}
                    borderCollapse={borderCollapse}
                  >
                    {!hideCheckbox && (
                      <TableCell padding="checkbox">
                        <OutlinedCheckbox
                          onClick={(event) => handleClick(event, row.id)}
                          checked={isSelected(row.id)}
                        />
                      </TableCell>
                    )}
                    {columns.map((column, index) => {
                      const colValue =
                        column.id === "requestStatus"
                          ? row["status"]
                          : row[column.id];
                      if (index === chipIndex && rowHasChip) {
                        return (
                          <TableCell sx={{ textAlign: "center" }} key={uuid()}>
                            {renderChip(row)}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          sx={{
                            maxWidth: "100px",
                            maxHeight: "66px",
                            overflow: "scroll",
                            "&::-webkit-scrollbar": {
                              display: "none",
                            },
                          }}
                        >
                          {activeTab === "Notifications" && !row.isRead ? (
                            <b>
                              <TableCellComponent
                                columnId={column.id}
                                colValue={colValue}
                                row={row}
                                id={row.id}
                                resendInvitation={resendInvitation}
                                tenant={tenant}
                                activeTab={activeTab}
                                onView={onView}
                                onDelete={onDelete}
                                onRestore={onRestore}
                                onClone={onClone}
                                navigateUrl={navigateUrl}
                              />
                            </b>
                          ) : (
                            <TableCellComponent
                              columnId={column.id}
                              colValue={colValue}
                              row={row}
                              id={row.id}
                              resendInvitation={resendInvitation}
                              tenant={tenant}
                              activeTab={activeTab}
                              onView={onView}
                              onDelete={onDelete}
                              onRestore={onRestore}
                              onClone={onClone}
                              navigateUrl={navigateUrl}
                            />
                          )}
                        </TableCell>
                      );
                    })}
                  </IXTableRow>
                ))}
              </>
            ) : (
              <TablePlaceholder
                colSpan={hideCheckbox ? columns.length : columns.length + 1}
                heightAuto={heightAuto}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ height: "55px" }}>
        {rows.length && !rowHasChip ? (
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={totalCount || rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            data-testid="table_pagination"
          />
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default DataTable;
