import React, { FC } from "react";
import { NavLinkProps } from "react-router-dom";
import { StyledLink } from "./styles";

type Props = NavLinkProps;
const SimpleLink: FC<Props> = ({ children, ...props }) => {
  return <StyledLink {...props}>{children}</StyledLink>;
};

export default SimpleLink;
