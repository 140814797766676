import {
  AllAttributesItemsFormat,
  AttributesPayload,
} from "shared/api/products/types";

export const recursiveAttributesFormat = (
  attributes: AttributesPayload[],
  root?: string,
  parentConcat = "",
  parentRoute = ""
) => {
  return attributes.reduce<AttributesPayload[]>((accum, item) => {
    const newItem: AttributesPayload = {
      ...item,
      root,
      parentConcat: parentConcat ? parentConcat + "." + item.id : item.id,
      parentRoute: parentRoute
        ? parentRoute + " > " + item.displayName
        : item.displayName,
      name: item.attributeName,
      subAttributes: [],
    } as AttributesPayload;
    if (item.subAttributes.length > 0) {
      newItem.subAttributes = recursiveAttributesFormat(
        item.subAttributes,
        root ?? item.attributeName,
        parentConcat ? parentConcat + "." + item.id : item.id,
        parentRoute ? parentRoute + " > " + item.displayName : item.displayName
      );
    }
    accum.push(newItem);
    return accum;
  }, []);
};

export const recursiveFiltering = (
  attributes: AttributesPayload[],
  selected: Record<string, boolean>
) => {
  return attributes.reduce<AttributesPayload[]>((accum, item) => {
    if (!selected[item.parentConcat]) {
      const newItem: any = {
        id: item.id,
        attributeName: item.attributeName,
        attributeDescription: item.attributeDescription,
        fillmentType: item.defaultFillmentType ?? item.fillmentType,
        valueType: item.valueType,
        type: item.attributeType,
        systemFieldId: item.systemFieldId,
        regexExpression: item.regexExpression,
        values: item.values,
        defaultValue: item.defaultValue,
        format: item.format,
        displayName: item.displayName,
        isRequired: item.isRequired,
        isArray: item.isArray,
      };
      if (item.subAttributes.length > 0) {
        newItem.subAttributes = recursiveFiltering(
          item.subAttributes,
          selected
        );
      }
      accum.push(newItem);
    }

    return accum;
  }, []);
};

export const recursiveSearch = (
  attributes: AttributesPayload[],
  search: string
) => {
  return attributes.reduce<AttributesPayload[]>((accum, item) => {
    const attrName = item.attributeName.toLowerCase();
    const newItem = {
      ...item,
    };
    if (item.subAttributes.length > 0) {
      newItem.subAttributes = recursiveSearch(newItem.subAttributes, search);
    }
    if (
      attrName.includes(search.toLowerCase()) ||
      newItem.subAttributes.length > 0
    ) {
      accum.push(newItem);
    }
    return accum;
  }, []);
};

export const recursiveSearchDataElements = (
  attributes: AttributesPayload[],
  search: string
) => {
  return attributes.reduce<AttributesPayload[]>((accum, item) => {
    const attrName = item.attributeName.toLowerCase();
    const newItem = {
      ...item,
    };
    if (attrName.includes(search.toLowerCase())) {
      accum.push(newItem);
    } else if (item.subAttributes.length > 0) {
      newItem.subAttributes = recursiveSearchDataElements(
        newItem.subAttributes,
        search
      );
    }
    return accum;
  }, []);
};

export const recursiveTypeAdd = (attributes: AttributesPayload[]) => {
  return attributes.reduce<AttributesPayload[]>((accum, item) => {
    const updated_attribute = {
      ...item,
      subAttributes: item.subAttributes.length
        ? recursiveTypeAdd(item.subAttributes)
        : [],
      type: "Category",
    };
    accum.push(updated_attribute);
    return accum;
  }, []);
};
export const getAllSelectedAttributes = (
  attribute: AttributesPayload,
  array: string[]
) => {
  attribute.parentConcat && array.push(attribute.parentConcat);
  if (attribute.subAttributes.length > 0) {
    attribute.subAttributes.forEach((item) => {
      getAllSelectedAttributes(item, array);
    });
  }
  return array;
};

export const getAllItems = (data: AttributesPayload[]) => {
  let obj = {} as any;
  data.forEach((item) => {
    obj[item.id] = { ...item, subAttributes: {} };
    obj = { ...obj, ...getAllItems(item.subAttributes) };
  });
  return obj;
};
type AttributesFormat = Record<
  string,
  Omit<AttributesPayload, "subAttributes"> & { subAttributes: AttributesFormat }
>;
export const parseResponse = (response: AttributesFormat) => {
  return Object.values(response).reduce<any>((acc, attribute) => {
    const newItem = {
      id: attribute.id,
      attributeName: attribute.attributeName,
      attributeDescription: attribute.attributeDescription,
      fillmentType: attribute.defaultFillmentType ?? attribute.fillmentType,
      valueType: attribute.valueType,
      type: attribute.attributeType,
      systemFieldId: attribute.systemFieldId,
      regexExpression: attribute.regexExpression,
      values: attribute.values,
      defaultValue: attribute.defaultValue,
      format: attribute.format,
      displayName: attribute.displayName,
      isRequired: attribute.isRequired,
      isArray: attribute.isArray,
      subAttributes: parseResponse(attribute.subAttributes),
    };
    acc.push(newItem);
    return acc;
  }, []);
};

export const buildResponse = (
  selected: string[],
  allItems: AllAttributesItemsFormat
) => {
  const response = {} as any;
  for (const oneOfSelected of selected) {
    const reversePath = oneOfSelected.split(".").reverse();
    let previousItem: AttributesPayload | undefined = undefined;
    for (const item of reversePath) {
      const Item = allItems[item] as any;
      if (previousItem) {
        Item.subAttributes[previousItem.id] = previousItem;
      }
      if (previousItem?.root === Item.id || reversePath.length === 1) {
        response[Item.name] = Item;
      }
      previousItem = Item;
    }
  }
  return parseResponse(response);
};
