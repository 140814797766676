import React from "react";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const StyledOutlinedInput = (props: any) => {
  const { title, value, onChange, error, id, placeholder } = props;
  return (
    <OutlinedInput
      fullWidth
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      error={!!error}
      id={id}
      endAdornment={
        <InputAdornment position="end">
          <Tooltip title={title} classes={{ popper: "white-tooltip" }}>
            <IconButton aria-label="toggle visibility" edge="end">
              <InfoOutlinedIcon sx={{ color: "#0671E0" }} />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      }
      sx={{
        "&.Mui-error": {
          background: "var(--lightred)",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid var(--secondary)",
          boxShadow: "0px 0px 10px rgba(98, 102, 234, 0.5)",
        },
        "&.Mui-focused .MuiOutlinedInput-input": {
          border: "none",
        },
      }}
    />
  );
};
