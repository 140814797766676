import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

export const getProductCategories = (state: RootState) =>
  state.productCategories;

export const getCategories = createSelector(
  getProductCategories,
  (productCategories) => {
    return productCategories.categories;
  }
);

export const getCategoriesList = createSelector(getCategories, (categories) => {
  return categories.items;
});

export const getProductCategory = createSelector(
  getProductCategories,
  (productCategories) => {
    return productCategories.productCategory;
  }
);
