import React from "react";

const TextMatchHighlight = (text: string, query: string) => {
  const parts = text.split(new RegExp(`(${query})`, "gi"));
  return (
    <pre>
      {parts.map((part, index) => {
        return (
          <span
            key={`${part}_${index}`}
            style={
              part.toLowerCase() === query.toLowerCase()
                ? { fontWeight: "bold" }
                : {}
            }
          >
            {part}
          </span>
        );
      })}
    </pre>
  );
};

export default TextMatchHighlight;
