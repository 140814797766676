import { Column } from "../Table/types";

export const initialColumns: Column[] = [
  {
    id: "name",
    label: "Product Name",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "category",
    label: "category",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "state",
    label: "Status",
    minWidth: 100,
    filtering: false,
  },
  {
    id: "createdDateUtc",
    label: "Creation",
    minWidth: 100,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Update",
    minWidth: 100,
    filtering: false,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 200,
    align: "center",
    filtering: false,
  },
];

export const archivedColumns: Column[] = [
  {
    id: "name",
    label: "Product Name",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "category",
    label: "category",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Date Deleted",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 100,
    align: "right",
    filtering: false,
  },
];
