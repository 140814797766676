import React from "react";
import { Container, StyledButton, StyledTypography } from "./styles";

const FirstimeBox: React.FC<{
  title: string;
  handleCreate: () => void;
  icon: any;
}> = ({ title, handleCreate, icon }) => {
  return (
    <Container>
      {icon}
      <StyledTypography>Start acquiring everywhere</StyledTypography>
      <StyledButton
        color={"secondary"}
        onClick={handleCreate}
        data-testid={`create-${title}-button`}
      >
        {`Create New ${title}`}
      </StyledButton>
    </Container>
  );
};

export default FirstimeBox;
