import React, { ReactNode } from "react";
import { Paper } from "@mui/material";

interface Props {
  children: ReactNode;
}
const TablePaper = (props: Props) => {
  const { children } = props;
  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        padding: "32px 50px",
        borderRadius: "20px",
        boxShadow: "none",
      }}
    >
      {children}
    </Paper>
  );
};

export default TablePaper;
