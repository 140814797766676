import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductResponse } from "shared/api/products/types";
import { ProgramResponse } from "shared/api/programs/types";

export const mockData: any[] = [
  {
    id: "checsc-cbhdcbh-qwhxj",
    name: "WorkersComp 1",
    createdDateUtc: "2023-07-12",
    updatedDateUtc: "2023-07-12",
    distribution: "Connection Junction",
  },
];

const initialState = {
  products: [],
  publishLoader: false,
  loading: false,
  totalCount: 0,
  product: {} as ProductResponse,
  program: {} as ProgramResponse,
  versions: {
    id: "",
    versions: [],
  },
  modules: [],
  dataEnrichRequests: mockData,
  thirdPartyRequests: [],
  productByCategory: {
    products: [],
    totalCount: 0,
  },
  productsByModuleTemplate: {
    products: <ProductResponse[]>[],
    totalCount: 0,
  },
};
const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    createNewThirdPartyRequest: (state, action) => {
      return {
        ...state,
        thirdPartyRequests: action.payload,
      };
    },
    createNewDataEnrichRequest: (state, action) => {
      return {
        ...state,
        dataEnrichRequests: action.payload,
      };
    },
    loadProducts: (state, action) => {
      return {
        ...state,
        products: [],
        ...action.payload,
        totalCount: 0,
        dataEnrichRequests: mockData,
      };
    },
    getProductsSuccess: (state, action) => {
      return {
        ...state,
        products: action.payload.products,
        totalCount: action.payload.totalCount,
        loading: false,
      };
    },
    loadProduct: (state, action) => {
      return {
        ...state,
        product: {
          ...action.payload,
        },
      };
    },
    loadProductProgram: (state, action) => {
      return {
        ...state,
        program: {
          ...action.payload?.items[0],
        },
      };
    },
    loadProductVersions: (state, action) => {
      return {
        ...state,
        versions: {
          ...action.payload,
        },
      };
    },
    getModules: (state, action) => {
      return {
        ...state,
        modules: action.payload.modules,
      };
    },
    loadProductByCategoryId: (state, action) => {
      return {
        ...state,
        productByCategory: {
          ...action.payload,
        },
      };
    },
    loadProductByModuleTemplateId: (state, action) => {
      return {
        ...state,
        productsByModuleTemplate: {
          ...action.payload,
        },
      };
    },
    setPublishLoader: (state, action: PayloadAction<boolean>) => {
      state.publishLoader = action.payload;
    },
  },
});

export const { reducer: productsReducer, actions: productActions } =
  productsSlice;
