import styled from "styled-components";

export const StyledActionWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Divider = styled.i`
  width: 1px;
  height: 20px;
  background: var(--darksilver);
  margin-left: 8px;
`;
