import React from "react";

import Label from "../../FormComponents/Label";
import CustomSelect from "../../FormComponents/Select";

import { Grid } from "@mui/material";
import { WarningIcon, WarningTextWrap, WarningWrap } from "./styles";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useLocation } from "react-router-dom";

interface PermissionsProps {
  selectRole: (event: any) => void;
  selectGroup: (event: any) => void;
  role: string[];
  group: string[];
}

const Permissions = ({
  selectRole,
  selectGroup,
  role,
  group,
}: PermissionsProps) => {
  const { pathname } = useLocation();
  return (
    <>
      {pathname === "/user-management/archive" && (
        <WarningWrap data-testid="editUserModal_permissionsWarning">
          <WarningIcon>
            <WarningAmberIcon />
          </WarningIcon>
          <WarningTextWrap>
            This User is <strong>Inactive</strong>. Your can restore this user
            with all permissions and settings. Please review and update user
            details if needed.
          </WarningTextWrap>
        </WarningWrap>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Label label="Role" />
          <CustomSelect
            value={role}
            id="role"
            options={["Role 1", "Role 2", "Role 3"]}
            onChange={selectRole}
            title="Role"
          />
        </Grid>
        <Grid item xs={12}>
          <Label label="Groups" />
          <CustomSelect
            value={group}
            id="group"
            options={["Group 1", "Group 2", "Group 3"]}
            onChange={selectGroup}
            title="Group"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Permissions;
