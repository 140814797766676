import React, { memo } from "react";
import { StyledChip } from "shared/ui/Table/styles";
import { ReactComponent as ActivateIcon } from "assets/icons/add.svg";
import { ReactComponent as DeactivateIcon } from "assets/icons/remove.svg";
import { useAppSelector } from "redux/store/hooks/hooks";
import { Spinner } from "shared/ui/Spinner";
import { ChipWrapper } from "../../applications/ui/styles";
import { useLinkedProductsActions } from "features/applications";
import {
  getLinkedProductsArrayData,
  getLinkedProductsArrayLoading,
} from "entities/applications";
import { useNavigate } from "react-router-dom";

interface Props {
  productId: string;
  appId: string;
}

export const ActivateButtonApplication = memo(
  function ActivateButtonApplication({ productId, appId }: Props) {
    const { linkToProduct, unlinkToProduct } = useLinkedProductsActions();
    const active = useAppSelector(getLinkedProductsArrayData(productId));
    const loading = useAppSelector(getLinkedProductsArrayLoading(productId));
    const navigate = useNavigate();

    const handleActivate = () => {
      if (!active) {
        linkToProduct({ id: appId, productId });
      } else {
        unlinkToProduct({ id: appId, productId });
      }
      navigate(`/products/list/${productId}`);
    };

    return (
      <ChipWrapper>
        {loading ? <Spinner /> : null}
        <StyledChip
          label={!active ? "Activate" : "Deactivate"}
          chiptype={"activate"}
          icon={!active ? <ActivateIcon /> : <DeactivateIcon />}
          onClick={handleActivate}
        />
      </ChipWrapper>
    );
  }
);
