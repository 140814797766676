/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Grid } from "@mui/material";
import {
  CardContentInfo,
  CardFooter,
  CardHeader,
  HubCard,
  CardContentWithIcon,
} from "shared/ui/Cards";
import { PageTitle } from "shared/ui/pageTitle";

import { ReactComponent as Configurator } from "assets/icons/program-configurator.svg";
import { ReactComponent as Application } from "assets/icons/programming-application.svg";
import { ReactComponent as Analytics } from "assets/icons/analytics-reporting.svg";

import CreateProgram from "components/Modals/CreateProgram";

import { useAppSelector } from "redux/store/hooks/hooks";
import { usersActions, getActiveUsersList } from "entities/users";
import { getProducts, productsActions } from "entities/products";
import { getProgramsTotal, programsActions } from "entities/programs";
import AddNewUser from "components/Modals/AddNewUser";

const CarrierHubPage: React.FC<any> = () => {
  const dispatch = useDispatch();
  const activeUsers = useAppSelector(getActiveUsersList);
  const products = useAppSelector(getProducts);
  const programsTotal = useAppSelector(getProgramsTotal);

  useEffect(() => {
    dispatch({
      type: usersActions.GET_USERS,
      data: { params: { ShowArchived: false } },
    });
    dispatch({
      type: productsActions.GET_PRODUCTS,
      data: {
        params: {
          ShowArchived: false,
          limit: 10,
          offset: 0,
        },
      },
    });
    dispatch({
      type: programsActions.GET_PROGRAMS,
      data: {
        params: {
          ShowArchived: false,
          limit: 10,
          offset: 0,
        },
      },
    });
  }, []);

  const carrierHubs = [
    {
      id: "1",
      name: "Program Configurator",
      content: {
        ...(programsTotal && { count: programsTotal }),
        description: programsTotal
          ? "Programs configured"
          : "Start acquiring everywhere",
      },
      icon: <Configurator />,
      links: programsTotal
        ? [
            {
              text: "Create New Program",
              to: "/?openModal=true",
            },
            {
              text: "View All Programs",
              to: "programs/list",
            },
          ]
        : [
            {
              text: "Create New Program",
              to: "/?openModal=true",
            },
          ],
    },
    {
      id: "2",
      name: "Product Configurator",
      content: {
        ...(products.totalCount && { count: products.totalCount }),
        description: products.totalCount
          ? "Products Configured"
          : "Start acquiring everywhere",
      },
      icon: <Application />,
      links: products.totalCount
        ? [
            {
              text: "Create New Product",
              to: "/create_new_product",
            },
            {
              text: "View All Products",
              to: "products/list",
            },
          ]
        : [
            {
              text: "Create New Product",
              to: "/create_new_product",
            },
          ],
    },
    {
      id: "3",
      name: "User Management",
      content: {
        count: activeUsers?.length || 0,
        description: "Active users",
      },
      links: [
        {
          text: "Add New",
          to: "/?openAddUserModal=true",
        },
        {
          text: "View All",
          to: "user-management/users",
        },
      ],
    },
    {
      id: "4",
      name: "Analytics & Reporting",
      icon: <Analytics />,
      content: {
        additional: "Top 5",
        description:
          "You will be able to visualize the performance of your active Programs/Products",
      },
    },
  ];

  const [openCreateProgramModal, setOpenCreateProgramModal] = useState(false);
  const [openAddNewUserModal, setOpenAddNewUserModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const openModal = searchParams.get("openModal");
    const openAddUserModal = searchParams.get("openAddUserModal");
    if (openModal && !openCreateProgramModal) setOpenCreateProgramModal(true);
    if (openAddUserModal && !openAddNewUserModal) setOpenAddNewUserModal(true);
  }, [searchParams]);

  useEffect(() => {
    if (openCreateProgramModal && searchParams.has("openModal")) {
      searchParams.delete("openModal");
      setSearchParams(searchParams);
    }
  }, [openCreateProgramModal]);

  useEffect(() => {
    if (openAddNewUserModal && searchParams.has("openAddUserModal")) {
      searchParams.delete("openAddUserModal");
      setSearchParams(searchParams);
    }
  }, [openAddNewUserModal]);

  return (
    <>
      <PageTitle title={"Carrier Hub"} />
      <Grid container spacing={3}>
        {carrierHubs.map((carrierHub) => {
          return (
            <Grid item xs={6} md={6} xl={6} key={carrierHub.id}>
              <HubCard>
                <CardHeader title={carrierHub.name} />
                {carrierHub.icon && !carrierHub.content.count && (
                  <CardContentWithIcon
                    icon={carrierHub.icon}
                    description={carrierHub.content.description}
                  />
                )}
                {carrierHub.content.hasOwnProperty("count") && (
                  <CardContentInfo
                    count={carrierHub.content.count}
                    description={carrierHub.content.description}
                  />
                )}
                {carrierHub.links && <CardFooter buttons={carrierHub.links} />}
              </HubCard>
            </Grid>
          );
        })}
      </Grid>
      <CreateProgram
        open={openCreateProgramModal}
        handleClose={() => setOpenCreateProgramModal(!openCreateProgramModal)}
      />
      <AddNewUser
        onClose={() => setOpenAddNewUserModal(false)}
        open={openAddNewUserModal}
      />
    </>
  );
};

export default CarrierHubPage;
