import { modalsSlice } from "entities/modals/model/slice/modalsSlice";
import { moduleTemplatesSlice } from "entities/moduleTemplates/model/slice/moduleTemplatesSlice";
import { alertsSlice } from "entities/alerts/model/slice/alertsSlice";
import { usersSlice } from "entities/users/model/slice/usersSlice";
import { productCategoriesSlice } from "entities/productCategories/model/slice/productCategoriesSlice";
import { productsReducer } from "entities/products/model/slice/productsSlice";
import { draftItemsSlice } from "entities/draftItems/model/slice/draftItemsSlice";
import { programReducer } from "entities/programs/model/slice/programsSlice";
import { authSlice } from "entities/auth/model/slice/authSlice";
import { requestsSlice } from "entities/requests/model/slice/requestsSlice";
import { modulesReducer } from "entities/modules";
import { eventsReducer } from "entities/events";
import { templatesReducer } from "entities/templates";
import { applicationsReducer } from "entities/applications";
import { widgetsReducer } from "entities/widgets";
import { platformNotificationsSlice } from "entities/platformNotifications";
import { filesReducer } from "entities/files";
import { kpisReducer } from "entities/kpis";
import { analyticsReducer } from "entities/analytics";

export const reducer = {
  auth: authSlice.reducer,
  users: usersSlice.reducer,
  productCategories: productCategoriesSlice.reducer,
  requests: requestsSlice.reducer,
  kpiItems: kpisReducer,
  products: productsReducer,
  programs: programReducer,
  alerts: alertsSlice.reducer,
  draftItems: draftItemsSlice.reducer,
  modals: modalsSlice.reducer,
  moduleTemplates: moduleTemplatesSlice.reducer,
  modules: modulesReducer,
  events: eventsReducer,
  template: templatesReducer,
  applications: applicationsReducer,
  widgets: widgetsReducer,
  platformNotifications: platformNotificationsSlice.reducer,
  files: filesReducer,
  analytics: analyticsReducer,
};
