import React, { useEffect, useState, createContext, useMemo } from "react";
import { Outlet, useLocation, useMatch, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Tabs from "components/Tabs";
import { PageTitle } from "shared/ui/pageTitle";
import { ProductResponse } from "shared/api/products/types";
import { CategoryData } from "shared/api/productCategories/types";
import { TabNames } from "../types";

import { productCategoriesActions } from "entities/productCategories";
import { productsActions } from "entities/products";
import { useAppSelector } from "redux/store/hooks/hooks";

import { getCategoriesList } from "entities/productCategories/model/selectors";
import { getProductsList } from "entities/products/model/selectors";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { BackDiv, StyledTitle } from "./styles";
import { AssociateProductData } from "features/associatedProducts/ui/types";
import { RoutesList } from "shared/routes";

export const AssociatedProductsContext: any = createContext(null);

const tabList = [
  {
    label: TabNames.DISTRIBUTION,
  },
  {
    label: TabNames.WIDGET,
  },
];

const backLinks: Record<string, { to: string; title: string }> = {
  "customer-engagement/widget/:id": {
    to: "/customer-engagement/widget",
    title: "Back to Embedded Hub",
  },
  "customer-engagement/distribution/:id": {
    to: "/customer-engagement/distribution",
    title: "Back to Embedded Hub",
  },
};

const EmbeddedHub = () => {
  const products = useAppSelector(getProductsList);
  const productCategories: CategoryData[] = useAppSelector(getCategoriesList);

  const [associateProducts, setAssociateProducts] = useState<
    AssociateProductData[]
  >([]);
  const [activeTab, setActiveTab] = useState(TabNames.DISTRIBUTION);
  const [viewAllProducts, setViewAllProducts] = useState(false);
  const location = useLocation();
  const match = useMatch("customer-engagement/:id/associated-products");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEngagementWidgetMatch = useMatch("customer-engagement/widget/:id");
  const isEngagementDistributionMatch = useMatch(
    "customer-engagement/distribution/:id"
  );
  const matching = isEngagementWidgetMatch || isEngagementDistributionMatch;
  useEffect(() => {
    if (location.pathname == RoutesList.CUSTOMER_ENGAGEMENT) {
      setActiveTab(TabNames.DISTRIBUTION);
      navigate("distribution");
    }
    if (location.pathname == "/customer-engagement/widget") {
      setActiveTab(TabNames.WIDGET);
    }
    if (location.pathname == "/customer-engagement/distribution") {
      setActiveTab(TabNames.DISTRIBUTION);
    }
  }, [location]);

  useEffect(() => {
    if (!productCategories.length) {
      dispatch({ type: productCategoriesActions.GET_PRODUCT_CATEGORIES });
    }
    if (!products.length) {
      dispatch({
        type: productsActions.GET_PRODUCTS,
        data: {
          params: {
            ShowArchived: false,
            limit: 10,
            offset: 0,
            states: ["Draft"],
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      associateProducts.length === 0 &&
      productCategories.length &&
      products.length
    ) {
      const result: AssociateProductData[] = [];
      products.map((product: ProductResponse) => {
        const data = {
          id: product.id,
          productName: product.name,
          productStatus: product.state,
          categoryName: getCategoryName(product.categoryId),
          createdDateUtc: product.createdDateUtc,
          updatedDateUtc: product.updatedDateUtc,
          version: product.version,
        };
        result.push(data);
      });
      if (result.length) setAssociateProducts(result);
    }
  }, [productCategories, products, associateProducts]);

  const getCategoryName = (id: string) => {
    const category = productCategories.find(
      (category: CategoryData) => category.id === id
    );
    if (category) return category.categoryName;
    else return "Unknown";
  };

  const viewProducts = (id: string) => {
    setViewAllProducts(!viewAllProducts);

    // TODO: once API connected, adjust associateProducts on view by ID
    console.log("list all associated products for ID", id);
  };

  const listProduct = true;

  const productsValue = useMemo(
    () => ({
      associateProducts,
      viewProducts,
      listProduct,
    }),
    [associateProducts, listProduct, viewProducts]
  );

  return (
    <AssociatedProductsContext.Provider value={productsValue}>
      {viewAllProducts && (
        <BackDiv onClick={() => setViewAllProducts(!viewAllProducts)}>
          <ArrowBackIosIcon />
          <StyledTitle>Back to Embedded Hub</StyledTitle>
        </BackDiv>
      )}
      <PageTitle
        title={match ? "Associated Products" : "Embedded Hub"}
        backLink={matching && backLinks[matching?.pattern.path]}
      />
      {!match ? (
        <Tabs
          setActiveTab={setActiveTab}
          tabList={tabList}
          activeTab={activeTab}
          basePath={RoutesList.CUSTOMER_ENGAGEMENT}
        >
          <Outlet />
        </Tabs>
      ) : (
        <Outlet />
      )}
    </AssociatedProductsContext.Provider>
  );
};

export default EmbeddedHub;
