import { call, put, select, takeLatest } from "redux-saga/effects";
import { AxiosError, AxiosResponse } from "axios";

import { getProductModules } from "api/IXPortalApi";
import { AxiosPromiseResponse, ErrorFromBackend } from "api/types";

import { productsActions } from "../actions";
import { productActions } from "../slice/productsSlice";
import { alertActions } from "entities/alerts";

import { ReduxAction } from "redux/sagas/types";

import historyBr from "../../../../history-br";
import { clearData, setModuleOrderQueue, setModules } from "entities/modules";
import { getProgram, programsActions } from "entities/programs";
import {
  CreateProductResponse,
  CreateVersionResponse,
  ProductResponse,
  ProductsResponse,
  ProductVersionsResponse,
} from "shared/api/products/types";
import ProductsApi from "shared/api/products/productsApi";
import { CreateProgramResponse } from "shared/api/programs/types";

import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

export function* getProductsWorker(action: ReduxAction) {
  try {
    yield put(productActions.loadProducts({ loading: true }));
    const response: AxiosResponse<ProductsResponse> = yield call(
      ProductsApi.getProducts,
      action.data
    );
    yield put(productActions.getProductsSuccess(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getProductsByCategoryIdWorker(action: ReduxAction) {
  try {
    yield put(productActions.loadProducts({ loading: true }));
    const response: AxiosResponse<any> = yield call(
      ProductsApi.getProducts,
      action.data
    );
    yield put(productActions.loadProductByCategoryId(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getProductsByModuleTemplateIdWorker(action: ReduxAction) {
  try {
    yield put(productActions.setLoading(true));
    const response: AxiosResponse<any> = yield call(
      ProductsApi.getProducts,
      action.data
    );
    if (response.data) {
      yield put(productActions.setLoading(false));
      yield put(productActions.loadProductByModuleTemplateId(response.data));
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* createProductWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<CreateProductResponse> = yield call(
      ProductsApi.createProduct,
      action.data
    );
    yield put({
      type: productsActions.GET_PRODUCT,
      data: response.data,
    });
    yield put(clearData());
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getProductWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<ProductResponse> = yield call(
      ProductsApi.getProduct,
      action.data
    );
    yield put(productActions.loadProduct(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* deleteProductWorker(action: ReduxAction) {
  try {
    yield call(ProductsApi.deleteProduct, action.data);
    yield put({
      type: productsActions.GET_PRODUCTS,
      data: {
        params: {
          ShowArchived: false,
          limit: 10,
          offset: 0,
          states: ["Draft", "Published"],
        },
      },
    });
    if (window.location.pathname !== "/products/list") {
      yield call(historyBr.back as any, "products/list");
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* undeleteProductWorker(action: ReduxAction) {
  try {
    yield call(ProductsApi.undeleteProduct, action.data);
    yield put({
      type: alertActions.SHOW_ALERT,
      data: {
        type: "productRestored",
        data: { name: action.data.name },
      },
    });
    yield put({
      type: productsActions.GET_PRODUCTS,
      data: {
        params: {
          ShowArchived: true,
          limit: 10,
          offset: 0,
          states: ["Deleted"],
        },
      },
    });
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* publishProductWorker(action: ReduxAction) {
  try {
    yield put(productActions.setPublishLoader(true));
    yield call(ProductsApi.publishProduct, action.data);

    const program: CreateProgramResponse = yield select(getProgram);
    if (program.id) {
      yield put({
        type: programsActions.LINK_PRODUCTS_TO_PROGRAM,
        data: {
          id: program.id,
          data: {
            productIds: [action.data.id],
          },
        },
      });
    } else {
      yield call(historyBr.push as any, `/products/list/${action.data.id}`);
    }
    yield put(productActions.loadProduct({}));
  } catch (e) {
    sagaErrorBoundary(e);
  } finally {
    yield put(productActions.setPublishLoader(false));
  }
}

export function* editProductWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<CreateProductResponse> = yield call(
      ProductsApi.editProduct,
      action.data
    );
    yield put({
      type: productsActions.GET_PRODUCT,
      data: response.data,
    });
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getProductVersionsWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<ProductVersionsResponse> = yield call(
      ProductsApi.getProductVersions,
      action.data
    );
    yield put(productActions.loadProductVersions(response.data));
    const version =
      response.data.versions[response.data.versions.length - 1].version;
    yield put({
      type: productsActions.GET_PRODUCT,
      data: {
        ...action.data,
        version,
      },
    });
    yield put({
      type: productsActions.GET_PRODUCT_MODULES,
      data: {
        productId: action.data.id,
        version,
      },
    });
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* cloneProductWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<CreateProductResponse> = yield call(
      ProductsApi.cloneProduct,
      action.data
    );
    yield put({
      type: productsActions.GET_PRODUCT,
      data: response.data,
    });
    yield call(
      historyBr.push as any,
      `/create_new_product/${response.data.id}`
    );
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* createNewProductVersionWorker(action: ReduxAction) {
  try {
    yield put(productActions.setPublishLoader(true));
    const response: AxiosResponse<CreateVersionResponse> = yield call(
      ProductsApi.createNewProductVersion,
      action.data
    );
    yield call(
      historyBr.push as any,
      `/create_new_product/${response.data.id}`
    );
    yield put({
      type: productsActions.GET_PRODUCT,
      data: {
        id: response.data.id,
        version: response.data.version,
      },
    });
  } catch (e) {
    const error = e as AxiosError<ErrorFromBackend>;
    const errorStatus = error.response?.data.status;
    if (errorStatus === 409) {
      yield put({
        type: productsActions.GET_PRODUCT,
        data: { ...action.data, version: action.data.version + 1 },
      });
      yield call(
        historyBr.push as any,
        `/create_new_product/${action.data.id}`
      );
    }
  } finally {
    yield put(productActions.setPublishLoader(false));
  }
}

export function* getProductModulesWorker(action: ReduxAction) {
  let requestError: any;
  const response: AxiosPromiseResponse = yield call(
    async () =>
      await getProductModules(action.data)
        .then((response) => response)
        .catch((error: any) => {
          requestError = error;
        })
  );
  if (response) {
    yield put(productActions.getModules(response.data));
    yield put(setModules(response.data));
    yield put(setModuleOrderQueue(response.data));
  } else if (requestError) {
    yield put({
      type: productsActions.GET_PRODUCT_MODULES_FAILURE,
      data: requestError.response.data,
    });
  }
}

export function* addAttributesToProductWorker(action: any) {
  try {
    const response: AxiosResponse<CreateProductResponse> = yield call(
      ProductsApi.addAttributesToProduct,
      action.data
    );
    yield put({
      type: alertActions.SHOW_ALERT,
      data: {
        type: action.alertType,
      },
    });
    yield put({
      type: productsActions.GET_PRODUCT,
      data: response.data,
    });
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* updateProductAttributeWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<CreateProductResponse> = yield call(
      ProductsApi.updateProductAttribute,
      action.data
    );
    yield put({
      type: productsActions.GET_PRODUCT,
      data: response.data,
    });
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* ProductsWatcher() {
  yield takeLatest(productsActions.GET_PRODUCTS, getProductsWorker);
  yield takeLatest(
    productsActions.GET_PRODUCTS_BY_MODULE_TEMPLATE,
    getProductsByModuleTemplateIdWorker
  );
  yield takeLatest(
    productsActions.GET_PRODUCTS_BY_CATEGORY,
    getProductsByCategoryIdWorker
  );
  yield takeLatest(productsActions.CREATE_PRODUCT, createProductWorker);
  yield takeLatest(productsActions.GET_PRODUCT, getProductWorker);
  yield takeLatest(productsActions.DELETE_PRODUCT, deleteProductWorker);
  yield takeLatest(productsActions.RESTORE_PRODUCT, undeleteProductWorker);
  yield takeLatest(productsActions.PUBLISH_PRODUCT, publishProductWorker);
  yield takeLatest(productsActions.EDIT_PRODUCT, editProductWorker);
  yield takeLatest(
    productsActions.GET_PRODUCT_VERSIONS,
    getProductVersionsWorker
  );
  yield takeLatest(productsActions.CLONE_PRODUCT, cloneProductWorker);
  yield takeLatest(
    productsActions.UPDATE_PRODUCT_ATTRIBUTE,
    updateProductAttributeWorker
  );
  yield takeLatest(
    productsActions.ADD_ATTRIBUTES_TO_PRODUCT,
    addAttributesToProductWorker
  );
  yield takeLatest(
    productsActions.GET_PRODUCT_MODULES,
    getProductModulesWorker
  );
  yield takeLatest(
    productsActions.CREATE_NEW_PRODUCT_VERSION,
    createNewProductVersionWorker
  );
}
