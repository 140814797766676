import React from "react";

import { ResponsivePie } from "@nivo/pie";
import { Grid, CircularProgress, Box } from "@mui/material";
import { Percentages } from "./styles";
import {
  AnalyticBlock,
  Title,
  Paragraph,
  useAnalytics,
} from "features/analytics";

interface QuoteProps {
  ranges?: string;
}

export const Quote = ({ ranges }: QuoteProps) => {
  const { stepDetails, loading } = useAnalytics();

  const quote_arr = stepDetails.filter((item) => item.outcomeType === "Quote");

  const initialValue = 0;
  const total = stepDetails.reduce(
    (accumulator, currentValue) => accumulator + currentValue.executionCount,
    initialValue
  );
  const quote_total = quote_arr.reduce(
    (accumulator, currentValue) => accumulator + currentValue.executionCount,
    initialValue
  );

  const calculatePercentages = (value: number) => {
    return (value / (total / 100)).toFixed(0);
  };

  const data = [
    {
      id: "other",
      label: "other",
      value: total - quote_total,
      color: "Hsl	hsl(239, 78%, 87%)",
    },
    {
      id: "quote",
      label: "quote",
      value: quote_total,
      color: "hsl(240, 35%, 34%)",
    },
  ];
  return (
    <AnalyticBlock>
      <Grid container spacing={3}>
        <Grid item md={8}>
          <Title>Quote</Title>
          <Paragraph>{ranges}</Paragraph>
        </Grid>
        <Grid item md={4} sx={{ textAlign: "right" }}>
          <Title>{quote_total}</Title>
          <Paragraph>Number Secondary</Paragraph>
        </Grid>
        <Grid item md={12} sx={{ height: "400px", position: "relative" }}>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress sx={{ color: "#383874" }} />
            </Box>
          ) : (
            <>
              <Percentages>
                {quote_total ? calculatePercentages(quote_total) : 0}
                <span>%</span>
              </Percentages>
              <ResponsivePie
                data={data}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{ theme: "background" }}
                colors={["#C6C7F8", "#383874"]}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: "ruby",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "c",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "go",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "python",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "scala",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "lisp",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "elixir",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "javascript",
                    },
                    id: "lines",
                  },
                ]}
              />
            </>
          )}
        </Grid>
      </Grid>
    </AnalyticBlock>
  );
};
