import React from "react";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

export const StyledOutlinedInput = (props: any) => {
  const { value, id, placeholder, onClick } = props;
  return (
    <OutlinedInput
      fullWidth
      type="text"
      placeholder={placeholder}
      value={value}
      id={id}
      onChange={() => {
        return;
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton edge="end" onClick={onClick}>
            <CalendarTodayIcon sx={{ color: "var(--secondary)" }} />
          </IconButton>
        </InputAdornment>
      }
      sx={{
        background: "#ffffff",
        "&.Mui-error": {
          background: "var(--lightred)",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid var(--secondary)",
          boxShadow: "0px 0px 10px rgba(98, 102, 234, 0.5)",
        },
        "&.Mui-focused .MuiOutlinedInput-input": {
          border: "none",
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "#383874",
        },
        ".MuiButtonBase-root": {
          marginRight: "4px",
        },
        ".MuiInputBase-input": {
          color: "#383874",
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          letterSpacing: "1px",
        },
      }}
    />
  );
};
