import React, { memo, useEffect, useState } from "react";

import { Table, TableBody, TableCell, TableContainer } from "@mui/material";
import { initialColumns } from "./types";
import {
  useApplicationActions,
  useApplicationsData,
  Kpi,
} from "features/applications";

import { IXTableRow, TableHeader } from "shared/ui/Table";
import { TableSkeleton } from "shared/ui/TableSkeleton";
import TablePlaceholder from "shared/ui/TablePlaceholder";
import SortingDefault, {
  SortRenderProps,
} from "shared/ui/Table/SortingDefault/SortingDefault";
import { OrderTypes } from "shared/config/types/Sorting";
import { SORT } from "shared/config/enums/Sorting";

import { useAppSelector } from "redux/store/hooks/hooks";
import { getProduct } from "entities/products";
import { getApplicationsData } from "entities/applications";
import { KpiPayload } from "shared/api/kpis/types";

interface Props {
  handleKpi?: (payload: KpiPayload) => void;
  entityId: string;
}

export const DistributeTable = memo(function DistributeTable({
  handleKpi,
  entityId,
}: Props) {
  const { id: productId } = useAppSelector(getProduct);
  const data = useAppSelector(getApplicationsData);
  const { isLoading } = useApplicationsData();
  const { getApplications } = useApplicationActions();
  const [order, setOrder] = useState<OrderTypes>({ type: SORT.ASC, label: "" });
  const [activeApplications, setActiveApplications] = useState<any[]>([]);

  useEffect(() => {
    getApplications({
      ...(order.label && { sortPreference: order.label }),
      ...(order.label && { sortKind: order.type }),
    });
  }, [getApplications, order]);

  useEffect(() => {
    const applications: Array<any> = [];
    if (data.length && productId) {
      data.map((item: any) => {
        if (item.productIds.length) {
          if (item.productIds.includes(productId)) {
            applications.push(item);
          }
        }
      });
      setActiveApplications(applications);
    }
  }, [data, productId]);

  return (
    <TableContainer
      sx={{
        height: `calc(100vh - ${"660px"})`,
        position: "relative",
      }}
      data-testid="tableContainer"
    >
      <Table stickyHeader>
        <TableHeader
          hideCheckbox={true}
          columns={initialColumns}
          rowCount={activeApplications.length}
          numSelected={0}
        >
          {(props: SortRenderProps) => (
            <SortingDefault
              setOrder={(data: OrderTypes) => setOrder(data)}
              order={order}
              {...props}
            />
          )}
        </TableHeader>
        <TableBody data-testid="tableBody">
          {isLoading && <TableSkeleton lines={3} columns={initialColumns} />}
          {!isLoading && activeApplications.length === 0 && (
            <TablePlaceholder colSpan={initialColumns.length} />
          )}
          {!isLoading &&
            activeApplications.length > 0 &&
            activeApplications.map((row) => {
              return (
                <IXTableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  data-testid={`tableRow_${row.id}`}
                >
                  <TableCell sx={{ fontWeight: 700 }}>{row.name}</TableCell>
                  <TableCell>
                    <Kpi
                      appId={row.id}
                      productId={entityId}
                      type="distribution"
                      handleKpi={handleKpi}
                    />
                  </TableCell>
                </IXTableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
