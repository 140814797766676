import React, { useEffect, useState } from "react";
import { initialColumns } from "./types";
import { Column } from "../Table/types";
import CreateRequestModal from "../Modals/CreateRequest";
import { AssociatedProductsModal } from "features/associatedProducts";
import { DistributionTable } from "widgets/DistributionTable";
import TablePaper from "shared/ui/TablePaper";

interface DistributionProps {
  listProduct: boolean;
}

const Distribution = ({ listProduct }: DistributionProps) => {
  const [openCreateRequest, setOpenCreateRequest] = useState(false);
  const [openListProductModal, setOpenListProductModal] = useState(false);
  const [columns, setColumns] = useState<Column[]>(initialColumns);

  useEffect(() => {
    if (!listProduct) {
      const filteredColumns = [...columns].filter(
        (col: Column) => col.id !== "products"
      );
      setColumns(filteredColumns);
    }
  }, [listProduct]);

  const openCreateRequestModal = () => {
    setOpenCreateRequest(!openCreateRequest);
  };

  return (
    <TablePaper>
      <DistributionTable
        openCreateRequestModal={openCreateRequestModal}
        type={"edit"}
      />
      <CreateRequestModal
        open={openCreateRequest}
        handleClose={() => setOpenCreateRequest(!openCreateRequest)}
        requestCategory={"Distribution"}
      />
      <AssociatedProductsModal
        open={openListProductModal}
        handleClose={() => setOpenListProductModal(!openListProductModal)}
      />
    </TablePaper>
  );
};

export default Distribution;
