import { Column } from "shared/config/types/Table";

export const initialColumns: Column[] = [
  {
    id: "chip",
    label: "",
    minWidth: 180,
    filtering: false,
  },
  {
    id: "Name",
    label: "Widget Name",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "Distribution",
    label: "Distribution",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "AssociatedProducts",
    label: "associated Products",
    minWidth: 300,
    filtering: false,
  },
  {
    id: "widgetType",
    label: "Widget Type",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "CreatedDateUtc",
    label: "Creation",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "UpdatedDateUtc",
    label: "Update",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 100,
    filtering: false,
    align: "center",
  },
  {
    id: "kpis",
    label: "Add KPI's",
    minWidth: 200,
    filtering: false,
  },
];
export const mockIncludedData: any = [
  {
    id: "ascascasc",
    status: "activated",
    name: "Personal Auto",
    associatedProducts: "Personal Auto",
    distribution: "IBM - www.weather.com",
    createdDateUtc: "08-24-2022",
    updatedDateUtc: "08-24-2022",
  },
];
