import {
  Application,
  ApplicationResponse,
  ApplicationsLinkPayload,
  ApplicationsPayload,
} from "./types";
import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";

export default class ApplicationsApi {
  static async getAll({
    search,
    sortKind,
    sortPreference,
    offset,
    limit,
  }: ApplicationsPayload): Promise<AxiosResponse<ApplicationResponse>> {
    return IXPortalApiPrivate.get("/api/Applications", {
      params: {
        SearchExpression: search,
        "SortingPreferences.Kind": sortKind,
        "SortingPreferences.SortingProperty": sortPreference,
        offset,
        limit,
      },
    });
  }
  static async getNetworkApplications({
    search,
    sortKind,
    sortPreference,
    offset,
    limit,
  }: ApplicationsPayload): Promise<AxiosResponse<ApplicationResponse>> {
    return IXPortalApiPrivate.get("/api/Applications", {
      params: {
        SearchExpression: search,
        "SortingPreferences.Kind": sortKind,
        "SortingPreferences.SortingProperty": sortPreference,
        offset,
        limit,
        DistributionType: "Network",
      },
    });
  }
  static async getDirectApplications({
    search,
    sortKind,
    sortPreference,
    offset,
    limit,
  }: ApplicationsPayload): Promise<AxiosResponse<ApplicationResponse>> {
    return IXPortalApiPrivate.get("/api/Applications", {
      params: {
        SearchExpression: search,
        "SortingPreferences.Kind": sortKind,
        "SortingPreferences.SortingProperty": sortPreference,
        offset,
        limit,
        DistributionType: "Direct",
      },
    });
  }

  static async linkToProduct({
    id,
    productId,
  }: ApplicationsLinkPayload): Promise<AxiosResponse<Application>> {
    return IXPortalApiPrivate.post(
      `/api/Applications/${id}/products/${productId}`
    );
  }

  static async unlinkToProduct({
    id,
    productId,
  }: ApplicationsLinkPayload): Promise<AxiosResponse<Application>> {
    return IXPortalApiPrivate.delete(
      `/api/Applications/${id}/products/${productId}`
    );
  }

  static async getApplicationById(
    id: string
  ): Promise<AxiosResponse<Application>> {
    return IXPortalApiPrivate.get(`/api/Applications/${id}`);
  }

  /**
   * Gets list of applications for current tenant by applications ids
   */
  static async getApplicationsByIds(
    ids: string
  ): Promise<AxiosResponse<ApplicationResponse>> {
    return IXPortalApiPrivate.get(`/api/Applications?${ids}`, {});
  }
}
