import { createSelector } from "@reduxjs/toolkit";
import { getApplications } from "./getApplications";

export const getNetworkData = createSelector(getApplications, (application) => {
  return application.networkData;
});

export const getNetworkLoading = createSelector(
  getApplications,
  (application) => {
    return application.networkLoading;
  }
);

export const getNetworkTotal = createSelector(
  getApplications,
  (application) => {
    return application.networkTotal;
  }
);

export const getAllApplications = createSelector(
  getApplications,
  (application) => {
    return application.applications;
  }
);

export const productActiveOnApplication = (appId: string, productId: string) =>
  createSelector(getAllApplications, (applications) => {
    const application = applications.filter((item: any) => item.id === appId);
    if (application.length) {
      const app = application[0];
      const productIds = app.productIds;
      return productIds.includes(productId);
    } else return false;
  });

export const getNetworkProductIdsByApp = (appId: string, productId: string) =>
  createSelector(getApplications, (application) => {
    if (application.networkProductIds[appId]) {
      return application.networkProductIds[appId].includes(productId);
    } else {
      return false;
    }
  });
