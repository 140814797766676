import styled from "styled-components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
} from "@mui/material";
import { Link } from "react-router-dom";
interface StyledPanelDivProps {
  rightSidebarPanel: number;
}

export const StyledDrawer = styled(Drawer)`
  div.MuiPaper-root {
    border-right: none;
  }
`;
export const StyledSidebarLogo = styled.img`
  min-width: 60px;
  margin-left: 5px;
  margin-right: 10px;
  max-width: 80px;
`;

export const StyledLogoDiv = styled.div`
  padding-top: 30px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
`;

export const StyledLogoText = styled.div<{ isFooter?: boolean }>`
  color: var(--primary);
  font-weight: 700;
  font-size: ${(props) => (props.isFooter ? "16px" : "1.6rem")};
  span {
    color: var(--secondary);
    font-weight: 400;
  }
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    box-shadow: none;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0;

    .MuiAccordionSummary-content {
      width: 100%;
      display: block;
      margin: 0;
    }
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0;
  }
`;

export const StyledSubLink = styled(Link)<{ isActive: boolean }>`
  text-decoration: none;
  padding: 20px;
  padding-left: 30px;
  font-size: 16px;
  display: flex;
  align-items: center;
  background: ${(props) => (props.isActive ? "#e4e4fa" : "var(--ghost)")};
  color: ${(props) => (props.isActive ? "var(--secondary)" : "var(--primary)")};

  letter-spacing: 2px;
  :last-child {
    border-bottom-right-radius: 15px;
  }
`;

export const StyledLink = styled(Link)<{
  isActive?: boolean;
  isAdmin?: boolean;
  expanded?: string;
}>`
  text-decoration: none;
  padding: 15px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  background: ${(props) => (props.isActive ? "var(--ghost)" : "transparent")};
  color: var(--primary);
  border-bottom-right-radius: ${(props) => (props.expanded ? "0" : "15px")};
  letter-spacing: 2px;
  ${(props) => (props.isAdmin ? "margin-bottom: 100px;" : "")}
  :hover {
    background: var(--ghost);
    border-bottom-right-radius: ${(props) =>
      props.expanded && props.isActive ? "0" : "15px"};
  }
  :active {
    color: var(--primary);
  }
  p {
    margin: 0;
    margin-left: 10px;
  }
`;
export const StyledAdminLink = styled.a<{
  isAdmin?: boolean;
}>`
  text-decoration: none;
  padding: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: var(--primary);
  border-bottom-right-radius: 15px;
  letter-spacing: 2px;
  cursor: pointer;
  ${(props) => (props.isAdmin ? "margin-bottom: 100px;" : "")}
  :hover {
    background: var(--ghost);
  }
  :active {
    color: var(--primary);
  }
  p {
    margin: 0;
    margin-left: 10px;
  }
`;

export const StyledFooterDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: absolute;
  bottom: 40px;
  padding-left: 20px;
  img {
    width: 25px;
    margin: 0 10px;
  }
`;
export const StyledFooterLogoWrap = styled.div`
  display: flex;
  img {
    margin-bottom: 20px;
  }
`;

export const StyledPoliciesWrap = styled.div`
  display: flex;
  color: var(--grey);
  align-items: center;
  height: 20px;
  white-space: nowrap;
  div {
    height: 16px;
    width: 2px;
    background-color: var(--grey);
    margin: 0 25px;
  }
`;

export const StyledPanelDiv = styled.div<StyledPanelDivProps>`
  display: flex;
  width: ${(props) => props.rightSidebarPanel + "px"};
  align-items: center;
  background: var(--white);
  height: 100vh;
  position: absolute;
  right: 0;
`;

export const StyledToggleDiv = styled.div<{ expanded: boolean }>`
  background: var(--ghost);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  ::before,
  ::after {
    content: "";
    position: absolute;
    right: 0;
    border-style: solid;
    border-width: 15px 20px 15px 0;
    bottom: -10px;
    border-color: transparent var(--ghost) transparent transparent;
  }
  ::before {
    top: -10px;
  }
  ::after {
    bottom: -10px;
  }
  }
`;
export const StyledToggleImg = styled.img<{ expanded: boolean }>`
  transition: 0.5s;
  z-index: 99;
  transform: ${(props) =>
    props.expanded ? "rotateZ(0deg)" : "rotateZ(180deg)"};
`;
