import { SORT } from "shared/config/enums/Sorting";
export const searchByValue = (
  items: Array<any>,
  key: string,
  search: string
) => {
  const searchString = search.toLowerCase();
  const outputItems = items.filter(
    (item: any) => item[key].toLowerCase().indexOf(searchString) !== -1
  );
  return outputItems;
};

export const filterByKeys = (
  items: Array<any>,
  filter: string,
  keys: string[]
) => {
  const sortKeys = [...keys];
  if (sortKeys.length == 0) return items;

  const filteredResult = [...items].filter((item: any) => {
    return sortKeys.includes(item[filter]);
  });
  return filteredResult;
};

export const sortByOrder = (items: any, filters: any) => {
  const sortKey = filters.name;
  const option = filters.sortKey;
  if (!sortKey && !option) return items;

  const sortedRows = items.toSorted((a: any, b: any) => {
    const itemA = String(a[sortKey]);
    const itemB = String(b[sortKey]);

    return itemA.toLowerCase() > itemB.toLowerCase() ? 1 : -1;
  });

  switch (option) {
    case SORT.ASC:
      return sortedRows;
    case SORT.DESC:
      return sortedRows.reverse();
    default:
      return sortedRows;
  }
};
