import { AxiosRequestConfig, AxiosResponseHeaders } from "axios";
import { FormattedAttributePayload } from "shared/api/products/types";

export interface RuleParameter {
  moduleId: string;
  ruleId: string;
}

export interface Rule {
  id: string;
  expression: string;
  name: string;
  link: string;
  message: string;
  onFailureExpression: string | null;
  onSuccessExpression: string | null;
  outputType: string;
  parameters: RuleParameter[];
  type: "Calculation" | "Validation" | "ConditionalCalculation";
}

export interface ModuleTemplate {
  id: string;
  name: string;
  tenantId: string;
  categoryId: string;
  version: string;
  marginPrice: number;
  actualPrice: number;
  type: string;
  moduleConfiguration: {
    name: string;
    description: string;
    moduleType: string;
    connectors: Array<any>;
  };
  provider: string;
  isIncluded: boolean;
  isDeleted: boolean;
}

export interface ModuleTemplatesResponse {
  moduleTemplates: ModuleTemplate[];
  totalCount: number;
}

export interface ModulesResponse {
  modules: Module[];
}

export interface Module {
  id: string;
  description: string;
  moduleType: string;
  name: string;
  responseAttributes: FormattedAttributePayload[];
  rules: Rule[];
  moduleTemplateId: string;
}

export interface PaginationParams {
  totalCount?: number;
  page?: number;
  limit: number;
  offset: number;
  SearchExpression?: string;
  filter?: string;
}

export interface AxiosPromiseResponse {
  config: AxiosRequestConfig;
  data: any;
  headers: AxiosResponseHeaders;
  request: any;
}

export interface SuccessResponse {
  id: string;
}

export enum RequestHeadersKeys {
  IXPORTAL_TENANT = "IXP-Tenant",
  IXPORTAL_TENANT_HOSTNAME = "ixportal-tenant-hostname",
  ACCEPT_LANGUAGE = "accept-language",
}
export interface ErrorFromBackend {
  errors: {
    Validations: string[];
  };
  status: number;
}
