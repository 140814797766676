import { takeLatest, put, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { ReduxAction } from "redux/sagas/types";
import RequestsApi from "shared/api/requests/requestApi";

import { SuccessResponse } from "api/types";

import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

import { requestActions } from "../actions";
import {
  getArchivedSlice,
  getCompletedSlice,
  getRequestsSlice,
  getRequestSlice,
  addRequestComment,
  loadRequestComments,
  loadRequestTypes,
  loadNewCountSlice,
  loadCompletedCountSlice,
  loadNewPendingCountSlice,
  increaseNewPendingCountSlice,
  decreaseNewPendingCountSlice,
  setRequestToDeleteSlice,
} from "../slice/requestsSlice";
import { CountSchema, RequestSchema } from "shared/api/requests/types";
import { alertActions } from "entities/alerts";

export function* createRequestWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<RequestSchema> = yield call(
      RequestsApi.createRequest,
      action.data
    );
    if (response.data) {
      yield put({
        type: alertActions.SHOW_ALERT,
        data: {
          type: "requestSent",
          severity: "success",
          data: { name: `IX-${response.data.id}` },
        },
      });
      if (action.callback) action.callback(response.data);
      yield put(increaseNewPendingCountSlice(1));
    }
  } catch (e) {
    yield put({ type: requestActions.CREATE_REQUEST_FAILED });
    sagaErrorBoundary(e);
  }
}

export function* countNewRequestsWorker() {
  try {
    const response: AxiosResponse<CountSchema> = yield call(
      RequestsApi.countNew
    );
    yield put(loadNewPendingCountSlice(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* countActiveRequestsWorker() {
  try {
    const response: AxiosResponse<CountSchema> = yield call(
      RequestsApi.countActive
    );
    yield put(loadNewCountSlice(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* countCompletedRequestsWorker() {
  try {
    const response: AxiosResponse<CountSchema> = yield call(
      RequestsApi.countCompleted
    );
    yield put(loadCompletedCountSlice(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getRequestsWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<RequestSchema> = yield call(
      RequestsApi.getRequests,
      action.data
    );
    yield put(getRequestsSlice(response.data));
  } catch (e) {
    yield put({ type: requestActions.GET_REQUESTS_FAILED });
    sagaErrorBoundary(e);
  }
}

export function* getCompletedRequestsWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<RequestSchema> = yield call(
      RequestsApi.getRequests,
      action.data
    );
    yield put(getCompletedSlice(response.data));
  } catch (e) {
    yield put({ type: requestActions.GET_REQUESTS_FAILED });
    sagaErrorBoundary(e);
  }
}

export function* getArchivedRequestsWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<RequestSchema> = yield call(
      RequestsApi.getRequests,
      action.data
    );
    yield put(getArchivedSlice(response.data));
  } catch (e) {
    yield put({ type: requestActions.GET_REQUESTS_FAILED });
    sagaErrorBoundary(e);
  }
}

export function* deleteRequestWorker(action: ReduxAction) {
  try {
    const request = action.data;
    const response: AxiosResponse<SuccessResponse> = yield call(
      RequestsApi.deleteRequest,
      request.id
    );
    if (response.data) {
      if (request.status !== "Completed") {
        yield put(decreaseNewPendingCountSlice(1));
      }
      yield put(setRequestToDeleteSlice(request));
      yield put({
        type: alertActions.SHOW_ALERT,
        data: {
          type: "requestDeleted",
          severity: "success",
          data: { name: `IX-${response.data.id}` },
        },
      });
    }
  } catch (e) {
    yield put({ type: requestActions.DELETE_REQUEST_FAILED });
    sagaErrorBoundary(e);
  }
}

export function* restoreRequestWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<SuccessResponse> = yield call(
      RequestsApi.undeleteRequest,
      action.data
    );
    if (response.data) {
      yield put({
        type: alertActions.SHOW_ALERT,
        data: {
          type: "requestRestored",
          severity: "success",
          data: { name: `IX-${response.data.id}` },
        },
      });
      if (action.callback) action.callback(response.data);
      yield put(increaseNewPendingCountSlice(1));
    }
  } catch (e) {
    yield put({ type: requestActions.RESTORE_REQUEST_FAILED });
    sagaErrorBoundary(e);
  }
}

export function* getRequestWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<RequestSchema> = yield call(
      RequestsApi.getRequest,
      action.data
    );
    yield put(getRequestSlice(response.data));
  } catch (e) {
    yield put({ type: requestActions.GET_REQUEST_FAILED });
    sagaErrorBoundary(e);
  }
}

export function* getTypesWorker() {
  try {
    const response: AxiosResponse<RequestSchema> = yield call(
      RequestsApi.getRequestTypes
    );
    yield put(loadRequestTypes(response.data));
  } catch (e) {
    yield put({ type: requestActions.GET_TYPES_FAILED });
    sagaErrorBoundary(e);
  }
}

export function* getCommentsWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<RequestSchema> = yield call(
      RequestsApi.getRequestComments,
      action.data
    );
    yield put(loadRequestComments(response.data));
  } catch (e) {
    yield put({ type: requestActions.GET_COMMENTS_FAILED });
    sagaErrorBoundary(e);
  }
}

export function* addRequestCommentWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<SuccessResponse> = yield call(
      RequestsApi.attachRequestComments,
      action.data
    );
    const newComment = {
      id: response.data.id,
      createdDateUtc: new Date(),
      ...action.data.data,
    };
    action.callback(response.data);
    yield put(addRequestComment(newComment));
  } catch (e) {
    yield put({ type: requestActions.ADD_COMMENT_FAILED });
    sagaErrorBoundary(e);
  }
}

export function* RequestsWatcher() {
  yield takeLatest(requestActions.CREATE_REQUEST, createRequestWorker);
  yield takeLatest(requestActions.GET_REQUESTS, getRequestsWorker);
  yield takeLatest(requestActions.GET_REQUEST, getRequestWorker);
  yield takeLatest(requestActions.ADD_COMMENT, addRequestCommentWorker);
  yield takeLatest(requestActions.GET_COMMENTS, getCommentsWorker);
  yield takeLatest(requestActions.GET_TYPES, getTypesWorker);
  yield takeLatest(requestActions.DELETE_REQUEST, deleteRequestWorker);
  yield takeLatest(requestActions.RESTORE_REQUEST, restoreRequestWorker);
  yield takeLatest(requestActions.COUNT_NEW_REQUESTS, countNewRequestsWorker);
  yield takeLatest(requestActions.GET_COMPLETED, getCompletedRequestsWorker);
  yield takeLatest(requestActions.GET_ARCHIVED, getArchivedRequestsWorker);
  yield takeLatest(
    requestActions.COUNT_COMPLETED_REQUESTS,
    countCompletedRequestsWorker
  );
  yield takeLatest(
    requestActions.COUNT_ACTIVE_REQUESTS,
    countActiveRequestsWorker
  );
}
