import React from "react";

import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Wrapper,
  Title,
  Header,
  Footer,
  Content,
  StyledButton,
  ScrollWrapper,
} from "./styles";

interface StyledModalProps {
  handleSubmit: () => void;
  handleClose: () => void;
  id: string;
  title: string;
  submitButtonText: string;
  children: React.ReactNode;
}

const Modal: React.FC<StyledModalProps> = ({
  handleSubmit,
  handleClose,
  id,
  title,
  children,
  submitButtonText,
}) => {
  return (
    <Wrapper id={id}>
      <Header>
        <Title>{title}</Title>
        <IconButton onClick={handleClose} sx={{ color: "#000000" }}>
          <CloseIcon />
        </IconButton>
      </Header>
      <ScrollWrapper>
        <Content>{children}</Content>
      </ScrollWrapper>
      <Footer>
        <StyledButton
          variant="outlined"
          onClick={handleClose}
          sx={{
            marginRight: "24px",
          }}
        >
          Cancel
        </StyledButton>
        <StyledButton variant="contained" onClick={handleSubmit}>
          {submitButtonText}
        </StyledButton>
      </Footer>
    </Wrapper>
  );
};

export default Modal;
