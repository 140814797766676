export enum RoutesList {
  HOME = "/",
  CUSTOMER_ENGAGEMENT = "/customer-engagement",
  NOTIFICATIONS = "/notifications",
  ANALYTICS = "/analytics",
  REQUESTS = "/requests",
  INTEGRATIONS = "/integrations",
  EVENTS_AND_TRIGGERS = "/events-and-triggers",
  LIST = "/list",
  KPIS = "/kpis",
  PROGRAMS_LIST = "/programs/list",
  PRODUCTS_LIST = "/products/list",
  USER_MANAGEMENT_USERS = "/user-management/users",

  ARCHIVE = "archive",
  WIDGET = "widget",
  NEW = "new",
  COMPLETED = "completed",
  WIDGET_VIEW = "widget-view",
}
