import {
  UsersRespose,
  UserRespose,
  InviteUserResponse,
  UserProfile,
  TenantInfo,
} from "./types";
import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";

export default class UsersApi {
  static async getUsers(params: any): Promise<AxiosResponse<UsersRespose>> {
    return IXPortalApiPrivate.get("/api/Users", params);
  }

  static async deleteUser(params: any): Promise<AxiosResponse<UserRespose>> {
    return IXPortalApiPrivate.delete(`/api/Users/${params.id}`);
  }

  static async inviteUser(
    params: any
  ): Promise<AxiosResponse<InviteUserResponse>> {
    return IXPortalApiPrivate.post("/api/Users/invite", params);
  }

  static async getUserProfile(): Promise<AxiosResponse<UserProfile>> {
    return IXPortalApiPrivate.get("/api/Users/profile");
  }

  static async getTenantInfo(): Promise<AxiosResponse<TenantInfo>> {
    return IXPortalApiPrivate.get("/api/Tenants/info");
  }
}
