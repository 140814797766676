import React from "react";
import { InfoLabel, InfoRow, InfoValues } from "./styles";
interface Props {
  label: string;
  value?: string | number;
}
export const DetailsInfo = ({ label, value }: Props) => {
  return (
    <InfoRow>
      <InfoLabel>{label}:</InfoLabel>
      {value && <InfoValues>{value}</InfoValues>}
    </InfoRow>
  );
};
