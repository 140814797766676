import React, { useEffect, useState } from "react";

import { Paper, Table, TableBody, TableContainer } from "@mui/material";

import TableRow from "./TableRowComponent";
import TableRowCollapsed from "./TableRowCollapsed";
import TableHeadComponent from "./TableHeadComponent";

import { AttributesPayload } from "shared/api/products/types";
import { TableComponentProps } from "./types";
import { getAllSelectedAttributes } from "../Steps/DataElements/helpers";

const TableComponent = ({
  rows,
  columns,
  selected,
  setSelected,
  small,
  openning,
  onEdit,
  filterByNames,
}: TableComponentProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filters, setFilters] = useState("");

  useEffect(() => {
    if (filters && filterByNames) {
      filterByNames(filters);
    }
  }, [filters]);

  const filterExistedItem = (newAttributesData: string[], item: string) => {
    return newAttributesData.every((attr) => {
      return item !== attr;
    });
  };

  const handleClick = (attribute: AttributesPayload) => {
    const existedItem = selected.find(
      (item) => item === attribute.parentConcat
    );
    if (attribute.subAttributes.length > 0) {
      const newArray: string[] = [];
      const newAttributesData = getAllSelectedAttributes(attribute, newArray);
      if (!existedItem) {
        setSelected((prevState: string[]) => {
          const set = new Set([...prevState, ...newAttributesData]);
          return Array.from(set.values());
        });
      } else {
        setSelected((prevState) => {
          return prevState.filter((item) => {
            return filterExistedItem(newAttributesData, item);
          });
        });
      }
    } else if (existedItem) {
      setSelected((prevState) =>
        prevState.filter((item) => item !== attribute.parentConcat)
      );
    } else {
      setSelected((prevState) => {
        return attribute.parentConcat
          ? [...prevState, attribute.parentConcat]
          : [...prevState];
      });
    }
  };

  const isSelectedGroup = (item: AttributesPayload) => {
    return selected.some((attr: string) => {
      return item.parentConcat === attr;
    });
  };

  const sortByName = (order: string) => {
    handleFilterClose();
    setFilters(order);
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const createRow = (rows: AttributesPayload[]) => {
    return rows.map((row: AttributesPayload) => {
      return row.subAttributes.length ? (
        <TableRowCollapsed
          key={row.id}
          selected={isSelectedGroup(row)}
          isSelectedGroup={isSelectedGroup}
          row={row}
          parentId={row.id}
          handleClick={handleClick}
          open={!!openning}
          small={small}
          onEdit={onEdit}
          stepIndex={1}
        />
      ) : (
        <TableRow
          key={row.id}
          selected={isSelectedGroup(row)}
          row={row}
          handleClick={handleClick}
          small={small}
          onEdit={onEdit}
          parentId={row.id}
        />
      );
    });
  };
  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        marginBottom: "7px",
      }}
    >
      <Table
        aria-label="separate table"
        sx={{
          borderCollapse: "separate",
          borderSpacing: "0 10px",
        }}
      >
        {!small && (
          <TableHeadComponent
            sortByName={sortByName}
            handleFilterClose={handleFilterClose}
            handleFilterClick={handleFilterClick}
            anchorEl={anchorEl}
            columns={columns}
          />
        )}
        <TableBody>{rows && createRow(rows)}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
