import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TenantInfo, UserProfile, UsersRespose } from "shared/api/users/types";
import { InitialState } from "../types/types";
import { FileDetails } from "shared/api/files/types";

const initialState: InitialState = {
  items: [],
  totalCount: 0,
  profile: <UserProfile>{},
  tenantInfo: null,
  tenantLogo: null,
};
export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    loadUsers: (state, action: PayloadAction<UsersRespose>) => {
      return {
        ...state,
        items: action.payload.items,
        totalCount: action.payload.totalCount,
      };
    },
    loadUserProfile: (state, action) => {
      return {
        ...state,
        profile: action.payload,
      };
    },
    addTenantInfo: (state, action: PayloadAction<TenantInfo>) => {
      return {
        ...state,
        tenantInfo: action.payload,
      };
    },
    setTenantImage: (state, action: PayloadAction<FileDetails | null>) => {
      return {
        ...state,
        tenantLogo: action.payload,
      };
    },
  },
});

export const { loadUsers, loadUserProfile, addTenantInfo, setTenantImage } =
  usersSlice.actions;
