import * as React from "react";

import { StyledDialog } from "./styles";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  id: string;
}

const CenteredModal = ({ open, onClose, children, id }: ModalProps) => {
  return (
    <StyledDialog onClose={onClose} aria-labelledby={`${id}-title`} open={open}>
      {children}
    </StyledDialog>
  );
};

export default CenteredModal;
