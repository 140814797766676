import React from "react";
import { StyledTableRow, StyledTableCell, FilterIcon } from "./styles";
import { IconButton, TableHead, Menu, MenuItem } from "@mui/material";
import { TableHeadComponentProps } from "./types";
import { SORT } from "shared/config/enums/Sorting";

const TableHeadComponent = ({
  sortByName,
  handleFilterClose,
  handleFilterClick,
  anchorEl,
  columns,
}: TableHeadComponentProps) => {
  const renderFilter = () => {
    return (
      <>
        <IconButton
          aria-controls="filter-menu"
          aria-haspopup="true"
          onClick={handleFilterClick}
          aria-label="search by name"
          title="search by name"
        >
          <FilterIcon />
        </IconButton>
        <Menu
          id="filter-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleFilterClose}
        >
          <MenuItem onClick={() => sortByName(SORT.ASC)}>Sort A-Z</MenuItem>
          <MenuItem onClick={() => sortByName(SORT.DESC)}>Sort Z-A</MenuItem>
        </Menu>
      </>
    );
  };
  return (
    <TableHead>
      <StyledTableRow className="transparent header">
        {columns.map((item: any) => {
          return (
            <StyledTableCell
              key={item.id}
              padding={
                item.id === "checkbox" || item.id === "icons"
                  ? "checkbox"
                  : "normal"
              }
              className={item.align ? `grey ${item.align}` : "grey"}
            >
              {item.label} {item.filtering && renderFilter}
            </StyledTableCell>
          );
        })}
      </StyledTableRow>
    </TableHead>
  );
};

export default TableHeadComponent;
