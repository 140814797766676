import React, { FC, useContext } from "react";

import { Paper, Box } from "@mui/material";
import { Content } from "./styles";

import Header from "./Header";
import ProductSetup from "./Steps/ProductSetup";
import DataElements from "./Steps/DataElements";
import ServicesAndRules from "./Steps/OutcomesAndServices";
import Distribution from "./Steps/Distribution";
import Confirmation from "./Steps/Confirmation";
import EventsAndTriggers from "./Steps/EventsAndTriggers";

import { CreateProductContext } from "pages/CreateProduct";

const steps: Record<number, FC> = {
  1: ProductSetup,
  2: DataElements,
  3: Integrations,
  4: ServicesAndRules,
  5: EventsAndTriggers,
  6: Distribution,
  7: Engagement,
  8: Confirmation,
};
import Integrations from "./Steps/Integrations";
import Engagement from "./Steps/Engagement";

const CreateNewProduct: React.FC<any> = () => {
  const { step }: any = useContext(CreateProductContext);
  const StepComponent = steps[step as number];
  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        padding: "0",
        boxShadow: "none",
        background: "#f2f2fc",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
        data-testid="createProductPage"
      >
        <Header />
        <Content>
          <StepComponent />
        </Content>
      </Box>
    </Paper>
  );
};

export default CreateNewProduct;
