import styled from "styled-components";
import { InputLabel } from "@mui/material";

export const StyledLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: var(--primary);
    margin-bottom: 10px;
    ${(props) => props.disabled && "color: var(--grey);"}
    &.Mui-disabled {
      color: var(--grey);
    }
  }
`;
export const Danger = styled.span`
  color: var(--darkred);
`;
