export const usersActions = {
  GET_USERS: "GET_USERS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
  INVITE_USER: "INVITE_USER",
  INVITE_USER_FAILURE: "INVITE_USER_FAILURE",
  LOAD_USER_PROFILE: "LOAD_USER_PROFILE",
  LOAD_USER_PROFILE_FAILED: "LOAD_USER_PROFILE_FAILED",
};
