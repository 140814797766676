import { useAppDispatch, useAppSelector } from "redux/store/hooks/hooks";
import { useCallback } from "react";
import {
  getTemplatesDefinitionsAction,
  getTemplatesAction,
  getTemplatesData,
  getTemplatesDefinition,
  getTemplatesLoading,
  getNotificationTemplates,
} from "entities/templates";

const useTemplatesDefinition = () => useAppSelector(getTemplatesDefinition);
const useTemplatesLoading = () => useAppSelector(getTemplatesLoading);
const useTemplatesData = () => useAppSelector(getTemplatesData);
const useNotificationTemplates = () => useAppSelector(getNotificationTemplates);

export const useTemplates = () => {
  const dispatch = useAppDispatch();
  const templatesDefinition = useTemplatesDefinition();
  const isLoading = useTemplatesLoading();
  const dataMap = useTemplatesData();
  const notificationTemplates = useNotificationTemplates();

  const getTemplatesDefinitions = useCallback(() => {
    dispatch(getTemplatesDefinitionsAction());
  }, []);

  const getTemplates = useCallback(() => {
    dispatch(getTemplatesAction());
  }, []);

  return {
    isLoading,
    templatesDefinition,
    getTemplatesDefinitions,
    getTemplates,
    dataMap,
    notificationTemplates,
  };
};
