import { Column } from "components/Table/types";

export const initialColumns: Column[] = [
  {
    id: "name",
    label: "Program Name",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "createdDateUtc",
    label: "Creation",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Update",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "actions",
    label: "Actions",
    align: "center",
    minWidth: 200,
    filtering: false,
  },
];
