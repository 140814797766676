import React, { memo } from "react";
import { StyledChip } from "shared/ui/Table/styles";
import { ReactComponent as ActivateIcon } from "assets/icons/add.svg";
import { ReactComponent as DeactivateIcon } from "assets/icons/remove.svg";
import { useAppSelector } from "redux/store/hooks/hooks";
import { Spinner } from "shared/ui/Spinner";
import { ChipWrapper } from "../../applications/ui/styles";
import { useGetWidgetsActions } from "../model/hooks/useWidgets";
import {
  getActiveWidgetsProductIdsById,
  getWidgetsArrayLoading,
  getWidgetsProductIdsById,
} from "entities/widgets";
import { KpiPayload } from "shared/api/kpis/types";
import { getEntityKPI } from "entities/kpis";

interface Props {
  productId: string;
  widgetId: string;
  handleKpi?: (payload: KpiPayload, callback?: any) => void;
}

export const ActivateButtonWidget = memo(function ActivateButtonWidget({
  productId,
  widgetId,
  handleKpi,
}: Props) {
  const { updateWidgetForProducts } = useGetWidgetsActions();
  const loading = useAppSelector(getWidgetsArrayLoading(widgetId));
  const activeWidget = useAppSelector(
    getActiveWidgetsProductIdsById(widgetId, productId)
  );
  const entityKpi = useAppSelector(getEntityKPI(widgetId, productId));

  const products = useAppSelector(getWidgetsProductIdsById(widgetId));

  const handleActivate = () => {
    if (products.includes(productId)) {
      const newProducts = products.filter((item) => item !== productId);
      if (handleKpi && entityKpi) {
        handleKpi(
          {
            entityId: widgetId,
            productId,
            kpiType: "Widget",
            value: null,
          },
          () =>
            updateWidgetForProducts({
              id: widgetId,
              productId,
              productIds: newProducts,
            })
        );
      } else {
        updateWidgetForProducts({
          id: widgetId,
          productId,
          productIds: newProducts,
        });
      }
    } else {
      const newProducts = [...products, productId];
      updateWidgetForProducts({
        id: widgetId,
        productId,
        productIds: newProducts,
      });
    }
  };

  return (
    <ChipWrapper>
      {loading ? <Spinner /> : null}
      <StyledChip
        label={!activeWidget ? "Activate" : "Deactivate"}
        chiptype={"activate"}
        icon={!activeWidget ? <ActivateIcon /> : <DeactivateIcon />}
        onClick={handleActivate}
      />
    </ChipWrapper>
  );
});
