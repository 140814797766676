import React from "react";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import { StyledButton } from "./styles";

interface ExpandAllButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
const ExpandAllButton: React.FC<any> = ({
  onClick,
  ...props
}: ExpandAllButtonProps) => {
  return (
    <StyledButton
      variant="outlined"
      startIcon={<UnfoldMoreIcon />}
      onClick={onClick}
      {...props}
    >
      Expand All
    </StyledButton>
  );
};

export default ExpandAllButton;
