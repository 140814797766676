import React, { memo, useMemo } from "react";
import { Table, TableBody, TableCell, TableContainer } from "@mui/material";
import { IXTableRow, TableHeader } from "shared/ui/Table";
import { initialColumns } from "pages/AssociatedProducts/ui/types";
import SortingDefault, {
  SortRenderProps,
} from "shared/ui/Table/SortingDefault/SortingDefault";
import { OrderTypes } from "shared/config/types/Sorting";
import { TableSkeleton } from "shared/ui/TableSkeleton";
import TablePlaceholder from "shared/ui/TablePlaceholder";
import { format } from "date-fns";
import { DateFormat } from "shared/config/enums/DateFormat";
import {
  ActivateButtonApplication,
  ActivateButtonProduct,
} from "features/associatedProducts";
import { ProductResponse } from "shared/api/products/types";
import { CategoryData } from "shared/api/productCategories/types";

export interface Props {
  data: ProductResponse[];
  setOrder: (data: OrderTypes) => void;
  order: OrderTypes;
  isLoading: boolean;
  categories: {
    items: CategoryData[];
    totalCount: number;
  };
  appId?: string;
  widgetId?: string;
}
export const AssociatedProductsTable = memo(function AssociatedProductsTable({
  data,
  setOrder,
  order,
  categories,
  isLoading,
  widgetId,
  appId,
}: Props) {
  const getCategoryName = useMemo(() => {
    return (id: string) => {
      const item = categories.items.find((item) => item.id === id);
      return item?.categoryName ?? "";
    };
  }, [categories]);
  return (
    <TableContainer
      sx={{
        height: `calc(100vh - ${"660px"})`,
        position: "relative",
      }}
      data-testid="tableContainer"
    >
      <Table stickyHeader>
        <TableHeader
          hideCheckbox={true}
          columns={initialColumns}
          rowCount={data.length}
        >
          {(props: SortRenderProps) => (
            <SortingDefault
              setOrder={(data: OrderTypes) => setOrder(data)}
              order={order}
              {...props}
            />
          )}
        </TableHeader>
        <TableBody data-testid="tableBody">
          {isLoading && <TableSkeleton lines={3} columns={initialColumns} />}
          {!isLoading && data.length === 0 && (
            <TablePlaceholder colSpan={initialColumns.length} />
          )}
          {!isLoading &&
            data.length > 0 &&
            data.map((row) => {
              return (
                <IXTableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  data-testid={`tableRow_${row.id}`}
                >
                  <TableCell sx={{ fontWeight: 700 }}>{row.name}</TableCell>
                  <TableCell>{getCategoryName(row.categoryId)}</TableCell>
                  <TableCell>{row.state}</TableCell>
                  <TableCell>
                    {format(new Date(row.createdDateUtc), DateFormat.DEFAULT)}
                  </TableCell>
                  <TableCell>
                    {format(new Date(row.updatedDateUtc), DateFormat.DEFAULT)}
                  </TableCell>

                  <TableCell sx={{ textAlign: "center" }}>
                    {appId && (
                      <ActivateButtonApplication
                        productId={row.id}
                        appId={appId}
                      />
                    )}
                    {widgetId && (
                      <ActivateButtonProduct
                        productId={row.id}
                        appId={widgetId}
                      />
                    )}
                  </TableCell>
                </IXTableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
