import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, Divider } from "@mui/material";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import ClearIcon from "@mui/icons-material/Clear";

import {
  OptionsWrap,
  FieldWrap,
  NoItemsWrap,
  ButtonsWrap,
  ControlsWrap,
  StyledButton,
  StyledCheckbox,
  Title,
} from "./styles";
import TextMatchHighlight from "components/FilterModal/TextMatchHighlight";

interface SelectsProps {
  rows: any;
  handleClose: () => void;
  onChange?: (searchValue?: any) => void;
  id: string;
}

const SearchMultiSelect: React.FC<SelectsProps> = ({
  rows,
  handleClose,
  onChange,
  id,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectOptions, setSelectOptions] = useState<any[] | undefined>([]);
  const [checked, setChecked] = useState<any>(null);

  useEffect(() => {
    if (searchValue) {
      const filteredOpts = filterOptions(rows);
      setSelectOptions(filteredOpts);
    } else {
      setSelectOptions([]);
    }
  }, [searchValue]);

  const handleCFilter = (value: string) => {
    setChecked(value);
  };

  const filterOptions = (rows: any) => {
    const uniqueOptions: any[] = [];
    rows.map((item: any) => {
      const found = uniqueOptions.find(
        (element: any) => element.productId === item.productId
      );
      if (item.productId && !found) {
        uniqueOptions.push({
          productId: item.productId,
          productName: item.productName,
        });
      }
    });
    return uniqueOptions.filter((option: any) =>
      option.productName?.toLowerCase().startsWith(searchValue?.toLowerCase())
    );
  };

  const handleApply = () => {
    handleClose();
    if (onChange) {
      onChange(checked);
    }
  };

  const handleClear = () => {
    setSearchValue("");
    if (onChange) {
      onChange();
    }
  };

  return (
    <>
      <Title>Search by keywords</Title>
      <FieldWrap onKeyDown={(e) => e.stopPropagation()}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon width={25} height={25} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment onClick={() => setSearchValue("")} position="end">
                <ClearIcon sx={{ cursor: "pointer" }} />
              </InputAdornment>
            ),
          }}
          value={searchValue}
          id={id}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </FieldWrap>
      {searchValue && (
        <OptionsWrap>
          {selectOptions?.length ? (
            selectOptions.map((option) => {
              return (
                <ButtonsWrap
                  key={option.productId}
                  data-testid={`multiSearch_value_${option.productId}`}
                >
                  <StyledButton onClick={() => handleCFilter(option)}>
                    <StyledCheckbox
                      checked={checked?.productId === option.productId}
                    />
                    {TextMatchHighlight(option.productName, searchValue)}
                  </StyledButton>
                </ButtonsWrap>
              );
            })
          ) : (
            <NoItemsWrap data-testid="noItemsFound">No items found</NoItemsWrap>
          )}
        </OptionsWrap>
      )}
      {selectOptions?.length ? (
        <>
          <Divider />
          <ControlsWrap>
            <button
              onClick={handleClear}
              data-testid="multiSearch_clearAllButton"
            >
              Clear All
            </button>
            <button onClick={handleApply} data-testid="multiSearch_applyButton">
              Apply
            </button>
          </ControlsWrap>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SearchMultiSelect;
