export enum Modules {
  APPETITE = "appetite",
  RESULT = "result",
  ELIGIBILITY = "eligibility",
  RATING = "rating",
  PAYMENT = "payment",
  BIND = "bind",
  NOTIFICATION = "notifications",
  FULFILLMENT = "Fulfillment",
  COMPLETION = "completion",
  RULE = "rule",
}

export enum ModuleTypes {
  UNKNOWN = "Unknown",
  RULE = "Rule",
  RESULT = "Result",
  CONNECTOR = "Connector",
  ENRICHMENT = "Enrichment",
  FULFILLMENT = "Fulfillment",
  INTEGRATION = "Integration",
}
