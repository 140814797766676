import React, { memo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import { RootState } from "redux/store";
import { SearchField } from "shared/ui/searchField";
import { StyledTypography } from "./styles";
import Label from "components/FormComponents/Label";
import CustomSelect from "shared/ui/Select";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
import { IXTableRow, TableHeader } from "shared/ui/Table";
import Button from "shared/ui/Button";
import { initialColumns } from "./types";
import { EditKpi } from "features/edit-kpi-form";
import { productsActions } from "entities/products";
import { ProductResponse, ProgramResponse } from "shared/api/programs/types";
import { programsActions } from "entities/programs";
import { useKpis } from "features/kpis/model/hooks/useKpis";
import { useGetWidgets } from "features/widgets";
import SortingDefault, {
  SortRenderProps,
} from "shared/ui/Table/SortingDefault/SortingDefault";
import { OrderTypes } from "shared/config/types/Sorting";
import { SORT } from "shared/config/enums/Sorting";
import TablePaper from "shared/ui/TablePaper";

export const KpisTable = memo(function KpisTable() {
  const programs: ProgramResponse[] = useSelector(
    (state: RootState) => state.programs.programs.items
  );
  const programProducts: ProductResponse[] = useSelector(
    (state: RootState) => state.programs.products
  );
  const [searchValue, setSearchValue] = useState("");
  const [program, setProgram] = useState("");
  const [productId, setProductId] = useState("");
  const [programId, setProgramId] = useState("");
  const [productName, setProductName] = useState("");
  const [products, setProducts] = useState<ProductResponse[]>([]);
  const [programNames, setProgramNames] = useState<string[]>([]);
  const [openEditKpiModal, setOpenEditKpiModal] = useState<boolean>(false);
  const [order, setOrder] = useState<OrderTypes>({ type: SORT.ASC, label: "" });

  const dispatch = useDispatch();
  const { getKpisByProduct, getKpiItem } = useKpis();
  const { getWidgets } = useGetWidgets();

  const [searchQuery] = useDebounce(searchValue, 1000);

  useEffect(() => {
    getWidgets({});
  }, [getWidgets]);

  useEffect(() => {
    if (programNames.length) setProgram(programNames[0]);
  }, [programNames]);

  useEffect(() => {
    if (programs.length) {
      const names = programs.map((e: ProgramResponse) => e.name);
      if (names.length) setProgramNames(names);
    }
  }, [programs]);

  useEffect(() => {
    if (programProducts.length) setProducts(programProducts);
    else setProducts([]);
  }, [programProducts]);

  useEffect(() => {
    if (program) {
      const data = programs.filter((p: ProgramResponse) => p.name === program);
      const id = data[0].id;

      dispatch({
        type: programsActions.GET_PROGRAM_PRODUCTS,
        data: {
          id,
          ...(order.label && {
            params: {
              "SortingPreferences.SortingProperty": "ProductName",
              "SortingPreferences.Kind": order.type,
            },
          }),
        },
      });
      setProgramId(id);
      getProgramKpi(id);
    }
  }, [program, order]);

  useEffect(() => {
    getPrograms();
  }, [searchQuery]);

  const getPrograms = () => {
    dispatch({
      type: programsActions.GET_PROGRAMS,
      data: {
        params: {
          ShowArchived: false,
          limit: 20,
          offset: 0,
          SearchExpression: searchQuery,
        },
      },
    });
  };

  const getProgramKpi = (id: string) => {
    const payload = {
      EntityIds: [id],
      KPIType: "Program",
    };
    getKpiItem(payload);
  };

  const handleEdit = (product: ProductResponse) => {
    // version needs to be a variable
    dispatch({
      type: productsActions.GET_PRODUCT,
      data: {
        id: product.productId,
        version: 1,
      },
    });
    const id = product.productId;
    setProductId(id);
    getKpisByProduct(id);
    setProductName(product.productName);
    setOpenEditKpiModal(!openEditKpiModal);
    getProgramKpi(programId);
  };

  return (
    <TablePaper>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <SearchField
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchValue(e.target.value)
            }
            id={"searchField-kpi"}
            placeholder={"Search"}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={10}>
          <StyledTypography>ADD KPI&apos;S TO PROGRAMS</StyledTypography>
        </Grid>
        <Grid item xs={4}>
          <Label label="Select Program" />
          <CustomSelect
            value={program}
            id="programs"
            options={programNames}
            onChange={(event) => setProgram(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TableContainer
            sx={{
              height: `calc(100vh - ${"660px"})`,
              position: "relative",
            }}
            data-testid="tableContainer"
          >
            <Table stickyHeader>
              <TableHeader
                hideCheckbox={true}
                columns={initialColumns}
                rowCount={programs.length}
                numSelected={0}
              >
                {(props: SortRenderProps) => (
                  <SortingDefault
                    setOrder={(data: OrderTypes) => setOrder(data)}
                    order={order}
                    {...props}
                  />
                )}
              </TableHeader>
              <TableBody data-testid="tableBody">
                {products.length > 0 &&
                  products.map((row) => {
                    return (
                      <IXTableRow
                        hover
                        tabIndex={-1}
                        key={row.productId}
                        data-testid={`tableRow_${row.productId}`}
                      >
                        <TableCell>{row.productName}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() => handleEdit(row)}
                            data-testid={"edit-kpi"}
                          >
                            Add/Edit KPI&apos;s
                          </Button>
                        </TableCell>
                      </IXTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {openEditKpiModal && (
        <EditKpi
          handleClose={() => setOpenEditKpiModal(!openEditKpiModal)}
          programName={program}
          productName={productName}
          open={openEditKpiModal}
          productId={productId}
          programId={programId}
        />
      )}
    </TablePaper>
  );
});
