import React from "react";

import { Box } from "@mui/material";
import GeneralInformation from "../../../ProductGeneralInformation";
import Attributes from "../../../ProductOverview/Attributes";
import CustomerEngagement from "../../../ProductOverview/CustomerEngagement";
import Requests from "./Requests";
import OutcomeConfiguration from "../../../ProductOverview/OutcomeConfiguration";
import Integrations from "../../../ProductOverview/Integrations";
import Distributions from "../../../ProductOverview/Distributions";
import EventsAndTriggers from "../../../ProductOverview/EventsAndTriggers";
import { Title } from "../../styles";
import { Wrapper } from "./styles";
import Kpis from "components/ProductOverview/Kpis";

const Confirmation: React.FC<any> = () => {
  return (
    <Wrapper>
      <Title>Confirmation</Title>
      <Box
        data-testid="confirmation"
        sx={{
          marginTop: "25px",
          background: "#fff",
          padding: "24px",
          borderRadius: "20px",
        }}
      >
        <GeneralInformation editable={false} />
        <Attributes editable={false} />
        <Integrations editable={false} />
        <OutcomeConfiguration editable={false} />
        <EventsAndTriggers editable={false} />
        <Distributions editable={false} />
        <CustomerEngagement editable={false} />
        <Requests editable={false} />
        <Kpis />
      </Box>
    </Wrapper>
  );
};

export default Confirmation;
