import {
  ProductsResponse,
  CreateProductResponse,
  ProductVersionsResponse,
  CreateVersionResponse,
  ProductResponse,
  LinkedProductsResponse,
} from "./types";
import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";
import { LinkedProductsPayload } from "entities/products/model/types";
import { SAFE_INTEGER } from "../../config/constants/constants";

export default class ProductsApi {
  static async getProducts(
    params: any
  ): Promise<AxiosResponse<ProductsResponse>> {
    return IXPortalApiPrivate.get("/api/Products", params);
  }
  static async createProduct(
    params: any
  ): Promise<AxiosResponse<CreateProductResponse>> {
    return IXPortalApiPrivate.post("/api/Products", params);
  }

  static async getProductVersions(
    params: any
  ): Promise<AxiosResponse<ProductVersionsResponse>> {
    return IXPortalApiPrivate.get(
      `/api/Products/${params.id}/versions?offset=0&limit=${SAFE_INTEGER}`
    );
  }
  static async createNewProductVersion(
    params: any
  ): Promise<AxiosResponse<CreateVersionResponse>> {
    return IXPortalApiPrivate.post(`/api/Products/${params.id}/versions`);
  }

  static async getProduct(
    params: any
  ): Promise<AxiosResponse<ProductResponse>> {
    return IXPortalApiPrivate.get(
      `/api/Products/${params.id}/versions/${params.version}`,
      params
    );
  }
  static async editProduct(
    params: any
  ): Promise<AxiosResponse<CreateProductResponse>> {
    return IXPortalApiPrivate.put(
      `/api/Products/${params.id}/versions/${params.version}`,
      params.data
    );
  }
  static async deleteProduct(
    params: any
  ): Promise<AxiosResponse<CreateVersionResponse>> {
    return IXPortalApiPrivate.delete(`/api/Products/${params.id}`, params);
  }
  static async cloneProduct(
    params: any
  ): Promise<AxiosResponse<CreateProductResponse>> {
    return IXPortalApiPrivate.post(`/api/Products/${params.id}/clone`);
  }
  static async undeleteProduct(
    params: any
  ): Promise<AxiosResponse<CreateVersionResponse>> {
    return IXPortalApiPrivate.put(
      `/api/Products/${params.id}/undelete`,
      params
    );
  }
  static async publishProduct(
    params: any
  ): Promise<AxiosResponse<CreateProductResponse>> {
    return IXPortalApiPrivate.put(
      `/api/Products/${params.id}/versions/${params.version}/publish`,
      params
    );
  }

  static async addAttributesToProduct(
    params: any
  ): Promise<AxiosResponse<CreateProductResponse>> {
    return IXPortalApiPrivate.post(
      `/api/Products/${params.productId}/versions/${params.version}/attributes/bulk`,
      params.attributes
    );
  }
  static async updateProductAttribute(
    params: any
  ): Promise<AxiosResponse<CreateProductResponse>> {
    return IXPortalApiPrivate.put(
      `/api/Products/${params.productId}/versions/${params.version}/attributes/${params.attributeId}`,
      params.attribute
    );
  }

  static async getLinkedProducts({
    productIds,
    sortKind,
    sortPreference,
    search,
    offset,
    limit,
  }: LinkedProductsPayload): Promise<AxiosResponse<LinkedProductsResponse>> {
    return IXPortalApiPrivate.get(`/api/Products/linked?${productIds}`, {
      params: {
        SearchExpression: search,
        "SortingPreferences.Kind": sortKind,
        "SortingPreferences.SortingProperty": sortPreference,
        offset,
        limit,
      },
    });
  }
}
