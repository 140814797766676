import React, { ReactNode } from "react";
import { ButtonProps } from "@mui/material/Button/Button";
import { StyledButton } from "./styles";

interface Props extends ButtonProps {
  children?: ReactNode;
  width?: BUTTON_WIDTH;
  padding?: string;
}
export enum BUTTON_WIDTH {
  LARGE = "230px",
  DEFAULT = "190px",
}
const Button = (props: Props) => {
  const {
    onClick,
    variant,
    children,
    width = BUTTON_WIDTH.LARGE,
    padding,
    ...otherProps
  } = props;
  return (
    <StyledButton
      {...otherProps}
      variant={variant}
      onClick={onClick}
      width={width}
      padding={padding}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
