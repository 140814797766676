import React, { useEffect, useRef } from "react";
import {
  StyledListItem,
  StyledListItemAvatar,
  StyledListItemText,
  StyledAvatar,
  StyledGrid,
  StyledAttachIcon,
  UserComment,
} from "../styles";
import { Grid } from "@mui/material";
import moment from "moment";
import { CommentSchema } from "shared/api/requests/types";
import { useRequests } from "features/requests/model/hooks/useRequests";
import { useFiles } from "features/files/model/hooks/useFiles";

const Comment: React.FC<{ comments: CommentSchema[] }> = ({ comments }) => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const tenant = localStorage.getItem("tenant-name") ?? "IX-Platform";
  const { orderedComments } = useRequests();
  const { retrieveFile } = useFiles();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  const download = (id: string) => {
    retrieveFile(id, callback);
  };

  const callback = (data: any) => {
    if (data?.id) {
      const a = document.createElement("a");
      a.href = data.uri;
      a.download = data.fileName;
      a.click();
    }
  };

  const avatarName = (name: string) => {
    const firstLetter = name.split(" ")[0][0];
    const secondLetter = name.split(" ")[0][1];

    return {
      sx: {
        bgcolor: "var(--secondary)",
      },
      children: `${firstLetter}${secondLetter}`.toUpperCase(),
    };
  };

  return (
    <StyledGrid
      container
      spacing={3}
      sx={{
        overflow: "auto",
        height: "calc(100% - 80px)",
        marginTop: "0",
      }}
    >
      {orderedComments(comments).map((comment: CommentSchema) => {
        const isAdmin = comment.createdByUserId === "Admin";
        return (
          <Grid
            item
            xs={12}
            key={comment.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                alignItems: "center",
                flexDirection: isAdmin ? "row" : "row-reverse",
              }}
              ref={messagesEndRef}
            >
              <Grid item xs={8}>
                <StyledListItem
                  sx={{
                    flexDirection: isAdmin ? "row" : "row-reverse",
                  }}
                >
                  <StyledListItemAvatar>
                    <StyledAvatar {...avatarName(tenant)} />
                  </StyledListItemAvatar>
                  <StyledListItemText
                    primary={tenant}
                    sx={{
                      wordWrap: "break-word",
                      maxWidth: isAdmin ? "60px" : "130px",
                    }}
                  />
                  <StyledListItemText
                    primary={`${moment(
                      new Date(comment.createdDateUtc)
                    ).fromNow(true)} ago`}
                    sx={{
                      minWidth: "140",
                      maxWidth: isAdmin ? "150px" : "inherit",
                    }}
                  />
                </StyledListItem>
              </Grid>
              <Grid
                item
                xs={10}
                sx={{
                  padding: isAdmin
                    ? "6px 0px 0 66px !important"
                    : "0 !important",
                }}
              >
                <UserComment
                  isPrivate={comment.isPrivate}
                  isAdmin={comment.createdByUserId === "Admin"}
                >
                  {comment.file ? (
                    <StyledAttachIcon
                      onClick={() => download(comment.file.fileId)}
                    />
                  ) : (
                    <></>
                  )}
                  {comment.comment}
                </UserComment>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </StyledGrid>
  );
};

export default Comment;
