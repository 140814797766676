import React from "react";
import { format } from "date-fns";
import { useNavigate, useLocation } from "react-router-dom";

import { StyledTypography, TableText } from "../styles";

import StatusButton from "../StatusButton";
import TableActions from "../TableActions";
import { TableCellProps } from "../types";

const TableCell = ({
  columnId,
  colValue,
  row,
  id,
  activeTab,
  resendInvitation,
  tenant,
  onView,
  onDelete,
  onRestore,
  onClone,
  navigateUrl,
}: TableCellProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const button_status: any = activeTab === "Archive" ? "Deleted" : colValue;

  switch (columnId) {
    case "name":
      return (
        <strong>
          <TableText>{colValue}</TableText>
        </strong>
      );
    case "fullName":
      return (
        <strong>
          <TableText>
            {row.firstName} {row.lastName}
          </TableText>
        </strong>
      );
    case "requestStatus":
    case "status":
    case "state":
      return (
        <StatusButton
          status={
            activeTab === "Archive" && location.pathname.includes("programs")
              ? "Inactive"
              : button_status
          }
          onClick={resendInvitation}
        />
      );
    case "category":
      return <TableText>{colValue.displayName}</TableText>;
    case "createdDateUtc":
    case "updatedDateUtc":
      if (!colValue) {
        return (
          <TableText>
            {format(Date.parse("2020-01-28"), "MM-dd-yyyy")}
          </TableText>
        );
      }
      return (
        <TableText>{format(Date.parse(colValue), "MM-dd-yyyy")}</TableText>
      );
    case "products":
      return (
        <StyledTypography onClick={() => onView?.(row)}>
          View All
        </StyledTypography>
      );
    case "actions":
      return (
        <TableActions
          activeTab={activeTab}
          handleEdit={() => onView?.(row)}
          handleDelete={() => onDelete?.(row)}
          handleRestore={() => onRestore?.(row)}
          handleClone={() => onClone?.(row)}
          handleView={() =>
            navigateUrl ? navigate(navigateUrl(id)) : navigate(`${id}`)
          }
        />
      );
    case "requestTenant":
      return <TableText>{tenant}</TableText>;
    default:
      return <TableText>{colValue}</TableText>;
  }
};

export default TableCell;
