import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Application,
  ApplicationResponse,
} from "shared/api/applications/types";
import { InitialState } from "../types/types";
import { addOrRemove } from "shared/utils/addOrRemove";
import { LinkedProductsResponse } from "shared/api/products/types";

const initialState: InitialState = {
  loading: false,
  applications: [],
  directData: [],
  directTotal: 0,
  directLoading: false,
  networkData: [],
  networkTotal: 0,
  networkLoading: false,
  data: {},
  total: 0,
  searchExpression: "",
  linkedApps: [],
  linkedAppsLoading: [],
  directProductIds: {},
  networkProductIds: {},
  linkedProducts: [],
  linkedProductsArray: [],
  linkedProductsArrayLoading: [],
  linkedLoading: false,
  linkedTotal: 0,
  applicationItem: {
    item: null,
    loading: false,
  },
};

export const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    setApplications: (state, action: PayloadAction<ApplicationResponse>) => {
      const newData = action.payload.items.reduce<
        Record<string, Application[]>
      >((accum, item) => {
        accum[item.distributionType] = accum[item.distributionType]
          ? [...accum[item.distributionType], item]
          : [item];
        return accum;
      }, {});
      return {
        ...state,
        applications: action.payload.items,
        loading: false,
        data: newData,
        total: action.payload.totalCount,
      };
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setApplicationItem: (state, action: PayloadAction<Application | null>) => {
      return {
        ...state,
        applicationItem: {
          item: action.payload,
          loading: false,
        },
      };
    },
    setApplicationItemLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        applicationItem: {
          ...state.applicationItem,
          loading: action.payload,
        },
      };
    },
    setLinkedAppLoading: (state, action: PayloadAction<string>) => {
      const newState = addOrRemove(
        [...state.linkedAppsLoading],
        action.payload
      );
      return {
        ...state,
        linkedAppsLoading: newState,
      };
    },
    setNetworkData: (state, action: PayloadAction<ApplicationResponse>) => {
      const data = action.payload.items.reduce<Record<string, string[]>>(
        (accum, item) => {
          accum[item.id] = item.productIds;
          return accum;
        },
        {}
      );
      return {
        ...state,
        networkData: action.payload.items,
        networkTotal: action.payload.totalCount,
        networkLoading: false,
        networkProductIds: data,
      };
    },
    setNetworkProductIds: (
      state,
      action: PayloadAction<{ appId: string; productId: string }>
    ) => {
      const productIds = state.networkProductIds[action.payload.appId];
      const newProductsIds = addOrRemove(
        [...productIds],
        action.payload.productId
      );
      return {
        ...state,
        networkProductIds: {
          ...state.networkProductIds,
          [action.payload.appId]: newProductsIds,
        },
      };
    },
    setNetworkLoading: (state, action) => {
      return {
        ...state,
        networkLoading: action.payload,
      };
    },
    setDirectData: (state, action: PayloadAction<ApplicationResponse>) => {
      const data = action.payload.items.reduce<Record<string, string[]>>(
        (accum, item) => {
          accum[item.id] = item.productIds;
          return accum;
        },
        {}
      );
      return {
        ...state,
        directData: action.payload.items,
        directTotal: action.payload.totalCount,
        directLoading: false,
        directProductIds: data,
      };
    },
    setDirectProductIds: (
      state,
      action: PayloadAction<{ appId: string; productId: string }>
    ) => {
      const productIds = state.directProductIds[action.payload.appId];
      const newProductsIds = addOrRemove(
        [...productIds],
        action.payload.productId
      );
      return {
        ...state,
        directProductIds: {
          ...state.networkProductIds,
          [action.payload.appId]: newProductsIds,
        },
      };
    },
    setDirectLoading: (state, action) => {
      return {
        ...state,
        directLoading: action.payload,
      };
    },
    setSearchExpression: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        searchExpression: action.payload,
      };
    },
    setLinkedProducts: (
      state,
      action: PayloadAction<LinkedProductsResponse>
    ) => {
      return {
        ...state,
        linkedProducts: action.payload.items,
        linkedTotal: action.payload.totalCount,
        linkedLoading: false,
        linkedProductsArray: action.payload.items.map((item) => item.id),
      };
    },
    setLinkedLoading: (state, action) => {
      return {
        ...state,
        linkedLoading: action.payload,
      };
    },
    setLinkedArrayData: (state, action) => {
      return {
        ...state,
        linkedProductsArray: addOrRemove(
          [...state.linkedProductsArray],
          action.payload
        ),
      };
    },
    setLinkedArrayDataMap: (state, action) => {
      return {
        ...state,
        linkedProductsArray: action.payload,
      };
    },
    setLinkedArrayLoading: (state, action) => {
      return {
        ...state,
        linkedProductsArrayLoading: addOrRemove(
          [...state.linkedProductsArrayLoading],
          action.payload
        ),
      };
    },
  },
});

export const { reducer: applicationsReducer, actions } = applicationsSlice;
