import styled from "styled-components";
import { Dialog } from "@mui/material";

export const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    font-family: "Inter";
  }
  .MuiPaper-root {
    width: 100%;
    max-width: 600px;
    box-shadow: 10px 10px 40px rgba(111, 138, 231, 0.25),
      0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 40px 50px;
  }
`;
