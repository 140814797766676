import styled from "styled-components";
import { AccordionSummary, AccordionDetails, Accordion } from "@mui/material";

export const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    box-shadow: none;
    &:before {
      background-color: transparent;
    }
  }
`;

export const StyledTitle = styled.h3`
  margin: 0;
  font-size: 24px;
  line-height: 32px;
`;

export const Summary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0;
    flex-direction: row-reverse;
    margin: 15px 0;
    min-height: 32px !important;
    .MuiAccordionSummary-content {
      margin: 0;
      display: flex;
      align-items: center;
    }
  }
`;

export const Details = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0;
    .MuiTableContainer-root {
      height: auto;
    }
  }
`;
