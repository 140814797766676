import styled from "styled-components";
import { Typography, DialogActions, Dialog, IconButton } from "@mui/material";

export const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    font-family: "Inter";
  }
  .MuiPaper-root {
    width: 100%;
    max-width: 575px;
    border-radius: 12px;
    padding: 32px;
  }
`;
export const Title = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 32px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--headings);
    text-align: center;
  }
`;
export const Paragraph = styled(Typography)`
  &.MuiTypography-root {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 58px;
  }
`;

export const StyledActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 0;
    justify-content: center;

    & > :not(:first-of-type) {
      margin-left: 20px;
    }
  }
`;
export const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    top: 25px;
    right: 32px;
  }
`;
