import styled from "styled-components";
import { Dialog, DialogActions, Typography } from "@mui/material";

export const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    font-family: "Inter";
  }
  .MuiPaper-root {
    width: 100%;
    max-width: 600px;
    box-shadow: 10px 10px 40px rgba(111, 138, 231, 0.25),
      0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 40px 50px;
  }
`;

export const StyledActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 0;
    justify-content: center;

    & > :not(:first-of-type) {
      margin-left: 20px;
    }
  }
`;

export const Container = styled.div`
  text-align: center;
  padding-bottom: 32px;
  > svg {
    color: var(--mediumred);
    margin-bottom: 30px;
    font-size: 100px;
  }
`;

export const Title = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 32px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--headings);
  }
`;
