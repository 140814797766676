import styled from "styled-components";
import { Typography, DialogActions } from "@mui/material";

export const Title = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 32px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--headings);
  }
`;
export const Text = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--headings);
  margin-bottom: 20px;
  b {
    font-weight: 700;
  }
`;
export const Container = styled.div`
  text-align: center;
  padding-bottom: 32px;
  svg {
    color: var(--mediumred);
    margin-bottom: 30px;
    font-size: 100px;
  }
`;

export const StyledActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 0;
    padding: 0;
    justify-content: center;

    & > :not(:first-of-type) {
      margin-left: 20px;
    }
  }
`;
