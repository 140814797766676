import {
  ProgramsResponse,
  CreateProgramResponse,
  ProgramFullResponse,
  ProgramProductsResponse,
} from "./types";
import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";

export default class ProgramsApi {
  static async getPrograms(
    params: any
  ): Promise<AxiosResponse<ProgramsResponse>> {
    return IXPortalApiPrivate.get("/api/Programs", params);
  }
  static async createProgram(
    params: any
  ): Promise<AxiosResponse<CreateProgramResponse>> {
    return IXPortalApiPrivate.post("/api/Programs", params);
  }
  static async getProgram(
    params: any
  ): Promise<AxiosResponse<ProgramFullResponse>> {
    return IXPortalApiPrivate.get(`/api/Programs/${params.id}`);
  }
  static async updateProgram(
    params: any
  ): Promise<AxiosResponse<CreateProgramResponse>> {
    return IXPortalApiPrivate.put(`/api/Programs/${params.id}`, params.data);
  }
  static async deleteProgram(
    params: any
  ): Promise<AxiosResponse<CreateProgramResponse>> {
    return IXPortalApiPrivate.delete(`/api/Programs/${params.id}`, params);
  }
  static async getProgramProducts(
    params: any
  ): Promise<AxiosResponse<ProgramProductsResponse>> {
    return IXPortalApiPrivate.get(
      `/api/Programs/${params.id}/products`,
      params
    );
  }
  static async linkProductsToProgram(
    params: any
  ): Promise<AxiosResponse<CreateProgramResponse>> {
    return IXPortalApiPrivate.post(
      `/api/Programs/${params.id}/products/link`,
      params.data
    );
  }
  static async unlinkProductsToProgram(params: {
    programId: string;
    productIds: string[];
  }): Promise<AxiosResponse<CreateProgramResponse>> {
    return IXPortalApiPrivate.post(
      `/api/Programs/${params.programId}/products/unlink`,
      { productIds: params.productIds }
    );
  }
  static async undeleteProgram(
    params: any
  ): Promise<AxiosResponse<CreateProgramResponse>> {
    return IXPortalApiPrivate.patch(
      `/api/Programs/${params.id}/undelete`,
      params
    );
  }
  static async cloneProgram(
    params: any
  ): Promise<AxiosResponse<CreateProgramResponse>> {
    return IXPortalApiPrivate.post(`/api/Programs/${params.id}/clone`);
  }
  static async updateProgramStatus(params: {
    programId: string;
    isActive: boolean;
  }): Promise<AxiosResponse<CreateProgramResponse>> {
    return IXPortalApiPrivate.patch(
      `/api/Programs/${params.programId}/status/`,
      { isActive: params.isActive }
    );
  }
}
