import { Column } from "shared/config/types/Table";

export const initialColumns: Column[] = [
  {
    id: "Name",
    label: "Name",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "Provider",
    label: "Provider",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "Location",
    label: "Location",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "Channel",
    label: "Channel",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "createdDateUtc",
    label: "Creation",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Update",
    minWidth: 200,
    filtering: false,
  },
];
