import React, { useState, useEffect } from "react";
import { format } from "date-fns";

import { Table, TableBody, TableCell } from "@mui/material";

import { IXTableRow, TableHeader } from "shared/ui/Table";
import TablePlaceholder from "shared/ui/TablePlaceholder";
import { TableContainerWrapper } from "shared/ui/Table/styles";
import { TableSkeleton } from "shared/ui/TableSkeleton";
import SortingDefault, {
  SortRenderProps,
} from "shared/ui/Table/SortingDefault/SortingDefault";

import { initialColumns } from "./types";
import { OrderTypes } from "shared/config/types/Sorting";
import { SORT } from "shared/config/enums/Sorting";
import { useEvents } from "features/events";
import { DateFormat } from "shared/config/enums/DateFormat";

const initialPagingData = {
  limit: 100,
  offset: 0,
  SearchExpression: "",
};

const EventsAndTriggersTable: React.FC<any> = () => {
  const [order, setOrder] = useState<OrderTypes>({ type: SORT.ASC, label: "" });

  const { isLoading, events, getEvents } = useEvents();

  useEffect(() => {
    const sorting = {
      "SortingPreferences.SortingProperty": order.label,
      "SortingPreferences.Kind": order.type,
    };
    getEvents({
      ...initialPagingData,
      ...(order.label && sorting),
    });
  }, [order]);

  return (
    <TableContainerWrapper data-testid="tableContainer" size={"lg"}>
      <Table stickyHeader>
        <TableHeader
          hideCheckbox={true}
          columns={initialColumns}
          rowCount={events?.length}
        >
          {(props: SortRenderProps) => (
            <SortingDefault
              setOrder={(data: OrderTypes) => setOrder(data)}
              order={order}
              {...props}
            />
          )}
        </TableHeader>
        <TableBody data-testid="tableBody">
          {isLoading && <TableSkeleton lines={3} columns={initialColumns} />}
          {!isLoading && events?.length === 0 && (
            <TablePlaceholder colSpan={initialColumns.length} />
          )}
          {!isLoading &&
            events?.length > 0 &&
            events.map((row) => {
              return (
                <IXTableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  data-testid={`tableRow_${row.id}`}
                >
                  <TableCell>{row.eventTrigger}</TableCell>
                  <TableCell>{row.triggerConfiguration.triggerType}</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    {row.actionConfiguration.actionType}
                  </TableCell>
                  <TableCell>
                    {
                      row.actionConfiguration.emailActionConfiguration
                        ?.tenantNotificationTemplateName
                    }
                  </TableCell>
                  <TableCell>
                    {row.createdDateUtc &&
                      format(new Date(row.createdDateUtc), DateFormat.DEFAULT)}
                  </TableCell>
                  <TableCell>
                    {row.updatedDateUtc &&
                      format(new Date(row.updatedDateUtc), DateFormat.DEFAULT)}
                  </TableCell>
                </IXTableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainerWrapper>
  );
};

export default EventsAndTriggersTable;
