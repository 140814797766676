import { RootState } from "redux/store";
import { createSelector } from "@reduxjs/toolkit";

export const getAnalytics = (state: RootState) => state.analytics;

export const getWidgetDeploysState = createSelector(
  getAnalytics,
  (analytics) => {
    return analytics.widgetDeploys;
  }
);

export const getLocationDetailsState = createSelector(
  getAnalytics,
  (analytics) => {
    return analytics.locationDetails;
  }
);

export const getStepDetailsState = createSelector(getAnalytics, (analytics) => {
  return analytics.stepDetails;
});

export const getDeploysLoading = createSelector(getAnalytics, (analytics) => {
  return analytics.loading;
});
