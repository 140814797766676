import React, { useEffect, useState } from "react";

import { Edit, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  ExpandTitle,
  StyledCollapse,
  StyledTableCell,
  StyledTableRow,
} from "./styles";
import { IconButton, Table } from "@mui/material";
import OutlinedCheckbox from "../../FormComponents/OutlinedCheckbox";
import TableRow from "./TableRowComponent";

import { AttributesPayload } from "shared/api/products/types";
import { TableRowCollapsedProps } from "./types";

const TableRowCollapsed = ({
  selected,
  row,
  handleClick,
  open,
  small,
  onEdit,
  isSelectedGroup,
  parentId,
  stepIndex = 0,
}: TableRowCollapsedProps) => {
  const [openning, setOpenning] = useState(false);

  useEffect(() => {
    if (open) {
      setOpenning(true);
    } else {
      setOpenning(false);
    }
  }, [open]);
  const createRow = (row: AttributesPayload, id?: string) => {
    return row.subAttributes.length > 0 ? (
      <TableRowCollapsed
        key={row.id}
        selected={isSelectedGroup(row)}
        row={row}
        parentId={id ? id + "." + row.id : row.id}
        isSelectedGroup={isSelectedGroup}
        handleClick={handleClick}
        open={!!openning}
        small={small}
        onEdit={onEdit}
        stepIndex={stepIndex + 1}
      />
    ) : (
      <TableRow
        key={row.id}
        selected={isSelectedGroup(row)}
        row={row}
        parentId={id ? id + "." + row.id : row.id}
        handleClick={handleClick}
        small={small}
        onEdit={onEdit}
        stepIndex={stepIndex + 1}
      />
    );
  };
  return (
    <>
      <StyledTableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          backgroundColor: "var(--lightPurple) !important",
        }}
      >
        <StyledTableCell padding="checkbox">
          <OutlinedCheckbox
            checked={selected}
            onChange={() => handleClick?.(row)}
          />
        </StyledTableCell>

        <StyledTableCell colSpan={small ? 6 : 5}>
          <ExpandTitle>
            {row.displayName} ({row.subAttributes.length})
          </ExpandTitle>
        </StyledTableCell>
        {!small && (
          <StyledTableCell className="right noWidth">
            <IconButton
              sx={{ color: "#383874", opacity: "0.5" }}
              onClick={() => {
                onEdit?.(row, parentId);
              }}
            >
              <Edit />
            </IconButton>
          </StyledTableCell>
        )}

        <StyledTableCell className="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenning(!openning)}
          >
            {openning ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          colSpan={8}
          sx={{ padding: "0 !important", border: "none !important" }}
        >
          <StyledCollapse
            in={openning}
            timeout="auto"
            unmountOnExit
            stepIndex={stepIndex}
          >
            <Table
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0 10px",
              }}
            >
              {row.subAttributes.map((item: AttributesPayload) =>
                createRow(item, parentId)
              )}
            </Table>
          </StyledCollapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default TableRowCollapsed;
