import styled from "styled-components";
import { Typography } from "@mui/material";

export const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    text-align: left;
    font-size: 1.25rem !important;
    font-family: Poppins;
    font-weight: 600;
    margin: 1.25rem 0;
  }
`;
