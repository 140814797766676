import React, { FC, useCallback, useState } from "react";
import CreateRequestModal from "components/Modals/CreateRequest";
import { AssociatedProductsModal } from "features/associatedProducts";
import { DistributionTable } from "widgets/DistributionTable";
import TablePaper from "shared/ui/TablePaper";

interface DistributionProps {
  listProduct?: boolean;
}

export const Distribution: FC<DistributionProps> = () => {
  const [openCreateRequest, setOpenCreateRequest] = useState(false);
  const [openListProductModal, setOpenListProductModal] = useState(false);

  const openCreateRequestModal = useCallback(() => {
    setOpenCreateRequest((prev) => !prev);
  }, []);

  return (
    <TablePaper>
      <DistributionTable openCreateRequestModal={openCreateRequestModal} />
      <CreateRequestModal
        open={openCreateRequest}
        handleClose={() => setOpenCreateRequest(!openCreateRequest)}
        requestCategory={"Distribution"}
      />
      <AssociatedProductsModal
        open={openListProductModal}
        handleClose={() => setOpenListProductModal(!openListProductModal)}
      />
    </TablePaper>
  );
};
