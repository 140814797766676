import { Column } from "shared/config/types/Table";

export const initialColumns: Column[] = [
  {
    id: "productName",
    label: "Name",
    minWidth: 50,
    filtering: true,
  },
  {
    id: "categoryName",
    label: "Category",
    minWidth: 50,
    filtering: false,
  },
  {
    id: "productStatus",
    label: "Status",
    minWidth: 50,
    filtering: false,
  },
  {
    id: "chip",
    label: "",
    minWidth: 50,
    filtering: false,
  },
];

export interface AssociateProductData {
  id: string;
  productName: string;
  categoryName: string;
  productStatus: string;
  createdDateUtc: string;
  updatedDateUtc: string;
  version: number;
}
