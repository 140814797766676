import { call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { getTemplatesDefinitionsAction, getTemplatesAction } from "../actions";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";
import { setTemplatesDefinitions, setTemplates } from "../slice/templatesSlice";
import TemplatesApi from "shared/api/templates/templatesApi";
import { TemplatesDefinitionsResponse } from "shared/api/templates/types";

export function* getTemplatesDefinitionsSaga() {
  try {
    const response: AxiosResponse<TemplatesDefinitionsResponse> = yield call(
      TemplatesApi.getTemplatesDefinitions
    );
    yield put(setTemplatesDefinitions(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}
export function* getTemplatesSaga() {
  try {
    const response: AxiosResponse<TemplatesDefinitionsResponse> = yield call(
      TemplatesApi.getTemplates
    );
    yield put(setTemplates(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* TemplatesWatcher() {
  yield takeLatest(getTemplatesDefinitionsAction, getTemplatesDefinitionsSaga);
  yield takeLatest(getTemplatesAction, getTemplatesSaga);
}
