import { styled, Typography, Tab, Tabs } from "@mui/material";

export const ListText = styled(Typography)`
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.5;
  color: #383874;
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
`;
export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #6266ea;
  }
`;
export const StyledTab = styled(Tab)`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #383874;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  padding: 12px 25px;
  &.Mui-selected {
    color: #6266ea;
  }
`;
