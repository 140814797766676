import { Column } from "../Table/types";

export const initialColumns: Column[] = [
  {
    id: "chip",
    label: "",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "name",
    label: "Name",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "provider",
    label: "Provider",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "location",
    label: "Location",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "channel",
    label: "Channel",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "products",
    label: "Associated Products",
    minWidth: 250,
    filtering: false,
  },
  {
    id: "createdDateUtc",
    label: "Creation",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Update",
    minWidth: 150,
    filtering: false,
  },
];

export interface DistributionData {
  id: string;
  status: string;
  type: string;
  name: string;
  provider: string;
  location: string;
  channel: string;
  createdDateUtc: string;
  updatedDateUtc: string;
}
