import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import ProductsTable from "../Table";
import TableControls from "../TableControls";
import FirstimeBox from "../FirstimeBox";
import RestoreModal from "../Modals/RestoreModal";
import TablePaper from "shared/ui/TablePaper";

// actions
import { productsActions } from "entities/products";
import { productCategoriesActions } from "entities/productCategories";
import { modalsActions } from "entities/modals";

import { ReactComponent as Application } from "assets/icons/programming-application.svg";

// types
import { Column } from "../Table/types";
import { PaginationParams } from "api/types";
import { ProductResponse } from "shared/api/products/types";
import { RootState } from "redux/store";
import { useAppSelector } from "redux/store/hooks/hooks";
import { CategoryData } from "shared/api/productCategories/types";
import { OrderTypes } from "shared/config/types/Sorting";

import { getCategoriesList } from "entities/productCategories/model/selectors";
import { getProgram } from "entities/programs";

import { initialColumns, archivedColumns } from "./types";
import { useProducts } from "features/associatedProducts/model/hooks/useProducts";

const initialPagingData = {
  totalCount: 0,
  page: 1,
  limit: 10,
  offset: 0,
};

interface ProductsProps {
  status: string;
}

const Products: React.FC<any> = ({ status }: ProductsProps) => {
  const productsState = useSelector((state: RootState) => state.products);
  const productCategories: CategoryData[] = useAppSelector(getCategoriesList);
  const program: any = useAppSelector(getProgram);
  const [pagingParams, setPagingParams] =
    useState<PaginationParams>(initialPagingData);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState<OrderTypes | null>(null);
  const [columns, setColumns] = useState<Column[]>([]);
  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const [productToRestore, setProductToRestore] = useState<ProductResponse>();
  const [tab, setTab] = useState("New");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateProductVersion } = useProducts();

  useEffect(() => {
    if (!productCategories.length) {
      dispatch({ type: productCategoriesActions.GET_PRODUCT_CATEGORIES });
    }
  }, [productCategories]);

  useEffect(() => {
    if (productsState.totalCount && productsState.totalCount !== totalCount) {
      setTotalCount(productsState.totalCount);
    }
    setIsLoading(productsState.loading);
  }, [productsState]);

  useEffect(() => {
    if (!isLoading && status === tab) getData();
    if (status === "New") {
      if (tab === "Archived") {
        setPagingParams(initialPagingData);
        setTab("New");
        getData(true);
      }
      setColumns(initialColumns);
    } else {
      if (tab === "New") {
        setPagingParams(initialPagingData);
        setTab("Archived");
        getData(true);
      }
      setColumns(archivedColumns);
    }
  }, [status, pagingParams]);

  const getData = (indexing?: boolean) => {
    const pagingData = indexing ? initialPagingData : pagingParams;
    const params: any = {
      ShowArchived: status === "Archived",
      limit: pagingData.limit,
      offset: pagingData.offset,
    };
    if (status !== "Archived" && program.id) {
      params.State = "Published";
    }
    dispatch({
      type: productsActions.GET_PRODUCTS,
      data: {
        params,
      },
    });
  };

  const getCategory = (categoryId: string) => {
    return productCategories.find(
      (category: CategoryData) => category.id === categoryId
    );
  };

  const productWithCategories = productsState.products.map((product: any) => {
    const category = getCategory(product.categoryId);
    return {
      ...product,
      category: {
        id: category?.id,
        displayName: category?.displayName,
      },
    };
  });

  const handleDelete = (data?: ProductResponse) => {
    dispatch({
      type: modalsActions.OPEN_MODAL,
      data: { delete: { type: "product", isModalOpen: true, data } },
    });
  };

  const handleClone = (data: ProductResponse) => {
    dispatch({
      type: productsActions.CLONE_PRODUCT,
      data,
    });
  };

  const handleOpen = () => {
    navigate("/create_new_product");
  };

  const handleEdit = (product: ProductResponse) => {
    if (product.state === "Published") {
      updateProductVersion(product);
    } else {
      navigate(`/create_new_product/${product.id}`);
    }
  };

  const handleSearch = (searchQuery: string) => {
    const params: any = {
      ShowArchived: status === "Archived",
      limit: pagingParams.limit,
      offset: 0,
      ProductName: searchQuery,
    };
    if (order) {
      params["SortingPreferences.Kind"] = order.type;
      params["SortingPreferences.SortingProperty"] = "Name";
    }
    if (status !== "Archived" && program.id) {
      params.State = "Published";
    }
    dispatch({
      type: productsActions.GET_PRODUCTS,
      data: { params },
    });
  };

  const [searchQuery] = useDebounce(searchValue, 1000);

  useEffect(() => {
    setPagingParams(initialPagingData);
    handleSearch(searchQuery);
  }, [searchQuery, order]);

  const setSortOrder = (order: OrderTypes) => {
    setOrder(order);
  };

  const onRestore = (el: ProductResponse) => {
    setProductToRestore(el);
    setOpenRestoreModal(true);
  };

  const restoreProduct = () => {
    if (productToRestore) {
      dispatch({
        type: productsActions.RESTORE_PRODUCT,
        data: productToRestore,
      });
    }
    setOpenRestoreModal(!openRestoreModal);
  };

  return (
    <TablePaper>
      <TableControls
        activeTab={status === "New" ? "Products" : "Archive"}
        handleOpen={handleOpen}
        searchValue={searchValue}
        handleSearch={(e: any) => setSearchValue(e.target.value)}
        hideCreate={status === "Archived" || !productWithCategories.length}
      />
      {totalCount > 0 ? (
        <ProductsTable
          rows={productWithCategories}
          columns={columns}
          pagingParams={pagingParams}
          totalCount={totalCount}
          setPagingParams={setPagingParams}
          getDataFunction={getData}
          activeTab={status === "New" ? "Products" : "Archive"}
          onDelete={handleDelete}
          onView={handleEdit}
          setSortOrder={setSortOrder}
          onRestore={onRestore}
          onClone={handleClone}
        />
      ) : (
        <FirstimeBox
          title="Products"
          handleCreate={handleOpen}
          icon={<Application />}
        />
      )}
      <RestoreModal
        onClose={() => setOpenRestoreModal(false)}
        open={openRestoreModal}
        restoreType={"Product"}
        handleSubmit={restoreProduct}
      />
    </TablePaper>
  );
};

export default Products;
