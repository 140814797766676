import React from "react";
import { useDispatch } from "react-redux";
import Button from "shared/ui/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  StyledActions,
  Title,
  Paragraph,
  StyledDialog,
  StyledIconButton,
} from "./styles";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getDraftItems } from "entities/draftItems/model/selectors";
import { programsActions, getProgram } from "entities/programs";
import { CreateProgramResponse } from "shared/api/programs/types";

interface ProductConfirmationModalProps {
  open: boolean;
  onClose: () => void;
}

const ProductConfirmation = ({
  open,
  onClose,
}: ProductConfirmationModalProps) => {
  const dispatch = useDispatch();

  const draftItems = useAppSelector(getDraftItems);
  const program: CreateProgramResponse = useAppSelector(getProgram);

  const handleSubmit = () => {
    dispatch({
      type: programsActions.LINK_PRODUCTS_TO_PROGRAM,
      data: {
        id: program.id,
        data: {
          productIds: draftItems,
        },
      },
    });
    onClose();
  };

  return (
    <StyledDialog
      id={"product-confirmation-modal"}
      data-testid={"product-confirmation-modal"}
      onClose={onClose}
      open={open}
    >
      <Title>
        Product Confirmation
        <StyledIconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "var(--black)",
          }}
        >
          <CloseIcon />
        </StyledIconButton>
      </Title>
      <Paragraph>
        Please confirm that the products, distribution and widgets selected have
        been configured and published
      </Paragraph>
      <StyledActions>
        <Button variant="outlined" onClick={onClose}>
          Configure products
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          OK
        </Button>
      </StyledActions>
    </StyledDialog>
  );
};

export default ProductConfirmation;
