import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  WidgetDeploysResponse,
  WidgetDetailsResponse,
  WidgetLocationsResponse,
} from "shared/api/analytics/types";
import { InitialState } from "../types/types";

const initialState: InitialState = {
  locationDetails: [],
  stepDetails: [],
  widgetDeploys: [],
  loading: false,
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setWidgetDeploys: (state, action: PayloadAction<WidgetDeploysResponse>) => {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    },
    setStepDetails: (state, action: PayloadAction<WidgetDetailsResponse>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setLocationDetails: (
      state,
      action: PayloadAction<WidgetLocationsResponse>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

export const { reducer: analyticsReducer, actions: analyticsActions } =
  analyticsSlice;
