import styled from "styled-components";
import { Typography, DialogContent } from "@mui/material";

export const Subtitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--secondary);
    margin-bottom: 24px;
  }
`;
export const Form = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 40px 51px;
    border-bottom: none;
  }
`;
export const Block = styled.div`
  padding: 40px 0;
  margin: 40px 0 0;
  border-top: 1px solid var(--silver);
`;
