import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/store/hooks/hooks";
import {
  getProducts,
  getProgram,
  getProgramLoading,
  programsActions,
  unlinkProductFromProgramAction,
} from "entities/programs";
import {
  getCurrentKpiItem,
  getKpiItemAction,
  getKpiItemsAction,
  clearKpiItemsAction,
} from "entities/kpis";

const useProgramData = () => useAppSelector(getProgram);
const useProgramLoading = () => useAppSelector(getProgramLoading);
const useProducts = () => useAppSelector(getProducts);
const useCurrentKpiItem = () => useAppSelector(getCurrentKpiItem);

export const usePrograms = () => {
  const dispatch = useAppDispatch();
  const programData = useProgramData();
  const isProgramLoading = useProgramLoading();
  const products = useProducts();
  const kpiItem = useCurrentKpiItem();

  const getProgram = useCallback((id: string) => {
    dispatch({ type: programsActions.GET_PROGRAM, data: { id } });
  }, []);

  const getProgramKpis = useCallback((programIds: string[]) => {
    dispatch(
      getKpiItemsAction({
        KPIType: "Program",
        EntityIds: programIds,
      })
    );
  }, []);

  const clearKpiItems = useCallback(() => {
    dispatch(clearKpiItemsAction());
  }, []);

  const getKpi = useCallback((programId: string) => {
    dispatch(
      getKpiItemAction({
        KPIType: "Program",
        EntityIds: [programId],
      })
    );
  }, []);

  const unlinkProduct = useCallback(
    (productIds: string[], programId: string, cb: () => void) => {
      dispatch(unlinkProductFromProgramAction({ productIds, programId, cb }));
    },
    []
  );
  const deleteProgram = useCallback((id: string, callback: () => void) => {
    dispatch({ type: programsActions.DELETE_PROGRAM, data: { id, callback } });
  }, []);

  const cloneProgram = useCallback((id: string) => {
    dispatch({ type: programsActions.CLONE_PROGRAM, data: { id } });
  }, []);

  return {
    getProgram,
    data: programData,
    isProgramLoading,
    products,
    unlinkProduct,
    deleteProgram,
    cloneProgram,
    getKpi,
    getProgramKpis,
    kpiItem,
    clearKpiItems,
  };
};
