import styled from "styled-components";

export const InfoLabel = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: rgba(56, 56, 116, 0.5);
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: nowrap;
  margin-right: 10px;
`;

export const InfoLabelMessage = styled(InfoLabel)`
  width: 76px;
`;

export const InfoLabelLink = styled(InfoLabel)`
  width: 42px;
`;
