import { createAction } from "@reduxjs/toolkit";

export const pushKpiAction = createAction<{
  productId?: string;
  entityId: string;
  kpiType: string;
  value: number | null;
  callback?: (data: any) => void;
}>("kpis/push");

export const getKpisAction = createAction<{
  productId: string;
}>("kpis/get");

type KPIType = "Product" | "Program" | "Widget" | "Application";

export const getKpiItemAction = createAction<{
  EntityIds: string[];
  KPIType: KPIType;
}>("kpis/get/Item");

export const getKpiItemsAction = createAction<{
  EntityIds: string[];
  KPIType: KPIType;
}>("kpis/get/Items");

export const clearKpiItemsAction = createAction("kpis/clear");
