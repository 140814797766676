import React from "react";

import { StyledControlHeader, TableControlsItemsWrapper } from "./styles";
import { SearchField } from "shared/ui/searchField";
import Button from "shared/ui/Button";
import { TableControlsProps } from "../../config/types/Table";

const TableControls: React.FC<TableControlsProps> = ({
  activeTab,
  handleOpen,
  searchValue,
  handleSearch,
  hideCreate,
  buttonLabel,
  children,
}) => {
  const createTabName = () => {
    if (buttonLabel) return buttonLabel;
    switch (activeTab) {
      case "Widget":
        return "Request Widget";
      case "Products":
        return "Create New Product";
      case "Requests":
      case "Distribution":
      case "Third Party":
      case "Data Enrichment":
        return "Create Request";
      case "Users":
        return "Add New User";
      case "Programs":
        return "Create New Program";
      default:
        return activeTab;
    }
  };

  return (
    <StyledControlHeader data-testid="tableControlHeader">
      <SearchField
        value={searchValue}
        onChange={handleSearch}
        id={`searchField-${activeTab}`}
        placeholder={`Search ${activeTab}`}
        sx={{ width: "384px" }}
      />
      {children && (
        <TableControlsItemsWrapper>{children}</TableControlsItemsWrapper>
      )}
      {!children && !hideCreate && (
        <Button
          variant="contained"
          onClick={handleOpen}
          data-testid={`create-${activeTab}-button`}
        >
          {createTabName()}
        </Button>
      )}
    </StyledControlHeader>
  );
};

export default TableControls;
