import * as React from "react";

import { StyledLabel, Danger } from "./styles";

interface LabelProps {
  label: string;
  danger?: boolean;
  disabled?: boolean;
}

const Label = ({ label, danger, disabled }: LabelProps) => {
  return (
    <StyledLabel disabled={disabled}>
      {label} {danger && <Danger>*</Danger>}
    </StyledLabel>
  );
};

export default Label;
