import React, { memo } from "react";
import { StyledChip } from "shared/ui/Table/styles";
import { ReactComponent as ActivateIcon } from "assets/icons/add.svg";
import { ReactComponent as DeactivateIcon } from "assets/icons/remove.svg";
import { useAppSelector } from "redux/store/hooks/hooks";
import { Spinner } from "shared/ui/Spinner";
import { ChipWrapper } from "../../applications/ui/styles";
import {
  getLinkedProductsArrayData,
  getLinkedProductsArrayLoading,
} from "entities/applications";
import { useLinkedProductsData } from "../../applications";
import { useGetWidgetsActions } from "features/widgets";
import { useNavigate } from "react-router-dom";

interface Props {
  productId: string;
  appId: string;
}

export const ActivateButtonProduct = memo(function ActivateButtonWidget({
  productId,
  appId,
}: Props) {
  const { updateWidgetProducts } = useGetWidgetsActions();
  const { dataMap } = useLinkedProductsData();
  const active = useAppSelector(getLinkedProductsArrayData(productId));
  const loading = useAppSelector(getLinkedProductsArrayLoading(productId));
  const navigate = useNavigate();

  const handleActivate = () => {
    if (active) {
      const newProducts = dataMap.filter((item) => item !== productId);
      updateWidgetProducts({ id: appId, productIds: newProducts, productId });
    } else {
      const newProducts = [...dataMap, productId];
      updateWidgetProducts({ id: appId, productIds: newProducts, productId });
    }
    navigate(`/products/list/${productId}`);
  };
  return (
    <ChipWrapper>
      {loading ? <Spinner /> : null}
      <StyledChip
        label={!active ? "Activate" : "Deactivate"}
        chiptype={"activate"}
        icon={!active ? <ActivateIcon /> : <DeactivateIcon />}
        onClick={handleActivate}
      />
    </ChipWrapper>
  );
});
