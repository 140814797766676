import React from "react";
import { StyledTableHead } from "./styles";
import { TableCell, TableRow } from "@mui/material";
import OutlinedCheckbox from "shared/ui/OutlinedCheckbox";
import { EnhancedTableProps } from "shared/config/types/Table";

export const TableHeader = (props: EnhancedTableProps) => {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    columns,
    hideCheckbox,
    children,
  } = props;
  return (
    <StyledTableHead data-testid="tableHeader">
      <TableRow>
        {!hideCheckbox && (
          <TableCell padding="checkbox">
            <OutlinedCheckbox
              checked={!!(rowCount && rowCount > 0 && numSelected === rowCount)}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            width={headCell.minWidth}
            align={headCell.align ? headCell.align : "left"}
            sx={{
              minWidth: headCell.minWidth,
              width: headCell.minWidth,
              maxWidth: headCell.minWidth,
            }}
          >
            {headCell.filtering && children ? (
              children({ headCell: headCell })
            ) : (
              <span>{headCell.label}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};
