import React, { useEffect, useState } from "react";
import {
  OutlinedInput,
  ListItemText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  StyledInput,
  StyledButton,
  StyledCheckbox,
  StyledMenuItem,
  StyledHelperText,
  MenuItemHeader,
  StyledSelect,
} from "./styles";
import Label from "../Label";

interface SelectProps {
  options: string[];
  id: string;
  title: string;
  value: string | string[];
  onChange?: (e: any) => void;
  multiple?: boolean;
  error?: boolean;
  hideCreate?: boolean;
  disabled?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  label?: string;
  required?: boolean;
}
const CustomizedSelect = ({
  options,
  id,
  onChange,
  multiple,
  value,
  title,
  error,
  hideCreate,
  disabled,
  placeholder,
  fullWidth = true,
  label,
  required,
}: SelectProps) => {
  const [newGroup, setNewGroup] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(options);
  useEffect(() => {
    setSelectedOptions(options);
  }, [options]);
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      const newOptions = [newGroup, ...selectedOptions];
      setSelectedOptions(newOptions);
      setNewGroup("");
      setOpen(false);
    }
  };
  const renderIconComponent = (props: any) => (
    <KeyboardArrowDownIcon {...props} />
  );

  return (
    <>
      {label && <Label label={label} disabled={disabled} danger={required} />}
      <StyledSelect
        multiple={multiple}
        fullWidth={fullWidth}
        id={id}
        value={value}
        onChange={onChange}
        input={<OutlinedInput placeholder={placeholder ?? "Please select"} />}
        renderValue={(selected: any) => {
          return Array.isArray(selected) ? selected.join(", ") : selected;
        }}
        error={error}
        disabled={disabled}
        label="test"
        IconComponent={renderIconComponent}
      >
        {!hideCreate && (
          <MenuItemHeader onKeyDown={(e) => e.stopPropagation()}>
            {open ? (
              <StyledInput
                fullWidth
                autoFocus={true}
                id={`new_${id}`}
                value={newGroup}
                onChange={(event) => setNewGroup(event.target.value)}
                onKeyDown={handleKeyDown}
                placeholder={`Enter ${title} Name here...`}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle"
                      onClick={() => setOpen(false)}
                      onMouseDown={() => setOpen(false)}
                      edge="end"
                      data-testid="select_closeButton"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            ) : (
              <StyledButton
                variant="text"
                onClick={() => setOpen(!open)}
                data-testid="select_addButton"
              >
                <AddIcon />
                Create {title}...
              </StyledButton>
            )}
          </MenuItemHeader>
        )}

        {selectedOptions.map((option) => (
          <StyledMenuItem
            key={option}
            value={option}
            data-testid={`select_${option}Option`}
          >
            <StyledCheckbox
              checked={
                !!(value && value instanceof Array
                  ? value.indexOf(option) > -1
                  : value === option)
              }
            />
            <ListItemText primary={option} />
          </StyledMenuItem>
        ))}
      </StyledSelect>
      {error && <StyledHelperText>This field is required.</StyledHelperText>}
    </>
  );
};

export default CustomizedSelect;
