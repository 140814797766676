import { createSelector } from "@reduxjs/toolkit";
import { getModule } from "../getModule/getModule";

export const getModuleOrder = createSelector(getModule, (modules) => {
  return modules.moduleOrderQueue;
});

export const getModules = createSelector(getModule, (modules) => {
  return modules.modules;
});

export const getModulesNames = createSelector(getModule, (modules) => {
  return modules.modulesNames;
});

export const getModulesObject = createSelector(getModule, (modules) => {
  return modules.dataModules;
});

export const getEnrichedModules = createSelector(getModule, (modules) => {
  return modules.enrichedModules;
});

export const getThirdPartyModules = createSelector(getModule, (modules) => {
  return modules.thirdPartyModules;
});

export const getModulesLoading = createSelector(getModule, (modules) => {
  return modules.loading;
});

export const getRulesLoading = createSelector(getModule, (modules) => {
  return modules.ruleLoading;
});

export const getTemplatesIntegrations = createSelector(getModule, (modules) => {
  return modules.integrations;
});

export const getTemplatesEnrichment = createSelector(getModule, (modules) => {
  return modules.enrichment;
});

export const getTemplatesLoading = createSelector(getModule, (modules) => {
  return modules.templatesLoading;
});

export const getAttributesLoading = createSelector(getModule, (modules) => {
  return modules.attributesLoading;
});
export const getResultsAttributesError = createSelector(
  getModule,
  (modules) => {
    return modules.resultAttributesError;
  }
);
export const getResultAttributes = createSelector(
  getModulesObject,
  (modules) => {
    if (!modules?.result) return [];
    return modules.result.responseAttributes;
  }
);
export const getResultRules = createSelector(getModulesObject, (modules) => {
  if (!modules?.result) return [];
  return modules.result.rules;
});
