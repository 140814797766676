import React, { FC, useState } from "react";
import {
  ButtonsWrap,
  NoItemsWrap,
  OptionsWrap,
  StyledButton,
  StyledCheckbox,
  ControlsWrap,
} from "./styles";

const FilterSelect: FC<any> = ({
  options,
  handleClose,
  filterKey,
  setFilterParams,
  setApplied,
}) => {
  const [checked, setChecked] = useState<string>("");

  const handleCFilter = (value: string) => {
    if (checked === value) {
      setChecked("");
    } else {
      setChecked(value);
    }
  };

  const applyFilters = () => {
    handleClose();
    setFilterParams({
      [filterKey]: checked,
    });
    if (checked.length) {
      setApplied(true);
    }
  };

  const clearFilters = () => {
    handleClose();
    setChecked("");
    setFilterParams({
      [filterKey]: "",
    });
    setApplied(false);
  };

  return (
    <>
      <OptionsWrap>
        <ButtonsWrap>
          {options?.length ? (
            options.map((option: any) => {
              return (
                <StyledButton
                  key={option}
                  onClick={() => handleCFilter(option)}
                  data-testid={`multiSelect_${option}Button`}
                >
                  <StyledCheckbox checked={checked === option} />
                  <p>{option}</p>
                </StyledButton>
              );
            })
          ) : (
            <NoItemsWrap data-testid="noItemsFound">No items found</NoItemsWrap>
          )}
        </ButtonsWrap>
      </OptionsWrap>
      {options?.length ? (
        <ControlsWrap data-testid="multiSelect_controls">
          <button
            onClick={() => clearFilters()}
            data-testid="multiSelect_clearAll"
          >
            Clear
          </button>
          <button
            onClick={() => applyFilters()}
            data-testid="multiSelect_apply"
          >
            Apply
          </button>
        </ControlsWrap>
      ) : (
        <></>
      )}
    </>
  );
};

export default FilterSelect;
