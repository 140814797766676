import React, {
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  AccordionAction,
  AccordionHeading,
  ServicesAccordionContainer,
  SubTitle,
  Title,
  Summary,
  StyledActionDots,
} from "./styles";
import { AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
  children?: ReactNode;
  heading: string;
  subHeading?: string;
  action?: ReactElement;
  icon?: ReactNode;
  expandedProp?: boolean;
  sortable?: boolean;
};

export function Accordion({
  children,
  heading,
  subHeading,
  action,
  expandedProp,
  sortable,
}: Readonly<Props>) {
  const [expanded, setExpanded] = useState(false);
  const handleExpand = (e: MouseEvent) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };
  const handle = (e: MouseEvent) => {
    e.stopPropagation();
  };
  useEffect(() => {
    if (expandedProp) setExpanded(expandedProp);
  }, [expandedProp]);
  return (
    <ServicesAccordionContainer
      disableGutters
      expanded={expanded}
      onClick={handleExpand}
    >
      <Summary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {action && <AccordionAction>{action}</AccordionAction>}
        {sortable && <StyledActionDots />}
        <AccordionHeading>
          <div>
            <Title>{heading}</Title>
            {subHeading && <SubTitle>{subHeading}</SubTitle>}
          </div>
        </AccordionHeading>
      </Summary>
      <AccordionDetails onClick={handle}>{children}</AccordionDetails>
    </ServicesAccordionContainer>
  );
}
