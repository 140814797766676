import React from "react";
import { CopyBlock, dracula } from "react-code-blocks";

import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { IconButton } from "@mui/material";
import { StyledTitle, Wrapper, BackLink, Content } from "./styles";

import { TriggerSettingsProps } from "./type";

export const TriggerSettings = (props: TriggerSettingsProps) => {
  const { handleClose, goBack, trigger } = props;

  return (
    <>
      <StyledTitle
        sx={{ m: 0, p: 2, borderBottom: "none !important" }}
        sidepadding={"32"}
      >
        <BackLink onClick={goBack}>
          <KeyboardArrowLeftIcon /> Back to New event
        </BackLink>
        {trigger.eventTriggerName} settings
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 47,
            top: 25,
            color: "var(--black)",
          }}
        >
          <CloseIcon />
        </IconButton>
      </StyledTitle>
      <Wrapper data-testid="create-event-modal">
        <Content>
          <CopyBlock
            codeContainerStyle={{ style: { "font-size": "12px" } }}
            language={"javascript"}
            text={JSON.stringify(trigger.dataAreas, null, 3)}
            showLineNumbers={false}
            theme={dracula as any}
            onCopy={() => ({})}
            codeBlock
            copied={false}
            wrapLongLines={true}
          />
        </Content>
      </Wrapper>
    </>
  );
};
