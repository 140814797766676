import React from "react";
import { TableRow, IconButton } from "@mui/material";
import { InfoLabel } from "../styles";
import { AttrName, StyledTableCell } from "./styles";
import { Tag } from "../../ProductGeneralInformation/styles";
import IconWrap from "../IconWrap";
import { ReactComponent as AttributeIcon } from "assets/icons/attribute.svg";
import { AttributesPayload } from "shared/api/products/types";

interface Props {
  item: AttributesPayload;
}

const fillmentTypesLabel: { [key: string]: string } = {
  BaseInformation: "Base Information",
  PostfillmentPrefill: "Postfillment Prefill",
  PostfillmentSensitive: "Postfillment Sensitive",
  Hidden: "Hidden",
  AdditionalInfo: "Additional Info",
  SystemContainer: "System Container",
};

const AttributeTableRow = ({ item }: Props) => {
  return (
    <TableRow sx={{ backgroundColor: "var(--white)" }}>
      <StyledTableCell sx={{ width: "5%" }}>
        <IconWrap
          color={item.isRequired ? "primary" : "secondary"}
          fillColor={item.isRequired ? "primary" : "secondary"}
          icon={<AttributeIcon />}
        />
      </StyledTableCell>
      <StyledTableCell sx={{ width: "20%" }}>
        <AttrName>
          <strong>{item.displayName}</strong>
        </AttrName>
      </StyledTableCell>
      <StyledTableCell sx={{ width: "10%" }}>
        <InfoLabel>{item.isRequired ? "Mandatory" : "Optional"}</InfoLabel>
      </StyledTableCell>
      <StyledTableCell>
        <InfoLabel>{item.valueType}</InfoLabel>
      </StyledTableCell>
      <StyledTableCell sx={{ width: "25%" }}>
        {item.attributeDescription}
      </StyledTableCell>
      <StyledTableCell sx={{ width: "13%" }} colSpan={5}>
        <Tag>{fillmentTypesLabel[item.fillmentType]}</Tag>
      </StyledTableCell>
      <StyledTableCell className="right" sx={{ width: "5%" }}>
        <IconButton />
      </StyledTableCell>
    </TableRow>
  );
};

export default AttributeTableRow;
