import React, { createContext, useMemo } from "react";

import { Title } from "../../styles";
import { Box } from "@mui/material";
import Distributions from "../../../Distribution";
import { AssociatedProductsContext } from "pages/EmbeddedHub/EmbeddedHub";

export const ProductsContext: any = createContext(null);

const Distribution: React.FC<any> = () => {
  const viewProducts = (id: string) => {
    console.log("list all associated products", id);
  };

  const associatedProductsValue = useMemo(
    () => ({
      viewProducts,
    }),
    [viewProducts]
  );
  return (
    <AssociatedProductsContext.Provider value={associatedProductsValue}>
      <Title>Distribution</Title>
      <Box data-testid="distributions" sx={{ marginTop: "25px" }}>
        <Distributions listProduct={false} />
      </Box>
    </AssociatedProductsContext.Provider>
  );
};

export default Distribution;
