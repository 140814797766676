import { toast } from "react-toastify";
import {
  ICONS,
  TOASTER_OPTIONS,
  TOASTER_TOP_OPTIONS,
  ToasterOptions,
} from "shared/lib/Toast";
import { ReactElement } from "react";

const showDefaultToast = (
  message: string | ReactElement,
  options?: ToasterOptions
) => {
  toast.success(message, {
    ...TOASTER_OPTIONS,
    ...options,
    ...(options?.iconAction ? { icon: ICONS[options.iconAction] } : null),
  });
};

const showInfoMessageToast = (
  message: string | ReactElement,
  options?: ToasterOptions
) => {
  toast.success(message, {
    ...TOASTER_TOP_OPTIONS,
    ...options,
    ...(options?.iconAction ? { icon: ICONS[options.iconAction] } : null),
  });
};

const showErrorToast = (
  error: string | ReactElement,
  options?: ToasterOptions
) => {
  toast.error(error, {
    ...TOASTER_TOP_OPTIONS,
    ...options,
    ...(options?.iconAction ? { icon: ICONS[options.iconAction] } : null),
  });
};

export const Toaster = {
  success: showDefaultToast,
  error: showErrorToast,
  infoMessage: showInfoMessageToast,
};
