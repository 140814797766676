import React, { useState, useEffect } from "react";
import { Range } from "react-date-range";
import { format } from "date-fns";

import { Box, Grid, CircularProgress } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormGroup, Wrapper } from "./styles";
import { PageTitle } from "shared/ui/pageTitle";
import Select from "shared/ui/Select";
import DateRangePicker from "shared/ui/DateRangePicker";
import {
  FunnelWidget,
  WidgetLocation,
  WidgetTraffic,
  CompletionByDistribution,
  CostByDistribution,
  CostPerWidget,
  WidgetCompletion,
  Binds,
  Quote,
  Lead,
} from "widgets/Analytics";

import { useProducts } from "features/products";
import { useGetWidgets, useWidgets } from "features/widgets";
import { useApplicationActions } from "features/applications";
import { StyledOutlinedInput, useAnalytics } from "features/analytics";

import { ProductResponse } from "shared/api/products/types";
import { Widget } from "shared/api/widgets/types";
import { Application } from "shared/api/applications/types";

const AnalyticsHub = () => {
  const [selectedProduct, setSelectedProduct] = useState<ProductResponse>();
  const [selectedWidget, setSelectedWidget] = useState<Widget>();
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [activeWidgets, setActiveWidgets] = useState<Widget[]>([]);
  const [ranges, setRanges] = useState<Range[]>();
  const [selectedDistribution, setSelectedDistribution] =
    useState<Application>();

  const { products, getProducts, loading } = useProducts();
  const { getWidgets } = useGetWidgets();
  const { data } = useWidgets();
  const { getWidgetDeploys, getWidgetDetails, getWidgetLocations } =
    useAnalytics();
  const { getApplications } = useApplicationActions();

  useEffect(() => {
    getProducts({
      limit: 1000,
      offset: 0,
    });
    getWidgets();
    getWidgetDeploys();
    getApplications({});
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      const widgets: Array<any> = [];
      data.map((item: any) => {
        if (item.products.length) {
          item.products.map((product: any) => {
            if (product.productId === selectedProduct.id) {
              widgets.push(item);
            }
          });
        }
      });
      setActiveWidgets(widgets);
    }
  }, [data, selectedProduct]);

  useEffect(() => {
    if (selectedProduct) {
      getWidgetLocations({
        ProductId: selectedProduct.id,
        WidgetId: selectedWidget?.id,
        FromDate: ranges?.[0].startDate,
        ToDate: ranges?.[0].endDate,
      });
      getWidgetDetails({
        ProductId: selectedProduct.id,
        WidgetId: selectedWidget?.id,
        FromDate: ranges?.[0].startDate,
        ToDate: ranges?.[0].endDate,
      });
    }
    getWidgetDeploys({
      ProductId: selectedProduct?.id,
      WidgetId: selectedWidget?.id,
      FromDate: ranges?.[0].startDate,
      ToDate: ranges?.[0].endDate,
      ApplicationId: selectedDistribution?.id,
    });
  }, [selectedWidget, selectedProduct, ranges, selectedDistribution]);

  const handleSelectProduct = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const selectedProduct: ProductResponse = products.products.filter(
      (item: ProductResponse) => item.name === ev.target.value
    )[0];
    setSelectedProduct(selectedProduct);
  };

  const handleSelectWidget = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const selectedWidget: Widget = activeWidgets.filter(
      (item: Widget) => item.name === ev.target.value
    )[0];
    setSelectedWidget(selectedWidget);
  };

  const handleSelectDistribution = (application: Application) => {
    setSelectedDistribution(application);
  };

  const handleSave = (data: Range[]) => {
    setRanges(data);
    setOpenDateRangePicker(false);
  };

  const renderIconComponent = (props: any) =>
    loading ? (
      <CircularProgress {...props} size="1rem" />
    ) : (
      <KeyboardArrowDownIcon {...props} />
    );

  const endDate = ranges?.[0].endDate;
  const startDate = ranges?.[0].startDate;

  const formatString = "MMM, EEE dd";
  const rangesStartDate = startDate && format(startDate as Date, formatString);
  const rangesEndDate = endDate && format(endDate as Date, formatString);

  return (
    <Box data-testid="analytics-page">
      <PageTitle title={"Analytics Hub"} />
      <Grid container spacing={3}>
        <Grid item md={4}>
          <FormGroup>
            <StyledOutlinedInput
              placeholder="Date range"
              value={
                rangesStartDate &&
                rangesEndDate &&
                `${rangesStartDate} - ${rangesEndDate}`
              }
              onClick={() => setOpenDateRangePicker(!openDateRangePicker)}
            />
            {openDateRangePicker && (
              <DateRangePicker
                onCancel={() => setOpenDateRangePicker(false)}
                onSave={handleSave}
              />
            )}
          </FormGroup>
        </Grid>
        <Grid item md={4}>
          <Select
            id={"selectedProduct"}
            value={selectedProduct?.name || ""}
            onChange={handleSelectProduct}
            options={products.products.map(
              (item: ProductResponse) => item.name
            )}
            placeholder={"Select Product"}
            sx={{
              background: "#ffffff",
              ".MuiSelect-select": {
                color: "#383874",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "1px",
              },
            }}
            disabled={loading}
            IconComponent={renderIconComponent}
          />
        </Grid>
        <Grid item md={4}>
          <Select
            onChange={handleSelectWidget}
            options={activeWidgets?.map((item: Widget) => item.name)}
            id={"selectedWidget"}
            value={selectedWidget?.name || ""}
            disabled={!selectedProduct}
            placeholder={"Select Widget"}
            sx={{
              background: "#ffffff",
              "&.Mui-disabled": { borderColor: "rgba(0, 0, 0, 0.23)" },
              ".MuiSelect-select": {
                color: "#383874",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "1px",
              },
            }}
          />
        </Grid>
      </Grid>
      <Wrapper>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <FunnelWidget
              ranges={
                rangesStartDate &&
                rangesEndDate &&
                `${rangesStartDate} - ${rangesEndDate}`
              }
            />
          </Grid>
          <Grid item md={6}>
            <WidgetLocation />
          </Grid>
          <Grid item md={12}>
            <WidgetTraffic
              ranges={
                rangesStartDate &&
                rangesEndDate &&
                `${rangesStartDate} - ${rangesEndDate}`
              }
              handleSelectDistribution={handleSelectDistribution}
              distribution={selectedDistribution}
            />
          </Grid>
          <Grid item md={6}>
            <CompletionByDistribution />
          </Grid>
          <Grid item md={6}>
            <CostByDistribution />
          </Grid>
          <Grid item md={6}>
            <CostPerWidget />
          </Grid>
          <Grid item md={6}>
            <WidgetCompletion
              ranges={
                rangesStartDate &&
                rangesEndDate &&
                `${rangesStartDate} - ${rangesEndDate}`
              }
            />
          </Grid>
          <Grid item md={4}>
            <Binds
              ranges={
                rangesStartDate &&
                rangesEndDate &&
                `${rangesStartDate} - ${rangesEndDate}`
              }
            />
          </Grid>
          <Grid item md={4}>
            <Quote
              ranges={
                rangesStartDate &&
                rangesEndDate &&
                `${rangesStartDate} - ${rangesEndDate}`
              }
            />
          </Grid>
          <Grid item md={4}>
            <Lead
              ranges={
                rangesStartDate &&
                rangesEndDate &&
                `${rangesStartDate} - ${rangesEndDate}`
              }
            />
          </Grid>
        </Grid>
      </Wrapper>
    </Box>
  );
};

export default AnalyticsHub;
