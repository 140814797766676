import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";

import { initialColumns } from "./types";

import TableControls from "../TableControls";
import Table from "../Table";
import TablePaper from "shared/ui/TablePaper";

import { AssociateProductData } from "features/associatedProducts/ui/types";
import { OrderTypes } from "shared/config/types/Sorting";

import { sortByOrder, searchByValue } from "utils/array";
import { useProducts } from "features/associatedProducts/model/hooks/useProducts";

interface AssociatedProductsProps {
  products: AssociateProductData[];
}

const AssociatedProducts = ({ products }: AssociatedProductsProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState<OrderTypes | null>(null);
  const [currentProducts, setCurrentProducts] =
    useState<AssociateProductData[]>(products);
  const navigate = useNavigate();
  const { updateProductVersion } = useProducts();

  const handleSearch = (searchQuery: string) => {
    if (searchQuery) {
      const searchResult = searchByValue(
        currentProducts,
        "productName",
        searchQuery
      );
      if (searchResult.length) setCurrentProducts(searchResult);
    }
  };

  const [searchQuery] = useDebounce(searchValue, 2000);

  useEffect(() => {
    if (searchQuery) {
      handleSearch(searchQuery);
    } else setCurrentProducts(products);
  }, [searchQuery]);

  useEffect(() => {
    setCurrentProducts(products);
  }, [products]);

  useEffect(() => {
    if (order) {
      const filters = {
        name: order.label,
        sortKey: order.type,
      };
      const sorted = sortByOrder([...currentProducts], filters);
      if (sorted.length > 0) setCurrentProducts(sorted);
    }
  }, [order]);

  const handleDeactivate = (record: AssociateProductData) => {
    if (record.productStatus === "Published") {
      updateProductVersion(record);
    }
    navigate(`/create_new_product/${record.id}/?step=3`);
  };

  return (
    <TablePaper>
      <TableControls
        activeTab="Products"
        searchValue={searchValue}
        handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
        hideCreate={true}
      />
      <Table
        rows={currentProducts}
        columns={initialColumns}
        isCompact={true}
        rowHasChip={true}
        chipName="Deactivate"
        onEdit={handleDeactivate}
        chipIndex={5}
        hideCheckbox={true}
        setSortOrder={(order: OrderTypes) => setOrder(order)}
      />
    </TablePaper>
  );
};

export default AssociatedProducts;
