import React, { useContext, useEffect, useState } from "react";

import { CreateProductContext } from "pages/CreateProduct";

import {
  Header,
  StyledButton,
  ButtonsGroup,
  Title,
  Nav,
  StyledLink,
} from "./styles";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import EastIcon from "@mui/icons-material/East";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getPublishLoader } from "entities/products";

interface NavProps {
  step: number;
  name: string;
}

const sidebarNav: Array<NavProps> = [
  {
    step: 1,
    name: "Product Setup",
  },
  {
    step: 2,
    name: "Data Elements",
  },
  {
    step: 3,
    name: "Integrations",
  },
  {
    step: 4,
    name: "Outcomes & Services",
  },
  {
    step: 5,
    name: "Events & Triggers",
  },
  {
    step: 6,
    name: "Distribution",
  },
  {
    step: 7,
    name: "Embedded Hub",
  },
  {
    step: 8,
    name: "Confirmation",
  },
];

enum Intervals {
  YEARS = "years",
  MONTHS = "months",
  DAYS = "days",
  HOURS = "hours",
  MINUTES = "minutes",
  SECONDS = "seconds",
}

const CreateProductHeader: React.FC<any> = () => {
  const {
    saveAndQuit,
    handleNext,
    category,
    name,
    step,
    product,
    handleBack,
  }: any = useContext(CreateProductContext);

  const [lastSaved, setLastSaved] = useState<number>();
  const [intervalSpead, setIntervalSpead] = useState<string>();
  const isPublishedLoading = useAppSelector(getPublishLoader);

  useEffect(() => {
    if (product?.updatedDateUtc) {
      const last_saved = timeSince(new Date());
      setLastSaved(last_saved);
      setInterval(() => {
        const last_saved = timeSince(new Date());
        setLastSaved(last_saved);
      }, 1000 * 60);
    }
  }, [product]);

  const timeSince = (date: any) => {
    const lastupdate: any = new Date(product.updatedDateUtc);
    const seconds = Math.floor((date - lastupdate) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      setIntervalSpead(Intervals.YEARS);
      return Math.floor(interval);
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      setIntervalSpead(Intervals.MONTHS);
      return Math.floor(interval);
    }
    interval = seconds / 86400;
    if (interval > 1) {
      setIntervalSpead(Intervals.DAYS);
      return Math.floor(interval);
    }
    interval = seconds / 3600;
    if (interval > 1) {
      setIntervalSpead(Intervals.HOURS);
      return Math.floor(interval);
    }
    interval = seconds / 60;
    if (interval > 1) {
      setIntervalSpead(Intervals.MINUTES);
      return Math.floor(interval);
    } else {
      setIntervalSpead(Intervals.SECONDS);
      return Math.floor(seconds);
    }
  };

  return (
    <>
      <Header data-testid="createProductPage_header">
        <StyledButton variant="outlined" onClick={saveAndQuit}>
          Save & Quit
        </StyledButton>
        <Title>Product Configurator</Title>
        <ButtonsGroup>
          {lastSaved ? (
            <p>
              Last saved : {lastSaved} {intervalSpead} ago
            </p>
          ) : null}
          <StyledButton
            variant="contained"
            onClick={handleNext}
            disabled={
              (step === 1 && (!category || !name)) ||
              (step === 8 && isPublishedLoading)
            }
          >
            {step === 8 ? (
              "Publish"
            ) : (
              <>
                Next <EastIcon sx={{ marginLeft: "7px" }} />
              </>
            )}
          </StyledButton>
        </ButtonsGroup>
      </Header>
      <Nav step={step}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {sidebarNav.map((item: NavProps) => {
            const step_class = step > item.step ? "visited" : "";
            return (
              <StyledLink
                key={item.step}
                color="inherit"
                onClick={step > item.step ? handleBack : null}
                className={step === item.step ? "active" : step_class}
              >
                {item.name}
              </StyledLink>
            );
          })}
        </Breadcrumbs>
      </Nav>
    </>
  );
};

export default CreateProductHeader;
