import { all, call } from "redux-saga/effects";

import { AppExceptionsWatcher } from "./appSaga";
import { AuthWatcher } from "entities/auth";
import { AlertsWatcher } from "entities/alerts";
import { DraftItemWatcher } from "entities/draftItems";
import { ModalsWatcher } from "entities/modals";
import { ModuleTemplatesWatcher } from "entities/moduleTemplates";
import { ModulesWatcher } from "entities/modules";
import { EventsWatcher } from "entities/events";
import { TemplatesWatcher } from "entities/templates";
import { ApplicationsWatcher } from "entities/applications";
import { WidgetsWatcher } from "entities/widgets";
import { UsersWatcher } from "entities/users";
import { ProductCategoryWatcher } from "entities/productCategories";
import { ProductsWatcher } from "entities/products";
import { RequestsWatcher } from "entities/requests";
import { KpisWatcher } from "entities/kpis";
import { FilesWatcher } from "entities/files";
import { PlatformNotificationsWatcher } from "entities/platformNotifications";
import { ProgramsWatcher } from "entities/programs";
import { AnalyticsWatcher } from "entities/analytics";

export default function* rootSaga() {
  yield all([
    call(AppExceptionsWatcher),
    call(AuthWatcher),
    call(ProductsWatcher),
    call(AlertsWatcher),
    call(DraftItemWatcher),
    call(ModalsWatcher),
    call(ModulesWatcher),
    call(EventsWatcher),
    call(TemplatesWatcher),
    call(ApplicationsWatcher),
    call(WidgetsWatcher),
    call(UsersWatcher),
    call(ProductCategoryWatcher),
    call(RequestsWatcher),
    call(KpisWatcher),
    call(FilesWatcher),
    call(ModuleTemplatesWatcher),
    call(PlatformNotificationsWatcher),
    call(ProgramsWatcher),
    call(AnalyticsWatcher),
  ]);
}
