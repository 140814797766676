import React, { useState, useEffect } from "react";

import { format } from "date-fns";
import { useDebounce } from "use-debounce";

import { Table, TableBody, TableCell, TablePagination } from "@mui/material";

import { IXTableRow, TableHeader } from "shared/ui/Table";
import { ActionButton, ActionButtonsWrapper } from "shared/ui/ActionButton";
import TablePlaceholder from "shared/ui/TablePlaceholder";
import TableControls from "shared/ui/TableControls/TableControls";
import { TableContainerWrapper } from "shared/ui/Table/styles";
import TablePaper from "shared/ui/TablePaper";
import { TableSkeleton } from "shared/ui/TableSkeleton";
import TableSorting, { SortRenderProps } from "./TableSorting";
import Button from "shared/ui/Button";
import DeleteSimpleModal from "components/Modals/DeleteSimpleModal";
import { Divider } from "components/Table/TableActions/styles";

import { initialColumns } from "./types";
import { ActionButtonTypes } from "shared/ui/ActionButton/types";
import { OrderTypes } from "shared/config/types/Sorting";
import { SORT } from "shared/config/enums/Sorting";
import { useEvents } from "features/events";
import { PaginationParams } from "api/types";
import { Event } from "shared/api/events/types";
import { DateFormat } from "shared/config/enums/DateFormat";
import { AddEventModal } from "features/add-event-modal";

const initialPagingData = {
  limit: 100,
  offset: 0,
  SearchExpression: "",
};

const EventsAndTriggersTable: React.FC<any> = () => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<OrderTypes>({ type: SORT.ASC, label: "" });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [pagingParams, setPagingParams] =
    useState<PaginationParams>(initialPagingData);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event>();

  const [rows, setRows] = useState<Event[]>([]);
  const [allEvents, setAllEvents] = useState<Event[]>([]);
  const [product, setProduct] = useState<any>();
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const [searchQuery] = useDebounce(searchValue, 2000);

  const { isLoading, events, total, getAllEvents, deleteEvent, cloneEvent } =
    useEvents();

  useEffect(() => {
    const sorting = {
      "SortingPreferences.SortingProperty": order.label,
      "SortingPreferences.Kind": order.type,
    };
    if (pagingParams)
      getAllEvents(
        {
          ...pagingParams,
          ...(order.label && sorting),
        },
        product?.productId
      );
  }, [pagingParams, order, rowsPerPage]);

  useEffect(() => {
    const sorting = {
      "SortingPreferences.SortingProperty": order.label,
      "SortingPreferences.Kind": order.type,
    };
    getAllEvents(
      {
        ...initialPagingData,
        ...(order.label && sorting),
      },
      product?.productId
    );
  }, [product]);

  useEffect(() => {
    if (searchQuery) {
      setPagingParams({
        ...initialPagingData,
        SearchExpression: searchQuery,
      });
    } else setPagingParams({ ...initialPagingData, SearchExpression: "" });
  }, [searchQuery]);

  useEffect(() => {
    const eventRows = events.slice(0, 10);
    setRows(eventRows);
    if (!allEvents.length) setAllEvents(events);
  }, [events]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    if (setPagingParams) {
      setPagingParams({
        ...pagingParams,
        offset: rowsPerPage * newPage,
      });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    if (setPagingParams) {
      setPagingParams({
        ...pagingParams,
        limit: Number(event.target.value),
        offset: 0,
      });
    }
  };
  const deleteSelectedEvent = () => {
    if (selectedEvent)
      deleteEvent(selectedEvent.id, selectedEvent.eventTrigger);
    setOpenDeleteModal(false);
  };

  const handleDelete = (item: Event) => {
    setOpenDeleteModal(true);
    setSelectedEvent(item);
  };

  const handleEdit = (item: Event) => {
    setOpenCreateModal(true);
    setSelectedEvent(item);
  };

  const handleCloseModal = () => {
    setOpenCreateModal(false);
    setSelectedEvent(undefined);
  };

  const handleClone = (item: Event) => {
    cloneEvent(item.id, callback);
  };

  const callback = (data: any) => {
    setOpenCreateModal(true);
    setSelectedEvent(data);
  };

  return (
    <>
      <TablePaper>
        <TableControls
          activeTab={"Events"}
          searchValue={searchValue}
          handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchValue(e.target.value)
          }
        >
          <Button
            variant="contained"
            onClick={() => setOpenCreateModal(true)}
            data-testid={"create-event-button"}
          >
            Create New Event
          </Button>
        </TableControls>
        <TableContainerWrapper data-testid="tableContainer" size={"lg"}>
          <Table stickyHeader>
            <TableHeader
              hideCheckbox={true}
              columns={initialColumns}
              rowCount={events?.length}
            >
              {(props: SortRenderProps) => (
                <TableSorting
                  rows={allEvents}
                  setOrder={(data: OrderTypes) => setOrder(data)}
                  order={order}
                  setProduct={(product: any) => setProduct(product)}
                  {...props}
                />
              )}
            </TableHeader>
            <TableBody data-testid="tableBody">
              {isLoading && (
                <TableSkeleton lines={3} columns={initialColumns} />
              )}
              {!isLoading && events?.length === 0 && (
                <TablePlaceholder colSpan={initialColumns.length} />
              )}
              {!isLoading &&
                rows?.length > 0 &&
                rows.map((row) => {
                  return (
                    <IXTableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      data-testid={`tableRow_${row.id}`}
                    >
                      <TableCell>{row.eventTrigger}</TableCell>
                      <TableCell sx={{ fontWeight: 700 }}>
                        {row.actionConfiguration.actionType}
                      </TableCell>
                      <TableCell>
                        {
                          row.actionConfiguration.emailActionConfiguration
                            ?.tenantNotificationTemplateName
                        }
                      </TableCell>
                      <TableCell>{row.productName}</TableCell>
                      <TableCell>
                        {row.createdDateUtc &&
                          format(
                            new Date(row.createdDateUtc),
                            DateFormat.DEFAULT
                          )}
                      </TableCell>
                      <TableCell>
                        {row.updatedDateUtc &&
                          format(
                            new Date(row.updatedDateUtc),
                            DateFormat.DEFAULT
                          )}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <ActionButtonsWrapper>
                          <ActionButton
                            title={"Clone"}
                            type={ActionButtonTypes.CLONE}
                            onClick={() => handleClone(row)}
                          />
                          <Divider />
                          <ActionButton
                            title={"Edit"}
                            type={ActionButtonTypes.EDIT}
                            onClick={() => handleEdit(row)}
                          />
                          <ActionButton
                            title={"Delete"}
                            type={ActionButtonTypes.DELETE}
                            onClick={() => handleDelete(row)}
                          />
                        </ActionButtonsWrapper>
                      </TableCell>
                    </IXTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainerWrapper>
        <TablePagination
          sx={{ marginTop: "30px" }}
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          data-testid="table_pagination"
        />
      </TablePaper>
      <DeleteSimpleModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(!openDeleteModal)}
        onSubmit={deleteSelectedEvent}
        title={`Are you sure you want to delete Event "${selectedEvent?.eventTrigger}"?`}
      />
      <AddEventModal
        open={openCreateModal}
        handleClose={handleCloseModal}
        event={selectedEvent}
      />
    </>
  );
};

export default EventsAndTriggersTable;
