import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import RequestsTable from "../Table";
import TableControls from "../TableControls";
import CreateRequestModal from "../Modals/CreateRequest";
import RestoreModal from "../Modals/RestoreModal";
import TablePaper from "shared/ui/TablePaper";
import { modalsActions } from "entities/modals";
import { initialColumns } from "./types";
import { Column } from "../Table/types";
import { sortByOrder } from "utils/array";
import { OrderTypes } from "shared/config/types/Sorting";
import { RequestSchema } from "shared/api/requests/types";
import { useRequests } from "features/requests/model/hooks/useRequests";
import {
  getNewRequestList,
  getCompletedRequestsList,
  getArchivedRequestList,
  countActive,
  countCompleted,
  countArchived,
  filteredCount,
  getDeletedRequest,
} from "entities/requests";
import { useAppSelector } from "redux/store/hooks/hooks";
import { PaginationParams } from "api/types";

interface RequestsProps {
  status: string;
}

const initialPagingData = {
  showArchived: false,
  limit: 10,
  offset: 0,
  page: 0,
  SearchExpression: "",
  filter: "Statuses=New&Statuses=Pending&Statuses=InProgress",
};

const initialCompletedPagingData = {
  showArchived: false,
  limit: 10,
  offset: 0,
  page: 0,
  SearchExpression: "",
  filter: "Statuses=Completed",
};

const initialArchivedPagingData = {
  showArchived: true,
  limit: 10,
  offset: 0,
  page: 0,
  SearchExpression: "",
  filter:
    "Statuses=New&Statuses=Pending&Statuses=InProgress&Statuses=Completed",
};

const initialFilterParams = {
  states: ["New", "InProgress", "Pending"],
  status: [],
};

const Requests = ({ status }: RequestsProps) => {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState<Column[]>(initialColumns);
  const [renderedItems, setRenderedItems] = useState<RequestSchema[]>([]);
  const [requestToRestore, setRequestToRestore] = useState<RequestSchema>();
  const [openCreateRequest, setOpenCreateRequest] = useState(false);
  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const [currentTab, setCurrentTab] = useState("Requests");
  const [filterParams, setFilterParams] = useState(initialFilterParams);
  const [searchValue, setSearchValue] = useState("");
  const [pagingParams, setPagingParams] =
    useState<PaginationParams>(initialPagingData);
  const newRequests = useAppSelector(getNewRequestList);
  const completedRequests = useAppSelector(getCompletedRequestsList);
  const archivedRequests = useAppSelector(getArchivedRequestList);
  const [selectedRequest, setSelectedRequest] = useState<RequestSchema>();
  const [order, setOrder] = useState<OrderTypes | null>(null);
  const [newRequestCount, setNewRequestCount] = useState(0);
  const count = useAppSelector(countActive);
  const completedCount = useAppSelector(countCompleted);
  const archivedCount = useAppSelector(countArchived);
  const totalFilteredCount = useAppSelector(filteredCount);
  const deletedRequest = useAppSelector(getDeletedRequest);
  const [searchQuery] = useDebounce(searchValue, 2000);

  const {
    getRequests,
    getRequestTypes,
    restoreRequest,
    countActiveRequest,
    countCompletedRequest,
    getCompletedRequests,
    getArchivedRequests,
  } = useRequests();

  useEffect(() => {
    getRequestTypes();
    getRequests(pagingParams);
    countActiveRequest();
    countCompletedRequest();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      if (status === "Completed") {
        setPagingParams({
          ...initialCompletedPagingData,
          SearchExpression: searchQuery,
        });
      } else {
        setPagingParams({
          ...initialPagingData,
          SearchExpression: searchQuery,
        });
      }
    }
    if (!searchQuery && status === "Completed") {
      setPagingParams({
        ...initialCompletedPagingData,
        SearchExpression: "",
      });
    } else setPagingParams({ ...initialPagingData, SearchExpression: "" });
  }, [searchQuery]);

  useEffect(() => {
    if (count !== newRequestCount && status === "New") {
      setNewRequestCount(count);
    }
    if (searchQuery && newRequestCount !== 0) setNewRequestCount(0);
  }, [count, searchQuery]);

  useEffect(() => {
    if (completedCount !== newRequestCount && status === "Completed") {
      setNewRequestCount(completedCount);
    }
    if (searchQuery && newRequestCount !== 0) setNewRequestCount(0);
  }, [completedCount, searchQuery]);

  useEffect(() => {
    if (archivedCount !== newRequestCount && status === "Archived") {
      setNewRequestCount(archivedCount);
    }
    if (searchQuery && newRequestCount !== 0) setNewRequestCount(0);
  }, [archivedCount, searchQuery]);

  useEffect(() => {
    if (status == "New") {
      setRenderedItems([...newRequests]);
    }
  }, [newRequests]);

  useEffect(() => {
    if (status == "Completed") {
      setRenderedItems([...completedRequests]);
    }
  }, [completedRequests]);

  useEffect(() => {
    if (status == "Archived") {
      setRenderedItems([...archivedRequests]);
    }
  }, [archivedRequests]);

  useEffect(() => {
    if (status === "New") {
      setNewRequestCount(totalFilteredCount);
    }
  }, [totalFilteredCount]);

  useEffect(() => {
    if (deletedRequest) getRequestData();
  }, [deletedRequest]);

  useEffect(() => {
    if (filterParams.status.length) {
      let output = "";
      for (const status of filterParams.status) {
        output = output.concat(`&Statuses=${status}`);
      }
      if (output !== pagingParams.filter) {
        setPagingParams({ ...initialPagingData, filter: output });
      } else setPagingParams({ ...pagingParams, filter: output });
    } else {
      setPagingParams(initialPagingData);
      resetCount();
    }
  }, [filterParams]);

  useEffect(() => {
    if (order) {
      const filters = {
        name: order.label,
        sortKey: order.type,
      };
      const sorted = sortByOrder([...renderedItems], filters);
      if (sorted.length > 0) setRenderedItems(sorted);
    }
  }, [order]);

  useEffect(() => {
    switch (status) {
      case "Completed":
        setPagingParams(initialCompletedPagingData);
        setNewRequestCount(completedCount);
        setCurrentTab("Requests");
        setColumns(setFilterColumns(false));
        setRenderedItems([...completedRequests]);
        break;
      case "Archived":
        setNewRequestCount(archivedCount);
        setPagingParams(initialArchivedPagingData);
        setCurrentTab("Archive");
        setColumns(setFilterColumns(false));
        setRenderedItems([...archivedRequests]);
        break;
      default:
        setPagingParams(initialPagingData);
        setNewRequestCount(count);
        setCurrentTab("Requests");
        setColumns(setFilterColumns(true));
        setRenderedItems([...newRequests]);
        break;
    }
  }, [status]);

  useEffect(() => {
    if (pagingParams) getRequestData();
  }, [pagingParams]);

  const getRequestData = () => {
    switch (status) {
      case "Completed":
        getCompletedRequests(pagingParams);
        break;
      case "Archived":
        getArchivedRequests(pagingParams);
        break;
      default:
        getRequests(pagingParams);
        break;
    }
  };

  const resetCount = () => {
    switch (status) {
      case "Completed":
        setNewRequestCount(completedCount);
        break;
      case "Archived":
        setNewRequestCount(archivedCount);
        break;
      default:
        setNewRequestCount(count);
        break;
    }
  };

  const setFilterColumns = (filter: boolean) => {
    const output: Column[] = [];
    [...initialColumns].map((item: Column) => {
      if (item["id"] === "requestStatus") {
        item["filtering"] = filter;
        output.push(item);
      } else output.push(item);
    });
    return output;
  };

  const openSelectedRequest = (request: RequestSchema) => {
    setSelectedRequest(request);
    setOpenCreateRequest(!openCreateRequest);
  };

  const openCreateRequestModal = () => {
    setSelectedRequest(undefined);
    setOpenCreateRequest(!openCreateRequest);
  };

  const handleDeleteOrRestore = (request: RequestSchema) => {
    if (status !== "Archived") {
      dispatch({
        type: modalsActions.OPEN_MODAL,
        data: {
          delete: {
            type: "request",
            isModalOpen: true,
            data: request,
          },
        },
      });
    } else {
      setRequestToRestore(request);
      setOpenRestoreModal(!openRestoreModal);
    }
  };

  const undeleteRequest = () => {
    if (requestToRestore) {
      restoreRequest(requestToRestore.id, callback);
    }
    setOpenRestoreModal(!openRestoreModal);
  };

  const callback = (data: any) => {
    if (requestToRestore && requestToRestore.id === data.id) {
      getRequestData();
    }
  };

  const setSortOrder = (order: OrderTypes) => {
    setOrder(order);
  };

  const requestCreated = (created: boolean) => {
    if (created) getRequestData();
  };

  return (
    <TablePaper>
      <TableControls
        activeTab={currentTab}
        handleOpen={openCreateRequestModal}
        searchValue={searchValue}
        handleSearch={(e: any) => setSearchValue(e.target.value)}
        hideCreate={status === "Archived"}
      />
      <RequestsTable
        rows={renderedItems}
        columns={columns}
        activeTab={currentTab}
        onView={(request: RequestSchema) => openSelectedRequest(request)}
        onDelete={(request: RequestSchema) => handleDeleteOrRestore(request)}
        onRestore={(request: RequestSchema) => handleDeleteOrRestore(request)}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        rowHasChip={false}
        chipIndex={0}
        hideCheckbox={true}
        setSortOrder={setSortOrder}
        pagingParams={pagingParams}
        setPagingParams={setPagingParams}
        totalCount={newRequestCount}
      />
      <CreateRequestModal
        open={openCreateRequest}
        handleClose={() => setOpenCreateRequest(!openCreateRequest)}
        request={selectedRequest}
        requestCategory={"Request"}
        requestCreated={requestCreated}
      />
      <RestoreModal
        onClose={() => setOpenRestoreModal(false)}
        open={openRestoreModal}
        restoreType={"Request"}
        handleSubmit={undeleteRequest}
      />
    </TablePaper>
  );
};

export default Requests;
