import { takeLatest, put, call, select } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  loadNotifications,
  loadNotification,
  loadCount,
  loadAlerts,
} from "entities/platformNotifications/model/slice/platformNotificationsSlice";
import {
  getAlerts,
  platformNotificationsActions,
} from "entities/platformNotifications";

import { ReduxAction } from "redux/sagas/types";

import PlatformNotificationsApi from "shared/api/platformNotifications/platformNotificationsApi";
import { PlatformNotificationsRespose } from "shared/api/platformNotifications/types";

import { Toaster } from "shared/ui/Toast/Toast";

import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

export function* getPlatformNotificationsWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<PlatformNotificationsRespose> = yield call(
      PlatformNotificationsApi.getPlatformNotifications,
      action.data
    );
    yield put(loadNotifications(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getPlatformNotificationWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<PlatformNotificationsRespose> = yield call(
      PlatformNotificationsApi.getPlatformNotification,
      action.data
    );
    yield put(loadNotification(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* deletePlatformNotificationWorker(action: ReduxAction) {
  try {
    const response: AxiosResponse<PlatformNotificationsRespose> = yield call(
      PlatformNotificationsApi.deletePlatformNotification,
      action.data
    );
    action.callback(response.data);
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* readPlatformNotificationWorker(action: ReduxAction) {
  try {
    yield call(PlatformNotificationsApi.readPlatformNotification, action.data);
    yield put({
      type: platformNotificationsActions.GET_NEW_PLATFORM_NOTIFICATIONS_COUNT,
    });
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getNewPlatformNotificationsCountWorker() {
  try {
    const response: AxiosResponse<PlatformNotificationsRespose> = yield call(
      PlatformNotificationsApi.getPlatformNotificationsCount
    );
    yield put(loadCount(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getNewAlertNotificationsWorker() {
  try {
    const response: AxiosResponse<PlatformNotificationsRespose> = yield call(
      PlatformNotificationsApi.getAlertNotifications
    );
    const alerts: PlatformNotificationsRespose = yield select(getAlerts);

    if (alerts.totalCount && alerts.totalCount !== response.data.totalCount) {
      const difference = response.data.items[0];
      Toaster.infoMessage(difference.title);
    }

    yield put(loadAlerts(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* PlatformNotificationsWatcher() {
  yield takeLatest(
    platformNotificationsActions.GET_PLATFORM_NOTIFICATIONS,
    getPlatformNotificationsWorker
  );
  yield takeLatest(
    platformNotificationsActions.GET_PLATFORM_NOTIFICATION,
    getPlatformNotificationWorker
  );
  yield takeLatest(
    platformNotificationsActions.DELETE_PLATFORM_NOTIFICATION,
    deletePlatformNotificationWorker
  );
  yield takeLatest(
    platformNotificationsActions.READ_PLATFORM_NOTIFICATION,
    readPlatformNotificationWorker
  );
  yield takeLatest(
    platformNotificationsActions.GET_NEW_PLATFORM_NOTIFICATIONS_COUNT,
    getNewPlatformNotificationsCountWorker
  );
  yield takeLatest(
    platformNotificationsActions.GET_NEW_ALERT_NOTIFICATIONS,
    getNewAlertNotificationsWorker
  );
}
