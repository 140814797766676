import styled from "styled-components";
import { Box, Dialog } from "@mui/material";

export const ModalConfiguration = styled(Dialog)`
  padding: 10px;
  .MuiBackdrop-root {
    background-color: #ffffff;
  }
  .MuiPaper-root {
    border-radius: 24px;
    box-shadow: none;
  }
`;
export const ModalConfigurationWrapper = styled(Box)`
  background: var(--ghost);
  width: 100%;
  height: 100%;
  padding: 20px;
`;
export const ModalConfigurationHeader = styled(Box)`
  height: 70px;
  & > div {
    padding: 0;
  }
`;
