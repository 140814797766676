import styled from "styled-components";
import { Button } from "@mui/material";

export const TableWrapper = styled.div`
  padding: 24px;
  margin-top: 32px;
  background: #ffffff;
  border-radius: 20px;
`;
export const ScrollWrapper = styled.div`
  overflow: auto;
  max-height: calc(100vh - 550px);
  &.left {
    max-height: calc(100vh - 665px);
  }
`;
export const TableActionsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const AddAttrButton = styled(Button)`
  &.MuiButton-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 2px;
    border-radius: 100px;
    padding: 16px 38px;
    text-transform: none;
  }
  &.MuiButton-outlined {
    border: 1px solid #9696a9;
    color: var(--secondary);
  }
  &.MuiButton-contained {
    background: var(--secondary);
    color: #ffffff;
  }
`;

export const AddAttrDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  &.left {
    justify-content: flex-start;
  }
`;
export const Block = styled.div`
  max-height: calc(100% - 100px);
  max-width: 1289px;
  margin: auto;
`;
