import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "shared/ui/Modal";
import Button from "shared/ui/Button";
import FormGroup from "components/FormComponents/FormGroup";
import { InputBlock, StyledTypography } from "./styles";
import { DistributeTable } from "./DistributeTable";
import { WidgetsTable } from "./WidgetsTable";
import { useKpis } from "features/kpis/model/hooks/useKpis";
import { KpiPayload } from "shared/api/kpis/types";
import { getCurrentKpiItem, getEntityKPI } from "entities/kpis";
import { useAppSelector } from "redux/store/hooks/hooks";
import { Toaster } from "shared/ui/Toast/Toast";
import { useDebounce } from "use-debounce";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  programName: string;
  productName: string;
  productId: string;
  programId: string;
}

const defaultValues = {
  program_kpi: "",
  product_kpi: "",
};

export const EditKpi = (props: ModalProps) => {
  const { handleClose, open, programName, productName, productId, programId } =
    props;
  const { control, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: { ...defaultValues },
  });
  const programKpi = useAppSelector(getCurrentKpiItem);
  const entityKpi = useAppSelector(getEntityKPI(productId, productId));
  const { pushKpi } = useKpis();

  const programKpiWatcher = watch("program_kpi");
  const productKpiWatcher = watch("product_kpi");

  const [userInputProgramKpi] = useDebounce(programKpiWatcher, 500);
  const [userInputProductKpi] = useDebounce(productKpiWatcher, 500);

  useEffect(() => {
    if (programKpi) {
      if (Object.keys(programKpi).length) {
        setValue("program_kpi", String(programKpi.value));
      }
    }
  }, [programKpi]);

  useEffect(() => {
    if (entityKpi) setValue("product_kpi", String(entityKpi));
  }, [entityKpi]);

  useEffect(() => {
    if (userInputProgramKpi && isNaN(Number(userInputProgramKpi))) {
      if (userInputProgramKpi !== "undefined") {
        Toaster.error("Please provide a valid number");
      }
      resetKpis();
    }
  }, [userInputProgramKpi]);

  useEffect(() => {
    if (userInputProductKpi && isNaN(Number(userInputProductKpi))) {
      Toaster.error("Please provide a valid number");
      resetKpis();
    }
  }, [userInputProductKpi]);

  const onSubmit = () => {
    handleSubmit((data: any) => {
      if (data.product_kpi !== String(entityKpi)) {
        updateProductKpi(data.product_kpi);
      }
      if (data.program_kpi !== String(programKpi.value)) {
        updateProgramKpi(data.program_kpi);
      }
      closeKpiModal();
    })();
  };

  const resetKpis = () => {
    reset({
      program_kpi: String(programKpi?.value) || "",
      product_kpi: String(entityKpi) || "",
    });
  };

  const closeKpiModal = () => {
    resetKpis();
    handleClose();
  };

  const handleKpi = (payload: KpiPayload) => {
    pushKpi(payload);
  };

  const updateProgramKpi = (kpi: number) => {
    const payload = {
      entityId: programId,
      kpiType: "Program",
      value: Number(kpi),
    };
    pushKpi(payload);
  };

  const updateProductKpi = (kpi: number) => {
    const payload = {
      productId,
      entityId: productId,
      kpiType: "Product",
      value: Number(kpi),
    };
    pushKpi(payload);
  };

  return (
    <Modal
      title="Add/Edit KPI's"
      open={open}
      handleClose={closeKpiModal}
      id="editKpisModal"
      footer={
        <>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              marginRight: "24px",
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit}>
            Save
          </Button>
        </>
      }
    >
      <form>
        <StyledTypography>{programName}</StyledTypography>
        <InputBlock>
          <Controller
            name="program_kpi"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormGroup
                  id="add-kpi"
                  value={value}
                  label="Add KPI"
                  placeholder="Type KPI"
                  onChange={onChange}
                  error={!!error}
                />
              );
            }}
          />
        </InputBlock>
        <StyledTypography>{productName}</StyledTypography>
        <InputBlock>
          <Controller
            name="product_kpi"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormGroup
                  id="add-kpi"
                  value={value}
                  label="Add KPI"
                  placeholder="Type KPI"
                  onChange={onChange}
                  error={!!error}
                />
              );
            }}
          />
        </InputBlock>
      </form>
      <StyledTypography>DISTRIBUTION LIST</StyledTypography>
      <DistributeTable handleKpi={handleKpi} entityId={productId} />
      <StyledTypography sx={{ marginTop: "1rem" }}>
        WIDGET LIST
      </StyledTypography>
      <WidgetsTable handleKpi={handleKpi} entityId={productId} />
    </Modal>
  );
};
