import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";

import TablePaper from "shared/ui/TablePaper";
import TableControls from "../TableControls";
import UsersTable from "../Table";
import AddNewUser from "../Modals/AddNewUser";
import ResendInvitation from "../Modals/ResendInvitation";
import UserProfile from "../Modals/UserProfile";
import RestoreModal from "../Modals/RestoreModal";

import { modalsActions } from "entities/modals";
import { usersActions } from "entities/users";
import { useAppSelector } from "redux/store/hooks/hooks";

import { initialColumns } from "./types";
import { UserRespose } from "shared/api/users/types";
import { getUsersList } from "entities/users/model/selectors";

interface UsersProps {
  status: string;
}

const Users: React.FC<any> = ({ status }: UsersProps) => {
  const dispatch = useDispatch();

  const [rows, setRows] = useState<UserRespose[]>([]);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openInvitation, setOpenInvitation] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const [userToRestore, setUserToRestore] = useState<UserRespose>();

  const usersState = useAppSelector(getUsersList);

  const [searchQuery] = useDebounce(searchValue, 2000);

  useEffect(() => {
    dispatch({
      type: usersActions.GET_USERS,
      data: { params: { ShowArchived: status === "Archive" } },
    });
  }, [status]);

  useEffect(() => {
    if (usersState) {
      const users: UserRespose[] = getUsers();
      setRows(users);
    }
  }, [usersState]);

  useEffect(() => {
    const users: UserRespose[] = getUsers();
    if (searchQuery) {
      const filteredUsers = users.filter((item: any) => {
        const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
        if (fullName.includes(searchQuery.toLowerCase())) return item;
      });
      setRows(filteredUsers);
    } else {
      setRows(users);
    }
  }, [searchQuery]);

  const getUsers = () => {
    const users: UserRespose[] = [];
    usersState.map((item: any) => {
      users.push({ ...item, id: item.userId });
    });
    return users;
  };

  const openAddNewUserModal = () => {
    setOpenAddUser(!openAddUser);
  };

  const resendInvitation = () => {
    setOpenInvitation(true);
  };

  const handleEdit = () => {
    setOpenEditModal(true);
  };

  const handleClose = () => {
    setOpenEditModal(false);
  };

  const handleDelete = (row: UserRespose) => {
    dispatch({
      type: modalsActions.OPEN_MODAL,
      data: { delete: { type: "user", isModalOpen: true, data: row } },
    });
  };

  const handleRestore = (row: UserRespose) => {
    setUserToRestore(row);
    setOpenRestoreModal(true);
  };

  const restoreUser = () => {
    if (userToRestore) {
      dispatch({
        type: usersActions.INVITE_USER,
        data: {
          recipientEmail: userToRestore.email,
          recipientName: `${userToRestore.firstName} ${userToRestore.lastName}`,
        },
        restore: true,
      });
    }
    setOpenRestoreModal(!openRestoreModal);
  };

  return (
    <TablePaper>
      <TableControls
        activeTab={status}
        handleOpen={openAddNewUserModal}
        searchValue={searchValue}
        handleSearch={(e: any) => setSearchValue(e.target.value)}
      />
      <UsersTable
        rows={rows}
        columns={initialColumns}
        activeTab={status}
        resendInvitation={resendInvitation}
        onView={handleEdit}
        onDelete={handleDelete}
        onRestore={handleRestore}
      />
      <AddNewUser onClose={() => setOpenAddUser(false)} open={openAddUser} />
      <ResendInvitation
        onClose={() => setOpenInvitation(false)}
        open={openInvitation}
      />
      <UserProfile onClose={handleClose} open={openEditModal} />
      <RestoreModal
        onClose={() => setOpenRestoreModal(false)}
        open={openRestoreModal}
        restoreType={"User"}
        handleSubmit={restoreUser}
      />
    </TablePaper>
  );
};

export default Users;
