import { styled } from "@mui/material";

export const FormGroup = styled("div")`
  position: relative;
`;
export const Wrapper = styled("div")`
  background: #ffffff;
  padding: 16px;
  border-radius: var(--24, 24px);
  margin-top: 32px;
`;
