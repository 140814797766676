import React from "react";
import { Status } from "shared/config/enums/Statuses";
import { InfoLabel, InfoRowIntend, InfoStatus } from "./styles";

const STATUS_COLORS = {
  [Status.INACTIVE]: {
    color: "var(--mediumorange)",
    bg: "var(--lightorange)",
  },
  [Status.ACTIVE]: {
    color: "#009c10",
    bg: "rgba(0, 156, 16, 0.08)",
  },
};

interface Props {
  label: string;
  value: string;
  status: Status;
}

export const DetailsStatus = ({ label, value, status }: Props) => {
  return (
    <InfoRowIntend>
      <InfoLabel>{label}:</InfoLabel>
      {value && (
        <InfoStatus
          bg={STATUS_COLORS[status].bg}
          color={STATUS_COLORS[status].color}
        >
          {value}
        </InfoStatus>
      )}
    </InfoRowIntend>
  );
};
