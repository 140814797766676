import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { initialColumns } from "./types";

import NotificationsTable from "../Table";
import DeleteSimpleModal from "../Modals/DeleteSimpleModal";
import TablePaper from "shared/ui/TablePaper";

import { NotificationDetail } from "features/notification-detail";

import {
  platformNotificationsActions,
  getNotificationsItems,
  getNotificationsTotalCount,
  getNewNotificationsCount,
} from "entities/platformNotifications";

import { PlatformNotificationRespose } from "shared/api/platformNotifications/types";
import { OrderTypes } from "shared/config/types/Sorting";

import { useAppSelector } from "redux/store/hooks/hooks";

import { PaginationParams } from "api/types";

const initialPagingData = {
  limit: 10,
  offset: 0,
};

const Notifications: React.FC<any> = () => {
  const dispatch = useDispatch();

  const notifications = useAppSelector(getNotificationsItems);
  const totalCount = useAppSelector(getNotificationsTotalCount);
  const newNotificationCount = useAppSelector(getNewNotificationsCount);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedNotification, setSelectedNotification] =
    useState<PlatformNotificationRespose>();
  const [filterParams, setFilterParams] = useState({
    notificationType: "",
  });
  const [pagingParams, setPagingParams] =
    useState<PaginationParams>(initialPagingData);
  const [order, setOrder] = useState<OrderTypes | null>(null);

  useEffect(() => {
    if (filterParams) {
      dispatch({
        type: platformNotificationsActions.GET_PLATFORM_NOTIFICATIONS,
        data: {
          params: {
            NotificationType: filterParams.notificationType,
            ...pagingParams,
            "SortingPreferences.SortingProperty": "Id",
            "SortingPreferences.Kind": order?.type,
          },
        },
      });
    }
  }, [
    filterParams,
    pagingParams,
    newNotificationCount,
    order,
    openDeleteModal,
  ]);

  const handleClose = () => {
    if (!selectedNotification?.isRead) {
      dispatch({
        type: platformNotificationsActions.READ_PLATFORM_NOTIFICATION,
        data: selectedNotification?.id,
      });
    }
    setOpenDetailModal(false);
  };

  const handleView = (notificaion: PlatformNotificationRespose) => {
    setSelectedNotification(notificaion);
    setOpenDetailModal(true);
    dispatch({
      type: platformNotificationsActions.GET_PLATFORM_NOTIFICATION,
      data: notificaion?.id,
    });
  };

  const handleDelete = (notification: PlatformNotificationRespose) => {
    setSelectedNotification(notification);
    setOpenDeleteModal(true);
  };

  const handleDeleteNotification = () => {
    dispatch({
      type: platformNotificationsActions.DELETE_PLATFORM_NOTIFICATION,
      data: selectedNotification?.id,
      callback,
    });
  };

  const callback = () => {
    setOpenDeleteModal(false);
  };

  return (
    <TablePaper>
      <NotificationsTable
        rows={notifications}
        columns={initialColumns}
        activeTab="Notifications"
        hideCheckbox={true}
        borderCollapse={true}
        onView={handleView}
        onDelete={handleDelete}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        pagingParams={pagingParams}
        setPagingParams={setPagingParams}
        totalCount={totalCount}
        setSortOrder={(e) => setOrder(e)}
      />
      <NotificationDetail open={openDetailModal} handleClose={handleClose} />
      <DeleteSimpleModal
        title={`Are you sure you want to delete Notification ${selectedNotification?.id}?`}
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onSubmit={handleDeleteNotification}
      />
    </TablePaper>
  );
};

export default Notifications;
