import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  cloneWidgetAction,
  createWidgetAction,
  deleteWidgetAction,
  getApplicationsByIdsAction,
  getProductsByIdsAction,
  getWidgetByIdAction,
  getWidgetsAction,
  updateWidgetByIdAction,
  updateWidgetForProductsAction,
  updateWidgetProductsAction,
} from "../actions";
import WidgetsApi from "shared/api/widgets/api";
import { Widget, WidgetsResponse } from "shared/api/widgets/types";
import { widgetsActions } from "entities/widgets";
import { actions as applicationActions } from "entities/applications";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";
import { IdOnlyResponse } from "shared/config/types/General";
import { Toaster } from "shared/ui/Toast/Toast";
import { ApplicationResponse } from "shared/api/applications/types";
import ApplicationsApi from "shared/api/applications/applicationsApi";
import ProductsApi from "shared/api/products/productsApi";
import { LinkedProductsResponse } from "shared/api/products/types";
import { ActionButtonTypes } from "shared/ui/ActionButton/types";

export function* getAllWidgetsSaga(
  action: ReturnType<typeof getWidgetsAction>
) {
  try {
    yield put(widgetsActions.setLoading(true));
    const response: AxiosResponse<WidgetsResponse> = yield call(
      WidgetsApi.getAll,
      action.payload
    );
    yield put(widgetsActions.setWidgetsData(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(widgetsActions.setLoading(false));
  }
}

export function* UpdateWidgetProductsSaga(
  action: ReturnType<typeof updateWidgetProductsAction>
) {
  try {
    const { payload } = action;
    yield put(applicationActions.setLinkedArrayLoading(payload.productId));
    const response: AxiosResponse<{ id: string }> = yield call(
      WidgetsApi.updateWidgetProducts,
      payload
    );
    if (response.data.id) {
      yield put(applicationActions.setLinkedArrayLoading(payload.productId));
      yield put(applicationActions.setLinkedArrayDataMap(payload.productIds));
    }
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(
      applicationActions.setLinkedArrayLoading(action.payload.productId)
    );
  }
}

export function* UpdateWidgetForProductsSaga(
  action: ReturnType<typeof updateWidgetForProductsAction>
) {
  try {
    const { payload } = action;
    yield put(widgetsActions.setWidgetArrayLoading(payload.id));
    const response: AxiosResponse<{ id: string }> = yield call(
      WidgetsApi.updateWidgetProducts,
      payload
    );
    if (response.data.id) {
      yield put(widgetsActions.setWidgetArrayLoading(payload.id));
      yield put(
        widgetsActions.setWidgetIds({
          productId: payload.productId,
          widgetId: payload.id,
        })
      );
    }
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(widgetsActions.setWidgetArrayLoading(action.payload.id));
  }
}
export function* createWidgetSaga(
  action: ReturnType<typeof createWidgetAction>
) {
  try {
    const { widgetData, cb } = action.payload;
    yield put(widgetsActions.setActionLoader(true));
    const response: AxiosResponse<IdOnlyResponse> = yield call(
      WidgetsApi.createWidget,
      widgetData
    );
    if (response.data.id) {
      yield put(widgetsActions.setNeedToRefreshWidgets(true));
      if (cb) cb();
      Toaster.infoMessage(`Your Widget ${widgetData.name} has been created`, {
        autoClose: 3000,
      });
    }
  } catch (e) {
    sagaErrorBoundary(e);
  } finally {
    yield put(widgetsActions.setActionLoader(false));
  }
}

export function* getWidgetByIdSaga(
  action: ReturnType<typeof getWidgetByIdAction>
) {
  try {
    yield put(widgetsActions.setConfigWidgetLoading(true));
    const response: AxiosResponse<Widget> = yield call(
      WidgetsApi.getWidgetById,
      action.payload
    );
    if (response.data) {
      yield put(widgetsActions.setWidgetItem(response.data));
    }
  } catch (e) {
    sagaErrorBoundary(e);
  } finally {
    yield put(widgetsActions.setConfigWidgetLoading(false));
  }
}
export function* updateWidgetByIdSaga(
  action: ReturnType<typeof updateWidgetByIdAction>
) {
  try {
    const { widgetData, cb } = action.payload;
    yield put(widgetsActions.setActionLoader(true));
    const response: AxiosResponse<Widget> = yield call(
      WidgetsApi.updateWidgetById,
      widgetData
    );
    if (response.data) {
      yield put(widgetsActions.setNeedToRefreshWidgets(true));
      if (cb) yield call(cb);
      Toaster.infoMessage(`Your Widget ${widgetData.name} has been updated`, {
        autoClose: 3000,
      });
    }
  } catch (e) {
    sagaErrorBoundary(e);
  } finally {
    yield put(widgetsActions.setActionLoader(false));
  }
}

export function* getApplicationsByIdsSaga(
  action: ReturnType<typeof getApplicationsByIdsAction>
) {
  try {
    yield put(widgetsActions.setWidgetConfigAppsLoader(true));
    const response: AxiosResponse<ApplicationResponse> = yield call(
      ApplicationsApi.getApplicationsByIds,
      action.payload
    );
    if (response.data) {
      yield put(widgetsActions.setWidgetConfigApps(response.data));
    }
  } catch (e) {
    sagaErrorBoundary(e);
  } finally {
    yield put(widgetsActions.setWidgetConfigAppsLoader(false));
  }
}
export function* getProductsByIdsSaga(
  action: ReturnType<typeof getProductsByIdsAction>
) {
  try {
    yield put(widgetsActions.setWidgetConfigProductsLoader(true));
    const response: AxiosResponse<LinkedProductsResponse> = yield call(
      ProductsApi.getLinkedProducts,
      {
        productIds: action.payload,
        offset: 0,
        limit: 100,
      }
    );
    if (response.data) {
      yield put(widgetsActions.setWidgetConfigProducts(response.data));
    }
  } catch (e) {
    sagaErrorBoundary(e);
  } finally {
    yield put(widgetsActions.setWidgetConfigProductsLoader(false));
  }
}
export function* cloneWidgetSaga(action: ReturnType<typeof cloneWidgetAction>) {
  try {
    const {
      item: { name, id },
      cb,
    } = action.payload;
    yield put(widgetsActions.setRowLoader(id));
    const response: AxiosResponse<Widget> = yield call(
      WidgetsApi.cloneWidget,
      id
    );
    if (response.data.id) {
      yield put(widgetsActions.setNeedToRefreshWidgets(true));
      Toaster.infoMessage(`Your Widget ${name} has been cloned`, {
        autoClose: 3000,
        iconAction: ActionButtonTypes.CLONE,
      });
    }
    if (response.data && cb) {
      cb(response.data.id);
    }
  } catch (e) {
    sagaErrorBoundary(e);
  } finally {
    yield put(widgetsActions.setRowLoader(""));
  }
}
export function* deleteWidgetSaga(
  action: ReturnType<typeof deleteWidgetAction>
) {
  try {
    const { item, cb } = action.payload;
    yield put(widgetsActions.setActionLoader(true));
    const response: AxiosResponse<Widget> = yield call(
      WidgetsApi.deleteWidget,
      item.id
    );

    if (response.data) {
      Toaster.infoMessage(`Your Widget ${item.name} has been deleted`, {
        autoClose: 3000,
        iconAction: ActionButtonTypes.DELETE,
      });
    }
    if (response.data && cb) {
      yield call(cb);
    }
  } catch (e) {
    sagaErrorBoundary(e);
  } finally {
    yield put(widgetsActions.setActionLoader(false));
  }
}
export function* WidgetsWatcher() {
  yield takeLatest(getWidgetsAction, getAllWidgetsSaga);
  yield takeEvery(updateWidgetProductsAction, UpdateWidgetProductsSaga);
  yield takeEvery(updateWidgetForProductsAction, UpdateWidgetForProductsSaga);
  yield takeEvery(createWidgetAction, createWidgetSaga);
  yield takeEvery(getWidgetByIdAction, getWidgetByIdSaga);
  yield takeEvery(updateWidgetByIdAction, updateWidgetByIdSaga);
  yield takeLatest(getApplicationsByIdsAction, getApplicationsByIdsSaga);
  yield takeLatest(getProductsByIdsAction, getProductsByIdsSaga);
  yield takeLatest(cloneWidgetAction, cloneWidgetSaga);
  yield takeLatest(deleteWidgetAction, deleteWidgetSaga);
}
