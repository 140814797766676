import styled from "styled-components";
import { Popover } from "@mui/material";

export const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  list-style: none;
  padding: 5px 0;
`;

export const Link = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const Modal = styled(Popover)`
  .MuiPaper-root {
    width: 100%;
    min-width: 250px;
    padding: 10px;
  }
`;
