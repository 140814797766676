import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Table, TableBody, TableCell, TablePagination } from "@mui/material";

import { ReactComponent as ActivateIcon } from "assets/icons/add.svg";
import { StyledChip, TableContainerWrapper } from "shared/ui/Table/styles";
import { IXTableRow, TableHeader } from "shared/ui/Table";
import SimpleLink from "shared/ui/SimpleLink/SimpleLink";
import { initialColumns, mockIncludedData } from "./types";
import { ActionButton, ActionButtonsWrapper } from "shared/ui/ActionButton";
import { ActionButtonTypes } from "shared/ui/ActionButton/types";
import { AssociatedProductsModal } from "features/associatedProducts";
import {
  ActivateButtonWidget,
  useGetWidgets,
  useGetWidgetsActions,
  useWidgetConfig,
  useWidgets,
  useWidgetsActions,
} from "features/widgets";
import { Kpi } from "features/applications";
import { format } from "date-fns";
import { DateFormat } from "shared/config/enums/DateFormat";
import TablePlaceholder from "shared/ui/TablePlaceholder";
import TableControls from "shared/ui/TableControls/TableControls";
import { useDebounce } from "use-debounce";
import SortingDefault, {
  SortRenderProps,
} from "shared/ui/Table/SortingDefault/SortingDefault";
import { OrderTypes } from "shared/config/types/Sorting";
import { SORT } from "shared/config/enums/Sorting";
import { TableSkeleton } from "shared/ui/TableSkeleton";
import { DistributionsList } from "entities/applications";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getProduct } from "entities/products";
import { Product, Widget, WidgetPayloadWithId } from "shared/api/widgets/types";
import { WidgetConfig } from "entities/widgets/ui/widget-config/WidgetConfigPopover";
import { KpiPayload } from "shared/api/kpis/types";
import WidgetConfiguration from "../../WidgetConfiguration/WidgetConfiguration";
import Button from "shared/ui/Button";
import DeleteSimpleModal from "components/Modals/DeleteSimpleModal";
import { RoutesList } from "shared/routes";
import ProductsOptionsModal from "../../WidgetConfiguration/ConfigurationPage/WidgetConfiguration/ProductsOptionsModal";

interface Props {
  openCreateRequestModal: () => void;
  handleKpi?: (payload: KpiPayload) => void;
  type?: string;
}

export const DistributionWidgetTable = memo(function DistributionWidgetTable({
  openCreateRequestModal,
  type,
  handleKpi,
}: Props) {
  const hostname = window.location.origin;
  const { cloneWidget, isLoadingActionID, deleteWidget } = useWidgetsActions();
  const { getWidgets, needToRefresh } = useGetWidgets();
  const { isActionLoading } = useWidgetConfig();
  const [deleteModal, setDeleteModal] = useState<Record<string, string> | null>(
    null
  );
  const { isLoading, data, total } = useWidgets();
  const { id: productIdEdit } = useAppSelector(getProduct);
  const { updateWidgetForProducts } = useGetWidgetsActions();
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);
  const [widgetId, setWidgetId] = useState<string | null>(null);
  const [linkedProducts, setLinkedProducts] = useState<Product[]>([]);
  const [order, setOrder] = useState<OrderTypes>({ type: SORT.ASC, label: "" });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [value] = useDebounce(searchValue, 500);
  const [openListProductModal, setOpenListProductModal] = useState(false);
  const [productsConfirmation, setProductsConfirmation] = useState(false);
  const [widgetData, setWidgetData] = useState<Widget | null>(null);
  const {
    isLoading: isWidgetDataLoading,
    clearWidget,
    clearWidgetItemId,
  } = useWidgetConfig();

  const handleClose = () => {
    clearWidget();
    clearWidgetItemId();
    setWidgetData(null);
    setProductsConfirmation(false);
  };
  const getData = useCallback(() => {
    const offset = page * rowsPerPage;
    getWidgets({
      offset: offset,
      limit: rowsPerPage,
      search: value,
      ...(order.label && { sortPreference: order.label }),
      ...(order.label && { sortKind: order.type }),
    });
  }, [getWidgets, value, page, rowsPerPage, order]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (needToRefresh) {
      getData();
    }
  }, [needToRefresh]);

  const handleViewWidget = (widget: Widget) => () => {
    if (
      widget.products &&
      widget.products.length === 1 &&
      widget.applications &&
      widget.applications.length === 1
    ) {
      const newObject = {
        productId: widget.products[0].productId ?? null,
        clientId: widget.applications?.[0].clientId ?? null,
        widgetId: widget.id,
      };
      localStorage.setItem("widgetConfig", JSON.stringify(newObject));
      window.open(
        `${hostname}/${RoutesList.WIDGET_VIEW}`,
        "_blank",
        "noopener,noreferrer"
      );
      return;
    }
    setWidgetData(widget);
    setProductsConfirmation(true);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected: any = mockIncludedData.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalForUpdate = (id: string) => {
    setOpenModal(true);
    setWidgetId(id);
  };
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setWidgetId(null);
  }, []);

  const handleOpenAssociated = () => {
    setOpenListProductModal((prevState) => !prevState);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleCloneWidget = (item: WidgetPayloadWithId) => () => {
    cloneWidget(item, handleOpenModalForUpdate);
  };
  const handleAddToProduct = (id: string, products: Product[]) => () => {
    setWidgetId(id);
    setLinkedProducts(products);
    handleOpenAssociated();
  };
  const activateProduct = useCallback(
    (productId: string) => {
      if (widgetId) {
        let newProducts: string[] = [];
        const productIds = linkedProducts.map((item) => item.productId);
        if (productIds.includes(productId)) {
          newProducts = productIds;
        } else {
          newProducts = [...productIds, productId];
        }
        updateWidgetForProducts({
          id: widgetId,
          productId,
          productIds: newProducts,
        });
      }
    },
    [updateWidgetForProducts, widgetId, linkedProducts]
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
  };
  const initialColumnsData = useMemo(() => {
    return type === "edit"
      ? initialColumns.filter((item) => item.id !== "AssociatedProducts")
      : initialColumns;
  }, [initialColumns]);

  const deleteSubmit = () => {
    setDeleteModal(null);
    getData();
  };

  return (
    <>
      <TableControls
        activeTab={"Widget"}
        buttonLabel={"Create Request"}
        handleOpen={openCreateRequestModal}
        searchValue={searchValue}
        handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
      >
        <Button
          variant="contained"
          onClick={handleOpenModal}
          data-testid={"create-widget-button"}
        >
          Create Widget
        </Button>
        <Button
          variant="contained"
          onClick={openCreateRequestModal}
          data-testid={"create-request-button"}
        >
          Create Request
        </Button>
      </TableControls>
      <TableContainerWrapper size="mg" data-testid="tableContainer">
        <Table stickyHeader>
          <TableHeader
            hideCheckbox={true}
            columns={initialColumnsData}
            rowCount={data.length}
            onSelectAllClick={handleSelectAllClick}
          >
            {(props: SortRenderProps) => (
              <SortingDefault
                setOrder={(data: OrderTypes) => setOrder(data)}
                order={order}
                {...props}
              />
            )}
          </TableHeader>
          <TableBody data-testid="tableBody">
            {isLoading && (
              <TableSkeleton lines={3} columns={initialColumnsData} />
            )}
            {!isLoading && data.length === 0 && (
              <TablePlaceholder colSpan={initialColumnsData.length} />
            )}
            {!isLoading &&
              data.length > 0 &&
              data.map((row) => {
                return (
                  <IXTableRow
                    selected={selected.indexOf(row.id) !== -1}
                    hover
                    tabIndex={-1}
                    key={row.id}
                    data-testid={`tableRow_${row.id}`}
                  >
                    <TableCell sx={{ textAlign: "center" }}>
                      {type === "edit" && (
                        <ActivateButtonWidget
                          widgetId={row.id}
                          productId={productIdEdit}
                          handleKpi={handleKpi}
                        />
                      )}
                      {!type && (
                        <StyledChip
                          label={
                            type === "edit" ? "Activate" : "Add to product"
                          }
                          chiptype="ready"
                          onClick={handleAddToProduct(row.id, row.products)}
                          icon={<ActivateIcon />}
                        />
                      )}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>{row.name}</TableCell>
                    <TableCell>
                      <DistributionsList
                        items={row.applications.map((item) => item.name)}
                      />
                    </TableCell>
                    {!type && (
                      <TableCell>
                        <SimpleLink
                          to={"associated-products"}
                          state={{
                            widgetId: row.id,
                            productIds: row.products.map(
                              (item) => item.productId
                            ),
                          }}
                        >
                          View all
                        </SimpleLink>
                      </TableCell>
                    )}
                    <TableCell>{row.widgetType}</TableCell>
                    <TableCell>
                      {format(new Date(row.createdDateUtc), DateFormat.DEFAULT)}
                    </TableCell>
                    <TableCell>
                      {format(new Date(row.updatedDateUtc), DateFormat.DEFAULT)}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <ActionButtonsWrapper
                        isLoading={
                          isLoadingActionID === row.id || isWidgetDataLoading
                        }
                      >
                        <ActionButton
                          title={"Clone Widget"}
                          type={ActionButtonTypes.CLONE}
                          onClick={handleCloneWidget({
                            id: row.id,
                            name: row.name,
                            widgetType: row.widgetType,
                            configuration: row.configuration,
                          })}
                        />
                        <ActionButton
                          title={"Edit Widget"}
                          type={ActionButtonTypes.EDIT}
                          onClick={() => handleOpenModalForUpdate(row.id)}
                        />
                        <ActionButton
                          title={"Delete Widget"}
                          type={ActionButtonTypes.DELETE}
                          onClick={() =>
                            setDeleteModal({ name: row.name, id: row.id })
                          }
                        />
                        {type === "edit" && (
                          <WidgetConfig
                            widgetId={row.id}
                            productId={productIdEdit}
                            name={row.name}
                            distributions={row.applications}
                          >
                            <ActionButton
                              title={ActionButtonTypes.SETTINGS}
                              type={ActionButtonTypes.SETTINGS}
                            />
                          </WidgetConfig>
                        )}
                        <ActionButton
                          title={ActionButtonTypes.VIEW}
                          type={ActionButtonTypes.VIEW}
                          onClick={handleViewWidget(row)}
                        />
                      </ActionButtonsWrapper>
                    </TableCell>
                    <TableCell>
                      <Kpi
                        appId={row.id}
                        productId={productIdEdit}
                        type="widget"
                        handleKpi={handleKpi}
                      />
                    </TableCell>
                  </IXTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainerWrapper>
      <TablePagination
        sx={{ marginTop: "30px" }}
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        data-testid="table_pagination"
      />
      <AssociatedProductsModal
        open={openListProductModal}
        handleClose={handleOpenAssociated}
        activateProduct={activateProduct}
      />
      {deleteModal && (
        <DeleteSimpleModal
          open={!!deleteModal}
          isLoading={isActionLoading}
          title={`Are you sure you want to delete Widget "${deleteModal.name}"?`}
          onSubmit={() => deleteWidget(deleteModal, deleteSubmit)}
          onClose={() => setDeleteModal(null)}
        />
      )}
      {openModal && (
        <WidgetConfiguration
          open={openModal}
          onClose={handleCloseModal}
          widgetId={widgetId}
        />
      )}
      {widgetData && productsConfirmation && (
        <ProductsOptionsModal
          widgetsConfigID={widgetData.id}
          apps={
            widgetData.applications.length > 0 ? widgetData.applications : null
          }
          products={widgetData.products.length > 0 ? widgetData.products : null}
          open={productsConfirmation}
          onClose={handleClose}
        />
      )}
    </>
  );
});
