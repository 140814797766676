import styled from "styled-components";
import { TableHead } from "@mui/material";

export const StyledTableHead = styled(TableHead)`
  th {
    border-bottom: 4px solid rgba(98, 102, 234, 0.25);
    color: rgba(56, 56, 116, 0.5);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 5px 16px;
  }
  th.MuiTableCell-paddingCheckbox {
    padding: 0 0 0 4px;
  }
  .MuiCheckbox-root {
    path {
      fill: var(--primary);
    }
  }
`;
