import React from "react";
import { StyledDatePicker, StyledHelperText } from "./styles";
import type { DatePickerProps } from "@mui/x-date-pickers/DatePicker";

interface DatePickerComponentProps extends DatePickerProps<any> {
  error?: boolean;
}

const DatePicker: React.FC<DatePickerComponentProps> = ({
  error,
  ...props
}) => {
  return (
    <>
      <StyledDatePicker
        className={error ? "Mui-error" : ""}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--primary)",
          },
        }}
        {...props}
      />
      {error && <StyledHelperText>This field is required.</StyledHelperText>}
    </>
  );
};

export default DatePicker;
