import styled from "styled-components";
import { Chip, TableContainer, TableHead, TableRow } from "@mui/material";
const TABLE_SIZES = {
  lg: "660px",
  mg: "525px",
  md: "470px",
  sm: "425px",
  xs: "325px",
};
export const StyledTableHead = styled(TableHead)`
  th {
    border-bottom: 4px solid rgba(98, 102, 234, 0.25);
    color: rgba(56, 56, 116, 0.5);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 5px 16px;
  }
  th.MuiTableCell-paddingCheckbox {
    padding: 0 0 0 4px;
  }
  .MuiCheckbox-root {
    path {
      fill: var(--primary);
    }
  }
`;

export const TableContainerWrapper = styled(TableContainer)<{
  size?: keyof typeof TABLE_SIZES;
}>`
  position: relative;
  height: ${({ size = "md" }) => `calc(100vh - ${TABLE_SIZES[size]})`};
`;
export const StyledTableRow = styled(TableRow)<{
  chiped?: boolean;
  borderCollapse?: boolean;
}>`
  ${(props) => {
    const height = props.chiped ? "61px" : "inherit";
    return props.borderCollapse
      ? `
    background-color: #fff;
    .MuiTableCell-root {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #010101;
  
      border-bottom: none;
      text-align: left;
      padding: 7px 8px;
  
      border-top: 1px solid #dadae3;
      border-bottom: 1px solid #dadae3;
  
      &:first-child {
        border-left: 1px solid #dadae3;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-right: 1px solid #dadae3;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
`
      : `
    &.MuiTableRow-root {
      &:nth-child(odd) {
        background: #f9f9f9;
      }
      height: ${height};
    }
    `;
  }}

  &.MuiTableRow-root.Mui-selected {
    background-color: var(--ghost);
    .MuiAvatar-root {
      background: var(--white);
    }
  }
  &.MuiTableRow-hover.MuiTableRow-root:hover {
    box-shadow: 10px 10px 40px rgba(111, 138, 231, 0.25);
    background-color: transparent;
    position: relative;
    z-index: 1;
  }

  &.MuiTableRow-hover.Mui-selected.MuiTableRow-root:hover {
    background-color: var(--ghost);
  }
  td {
    padding: 16px;
    font-family: "Inter", sans-serif;
  }

  td.MuiTableCell-paddingCheckbox {
    padding: 0 0 0 4px;
  }

  .MuiCheckbox-root {
    path {
      fill: var(--primary);
    }
  }
`;

export const StyledChip = styled(Chip)<{ chiptype: string }>`
  &.MuiChip-root {
    min-width: 130px;
    background-color: ${(props) =>
      props.chiptype !== "pending" ? "transparent" : "#ffebd1"};
    color: ${(props) =>
      props.chiptype !== "pending" ? "var(--black)" : "#fb9a1a"};
    padding: ${(props) =>
      props.chiptype === "pending" ? "15px 20px" : "8px 12px 8px 8px"};
    border: ${(props) =>
      props.chiptype === "pending" ? "none" : "1px solid #9696a9"};
    border-radius: ${(props) =>
      props.chiptype === "pending" ? "100px" : "6px"};
  }
`;
