import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { usersActions } from "entities/users";

export const useUsers = () => {
  const dispatch = useDispatch();

  const getUserProfile = useCallback(() => {
    dispatch({ type: usersActions.LOAD_USER_PROFILE });
  }, [dispatch]);

  return { getUserProfile };
};
