import styled from "styled-components";
import { Button } from "@mui/material";

export const Divider = styled.div`
  margin: 32px 0;
  height: 1px;
  background: #dadae3;
`;

export const Description = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #190a3a;
  margin: 0 0 34px;
  background: #f2f2fc;
  border-radius: 8px;
  padding: 16px;
`;

export const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    font-family: "Inter";
    font-style: normal;
    text-transform: none;
    background-color: #fff;
    display: contents;
    font-size: 16px;
    color: var(--secondary);
  }
`;
