import { Column } from "shared/config/types/Table";

export const initialColumns: Column[] = [
  {
    id: "chip",
    label: "",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "Name",
    label: "Name",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "Provider",
    label: "Provider",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "Location",
    label: "Location",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "Channel",
    label: "Channel",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "associatedProducts",
    label: "Associated products",
    minWidth: 300,
    filtering: false,
  },
  {
    id: "createdDateUtc",
    label: "Creation",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Update",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "kpis",
    label: "Add KPI's",
    minWidth: 200,
    filtering: false,
  },
];

export const mockIncludedData: any = [
  {
    id: "ascascasc",
    status: "activated",
    provider: "IBM - Open Network",
    location: "www.weather.com",
    channel: "media",
    associatedProducts: "image",
    createdDateUtc: "08-24-2022",
    updatedDateUtc: "08-24-2022",
  },
];
