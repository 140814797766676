import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import Sidebar from "widgets/Sidebar";
import Header from "widgets/Header";
import Alerts from "components/Alerts";
import DeleteModal from "components/Modals/DeleteModal";
import { StyledDiv } from "./styles";

import { platformNotificationsActions } from "entities/platformNotifications";

import { useRequests } from "features/requests/model/hooks/useRequests";

// sidebar sizes
// change these 3 params to change the width of sidebar and paddings
const initialSidebarWidth = 266;
const rightSidebarPanel = 20;
const defaultSpacing = 20;

const Home = () => {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(true);
  const [sidebarWidth, setSidebarWidth] = useState(initialSidebarWidth);
  const [sidebarSpacing, setSidebarSpacing] = useState(defaultSpacing);

  const { countNewRequest } = useRequests();

  useEffect(() => {
    dispatch({
      type: platformNotificationsActions.GET_NEW_PLATFORM_NOTIFICATIONS_COUNT,
    });
    countNewRequest();

    const interval = setInterval(() => {
      dispatch({
        type: platformNotificationsActions.GET_NEW_ALERT_NOTIFICATIONS,
      });
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // sizes for expanded/collapsed sidebar
    expanded ? setSidebarWidth(initialSidebarWidth) : setSidebarWidth(70);
    expanded ? setSidebarSpacing(defaultSpacing) : setSidebarSpacing(5);
  }, [expanded]);

  const toggleSidebar = () => {
    setExpanded(!expanded);
  };
  const computedWidth = sidebarWidth + sidebarSpacing * 2 + rightSidebarPanel;
  return (
    <Box
      sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      data-testid="homePage"
    >
      <Sidebar
        expanded={expanded}
        sidebarWidth={sidebarWidth}
        rightSidebarPanel={rightSidebarPanel}
        defaultSpacing={sidebarSpacing}
        toggleSidebar={toggleSidebar}
      />
      <Box
        component="main"
        sx={{
          width: `calc(100% - ${computedWidth}px)`,
          transition: ".1s ease",
        }}
      >
        <StyledDiv>
          <Header />
          <Box sx={{ padding: "0 2% 20px" }}>
            <Outlet />
          </Box>
          <Alerts />
          <DeleteModal />
        </StyledDiv>
      </Box>
    </Box>
  );
};

export default Home;
