import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Tabs from "components/Tabs";
import BulkActions from "components/BulkActions";
import { PageTitle } from "shared/ui/pageTitle";
import { TabNames } from "../types";

const tabList = [
  {
    label: TabNames.PROGRAMS,
  },
  {
    label: TabNames.ARCHIVE,
  },
];

const ProgramsPage = () => {
  const [activeTab, setActiveTab] = useState(TabNames.PROGRAMS);
  const { key, pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (key === "default") {
      navigate("list");
    }
  }, []);

  useEffect(() => {
    if (pathname === "/programs/list") {
      if (activeTab === TabNames.ARCHIVE) setActiveTab(TabNames.PROGRAMS);
    }
  }, [pathname]);

  return (
    <Box data-testid="products-page">
      <PageTitle title={"Programs"} />
      <Tabs
        setActiveTab={setActiveTab}
        tabList={tabList}
        activeTab={activeTab}
        basePath={"/programs"}
      >
        <Outlet />
      </Tabs>
      <BulkActions activeTab={activeTab} />
    </Box>
  );
};

export default ProgramsPage;
