import { takeLatest, put } from "redux-saga/effects";
import { signIn, logout } from "entities/auth/model/slice/authSlice";
import { authActions } from "entities/auth";

export function* signInWorker(payload: any) {
  try {
    yield put(signIn(payload.data));
  } catch (e) {
    yield put({ type: "SIGN_IN_FAILED" });
  }
}

export function* logoutWorker() {
  try {
    yield put(logout());
  } catch (e) {
    yield put({ type: "LOG_OUT_FAILED" });
  }
}

export function* AuthWatcher() {
  yield takeLatest(authActions.SIGN_IN, signInWorker);
  yield takeLatest(authActions.LOG_OUT, logoutWorker);
}
