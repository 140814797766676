import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Grid,
  Chip,
  Avatar,
  TableContainer,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import { ReactComponent as AttachIcon } from "assets/icons/attach.svg";
import { ReactComponent as SendIcon } from "assets/icons/send.svg";

export const StyledListItem = styled(ListItem)`
  &.MuiListItem-root {
    padding: 8px 0;
  }
`;
export const StyledListItemText = styled(ListItemText)`
  &.MuiListItemText-root {
    margin-left: 10px;
  }
`;

export const StyledSpan = styled.span`
  color: var(--secondary);
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledListItemAvatar = styled(ListItemAvatar)`
  &.MuiListItemAvatar-root {
    min-width: 40px;
  }
`;

export const StyledAvatar = styled(Avatar)`
  font-size: 16px !important;
  font-weight: 600;

  &.MuiAvatar-root {
    margin: 0 !important;
  }
`;
export const StyledGrid = styled(Grid)`
  padding: 0 !important;
  margin-right: 6px !important;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

export const DescripGrid = styled(Grid)`
  &.MuiGrid-root {
    padding-top: 3px !important;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  &.MuiTableContainer-root {
    height: calc(100% - 80px);
  }
`;
export const StyledChip = styled(Chip)<{ isPrivate: boolean }>`
  font-size: 1rem !important;
  padding: 0.375rem !important;
  border-radius: 0.375rem !important;

  &.MuiChip-root {
    box-shadow: 0px 2px 6px 2px #e1e1e1;
    background: ${(props) =>
      props.isPrivate ? "var(--secondary)" : "transparent"};
    color: ${(props) => (props.isPrivate ? "var(--ghost)" : "var(--black)")};
  }
`;
export const StyledButton = styled(Button)<{ component: string }>`
  &.MuiButtonBase-root {
    border-radius: 100px;
    padding: 10px 30px;
    color: var(--secondary);
    border: 1px solid var(--secondary);
    text-transform: none;
  }
`;

export const StyledAttachIcon = styled((props) => <AttachIcon {...props} />)`
  margin-right: 10px;
  cursor: pointer;
  vertical-align: middle;
`;
export const StyledSendIcon = styled((props) => <SendIcon {...props} />)`
  cursor: pointer;
`;
export const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    justify-content: flex-end;
    font-family: "Inter";
  }
  .MuiDialog-paper {
    max-height: 100%;
    height: 100%;
    width: 560px;
    margin: inherit;
    position: relative;
    overflow-x: hidden;
  }
`;
export const StyledTypography = styled(Typography)<{ danger?: boolean }>`
  font-size: 0.75rem !important;
  margin-left: 1rem !important;

  &.warnings {
    color: ${(props) => (props.danger ? "var(--darkred)" : "var(--grey)")};
  }

  &.top {
    margin-top: 0.75rem !important;
  }

  &.scroll {
    width: calc(100% - 24px);
    overflow: auto;
  }

  &.text {
    text-transform: none;
    color: #000;
  }
`;
export const StyledHeader = styled(Typography)<{ danger?: boolean }>`
  color: ${(props) => (props.danger ? "var(--darkred)" : "var(--grey)")};
  margin-left: 1rem !important;
  font-size: 0.75rem !important;
  text-transform: uppercase;
  font-weight: 600 !important;
`;
export const StyledActions = styled(DialogActions)<{ centered: number }>`
  &.MuiDialogActions-root {
    padding: ${(props) =>
      props.centered == 1 ? "20px 20px" : "20px 20px 13px 0px"};
    justify-content: ${(props) => (props.centered == 1 ? "center" : "left")};
    position: absolute;
    bottom: 0;
    width: ${(props) => (props.centered == 1 ? "100%" : "84%")};
    left: ${(props) => (props.centered == 1 ? "0" : "8%")};
  }
`;
export const StyledTitle = styled(DialogTitle)<{ sidepadding: string }>`
  &.MuiTypography-root {
    font-weight: 600;
    font-size: 24px;
    line-height: 48px;
    color: var(--headings);
    padding: ${(props) => `20px ${props.sidepadding}px`};
    border-bottom: 1px solid var(--silver);
  }
`;
export const Wrapper = styled.div`
  height: calc(100% - 156px);
`;
export const CommentWrapper = styled.div`
  height: calc(100% - 364px);
  margin: 22px 46px 16px 46px;
  position: relative;
`;
export const FileUpload = styled.div`
  height: 50%;
`;
export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const StyledTabButton = styled(Button)`
  &.MuiButton-root {
    padding: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: none;
    padding: 10px 33px;
  }

  &.MuiButton-contained {
    background-color: var(--secondary);
    color: (--white);
  }

  &.MuiButton-outlined {
    background-color: var(--white);
    color: var(--secondary);
    border-color: var(--secondary);
    border-style: solid;
  }

  &:hover {
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
    color: var(--white) !important;
  }
`;
export const UserComment = styled(Typography)<{
  isPrivate?: boolean;
  isAdmin?: boolean;
}>`
  white-space: pre-wrap;
  width: fit-content;
  float: right;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  padding: 6px;
  box-shadow: 0px 2px 6px 2px #e1e1e1;
  background: ${(props) =>
    props.isPrivate ? "var(--secondary)" : "transparent"};
  color: ${(props) => (props.isPrivate ? "var(--ghost)" : "var(--black)")};
`;
