import { useAppDispatch, useAppSelector } from "redux/store/hooks/hooks";
import { useCallback } from "react";
import {
  productsActions,
  getProducts as getProductsState,
  getProductsTotal,
  getProduct,
  getProductsLoading,
} from "entities/products";
import { PaginationParams } from "api/types";

export const useProducts = () => {
  const dispatch = useAppDispatch();

  const products = useAppSelector(getProductsState);
  const product = useAppSelector(getProduct);
  const total = useAppSelector(getProductsTotal);
  const loading = useAppSelector(getProductsLoading);

  const getProducts = useCallback(
    (pagingParams?: PaginationParams) => {
      dispatch({
        type: productsActions.GET_PRODUCTS,
        data: {
          params: pagingParams,
        },
      });
    },
    [dispatch]
  );

  return {
    getProducts,
    products,
    total,
    product,
    loading,
  };
};
