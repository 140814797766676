import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

import Modal from "../CenteredModal";
import Button from "shared/ui/Button";

import { Title, Text, Container, StyledActions } from "./styles";

import { RootState } from "redux/store";
import { modalsActions } from "entities/modals";
import { productsActions } from "entities/products";
import { alertActions } from "entities/alerts";

import { usersActions } from "entities/users";
import { DeleteModalProps } from "entities/modals/model/slice/modalsSlice";
import { programsActions } from "entities/programs";

import { useRequests } from "features/requests/model/hooks/useRequests";

interface TypeProps {
  title: string;
  text: string;
  boldText?: string;
  subText?: string;
}
interface ModalTypesProps {
  request: TypeProps;
  user: TypeProps;
  users: TypeProps;
  group: TypeProps;
  groups: TypeProps;
  userFromGroup: TypeProps;
  usersFromGroup: TypeProps;
  product: TypeProps;
  program: TypeProps;
}
interface DescriptionProps {
  text: string;
  boldText?: string;
}

const DescriptionComponent = ({ text, boldText }: DescriptionProps) => {
  return boldText ? (
    <Text>
      {text} <b>{boldText}</b>?
    </Text>
  ) : (
    <Text>{text}</Text>
  );
};

const DeleteModal = () => {
  const dispatch = useDispatch();
  const deleteModal: DeleteModalProps = useSelector(
    (state: RootState) => state.modals?.delete
  );
  const { isModalOpen, type, data } = deleteModal;
  const { deleteRequest } = useRequests();

  const deleteModalTypes: ModalTypesProps = {
    user: {
      title: "Do you want to delete the user?",
      text: "Are you sure you want to delete user",
      boldText: data?.firstName
        ? `${data?.firstName} ${data?.lastName}`
        : data?.email,
      subText:
        "The user will be moved to Archive. All permissions will be canceled. ",
    },
    users: {
      title: "Delete selected users?",
      text: "Are you sure you want to delete selected?",
      subText:
        "The user will be moved to Archive. All permissions will be canceled.",
    },
    group: {
      title: "Delete group?",
      text: "Are you sure you want to delete group [GroupName]?",
      subText:
        "The group will be moved to Archive. All members will be revoked from this group.",
    },
    groups: {
      title: "Delete selected groups?",
      text: "Are you sure you want to delete selected groups?",
      subText:
        "The groups will be moved to Archive. All members will be revoked from these groups.",
    },
    userFromGroup: {
      title: "Remove the user from the group?",
      text: "Are you sure you want to remove user UserName from this group?",
    },
    usersFromGroup: {
      title: "Remove selected users from the group?",
      text: "Are you sure you want to remove selected users from this group?",
    },
    product: {
      title: "Delete Product?",
      text: "Are you sure you want to delete product",
      boldText: data?.name,
      subText:
        "The product will be moved to Archive. You can restore it anytyme.",
    },
    request: {
      title: "Delete Request?",
      text: "Are you sure you want to delete Request",
      boldText: data?.requestId,
      subText:
        "The request will be moved to Archive. You can restore it anytime.",
    },
    program: {
      title: "Delete Program?",
      text: "Are you sure you want to delete Program",
      boldText: data?.name,
      subText:
        "The program will be moved to Archive. You can restore it anytime.",
    },
  };

  const onClose = () => {
    dispatch({ type: modalsActions.CLOSE_MODAL });
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onSubmit = () => {
    onClose();
    if (type === "product") {
      dispatch({ type: productsActions.DELETE_PRODUCT, data: { id: data.id } });
      dispatch({
        type: alertActions.SHOW_ALERT,
        data: {
          type: "deleteProduct",
          data: data,
        },
      });
    } else if (type === "request") {
      deleteRequest(data);
    } else if (type === "user") {
      dispatch({
        type: usersActions.DELETE_USER,
        data: {
          id: data.userId,
          name: data.firstName
            ? `${data.firstName} ${data.lastName}`
            : data.email,
        },
      });
    } else if (type === "program") {
      dispatch({
        type: programsActions.DELETE_PROGRAM,
        data: data,
      });
    } else {
      dispatch({
        type: alertActions.SHOW_ALERT,
        data: {
          undo: type !== "usersFromGroup" && type !== "userFromGroup",
          type: type && `delete${capitalizeFirstLetter(type)}`,
        },
      });
    }
  };

  return (
    <Modal id={`deleteModal-${type}`} onClose={onClose} open={!!isModalOpen}>
      <Container>
        <DeleteIcon />
        <Title>{type && deleteModalTypes[type].title}</Title>

        {type && (
          <DescriptionComponent
            text={deleteModalTypes[type].text}
            boldText={deleteModalTypes[type]?.boldText}
          />
        )}
        {type && deleteModalTypes[type].subText && (
          <Text>{deleteModalTypes[type].subText}</Text>
        )}
      </Container>

      <StyledActions>
        <Button
          onClick={onClose}
          variant="outlined"
          data-testid="deleteModal_cancelButton"
        >
          No, Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          color="error"
          data-testid="deleteModal_yesButton"
        >
          Yes,{" "}
          {type === "usersFromGroup" || type === "userFromGroup"
            ? "Remove"
            : "Delete"}
        </Button>
      </StyledActions>
    </Modal>
  );
};

export default DeleteModal;
