import { createSlice } from "@reduxjs/toolkit";
import { KpiItem } from "shared/api/kpis/types";

interface InitialState {
  loading: boolean;
  items: KpiItem[];
  totalCount: number;
  kpiItem: KpiItem;
  kpiItems: KpiItem[];
}
const initialState: InitialState = {
  loading: false,
  items: [],
  totalCount: 0,
  kpiItem: {} as KpiItem,
  kpiItems: [],
};

export const kpisSlice = createSlice({
  name: "kpis",
  initialState,
  reducers: {
    setKpisLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    loadKpis: (state, action) => {
      return {
        ...state,
        items: action.payload.items,
        totalCount: action.payload.totalCount,
      };
    },
    setKpi: (state, action) => {
      return {
        ...state,
        kpiItem: {
          ...action.payload,
        },
      };
    },
    setKpiItems: (state, action) => {
      return {
        ...state,
        kpiItems: action.payload,
      };
    },
  },
});

export const { reducer: kpisReducer, actions: kpiActions } = kpisSlice;
