import { Column } from "shared/config/types/Table";

export const initialColumns: Column[] = [
  {
    id: "requestTypeName",
    label: "Request Type",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "id",
    label: "Request ID",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "createdDateUtc",
    label: "Creation",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Update",
    minWidth: 150,
    filtering: false,
  },
];
