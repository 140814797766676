import { call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  getWidgetDeploysAction,
  getWidgetDetailsAction,
  getWidgetLocationsAction,
} from "../actions";
import AnalyticsApi from "shared/api/analytics/analyticsApi";
import {
  WidgetDeploysResponse,
  WidgetDetailsResponse,
  WidgetLocationsResponse,
} from "shared/api/analytics/types";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";
import { analyticsActions } from "../slice/analyticsSlice";

export function* getWidgetDeploysSaga(
  action: ReturnType<typeof getWidgetDeploysAction>
) {
  try {
    yield put(analyticsActions.setLoading(true));
    const response: AxiosResponse<WidgetDeploysResponse> = yield call(
      AnalyticsApi.getWidgetDeploys,
      action.payload
    );
    yield put(analyticsActions.setWidgetDeploys(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getWidgetDetailsSaga(
  action: ReturnType<typeof getWidgetDetailsAction>
) {
  try {
    const response: AxiosResponse<WidgetDetailsResponse> = yield call(
      AnalyticsApi.getWidgetDetails,
      action.payload
    );
    yield put(analyticsActions.setStepDetails(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getWidgetLocationsSaga(
  action: ReturnType<typeof getWidgetLocationsAction>
) {
  try {
    const response: AxiosResponse<WidgetLocationsResponse> = yield call(
      AnalyticsApi.getWidgetLocations,
      action.payload
    );
    yield put(analyticsActions.setLocationDetails(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* AnalyticsWatcher() {
  yield takeLatest(getWidgetDeploysAction, getWidgetDeploysSaga);
  yield takeLatest(getWidgetDetailsAction, getWidgetDetailsSaga);
  yield takeLatest(getWidgetLocationsAction, getWidgetLocationsSaga);
}
