import styled from "styled-components";

export const StyledDiv = styled.div`
  margin: 10px 10px 10px 0;
  background: var(--ghost);
  min-height: calc(100vh - 20px);
  border-radius: 20px;
  position: relative;
  overflow: hidden;
`;
