import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/store/hooks/hooks";
import { useCallback } from "react";
import { getPublishLoader, productsActions } from "entities/products";
import { productCategoriesActions } from "entities/productCategories";
import { ProductResponse } from "shared/api/products/types";
import { getCategoriesList } from "entities/productCategories/model/selectors";
import { moduleTemplatesActions } from "entities/moduleTemplates";
import { AssociateProductData } from "features/associatedProducts/ui/types";

import { CategoryData } from "shared/api/productCategories/types";

const useProductCategories = () => useAppSelector(getCategoriesList);
const useIsPublishedLoadings = () => useAppSelector(getPublishLoader);

export const useProducts = () => {
  const dispatch = useDispatch();
  const isPublishedLoading = useIsPublishedLoadings();

  const updateProductVersion = useCallback(
    (product: any) => {
      dispatch({
        type: productsActions.CREATE_NEW_PRODUCT_VERSION,
        data: product,
      });
    },
    [dispatch]
  );

  const activateModuleTemplate = useCallback(
    (version: number, templateId: string, productId: string, queue: number) => {
      dispatch({
        type: moduleTemplatesActions.ADD_MODULE_TEMPLATE,
        data: {
          version,
          templateId,
          id: productId,
          moduleOrderQueue: queue,
          outcomeType: "Lead",
        },
      });
    },
    [dispatch]
  );

  const deactivateModuleTemplate = useCallback(
    (version: number, templateId: string, productId: string) => {
      dispatch({
        type: moduleTemplatesActions.REMOVE_MODULE_TEMPLATE,
        data: {
          version,
          templateId,
          id: productId,
        },
      });
    },
    [dispatch]
  );

  const getProducts = useCallback(
    (index: number, key?: string) => {
      dispatch({
        type: productsActions.GET_PRODUCTS,
        data: {
          params: {
            ProductName: key,
            ShowArchived: false,
            limit: 10,
            offset: index * 10 - 10,
            states: ["Draft"],
          },
        },
      });
    },
    [dispatch]
  );

  const getProductsByCategory = useCallback(
    (index: number, categoryId: string, key?: string) => {
      dispatch({
        type: productsActions.GET_PRODUCTS_BY_CATEGORY,
        data: {
          params: {
            ProductName: key,
            categoryId,
            ShowArchived: false,
            limit: 10,
            offset: index * 10 - 10,
          },
        },
      });
    },
    [dispatch]
  );

  const searchProductsByName = useCallback(
    (ProductName: string) => {
      dispatch({
        type: productsActions.GET_PRODUCTS_BY_CATEGORY,
        data: {
          params: {
            ProductName,
            ShowArchived: false,
            limit: 10,
            offset: 0,
            states: ["Draft"],
          },
        },
      });
    },
    [dispatch]
  );

  const getProductCategories = useCallback(() => {
    dispatch({ type: productCategoriesActions.GET_PRODUCT_CATEGORIES });
  }, [dispatch]);

  const processAssociatedProducts = (currentProducts: ProductResponse[]) => {
    const result: AssociateProductData[] = [];
    if (!currentProducts.length) return [];
    currentProducts.map((product: ProductResponse) => {
      const data = {
        id: product.id,
        productName: product.name,
        productStatus: product.state,
        categoryName: getCategoryName(product.categoryId),
        createdDateUtc: product.createdDateUtc,
        updatedDateUtc: product.updatedDateUtc,
        version: product.version,
      };
      result.push(data);
    });
    return result;
  };

  const productCategories: CategoryData[] = useProductCategories();
  const getCategoryName = (id: string) => {
    const category = productCategories.find(
      (category: CategoryData) => category.id === id
    );
    if (category) return category.categoryName;
    else return "Unknown";
  };

  return {
    updateProductVersion,
    activateModuleTemplate,
    deactivateModuleTemplate,
    getProducts,
    getProductsByCategory,
    getProductCategories,
    processAssociatedProducts,
    searchProductsByName,
    isPublishedLoading,
  };
};
