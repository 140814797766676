import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  getApplicationByIdAction,
  getApplicationsAction,
  getApplicationsDirectDataAction,
  getApplicationsNetworkDataAction,
  linkDirectToProductAction,
  linkNetworkToProductAction,
  linkToProductAction,
  unlinkDirectToProductAction,
  unlinkNetworkToProductAction,
  unlinkNToProductAction,
} from "../actions";
import ApplicationsApi from "shared/api/applications/applicationsApi";
import { actions } from "../slice/applicationsSlice";
import {
  Application,
  ApplicationResponse,
} from "shared/api/applications/types";
import { getLinkedProductsAction } from "entities/products";
import { LinkedProductsResponse } from "shared/api/products/types";
import ProductsApi from "shared/api/products/productsApi";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

export function* getApplicationsSaga(
  action: ReturnType<typeof getApplicationsAction>
) {
  try {
    const { payload } = action;
    yield put(actions.setLoading(true));
    const response: AxiosResponse<ApplicationResponse> = yield call(
      ApplicationsApi.getAll,
      payload
    );
    yield put(actions.setApplications(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(actions.setLoading(false));
  }
}

export function* getApplicationsNetworkSaga(
  action: ReturnType<typeof getApplicationsAction>
) {
  try {
    const { payload } = action;
    yield put(actions.setNetworkLoading(true));
    const response: AxiosResponse<ApplicationResponse> = yield call(
      ApplicationsApi.getNetworkApplications,
      payload
    );
    yield put(actions.setNetworkData(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(actions.setLoading(false));
  }
}

export function* getApplicationsDirectSaga(
  action: ReturnType<typeof getApplicationsAction>
) {
  try {
    const { payload } = action;
    yield put(actions.setDirectLoading(true));
    const response: AxiosResponse<ApplicationResponse> = yield call(
      ApplicationsApi.getDirectApplications,
      payload
    );
    yield put(actions.setDirectData(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(actions.setLoading(false));
  }
}

export function* linkApplicationNetworkToProductSaga(
  action: ReturnType<typeof linkNetworkToProductAction>
) {
  try {
    const { payload } = action;
    yield put(actions.setLinkedAppLoading(payload.id));
    const response: AxiosResponse<Application> = yield call(
      ApplicationsApi.linkToProduct,
      payload
    );
    if (response.data.id) {
      yield put(actions.setLinkedAppLoading(payload.id));
      yield put(
        actions.setNetworkProductIds({
          appId: response.data.id,
          productId: payload.productId,
        })
      );
    }
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(actions.setLinkedAppLoading(action.payload.id));
  }
}

export function* unlinkApplicationNetworkToProductSaga(
  action: ReturnType<typeof linkNetworkToProductAction>
) {
  try {
    const { payload } = action;
    yield put(actions.setLinkedAppLoading(payload.id));
    const response: AxiosResponse<Application> = yield call(
      ApplicationsApi.unlinkToProduct,
      payload
    );
    if (response.data) yield put(actions.setLinkedAppLoading(payload.id));
    yield put(
      actions.setNetworkProductIds({
        appId: response.data.id,
        productId: payload.productId,
      })
    );
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(actions.setLinkedAppLoading(action.payload.id));
  }
}

export function* linkApplicationDirectToProductSaga(
  action: ReturnType<typeof linkDirectToProductAction>
) {
  try {
    const { payload } = action;
    yield put(actions.setLinkedAppLoading(payload.id));
    const response: AxiosResponse<Application> = yield call(
      ApplicationsApi.linkToProduct,
      payload
    );
    if (response.data.id) {
      yield put(actions.setLinkedAppLoading(payload.id));
      yield put(
        actions.setDirectProductIds({
          appId: response.data.id,
          productId: payload.productId,
        })
      );
    }
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(actions.setLinkedAppLoading(action.payload.id));
  }
}

export function* unlinkApplicationDirectToProductSaga(
  action: ReturnType<typeof unlinkDirectToProductAction>
) {
  try {
    const { payload } = action;
    yield put(actions.setLinkedAppLoading(payload.id));
    const response: AxiosResponse<Application> = yield call(
      ApplicationsApi.unlinkToProduct,
      payload
    );
    if (response.data.id) {
      yield put(actions.setLinkedAppLoading(payload.id));
      yield put(
        actions.setDirectProductIds({
          appId: response.data.id,
          productId: payload.productId,
        })
      );
    }
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(actions.setLinkedAppLoading(action.payload.id));
  }
}
export function* linkApplicationProductSaga(
  action: ReturnType<typeof linkNetworkToProductAction>
) {
  try {
    const { payload } = action;
    yield put(actions.setLinkedArrayLoading(payload.productId));
    const response: AxiosResponse<Application> = yield call(
      ApplicationsApi.linkToProduct,
      payload
    );
    if (response.data.id) {
      yield put(actions.setLinkedArrayData(payload.productId));
      yield put(actions.setLinkedArrayLoading(payload.productId));
    }
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(actions.setLinkedArrayLoading(action.payload.productId));
  }
}

export function* unlinkApplicationToProductSaga(
  action: ReturnType<typeof linkNetworkToProductAction>
) {
  try {
    const { payload } = action;
    yield put(actions.setLinkedArrayLoading(payload.productId));
    const response: AxiosResponse<Application> = yield call(
      ApplicationsApi.unlinkToProduct,
      payload
    );
    if (response.data.id) {
      yield put(actions.setLinkedArrayLoading(payload.productId));
      yield put(actions.setLinkedArrayData(payload.productId));
    }
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(actions.setLinkedArrayLoading(action.payload.productId));
  }
}
export function* getLinkedProductsSaga(
  action: ReturnType<typeof getLinkedProductsAction>
) {
  try {
    if (action.payload.productIds.length <= 0) {
      yield put(actions.setLinkedProducts({ items: [], totalCount: 0 }));
    } else {
      yield put(actions.setLinkedLoading(true));
      const response: AxiosResponse<LinkedProductsResponse> = yield call(
        ProductsApi.getLinkedProducts,
        action.payload
      );
      yield put(actions.setLinkedProducts(response.data));
    }
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(actions.setLinkedLoading(false));
    yield put(actions.setLinkedProducts({ items: [], totalCount: 0 }));
  }
}

export function* getApplicationByIdSaga(
  action: ReturnType<typeof getApplicationByIdAction>
) {
  try {
    yield put(actions.setApplicationItemLoading(true));
    const response: AxiosResponse<Application> = yield call(
      ApplicationsApi.getApplicationById,
      action.payload
    );
    if (response.data) {
      yield put(actions.setApplicationItem(response.data));
    }
  } catch (e) {
    sagaErrorBoundary(e);
  } finally {
    yield put(actions.setApplicationItemLoading(false));
  }
}
export function* ApplicationsWatcher() {
  yield takeLatest(getApplicationsAction, getApplicationsSaga);
  yield takeEvery(
    linkNetworkToProductAction,
    linkApplicationNetworkToProductSaga
  );
  yield takeEvery(
    unlinkNetworkToProductAction,
    unlinkApplicationNetworkToProductSaga
  );
  yield takeEvery(
    linkDirectToProductAction,
    linkApplicationDirectToProductSaga
  );
  yield takeEvery(
    unlinkDirectToProductAction,
    unlinkApplicationDirectToProductSaga
  );
  yield takeEvery(linkToProductAction, linkApplicationProductSaga);
  yield takeEvery(unlinkNToProductAction, unlinkApplicationToProductSaga);
  yield takeLatest(
    getApplicationsNetworkDataAction,
    getApplicationsNetworkSaga
  );
  yield takeLatest(getApplicationsDirectDataAction, getApplicationsDirectSaga);
  yield takeLatest(getLinkedProductsAction, getLinkedProductsSaga);
  yield takeLatest(getApplicationByIdAction, getApplicationByIdSaga);
}
