import React from "react";
import { useLocation } from "react-router-dom";

import { Grid, InputAdornment } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import Button from "shared/ui/Button";
import FormGroup from "../../FormComponents/FormGroup";

import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import {
  Block,
  Text,
  StyledAvatar,
  WarningWrap,
  WarningIcon,
  WarningTextWrap,
} from "./styles";

interface GeneralInfoProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  phone: string;
  location: string;
}

const GeneralInfo = ({
  handleChange,
  firstName,
  lastName,
  title,
  email,
  phone,
  location,
}: GeneralInfoProps) => {
  const [image, setImage] = React.useState(null);
  const { pathname } = useLocation();
  const inputEl: any = React.useRef(null);

  const handleUpload = () => {
    inputEl.current.click();
  };

  return (
    <>
      {pathname === "/user-management/archive" && (
        <WarningWrap data-testid="editUserModal_generalInfoWarning">
          <WarningIcon>
            <WarningAmberIcon />
          </WarningIcon>
          <WarningTextWrap>
            This User is Inactive. Your can restore this user with all
            permissions and settings. Please review and update user details if
            needed.
          </WarningTextWrap>
        </WarningWrap>
      )}

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={2}>
          <StyledAvatar
            sx={{ bgcolor: "#F2F2FB", width: 100, height: 100 }}
            src={image ? URL.createObjectURL(image) : ""}
            data-testid="editUserModal_avatar"
          >
            JS
          </StyledAvatar>
        </Grid>
        <Grid item xs={10}>
          <input
            type="file"
            name="myImage"
            hidden
            onChange={(event: any) => {
              setImage(event.target.files[0]);
            }}
            ref={inputEl}
          />
          <Button
            variant="contained"
            onClick={handleUpload}
            color="secondary"
            startIcon={<DownloadIcon />}
            data-testid="editUserModal_changeImageButton"
          >
            {image ? "Change Image" : "Upload Image"}
          </Button>
          <Text>
            Please upload a .jpg or a .png file with a minimum dimension of 400w
            x 400h not exceeding 5MB.
          </Text>
        </Grid>
      </Grid>
      <Block>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormGroup
              placeholder="John"
              value={firstName}
              id="firstName"
              onChange={handleChange}
              label="First Name"
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup
              placeholder="Smith"
              value={lastName}
              id="lastName"
              onChange={handleChange}
              label="Last Name"
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup
              disabled
              value={
                firstName || lastName ? `${firstName} ${lastName}` : "Full Name"
              }
              id="fullName"
              label="Full Name"
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup
              placeholder="Enter Title"
              value={title}
              id="title"
              onChange={handleChange}
              label="Title"
            />
          </Grid>
        </Grid>
      </Block>
      <Block>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormGroup
              disabled
              label="Email"
              type="email"
              placeholder="Enter Email"
              value={email}
              id="email"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup
              label="Phone"
              placeholder="Enter Phone Number"
              value={phone}
              id="phone"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup
              label="Location"
              placeholder="Enter Location"
              value={location}
              id="location"
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Block>
    </>
  );
};

export default GeneralInfo;
