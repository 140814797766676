import React from "react";
import { Box } from "@mui/material";
import { PageTitle } from "shared/ui/pageTitle";
import Notifications from "components/Notifications";

const NotificationsPage = () => {
  return (
    <Box data-testid="products-page">
      <PageTitle title={"Notifications"} />
      <Notifications />
    </Box>
  );
};

export default NotificationsPage;
