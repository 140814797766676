import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";

import { Grid, IconButton } from "@mui/material";

import { StyledGrid, PaginationGrid, Wrapper } from "./styles";

// components
import ProductsTable from "components/Table";
import Modal from "components/Modals/CreateRequest/RequestModal";
import Button from "shared/ui/Button";
import { SearchField } from "shared/ui/searchField";
import ExpandAllButton from "shared/ui/ExpandAllButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// types
import { ProductResponse } from "shared/api/products/types";
import { AssociateProductData, initialColumns } from "./types";
import { RootState } from "redux/store";
import { CategoryData } from "shared/api/productCategories/types";
import { OrderTypes } from "shared/config/types/Sorting";

import { useAppSelector } from "redux/store/hooks/hooks";
import { getCategoriesList } from "entities/productCategories/model/selectors";
import { getProductsList } from "entities/products/model/selectors";
import { useProducts } from "features/associatedProducts/model/hooks/useProducts";
import { sortByOrder } from "utils/array";

interface AssociateProductProps {
  open: boolean;
  handleClose: () => void;
  categoryId?: string;
  enrichId?: string;
  step?: number;
  activateProduct?: (productId: string) => void;
  type?: string;
}

export const AssociatedProductsModal = ({
  open,
  handleClose,
  categoryId,
  enrichId,
  step = 3,
  activateProduct,
  type,
}: AssociateProductProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [openning, setOpenning] = useState(false);
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [associateProducts, setAssociateProducts] = useState<
    AssociateProductData[]
  >([]);
  const [order, setOrder] = useState<OrderTypes | null>(null);
  const [searchQuery] = useDebounce(searchValue, 2000);

  const products: ProductResponse[] = useAppSelector(getProductsList);
  const productCategories: CategoryData[] = useAppSelector(getCategoriesList);

  const productsByCategory = useSelector(
    (state: RootState) => state.products.productByCategory.products
  );
  const {
    getProducts,
    getProductsByCategory,
    getProductCategories,
    processAssociatedProducts,
    searchProductsByName,
    updateProductVersion,
  } = useProducts();
  const navigate = useNavigate();

  useEffect(() => {
    if (!productCategories.length) getProductCategories();
    if (!products.length) getProducts(page);
  }, []);

  useEffect(() => {
    if (order) {
      const filters = {
        name: order.label,
        sortKey: order.type,
      };
      const sorted = sortByOrder([...associateProducts], filters);
      if (sorted.length > 0) setAssociateProducts(sorted);
    }
  }, [order]);

  useEffect(() => {
    if (!productsByCategory.length && categoryId) {
      getProductsByCategory(page, categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    if (products.length && !categoryId) {
      const result = processAssociatedProducts(products);
      if (result.length) setAssociateProducts(result);
    }
    if (products.length && searching) {
      const result = processAssociatedProducts(products);
      if (result.length) setAssociateProducts(result);
      setSearching(!searching);
    }
  }, [products]);

  useEffect(() => {
    if (productsByCategory.length && productCategories.length) {
      const result = processAssociatedProducts(productsByCategory);
      if (result.length) setAssociateProducts(result);
    }
  }, [productsByCategory]);

  useEffect(() => {
    if (searchQuery) {
      setSearching(!searching);
      searchProductsByName(searchQuery);
    } else {
      setPage(1);
      getProducts(1);
    }
  }, [searchQuery]);

  const handleActivate = (product: AssociateProductData) => {
    if (activateProduct) {
      activateProduct(product.id);
      navigate(`/products/list/${product.id}`);
      return;
    }
    if (type === "dataEnrichment") {
      if (product.productStatus === "Published") updateProductVersion(product);
      navigate(`/create_new_product/${product.id}/?step=${step}&tab=enrich`);
    }
    if (type === "thirdParty") {
      if (product.productStatus === "Published") updateProductVersion(product);
      navigate(
        `/create_new_product/${product.id}/?step=${step}&tab=thirdParty`
      );
    }
  };

  const handleBack = () => {
    if (page >= 1) {
      if (page === 1) setPage(1);
      else setPage(page - 1);
      if (page > 1) {
        if (categoryId)
          getProductsByCategory(page - 1, categoryId, searchQuery);
        else getProducts(page - 1, searchQuery);
      }
    }
  };

  const handleNext = () => {
    setPage(page + 1);
    if (categoryId) getProductsByCategory(page + 1, categoryId, searchQuery);
    else getProducts(page + 1, searchQuery);
  };

  return (
    <Modal
      onClose={handleClose}
      open={open}
      title={"Associate Product"}
      copying={false}
      hideBottom={false}
      submitButton={
        <Button
          variant="contained"
          onClick={handleClose}
          data-testid="associateProduct_button"
        >
          {"Done"}
        </Button>
      }
      sidepadding="35"
      closeIconRightSpacing={20}
    >
      <Wrapper>
        <StyledGrid
          container
          spacing={3}
          sx={{ justifyContent: "center", marginTop: "0" }}
        >
          <Grid item xs={10}>
            <SearchField
              value={searchValue}
              onChange={(e: any) => setSearchValue(e.target.value)}
              id={"searchField-products"}
              placeholder={"Search Products"}
              sx={{ width: "493px", padding: "20px 0 0" }}
            />
          </Grid>
          <Grid item xs={10}>
            <ExpandAllButton
              onClick={() => setOpenning(!openning)}
              sx={{
                marginBottom: "24px",
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <ProductsTable
              rows={associateProducts}
              columns={initialColumns}
              rowHasChip={true}
              chipIndex={initialColumns.length - 1}
              onEdit={handleActivate}
              hideCheckbox={true}
              setSortOrder={(order: OrderTypes) => setOrder(order)}
            />
          </Grid>
          <PaginationGrid item xs={10}>
            <IconButton
              aria-label="back"
              onClick={handleBack}
              sx={{
                opacity: page === 1 ? 0.3 : 1,
              }}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton aria-label="next" onClick={handleNext}>
              <NavigateNextIcon />
            </IconButton>
          </PaginationGrid>
        </StyledGrid>
      </Wrapper>
    </Modal>
  );
};
