import React, { ChangeEvent } from "react";
import { CommentWrapper, StyledAttachIcon, StyledSendIcon } from "../styles";
import { Button, Chip, Grid, IconButton, InputAdornment } from "@mui/material";
import Comment from "./Comment";
import UploadedFiles from "./UploadedFiles";
import FormGroup from "../../../FormComponents/FormGroup";
import { RequestFile, CommentSchema } from "shared/api/requests/types";

interface CommentSectionProps {
  userComment: string;
  renderFiles: boolean;
  file: File | undefined;
  comments: CommentSchema[];
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  removeFile: () => void;
  removeUploadedFile: (file: RequestFile) => void;
  attachNewComment: () => void;
  files: RequestFile[];
}

const CommentSection = ({
  file,
  files,
  comments,
  userComment,
  renderFiles,
  attachNewComment,
  removeFile,
  removeUploadedFile,
  handleFileUpload,
  handleChange,
}: CommentSectionProps) => {
  const placeholder = renderFiles ? "Upload file" : "Type something";
  return (
    <CommentWrapper>
      {!renderFiles ? (
        <Comment comments={comments} />
      ) : (
        <UploadedFiles
          files={files}
          comments={comments}
          removeUploadedFile={removeUploadedFile}
        />
      )}
      <Grid
        container
        spacing={3}
        sx={{
          position: "absolute",
          bottom: "0",
        }}
      >
        <Grid item xs={12}>
          <FormGroup
            value={userComment}
            id="userComment"
            onChange={handleChange}
            disabled={renderFiles}
            placeholder={file ? "" : placeholder}
            multiline={true}
            maxRows={1}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    aria-label="upload attachment"
                    component="label"
                    sx={{ paddingRight: "0" }}
                  >
                    <input
                      type="file"
                      onChange={handleFileUpload}
                      hidden
                      multiple
                    />
                    <StyledAttachIcon />
                  </IconButton>
                  <Button
                    variant="contained"
                    endIcon={<StyledSendIcon />}
                    onClick={attachNewComment}
                    sx={{
                      background: "var(--secondary)",
                      textTransform: "capitalize",
                    }}
                  >
                    Send
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          {file && (
            <Chip
              label={file.name}
              onDelete={removeFile}
              sx={{
                position: "absolute",
                top: "34px",
                left: "34px",
                maxWidth: "calc(100% - 188px)",
              }}
            />
          )}
        </Grid>
      </Grid>
    </CommentWrapper>
  );
};

export default CommentSection;
