import styled from "styled-components";
import { TableRow } from "@mui/material";

export const StyledTableRow = styled(TableRow)<{ chiped?: boolean }>`
  &.MuiTableRow-root {
    &:nth-child(odd) {
      background: #f9f9f9;
    }
    height: ${(props) => (props.chiped ? "61px" : "inherit")};
  }

  &.MuiTableRow-root.Mui-selected {
    background-color: var(--ghost);
    .MuiAvatar-root {
      background: var(--white);
    }
  }
  &.MuiTableRow-hover.MuiTableRow-root:hover {
    box-shadow: 10px 10px 40px rgba(111, 138, 231, 0.25);
    background-color: transparent;
    position: relative;
    z-index: 1;
  }

  &.MuiTableRow-hover.Mui-selected.MuiTableRow-root:hover {
    background-color: var(--ghost);
  }
  td {
    font-family: "Inter", sans-serif;
  }

  td.MuiTableCell-paddingCheckbox {
    padding: 0 0 0 4px;
  }

  .MuiCheckbox-root {
    path {
      fill: var(--primary);
    }
  }
`;

export const NoDataWrapperRelative = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-weight: 400;
      color: var(--grey);
      font-size: 18px;
      line-height: 29px;
      margin-top: 20px;
      margin-left: 20px;
    }
  }
`;
