import React from "react";
import { Typography } from "@mui/material";

export const Subtitle = ({ children }: any) => {
  return (
    <Typography
      sx={{
        color: "#6266EA",
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "24px",
        marginBottom: "24px",
        textTransform: "uppercase",
      }}
    >
      {children}
    </Typography>
  );
};
