import styled from "styled-components";

const pickBackgroundColor = (color: string) => {
  switch (color) {
    case "secondary":
      return "rgba(98, 102, 234, 0.25)";
    case "primary":
      return "rgba(56, 56, 116, 0.25)";
    case "ghost":
      return "var(--ghost)";
    default:
      return "rgba(98, 102, 234, 0.25)";
  }
};

const pickFillColor = (color: string) => {
  if (color === "secondary") {
    return "var(--secondary)";
  } else {
    return "var(--primary)";
  }
};

export const StyledImageWrap = styled.div<{
  color: string;
  fillColor: string;
}>`
  display: flex;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => pickBackgroundColor(props.color)};
  border-radius: 50%;
  margin-right: 10px;
  transition: background-color 0.2s;
  svg {
    fill: ${(props) => pickFillColor(props.fillColor)};
  }
`;
