import React, { useEffect, useState } from "react";

import { TablePagination } from "@mui/material";
import TableControls from "shared/ui/TableControls/TableControls";
import { useLocation } from "react-router-dom";
import {
  useLinkedProductsAction,
  useLinkedProductsData,
} from "features/applications";
import { useGetWidgetsActions } from "features/widgets";
import { OrderTypes } from "shared/config/types/Sorting";
import { SORT } from "shared/config/enums/Sorting";
import { useDebounce } from "use-debounce";
import { AssociatedProductsTable } from "widgets/AssociatedProductsTable";
import TablePaper from "shared/ui/TablePaper";

export interface LocationProps {
  state: {
    productIds: string[];
    appId?: string;
    widgetId?: string;
  };
}
export const AssociatedProducts = () => {
  const [openCreateRequest, setOpenCreateRequest] = useState(false);
  const [order, setOrder] = useState<OrderTypes>({ type: SORT.ASC, label: "" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery] = useDebounce(searchValue, 500);
  const { state } = useLocation() as LocationProps;
  const { setLinkedArrayDataMap } = useGetWidgetsActions();
  const { getLinkedProducts } = useLinkedProductsAction();
  const { data, isLoading, categories, total } = useLinkedProductsData();

  useEffect(() => {
    const offset = page * rowsPerPage;
    if (state?.productIds) {
      const params = new URLSearchParams();
      state.productIds.forEach((productId) =>
        params.append("ProductIds", productId)
      );
      getLinkedProducts({
        productIds: params.toString(),
        offset: offset,
        limit: rowsPerPage,
        search: searchQuery,
        ...(order.label && { sortPreference: order.label }),
        ...(order.label && { sortKind: order.type }),
      });
    }
  }, [getLinkedProducts, state, order, searchQuery, page, rowsPerPage]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
  };

  const openCreateRequestModal = () => {
    setOpenCreateRequest(!openCreateRequest);
  };

  useEffect(() => {
    setLinkedArrayDataMap(state.productIds);
  }, [setLinkedArrayDataMap]);

  return (
    <TablePaper>
      <TableControls
        activeTab={"Product"}
        buttonLabel={"Create Request"}
        handleOpen={openCreateRequestModal}
        searchValue={searchValue}
        handleSearch={(e: any) => setSearchValue(e.target.value)}
        hideCreate={true}
      />
      <AssociatedProductsTable
        appId={state.appId}
        categories={categories}
        order={order}
        data={data}
        widgetId={state.widgetId}
        setOrder={setOrder}
        isLoading={isLoading}
      />
      <TablePagination
        sx={{ marginTop: "30px" }}
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        data-testid="table_pagination"
      />
    </TablePaper>
  );
};
