import { createAction } from "@reduxjs/toolkit";

import {
  ApplicationsLinkPayload,
  ApplicationsPayload,
} from "shared/api/applications/types";

export const getApplicationsAction = createAction<ApplicationsPayload>(
  "applications/getAll"
);
export const linkNetworkToProductAction = createAction<ApplicationsLinkPayload>(
  "applications/link/network"
);
export const unlinkNetworkToProductAction =
  createAction<ApplicationsLinkPayload>("applications/unlink/network");

export const linkDirectToProductAction = createAction<ApplicationsLinkPayload>(
  "applications/link/direct"
);
export const unlinkDirectToProductAction =
  createAction<ApplicationsLinkPayload>("applications/unlink/direct");

export const getApplicationsNetworkDataAction =
  createAction<ApplicationsPayload>("applications/network/getAll");

export const getApplicationsDirectDataAction =
  createAction<ApplicationsPayload>("applications/direct/getAll");

export const linkToProductAction =
  createAction<ApplicationsLinkPayload>("applications/link");
export const unlinkNToProductAction = createAction<ApplicationsLinkPayload>(
  "applications/unlink"
);

export const getApplicationByIdAction = createAction<string>(
  "applications/getById"
);
