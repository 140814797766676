import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState } from "../types/types";
import {
  NotificationTemplateDefinition,
  TemplatesDefinitionsResponse,
} from "shared/api/templates/types";

const initialState: InitialState = {
  loading: false,
  templates: [],
  templatesDefinition: null,
  tenantNotificationTemplates: [],
  count: 0,
};

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    setTemplatesDefinitions: (
      state,
      action: PayloadAction<TemplatesDefinitionsResponse>
    ) => {
      const dataObject = action.payload.notificationTemplateDefinitions.reduce<
        Record<string, NotificationTemplateDefinition>
      >((accum, item) => {
        accum[item.displayName] = item;
        return accum;
      }, {});
      return {
        ...state,
        templatesDefinition: dataObject,
        templates: action.payload.notificationTemplateDefinitions,
      };
    },
    setEventsLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setTemplates: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setEventsLoading, setTemplatesDefinitions, setTemplates } =
  templatesSlice.actions;

export const { reducer: templatesReducer } = templatesSlice;
