import React, { ChangeEvent, useState } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { UserProfile } from "shared/api/users/types";
import { StyledIconWrap } from "widgets/Header/styles";
import { StyledProfilePicture, StyledButton, StyledImage } from "./styles";
import Button from "shared/ui/Button";
import Modal from "components/Modals/CreateRequest/RequestModal";
import FormGroup from "components/FormComponents/FormGroup";
import Label from "components/FormComponents/Label";
import CustomSelect from "components/FormComponents/Select";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";

interface UserProfileProps {
  open: boolean;
  handleClose: () => void;
  profile: UserProfile;
}

export const UserProfileModal = ({
  open,
  handleClose,
  profile,
}: UserProfileProps) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [file, setFile] = useState<File>();
  const [imageUrl, setImageUrl] = useState<any>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, id },
    } = event;
    if (id === "phone") setPhoneNumber(value);
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedfiles = e.target.files;
    if (uploadedfiles && uploadedfiles.length > 0) {
      const uploadedFile = uploadedfiles[0];
      const fileType = uploadedFile.type;
      if (fileType.includes("image")) {
        setFile(uploadedFile);
        const reader = new FileReader();
        reader.readAsDataURL(uploadedFile);
        reader.onload = () => {
          setImageUrl(reader.result);
        };
      }
    }
  };

  return (
    <Modal
      onClose={handleClose}
      open={open}
      title={"Profile"}
      copying={false}
      hideBottom={false}
      submitButton={
        <Button
          variant="contained"
          onClick={handleClose}
          data-testid="updateUserProfile_button"
        >
          {"Update"}
        </Button>
      }
      sidepadding="45"
      closeIconRightSpacing={40}
    >
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: "center",
          height: "calc(100% - 168px)",
          overflow: "auto",
          marginTop: "0",
        }}
      >
        <Grid item xs={10} sx={{ display: "flex" }}>
          <Grid item xs={2.5}>
            <StyledProfilePicture>
              <StyledIconWrap>
                {file ? (
                  <StyledImage src={imageUrl} />
                ) : (
                  <PersonOutlineOutlinedIcon />
                )}
              </StyledIconWrap>
            </StyledProfilePicture>
          </Grid>
          <Grid item xs={9.5}>
            <Grid item xs>
              <StyledButton
                variant="outlined"
                startIcon={<UploadIcon />}
                component="label"
              >
                {"Upload File"}
                <input type="file" onChange={handleFileUpload} hidden />
              </StyledButton>
            </Grid>
            <Grid item xs>
              <Typography sx={{ fontSize: "0.75rem", color: "var(--grey)" }}>
                Please upload a .jpg or a .png file with a minimum dimension of
                400w x 400h not exceeding 5MB.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10} sx={{ paddingTop: "15px" }}>
          <Divider />
        </Grid>
        <Grid item xs={10}>
          <FormGroup
            value={profile.firstName}
            id="firstName"
            label="First Name"
            danger
            disabled
          />
        </Grid>
        <Grid item xs={10}>
          <FormGroup
            value={profile.lastName}
            id="lastName"
            label="Last Name"
            danger
            disabled
          />
        </Grid>
        <Grid item xs={10}>
          <FormGroup
            value={""}
            id="title"
            label="Title"
            placeholder="Enter Title"
            danger
            disabled
          />
        </Grid>
        <Grid item xs={10}>
          <FormGroup
            value={profile.email}
            id="email"
            label="Email"
            danger
            disabled
          />
        </Grid>
        <Grid item xs={10}>
          <FormGroup
            value={phoneNumber}
            id="phone"
            label="Phone"
            placeholder="Enter Phone Number"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={10}>
          <Label label="Request Type" danger />
          <CustomSelect
            value={"Role"}
            id="role"
            options={["Admin", "Super Admin", "User"]}
            disabled
            title="Role"
          />
        </Grid>
        <Grid item xs={10}>
          <FormGroup
            value={""}
            id="group"
            label="Group"
            placeholder="Enter Group"
            disabled
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
