import React, { useMemo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "shared/ui/Modal";
import Button from "shared/ui/Button";
import FormGroup from "components/FormComponents/FormGroup";
import Select from "shared/ui/Select";
import Label from "components/FormComponents/Label";
import { InputBlock } from "./styles";
import { Parameter, RulePayload } from "shared/api/modules/types";
import { useModule, useRule } from "features/modules";
import { modulesMap, Outcomes, RuleSelect } from "entities/modules";

interface ModalProps {
  handleClose: () => void;
  modelName: string;
  open: boolean;
  moduleId: string;
  moduleType?: string;
}

type Data = Omit<RulePayload, "parameters"> & {
  parameters: string[];
  outcomeType?: Outcomes;
};

const options_rule_type = [
  "Validation",
  "Calculation",
  "ConditionalCalculation",
  "ExecutionCondition",
];

const options_type = ["String", "Integer", "Decimal", "Bool"];
const outcome_type = ["Bind", "Lead", "Quote"];

const defaultValues: Data = {
  name: "",
  link: "",
  message: "",
  outputType: "",
  parameters: [],
  expression: "",
  onFailureExpression: "",
  type: "",
  onSuccessExpression: "",
  outcomeType: "Bind",
  attributeName: "",
};

export const AddRuleForm = (props: ModalProps) => {
  const { handleClose, modelName, open, moduleId, moduleType } = props;
  const [typeOptions, setTypeOptions] = useState<string[]>(options_rule_type);
  const [optionTypes, setOptionTypes] = useState<string[]>(options_type);
  const { modules } = useModule();
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      ...defaultValues,
    },
  });

  const typeWatcher = watch("type");

  const { isLoading, creatRule } = useRule();
  const dataModules = useMemo(() => {
    const filteredModules = modules.filter((item) =>
      modulesMap.includes(item.moduleType)
    );
    if (modelName !== "Result") {
      return filteredModules.filter((module) => module.name !== "Result");
    }
    return filteredModules;
  }, [modules, modelName]);

  const optionsDataDisabled = useMemo(() => {
    return dataModules.filter((option) => option.rules.length > 0);
  }, [dataModules]);

  useEffect(() => {
    if (modelName === "Result") {
      const filtered = options_rule_type.filter(
        (item: string) => item !== "ExecutionCondition"
      );
      setTypeOptions(filtered);
    }
    if (
      moduleType === "Enrichment" ||
      moduleType === "Integration" ||
      moduleType === "Fulfillment" ||
      moduleType === "Completion"
    ) {
      setTypeOptions(["ExecutionCondition"]);
      setOptionTypes(["Bool"]);
    }
  }, []);

  const onSubmit = () => {
    handleSubmit((data: Data) => {
      let newData: Data = data;
      const newParams = data.parameters.reduce<Parameter[]>(
        (accum, element) => {
          const item = JSON.parse(element);
          if (item.module) {
            accum.push({ moduleId: item.module, ruleId: item.value });
          }
          return accum;
        },
        []
      );
      if (modelName !== "Result") {
        const { link, message, outcomeType, ...other } = newData;
        newData = other;
      }
      if (typeWatcher === "Validation" || typeWatcher === "Calculation") {
        const { onFailureExpression, onSuccessExpression, ...otherData } =
          newData;
        newData = otherData;
      }
      const payloadData = { ...newData, parameters: newParams };
      creatRule(moduleId, payloadData);
    })();
  };

  return (
    <Modal
      title="Add Rule"
      open={open}
      handleClose={handleClose}
      id="addAttributesModal"
      isLoading={isLoading}
      footer={
        <>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              marginRight: "24px",
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit}>
            Add Rule
          </Button>
        </>
      }
    >
      <form>
        <InputBlock>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormGroup
                  id="name"
                  value={value}
                  label="Name"
                  placeholder="Name"
                  danger={true}
                  onChange={onChange}
                  error={!!error}
                />
              );
            }}
          />
        </InputBlock>
        <InputBlock>
          <Controller
            name="type"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <>
                  <Label label="Rule Type" danger={true} />
                  <Select
                    onChange={onChange}
                    options={typeOptions}
                    id={"type"}
                    error={!!error}
                    value={value || ""}
                    multiple={false}
                  />
                </>
              );
            }}
          />
        </InputBlock>
        <InputBlock>
          <Controller
            name="outputType"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <>
                  <Label label="Output Type" danger={true} />
                  <Select
                    onChange={onChange}
                    options={optionTypes}
                    id={"outputType"}
                    value={value || ""}
                    error={!!error}
                    multiple={false}
                  />
                </>
              );
            }}
          />
        </InputBlock>
        <InputBlock>
          <Controller
            name="parameters"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <RuleSelect
                  onChange={onChange}
                  options={dataModules}
                  id={"parameters"}
                  title={""}
                  value={value || ""}
                  label="Parameters"
                  hideCreate={true}
                  multiple={true}
                  disabled={!optionsDataDisabled.length}
                />
              );
            }}
          />
        </InputBlock>
        <InputBlock>
          <Controller
            name="expression"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormGroup
                id="expression"
                label="Expressions"
                placeholder="Expressions"
                value={value}
                danger={true}
                onChange={onChange}
                error={!!error}
              />
            )}
          />
        </InputBlock>
        {typeWatcher !== "Validation" &&
          typeWatcher !== "Calculation" &&
          typeWatcher !== "ExecutionCondition" && (
            <>
              <InputBlock>
                <Controller
                  name="onSuccessExpression"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormGroup
                      id="onSuccessExpression"
                      label="On Success Expressions"
                      placeholder="On Success Expressions"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </InputBlock>
              <InputBlock>
                <Controller
                  name="onFailureExpression"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormGroup
                      id="onFailureExpression"
                      label="On Failure Expression"
                      placeholder="On Failure Expression"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </InputBlock>
            </>
          )}
        {modelName === "Result" && (
          <>
            <InputBlock>
              <Controller
                name="message"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormGroup
                    id="message"
                    label="Message"
                    placeholder="Message"
                    value={value}
                    danger={true}
                    error={!!error}
                    onChange={onChange}
                  />
                )}
              />
            </InputBlock>
            <InputBlock>
              <Controller
                name="link"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormGroup
                    id="link"
                    label="Link"
                    placeholder="Link"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </InputBlock>
            <InputBlock>
              <Controller
                name="outcomeType"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <Label label="Outcome Type" danger={false} />
                      <Select
                        onChange={onChange}
                        options={outcome_type}
                        id={"outcomeType"}
                        error={!!error}
                        value={value || ""}
                        multiple={false}
                      />
                    </>
                  );
                }}
              />
            </InputBlock>
          </>
        )}
        {(typeWatcher === "Validation" ||
          typeWatcher === "Calculation" ||
          typeWatcher === "ConditionalCalculation") && (
          <InputBlock>
            <Controller
              name="attributeName"
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormGroup
                  id="attributeName"
                  label="Attribute Name"
                  placeholder="Attribute Name"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </InputBlock>
        )}
      </form>
    </Modal>
  );
};
