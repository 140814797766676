import { createSelector } from "@reduxjs/toolkit";
import { getTemplates } from "./getTemplates";

export const getTemplatesDefinition = createSelector(
  getTemplates,
  (templates) => {
    return templates.templatesDefinition;
  }
);
export const getTemplatesData = createSelector(getTemplates, (templates) => {
  return templates.templates;
});

export const getTemplatesLoading = createSelector(getTemplates, (templates) => {
  return templates.loading;
});

export const getNotificationTemplates = createSelector(
  getTemplates,
  (templates) => {
    return templates.tenantNotificationTemplates;
  }
);
