export const requestActions = {
  CREATE_REQUEST: "CREATE_REQUEST",
  CREATE_REQUEST_FAILED: "CREATE_REQUEST_FAILED",
  GET_REQUESTS: "GET_REQUESTS",
  GET_REQUESTS_FAILED: "GET_REQUESTS_FAILED",
  GET_REQUEST: "GET_REQUEST",
  GET_REQUEST_FAILED: "GET_REQUEST_FAILED",
  DELETE_REQUEST: "DELETE_REQUEST",
  DELETE_REQUEST_FAILED: "DELETE_REQUEST_FAILED",
  RESTORE_REQUEST: "RESTORE_REQUEST",
  RESTORE_REQUEST_FAILED: "RESTORE_REQUEST_FAILED",
  ADD_COMMENT: "ADD_COMMENT",
  ADD_COMMENT_FAILED: "ADD_COMMENT_FAILED",
  GET_COMMENTS: "GET_COMMENTS",
  GET_COMMENTS_FAILED: "GET_COMMENTS_FAILED",
  GET_REQUEST_TYPES: "GET_REQUEST_TYPES",
  GET_REQUEST_TYPES_FAILED: "GET_REQUEST_TYPES_FAILED",
  GET_TYPES: "GET_TYPES",
  GET_TYPES_FAILED: "GET_TYPE_FAILED",
  COUNT_NEW_REQUESTS: "COUNT_NEW_REQUESTS",
  COUNT_ACTIVE_REQUESTS: "COUNT_ACTIVE_REQUESTS",
  COUNT_COMPLETED_REQUESTS: "COUNT_COMPLETED_REQUESTS",
  COUNT_ARCHIVED_REQUESTS: "COUNT_ARCHIVED_REQUESTS",
  GET_COMPLETED: "GET_COMPLETED",
  GET_ARCHIVED: "GET_ARCHIVED",
};
