import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Description, Divider, StyledButton } from "./styles";
import Table from "../../AttributesTableCollapsed";
import ExpandAllButton from "shared/ui/ExpandAllButton";
import { SearchField } from "shared/ui/searchField";
import SelectAllButton from "shared/ui/SelectAllButton";

import StyledModal from "../styled-modal";

import { AttributesPayload } from "shared/api/products/types";

import { productsActions } from "entities/products";
import { useAppSelector } from "redux/store/hooks/hooks";

import { columns } from "./types";

import { getProductCategory } from "entities/productCategories/model/selectors";
import { getProduct } from "entities/products/model/selectors";
import {
  buildResponse,
  getAllItems,
  getAllSelectedAttributes,
  recursiveAttributesFormat,
  recursiveSearchDataElements,
} from "../../Steps/DataElements/helpers";
import { generateAttributeData } from "pages/CreateProduct/helpers";

const AddAttributesModal: React.FC<any> = ({
  openRequestDataElementModal,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const productCategory = useAppSelector(getProductCategory);
  const product = useAppSelector(getProduct);

  const [searchValue, setSearchValue] = useState<string>();
  const [openning, setOpenning] = useState(false);
  const [selectedAttr, setSelectedAttr] = useState<string[]>([]);
  const [attributes, setAttributes] = useState<AttributesPayload[]>([]);

  const handleSubmit = () => {
    if (selectedAttr.length) {
      let data = [];
      const formattedData = getAllItems(attributes);
      const newData = buildResponse(selectedAttr, formattedData);
      const newProductAttributes = product.attributes.map((item) => {
        return generateAttributeData(item);
      });
      if (product.attributes.length) {
        data = [...newData, ...newProductAttributes];
      } else {
        data = [...newData];
      }
      dispatch({
        type: productsActions.ADD_ATTRIBUTES_TO_PRODUCT,
        data: {
          attributes: { attributes: data },
          productId: product.id,
          version: product.version,
        },
        alertType: "dataElementsAdded",
      });
      handleClose();
    }
  };

  useEffect(() => {
    if (productCategory?.attributes) {
      setAttributes(recursiveAttributesFormat(productCategory.attributes));
    }
  }, [productCategory]);

  useEffect(() => {
    if (searchValue) {
      const formattedData = recursiveAttributesFormat(
        productCategory.attributes
      );
      const data = recursiveSearchDataElements(formattedData, searchValue);
      setAttributes(data);
    } else {
      setAttributes(recursiveAttributesFormat(productCategory.attributes));
    }
  }, [searchValue]);

  useEffect(() => {
    if (productCategory?.attributes) {
      setAttributes(recursiveAttributesFormat(productCategory.attributes));
    }
  }, [productCategory]);

  const handleSelectAllClick = () => {
    const newArray: string[] = [];
    attributes.forEach((attribute) => {
      getAllSelectedAttributes(attribute, newArray);
    });
    setSelectedAttr(newArray);
  };

  return (
    <StyledModal
      title="Available Data Elements"
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      id="addAttributesModal"
      submitButtonText="Add Data Element"
    >
      <>
        <Description>
          If you can&apos;t find the attribute you need request one&nbsp;
          <StyledButton onClick={openRequestDataElementModal}>
            here.
          </StyledButton>
        </Description>
        <Divider />
        <SearchField
          onChange={(e: any) => setSearchValue(e.target.value)}
          id="search-attributes"
          placeholder="Search Data Elements"
          value={searchValue}
          sx={{ width: "100%", marginBottom: "24px" }}
        />
        <ExpandAllButton
          onClick={() => setOpenning(!openning)}
          sx={{
            marginBottom: "24px",
          }}
        />
        <SelectAllButton
          onClick={handleSelectAllClick}
          sx={{
            marginBottom: "24px",
            marginLeft: "10px",
          }}
        />
        <Table
          rows={attributes}
          selected={selectedAttr}
          setSelected={setSelectedAttr}
          small={true}
          openning={openning}
          columns={columns}
        />
      </>
    </StyledModal>
  );
};

export default AddAttributesModal;
