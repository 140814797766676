import {
  Widget,
  WidgetPayload,
  WidgetPayloadWithId,
  WidgetsPayload,
  WidgetsProductUpdatePayload,
  WidgetsResponse,
} from "./types";
import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";
import { IdOnlyResponse } from "../../config/types/General";

type WidgetsApiProps = WidgetsPayload | undefined;

export default class WidgetsApi {
  static async getAll(
    props: WidgetsApiProps
  ): Promise<AxiosResponse<WidgetsResponse>> {
    return IXPortalApiPrivate.get(
      "/api/Widgets",
      props && {
        params: {
          SearchExpression: props.search,
          "SortingPreferences.Kind": props.sortKind,
          "SortingPreferences.SortingProperty": props.sortPreference,
          offset: props.offset,
          limit: props.limit,
        },
      }
    );
  }

  static async updateWidgetProducts({
    productIds,
    id,
  }: WidgetsProductUpdatePayload): Promise<AxiosResponse<{ id: string }>> {
    return IXPortalApiPrivate.put(`/api/Widgets/${id}/products/bulk`, {
      productIds,
    });
  }

  static async createWidget(
    data: WidgetPayload
  ): Promise<AxiosResponse<IdOnlyResponse>> {
    return IXPortalApiPrivate.post("/api/Widgets", data);
  }

  static async getWidgetById(id: string): Promise<AxiosResponse<Widget>> {
    return IXPortalApiPrivate.get(`/api/Widgets/${id}`);
  }
  static async updateWidgetById({
    id,
    ...otherData
  }: WidgetPayloadWithId): Promise<AxiosResponse<Widget>> {
    return IXPortalApiPrivate.put(`/api/Widgets/${id}`, { ...otherData });
  }

  static async cloneWidget(id: string): Promise<AxiosResponse<Widget>> {
    return IXPortalApiPrivate.post(`/api/Widgets/${id}/clone`, {
      includeLinkedEntities: false,
    });
  }
  static async deleteWidget(id: string): Promise<AxiosResponse<Widget>> {
    return IXPortalApiPrivate.delete(`/api/Widgets/${id}`);
  }
}
