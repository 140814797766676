import { AttributesPayloadWithRoot } from "../../model/selectors";
import {
  AllAttributesItemsFormat,
  FormattedAttributePayload,
} from "shared/api/products/types";

export const getAllItems = (data: AttributesPayloadWithRoot[]) => {
  let obj = {} as any;
  data.forEach((item) => {
    obj[item.name] = { ...item, subAttributes: {} };
    obj = { ...obj, ...getAllItems(item.subAttributes) };
  });
  return obj;
};

export const parseResponse = (response: AllAttributesItemsFormat) => {
  return Object.values(response).reduce<FormattedAttributePayload[]>(
    (acc, item) => {
      const newItem = {
        name: item.name,
        displayName: item.displayName,
        isArray: item.isArray,
        subAttributes: parseResponse(item.subAttributes),
      };
      acc.push(newItem);
      return acc;
    },
    []
  );
};

export const buildResponse = (
  selected: Record<string, string>,
  allItems: AllAttributesItemsFormat
) => {
  const response = {} as any;
  for (const oneOfSelected of Object.keys(selected)) {
    const reversePath = oneOfSelected.split(".").reverse();
    let previousItem: AttributesPayloadWithRoot | undefined = undefined;
    for (const item of reversePath) {
      const Item = allItems[item] as any;
      if (previousItem) {
        Item.subAttributes[previousItem.name] = previousItem;
      }
      if (previousItem?.root === Item.name || reversePath.length === 1) {
        response[Item.name] = Item;
      }
      previousItem = Item;
    }
  }
  return parseResponse(response);
};

export const recursive = (
  attributes: FormattedAttributePayload[],
  root?: string,
  parentConcat = "",
  parentRoute = ""
) => {
  return attributes.reduce<AttributesPayloadWithRoot[]>((accum, item) => {
    const newItem: AttributesPayloadWithRoot = {
      root,
      parentConcat,
      parentRoute: parentRoute
        ? parentRoute + " > " + item.displayName
        : item.displayName,
      isArray: item.isArray,
      fillmentType: "",
      name: item.name,
      displayName: item.displayName,
      subAttributes: [],
    } as AttributesPayloadWithRoot;
    if (item.subAttributes.length > 0) {
      newItem.subAttributes = recursive(
        item.subAttributes,
        root ?? item.name,
        parentConcat ? parentConcat + "." + item.name : item.name,
        parentRoute ? parentRoute + " > " + item.displayName : item.displayName
      );
    }
    accum.push(newItem);
    return accum;
  }, []);
};
export const filterByResponse = (
  attribute: FormattedAttributePayload,
  array: FormattedAttributePayload[]
) => {
  array.push(attribute);
  if (attribute.subAttributes.length > 0) {
    attribute.subAttributes.forEach((item) => {
      filterByResponse(item, array);
    });
  }
  return array;
};

export const defaultSelectedAttributes = (
  responseAttributes: FormattedAttributePayload[]
) => {
  if (responseAttributes.length <= 0) return [];
  const newArray = [] as FormattedAttributePayload[];
  const formattedArray = recursive(responseAttributes);
  formattedArray.forEach((item) => {
    filterByResponse(item, newArray);
  });
  return newArray;
};

export const filterByLabel = (
  attribute: AttributesPayloadWithRoot,
  array: AttributesPayloadWithRoot[]
) => {
  array.push(attribute);
  if (attribute.subAttributes.length > 0) {
    attribute.subAttributes.forEach((item) => {
      filterByLabel(item, array);
    });
  }
  return array;
};
