import React from "react";

import GeneralInformation from "../ProductGeneralInformation";
import Integrations from "./Integrations";
import Distribution from "./Distributions";
import Attributes from "./Attributes";
import CustomerEngagement from "./CustomerEngagement";
import OutcomeConfiguration from "./OutcomeConfiguration";
import Kpis from "./Kpis";
import EventsAndTriggers from "./EventsAndTriggers";
import TablePaper from "shared/ui/TablePaper";

const OverviewTab: React.FC = () => {
  return (
    <TablePaper>
      <GeneralInformation />
      <Attributes />
      <Integrations />
      <OutcomeConfiguration editable={true} />
      <EventsAndTriggers />
      <Distribution />
      <CustomerEngagement />
      <Kpis />
    </TablePaper>
  );
};

export default OverviewTab;
