import React, { FC, ReactNode } from "react";
import { StyledTableRow } from "./styles";
import { TableRowProps } from "@mui/material/TableRow/TableRow";

interface Props extends TableRowProps {
  children: ReactNode;
  borderCollapse?: boolean;
}
export const IXTableRow: FC<Props> = ({ children, ...props }) => (
  <StyledTableRow {...props}>{children}</StyledTableRow>
);
