export enum ActionButtonTypes {
  EDIT = "edit",
  DELETE = "delete",
  RESTORE = "restore",
  CLONE = "clone",
  COPY = "copy",
  COMMENT = "comment",
  VIEW = "view",
  SETTINGS = "settings",
}
