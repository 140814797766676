import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Box, FormControl, FormControlLabel } from "@mui/material";

import FormGroup from "../../FormComponents/FormGroup";
import Label from "../../FormComponents/Label";
import CustomSelect from "shared/ui/Select";
import OutlinedCheckbox from "../../FormComponents/OutlinedCheckbox";
import Modal from "../StyledModal";
import Button from "shared/ui/Button";

import { Form, StyledTypography } from "./styles";

import { productsActions } from "entities/products";

import { AttributesPayload } from "shared/api/products/types";

import { useAppSelector } from "redux/store/hooks/hooks";

import { getProduct } from "entities/products/model/selectors";
import { recursiveTypeAdd } from "../../CreateNewProduct/Steps/DataElements/helpers";

interface AddNewUserProps {
  open: boolean;
  onClose: () => void;
  attribute?: AttributesPayload;
  parentid: string;
}
interface ErrorsProps {
  displayName?: boolean;
  attributeDescription?: boolean;
}

const initialOptions: string[] = [
  "BaseInformation",
  "PostfillmentPrefill",
  "PostfillmentSensitive",
  "Hidden",
  "AdditionalInfo",
];

const EditDataElements = ({
  open,
  onClose,
  attribute,
  parentid,
}: AddNewUserProps) => {
  const dispatch = useDispatch();
  const [displayName, setDisplayName] = useState("");
  const [attributeDescription, setAttributeDescription] = useState("");
  const [regexExpression, setRegexExpression] = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const [values, setValues] = useState("");
  const [format, setFormat] = useState("");
  const [valueType, setValueType] = useState("");
  const [isRequired, setIsRequired] = useState(false);
  const [isArray, setIsArray] = useState(false);
  const [isWriteOnly, setIsWriteOnly] = useState(false);
  const [isRewriteAllowed, setIsRewriteAllowed] = useState(false);
  const [fillmentType, setFillmentType] = useState<string[]>([]);
  const [errors, setErrors] = useState<ErrorsProps>({});
  const [fillmentTypesOptions, setFillmentTypesOptions] = useState<string[]>(
    []
  );
  const [isCompound, setIsCompound] = useState(false);

  const product = useAppSelector(getProduct);

  useEffect(() => {
    if (attribute) {
      setDisplayName(attribute.displayName);
      setAttributeDescription(attribute.attributeDescription);
      setRegexExpression(attribute.regexExpression);
      setDefaultValue(attribute.defaultValue);
      setIsRequired(attribute.isRequired);
      setIsArray(attribute.isArray);
      setIsWriteOnly(attribute.isWriteOnly);
      setValues(attribute.values.join(", "));
      setValueType(attribute.valueType);
      setFormat(attribute.format);
      setFillmentType([attribute.fillmentType]);
      if (attribute.valueType === "CompoundAttribute") {
        setIsCompound(true);
        setFillmentTypesOptions([...initialOptions, "SystemContainer"]);
      } else {
        setFillmentTypesOptions(initialOptions);
      }
      if (attribute.isRewriteAllowed) {
        setIsRewriteAllowed(attribute.isRewriteAllowed);
      }
    }
  }, [attribute]);

  const validateForm = () => {
    if (displayName && attributeDescription) {
      return true;
    } else {
      const err: ErrorsProps = {};
      if (!displayName) err.displayName = true;
      if (!attributeDescription) err.attributeDescription = true;
      setErrors({ ...errors, ...err });
    }
    return false;
  };

  const attributesFormat = (attributes: AttributesPayload[]) => {
    return attributes.map<AttributesPayload[]>((item) => {
      let updated_attribute: any = null;
      if (item.id === attribute?.id) {
        updated_attribute = {
          id: item?.id,
          type: "Category",
          subAttributes:
            item.subAttributes.length > 0
              ? recursiveTypeAdd(item.subAttributes)
              : [],
          systemFieldId: item?.systemFieldId,
          displayName,
          attributeDescription,
          regexExpression,
          defaultValue,
          isRequired,
          isWriteOnly,
          isRewriteAllowed,
          values: values.split(", "),
          valueType,
          format,
          fillmentType: fillmentType[0],
        };
      } else if (item.subAttributes.length) {
        updated_attribute = {
          ...item,
          subAttributes: item.subAttributes.length
            ? attributesFormat(item.subAttributes)
            : [],
          type: "Category",
        };
      } else {
        updated_attribute = {
          ...item,
          type: "Category",
        };
      }
      return updated_attribute;
    }, []);
  };

  const onSubmit = () => {
    const valid = validateForm();

    if (valid) {
      if (
        attribute?.displayName !== displayName ||
        attribute?.fillmentType !== fillmentType[0] ||
        attribute?.attributeDescription !== attributeDescription ||
        attribute?.regexExpression !== regexExpression ||
        attribute?.defaultValue !== defaultValue ||
        attribute?.format !== format ||
        attribute?.isRequired !== isRequired ||
        attribute?.isWriteOnly !== isWriteOnly ||
        attribute?.isRewriteAllowed !== isRewriteAllowed ||
        attribute?.valueType !== valueType ||
        JSON.stringify(attribute?.values) === values
      ) {
        const subAttributes: any[] = attributesFormat(product.attributes);
        const idsRouteForSubmit = parentid.split(".");

        const submit_data = {
          attribute:
            subAttributes.find((item) => item.id === idsRouteForSubmit[0]) ||
            subAttributes[0],
          attributeId:
            subAttributes.find((item) => item.id === idsRouteForSubmit[0])
              ?.id ?? subAttributes[0].id,
        };

        dispatch({
          type: productsActions.UPDATE_PRODUCT_ATTRIBUTE,
          data: {
            ...submit_data,
            productId: product?.id,
            version: product?.version,
          },
        });
      }
      onClose();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, id },
    } = event;
    setErrors({
      ...errors,
      [id]: false,
    });
    if (id === "displayName") setDisplayName(value);
    if (id === "attributeDescription" && value.length < 251)
      setAttributeDescription(value);
  };

  const selectFillmentType = (event: any) => {
    const {
      target: { value },
    } = event;
    setFillmentType(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Modal
      id="editDataElementsModal"
      onClose={onClose}
      open={open}
      title="Edit Data Elements"
      fullsize={true}
      submitButton={
        <Button
          variant="contained"
          onClick={onSubmit}
          data-testid="editDataElementsModal_button"
        >
          Update
        </Button>
      }
    >
      <Form dividers>
        <Grid container spacing={3} sx={{ maxWidth: "900px", margin: "auto" }}>
          <Grid item xs={6}>
            <FormGroup
              danger
              value={displayName}
              id="displayName"
              onChange={handleChange}
              error={errors?.displayName}
              label="Display Name"
              placeholder="-"
            />
          </Grid>
          <Grid item xs={6}>
            <Label label="Fillment Type" />
            {fillmentTypesOptions.length && (
              <CustomSelect
                value={fillmentType}
                id="fillmentType"
                options={fillmentTypesOptions}
                onChange={selectFillmentType}
                disabled={isCompound || false}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <FormGroup
              danger
              value={attributeDescription}
              id="attributeDescription"
              onChange={handleChange}
              error={errors?.attributeDescription}
              label="Description"
              placeholder="-"
            />
            <Box sx={{ textAlign: "right", marginTop: "6px" }}>
              <StyledTypography className="warnings">
                {attributeDescription.length}/250 characters
              </StyledTypography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <FormGroup
              value={regexExpression}
              id="regexExpression"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRegexExpression(e.target.value)
              }
              label="Regex Expression"
              placeholder="-"
              disabled={isCompound || false}
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup
              value={defaultValue}
              id="defaultValue"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDefaultValue(e.target.value)
              }
              label="Default Value"
              placeholder="-"
              disabled={isCompound || false}
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup
              value={values}
              id="values"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValues(e.target.value)
              }
              label="Values"
              placeholder="-"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup
              value={format}
              id="format"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFormat(e.target.value)
              }
              label="Format"
              placeholder="-"
              disabled={isCompound || false}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "row",
                paddingTop: isCompound ? "42px" : "25px",
                flexWrap: "wrap",
              }}
            >
              <FormControlLabel
                control={
                  <OutlinedCheckbox
                    checked={isRequired}
                    disabled={isCompound || false}
                    name="isRequired"
                    onChange={() => {
                      setIsRequired(!isRequired);
                    }}
                  />
                }
                label="Required Attribute"
              />
              {attribute?.valueType !== "CompoundAttribute" && (
                <FormControlLabel
                  control={
                    <OutlinedCheckbox
                      checked={isWriteOnly}
                      name="isWriteOnly"
                      onChange={() => {
                        setIsWriteOnly(!isWriteOnly);
                      }}
                    />
                  }
                  label="Is Write Only"
                />
              )}
              <FormControlLabel
                control={
                  <OutlinedCheckbox
                    checked={isRewriteAllowed}
                    name="isReWriteAllowed"
                    onChange={() => {
                      setIsRewriteAllowed(!isRewriteAllowed);
                    }}
                  />
                }
                label="Is Rewrite Allowed"
              />
              <FormControlLabel
                control={
                  <OutlinedCheckbox
                    checked={isArray}
                    disabled={true}
                    name="isArray"
                  />
                }
                label="is Array"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Label label="Value Type" />
            <CustomSelect
              disabled={true}
              value={valueType}
              id="valueType"
              options={[]}
              onChange={(e) => setValueType(e.target.value)}
            />
          </Grid>
        </Grid>
      </Form>
    </Modal>
  );
};

export default EditDataElements;
