import React from "react";
import { FormHelperText } from "@mui/material";

interface ErrorProps {
  message?: string;
  type: string;
  ref?: RefProps;
}
interface RefProps {
  name: string;
}

interface ErrorTextProps {
  error: ErrorProps;
}

export const ErrorText = ({ error }: ErrorTextProps) => {
  return (
    <FormHelperText sx={{ color: "#d32f2f", marginLeft: "14px" }}>
      {error?.message ? error.message : "This field is required."}
    </FormHelperText>
  );
};
