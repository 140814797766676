import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Module,
  ModulesQueue,
  ModulesResponse,
  ModuleTemplate,
  ModuleTemplatesResponse,
} from "shared/api/modules/types";
import { Modules, ModuleTypes } from "../types/enum";

interface InitialState {
  loading: boolean;
  ruleLoading: boolean;
  moduleLoading: boolean;
  templatesLoading: boolean;
  attributesLoading: boolean;
  modules: Module[];
  enrichedModules: Module[];
  thirdPartyModules: Module[];
  modulesNames: string[];
  moduleOrderQueue: number;
  enrichment: ModuleTemplate[];
  integrations: ModuleTemplate[];
  dataModules: Record<string, Module> | null;
  resultAttributesError: boolean;
}
const initialState: InitialState = {
  loading: true,
  ruleLoading: false,
  moduleLoading: false,
  templatesLoading: false,
  modules: [],
  modulesNames: [],
  enrichedModules: [],
  thirdPartyModules: [],
  moduleOrderQueue: 0,
  enrichment: [],
  integrations: [],
  dataModules: null,
  attributesLoading: false,
  resultAttributesError: false,
};
export const modulesMap: string[] = [
  "Appetite",
  "Result",
  "Eligibility",
  "Rating",
  "Payment",
  "Bind",
  "Notifications",
  "Fulfillment",
  "Enrichment",
  "Integration",
  "Rule",
  "Completion",
];

export const modulesSlice = createSlice({
  name: "modules",
  initialState,
  reducers: {
    setModuleOrderQueue: (state, action: PayloadAction<ModulesResponse>) => {
      const queues = action.payload.modules.map(
        (item) => item.moduleOrderQueue
      );
      return {
        ...state,
        moduleOrderQueue: Math.max(...queues),
      };
    },
    setNewOrder: (state, action: PayloadAction<ModulesQueue[]>) => {
      const newDataModulesOrder = state.dataModules
        ? Object.entries(state.dataModules).reduce<Record<string, Module>>(
            (accum, [key, item]) => {
              const moduleItem = action.payload.find(
                (itemQueue) => itemQueue.moduleId === item.id
              );
              accum[key] = {
                ...item,
                ...(moduleItem && {
                  moduleOrderQueue: moduleItem.moduleOrderQueue,
                }),
              };
              return accum;
            },
            {}
          )
        : state.dataModules;
      const newModulesOrder = state.modules.map((item) => {
        const moduleItem = action.payload.find(
          (itemQueue) => itemQueue.moduleId === item.id
        );
        if (moduleItem) {
          return {
            ...item,
            moduleOrderQueue: moduleItem.moduleOrderQueue,
          };
        }
        return item;
      });
      return {
        ...state,
        dataModules: newDataModulesOrder,
        modules: newModulesOrder,
      };
    },
    setModules: (state, action: PayloadAction<ModulesResponse>) => {
      const enrichedModules = action.payload.modules.filter((item) =>
        ["Enrichment"].includes(item.moduleType)
      );
      const thirdPartyModules = action.payload.modules.filter((item) =>
        ["Integration"].includes(item.moduleType)
      );
      const filteredModules = action.payload.modules.filter((item) =>
        modulesMap.includes(item.moduleType)
      );
      const modulesNames = filteredModules.map((item) => item.name);
      const modulesObject = filteredModules.reduce<Record<string, Module>>(
        (accum, module) => {
          if (
            module.moduleType === ModuleTypes.FULFILLMENT ||
            module.moduleType === ModuleTypes.ENRICHMENT ||
            module.moduleType === ModuleTypes.INTEGRATION
          ) {
            accum[
              (module.moduleType.toLowerCase() as Modules) + "." + module.id
            ] = module;
          } else {
            accum[(module.name.toLowerCase() as Modules) + "." + module.id] =
              module;
          }
          return accum;
        },
        {} as Record<Modules, Module>
      );
      return {
        ...state,
        modules: filteredModules,
        modulesNames: modulesNames,
        dataModules: modulesObject,
        enrichedModules: enrichedModules,
        thirdPartyModules: thirdPartyModules,
      };
    },
    setTemplates: (state, action: PayloadAction<ModuleTemplatesResponse>) => {
      const dataTemplates = processedModules(action);
      return {
        ...state,
        enrichment: dataTemplates?.enrichment || [],
        integrations: dataTemplates?.integrations || [],
        templatesLoading: false,
      };
    },
    setModulesLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setModuleLoading: (state, action) => {
      return {
        ...state,
        moduleLoading: action.payload,
      };
    },
    setRuleLoading: (state, action) => {
      return {
        ...state,
        ruleLoading: action.payload,
      };
    },
    setAttributesLoading: (state, action) => {
      return {
        ...state,
        attributesLoading: action.payload,
      };
    },
    setResultsAttributesError: (state, action) => {
      return {
        ...state,
        resultAttributesError: action.payload,
      };
    },
    setResponseAttributesForResult: (state, action) => {
      if (!state.dataModules) {
        return {
          ...state,
        };
      }
      const modules = { ...state.dataModules };
      const keys = Object.keys(modules);
      const fulfillKey = keys.find((item: string) =>
        item.includes(action.payload.moduleId)
      );
      const isModuleResult = fulfillKey?.includes("result");
      if (fulfillKey) {
        return {
          ...state,
          resultAttributesError:
            (isModuleResult && action.payload.attributes.length <= 0) || false,
          dataModules: {
            ...state.dataModules,
            [fulfillKey]: {
              ...state.dataModules[fulfillKey],
              responseAttributes: action.payload.attributes,
            },
          },
        };
      }
    },
    clearData: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

const processedModules = (action: PayloadAction<ModuleTemplatesResponse>) => {
  const dataTemplates =
    action.payload.items.length > 0
      ? action.payload.items.reduce<Record<string, ModuleTemplate[]>>(
          (accum, template) => {
            if (template.moduleConfiguration.moduleType === "Enrichment") {
              accum.enrichment = accum.enrichment
                ? [...accum.enrichment, template]
                : [template];
            }
            if (template.moduleConfiguration.moduleType === "Integration") {
              accum.integrations = accum.integrations
                ? [...accum.integrations, template]
                : [template];
            }
            return accum;
          },
          {}
        )
      : { enrichment: [], integrations: [] };
  return dataTemplates;
};

export const {
  setModuleOrderQueue,
  setModules,
  setModulesLoading,
  setRuleLoading,
  setModuleLoading,
  clearData,
  setTemplates,
  setAttributesLoading,
  setResultsAttributesError,
  setResponseAttributesForResult,
  setNewOrder,
} = modulesSlice.actions;

export const { reducer: modulesReducer } = modulesSlice;
