import { RootState } from "redux/store";
import { createSelector } from "@reduxjs/toolkit";
import { AttributesPayload } from "shared/api/products/types";

export const getProducts = (state: RootState) => state.products;

export const getPublishLoader = createSelector(getProducts, (products) => {
  return products.publishLoader;
});
export const getProduct = createSelector(getProducts, (products) => {
  return products.product;
});

export const getProgram = createSelector(getProducts, (products) => {
  return products.program;
});

export const getIsLoading = createSelector(getProducts, (products) => {
  return products.loading;
});

export const getProductsList = createSelector(getProducts, (products) => {
  return products.products;
});

export const getProductsTotal = createSelector(getProducts, (products) => {
  return products.totalCount;
});

export const getProductsLoading = createSelector(getProducts, (products) => {
  return products.loading;
});

export const getProductVersions = createSelector(getProducts, (products) => {
  return products.versions;
});

export const getProductModules = createSelector(getProducts, (products) => {
  return products.modules;
});
export const getProductAttributes = createSelector(getProduct, (product) => {
  if (!product.attributes) return {};
  return product.attributes.reduce<Record<string, AttributesPayload>>(
    (accum, attr) => {
      accum[attr.attributeName] = attr;
      return accum;
    },
    {}
  );
});
export type AttributeItem = {
  name: string;
  attributeName: string;
  isArray: boolean;
  checked?: boolean;
  sub: Record<string, AttributeItem> | null;
};

export type AttributesPayloadWithRoot = {
  root?: string;
  name: string;
  isArray: boolean;
  displayName: string;
  fillmentType?: string;
  parentConcat?: string;
  parentRoute?: string;
  subAttributes: AttributesPayloadWithRoot[];
};

export const recursive = (
  attributes: AttributesPayload[],
  root?: string,
  parentConcat = "",
  parentRoute = ""
) => {
  return attributes.reduce<AttributesPayloadWithRoot[]>((accum, item) => {
    const newItem: AttributesPayloadWithRoot = {
      root,
      parentConcat,
      parentRoute: parentRoute
        ? parentRoute + " > " + item.displayName
        : item.displayName,
      isArray: item.isArray,
      fillmentType: item.fillmentType || "",
      name: item.attributeName,
      displayName: item.displayName,
      subAttributes: [],
    } as AttributesPayloadWithRoot;
    if (item.subAttributes.length > 0) {
      newItem.subAttributes = recursive(
        item.subAttributes,
        root ?? item.attributeName,
        parentConcat
          ? parentConcat + "." + item.attributeName
          : item.attributeName,
        parentRoute ? parentRoute + " > " + item.displayName : item.displayName
      );
    }
    accum.push(newItem);
    return accum;
  }, []);
};
export const getProductAttributesNames = createSelector(
  getProduct,
  (product) => {
    if (!product.attributes) return [];
    return recursive(product.attributes);
  }
);

export const getProductsByModuleTemplate = createSelector(
  getProducts,
  (products) => {
    return products.productsByModuleTemplate.products;
  }
);
