import React from "react";

import { ActionButton } from "shared/ui/ActionButton";
import { Divider, StyledActionWrap } from "./styles";

import { ActionButtonTypes } from "../../ActionButton/types";
import { TabNames } from "pages/types";
import { useProducts } from "../../../features/associatedProducts/model/hooks/useProducts";

interface TableActionsProps {
  activeTab: string | undefined;
  handleEdit: () => void;
  handleDelete: (data?: any) => void;
  handleRestore: () => void;
  handleView: () => void;
  handleClone: () => void;
}

interface ActionProps {
  title: string;
  onClick: any;
  type: ActionButtonTypes;
  divider?: boolean;
}

const TableActions: React.FC<TableActionsProps> = ({
  activeTab,
  handleDelete,
  handleEdit,
  handleRestore,
  handleView,
  handleClone,
}) => {
  const { isPublishedLoading } = useProducts();
  const table_actions: any = {
    [TabNames.PRODUCTS]: [
      {
        type: ActionButtonTypes.CLONE,
        title: "Clone Product",
        onClick: handleClone,
        divider: true,
      },
      {
        type: ActionButtonTypes.EDIT,
        title: "Edit Product",
        onClick: handleEdit,
      },
      {
        type: ActionButtonTypes.DELETE,
        title: "Delete Product",
        onClick: handleDelete,
      },
      {
        type: ActionButtonTypes.VIEW,
        title: "View Product",
        onClick: handleView,
      },
    ],
    [TabNames.PROGRAMS]: [
      {
        type: ActionButtonTypes.CLONE,
        title: "Clone Program",
        onClick: handleClone,
        divider: true,
      },
      {
        type: ActionButtonTypes.EDIT,
        title: "Edit Program",
        onClick: handleEdit,
      },
      {
        type: ActionButtonTypes.DELETE,
        title: "Delete Program",
        onClick: handleDelete,
      },
      {
        type: ActionButtonTypes.VIEW,
        title: "View Program",
        onClick: handleView,
      },
    ],
    [TabNames.USERS]: [
      {
        type: ActionButtonTypes.EDIT,
        title: "Edit Profile",
        onClick: handleEdit,
      },
      {
        type: ActionButtonTypes.DELETE,
        title: "Delete User",
        onClick: handleDelete,
      },
      {
        type: ActionButtonTypes.VIEW,
        title: "View User",
        onClick: () => console.log("View"),
      },
    ],
    [TabNames.REQUEST]: [
      {
        type: ActionButtonTypes.COMMENT,
        title: "View",
        onClick: handleEdit,
      },
      {
        type: ActionButtonTypes.DELETE,
        title: "Delete Request",
        onClick: handleDelete,
      },
    ],
    [TabNames.NOTIFICATIONS]: [
      {
        type: ActionButtonTypes.DELETE,
        title: "Delete Notification",
        onClick: handleDelete,
      },
      {
        type: ActionButtonTypes.VIEW,
        title: "View Notification",
        onClick: handleEdit,
      },
    ],
    [TabNames.ARCHIVE]: [
      {
        type: ActionButtonTypes.RESTORE,
        title: "Restore",
        onClick: handleRestore,
      },
    ],
  };

  return (
    <StyledActionWrap>
      {activeTab &&
        table_actions[activeTab].map((item: ActionProps) => (
          <>
            <ActionButton
              key={item.type}
              type={item.type}
              title={item.title}
              onClick={item.onClick}
              disabled={item.title === "Edit Product" && isPublishedLoading}
            />
            {item.divider && <Divider />}
          </>
        ))}
    </StyledActionWrap>
  );
};

export default TableActions;
