import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";

import { Title } from "../../styles";
import { Box } from "@mui/material";
import Tabs from "../../../Tabs";
import DataEnrichment from "../../../DataEnrichment";
import { ThirdPartyWidgets } from "pages/ThirdPartyWidgets";
import { AssociatedProductsContext } from "pages/IntegrationHub";
import { CreateProductContext } from "pages/CreateProduct";

export const IntegrationContext: any = createContext(null);

const tabList = [
  {
    label: "Third Party",
  },
  {
    label: "Data Enrichment",
  },
];

const Integrations: React.FC<any> = () => {
  const { currentProduct, tab }: any = useContext(CreateProductContext);
  const [activeTab, setActiveTab] = useState("Third Party");

  const viewProducts = (id: string) => {
    console.log("list all associated products", id);
  };

  useEffect(() => {
    if (tab) {
      if (tab === "enrich") setActiveTab("Data Enrichment");
      if (tab === "thirdParty") setActiveTab("Third Party");
    }
  }, [tab]);

  const associatedProductsValue = useMemo(
    () => ({
      viewProducts,
      currentProduct,
    }),
    [viewProducts, currentProduct]
  );

  return (
    <AssociatedProductsContext.Provider value={associatedProductsValue}>
      <Title>Integrations</Title>
      <Box data-testid="integrations" sx={{ marginTop: "25px" }}>
        <Tabs
          setActiveTab={setActiveTab}
          tabList={tabList}
          activeTab={activeTab}
          basePath={"/"}
          isNotRedirect={true}
        >
          {activeTab === "Third Party" ? (
            <ThirdPartyWidgets
              dataType={activeTab}
              listProduct={false}
              product={currentProduct}
            />
          ) : (
            <DataEnrichment
              dataType={activeTab}
              listProduct={false}
              product={currentProduct}
            />
          )}
        </Tabs>
      </Box>
    </AssociatedProductsContext.Provider>
  );
};

export default Integrations;
