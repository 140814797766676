import { createSlice } from "@reduxjs/toolkit";

type AlertsState = Array<any>;
const initialState: AlertsState = [];

export const draftItemsSlice = createSlice({
  name: "draftItems",
  initialState,
  reducers: {
    addDraftitem: (state, action) => {
      return action.payload;
    },
  },
});

export const { addDraftitem } = draftItemsSlice.actions;
