import React, { useEffect, useMemo, useState } from "react";

import { Container } from "@mui/material";

import { Title } from "../../styles";
import { Block, OutcomesTableWrapper } from "./styles";

import { SelectModuleWrapper } from "shared/ui/Cards/styles";
import Button from "shared/ui/Button";
import { SkeletonLoader } from "shared/ui/Skeleton";
import { Spinner } from "shared/ui/Spinner";

import Select from "../../../FormComponents/Select";
import AddFulfillment from "./AddFullfillment";
import ModuleModal from "./ModuleModal";

import {
  useModule,
  useTemplates,
} from "features/modules/model/hooks/useModule";

import { ModuleBody, Modules, ModuleTypes, Outcomes } from "entities/modules";

import { Module } from "shared/api/modules/types";

import ModulesList from "./ModulesList";

interface ModuleList {
  name: string;
  id: string;
  position: number;
  moduleType: ModuleTypes;
  outcomeType: Outcomes;
  description: string;
  moduleName: string;
}

const OutcomesAndServices: React.FC<any> = () => {
  const [outcomeNext, setOutcomeNext] = useState("");
  const [dragEnded, setDragEnded] = useState(false);
  const [blocks, setBlocks] = useState<any>([]);

  const moduleOptions: string[] = ["Fulfillment", "Completion", "Rule"];
  const [moduleToEdit, setModuleToEdit] = useState<Module>();
  const [fulfillmentModal, setFulfillmentModal] = useState(false);
  const [editModuleModal, setEditModuleModal] = useState(false);
  const [moduleQueue, setModuleQueue] = useState<string[]>([]);
  const {
    createModules,
    isLoading: isLoadingModules,
    modules,
    getModules,
    createModule,
    editModule,
    modulesDataObject,
    setModulesOrder,
    enrichedDataObject,
    thirdPartyDataObject,
  } = useModule();

  const { getTemplates, isLoading: isTemplatesLoading } = useTemplates();

  useEffect(() => {
    getModules();
    getTemplates();
  }, []);

  useEffect(() => {
    if (modules.length === 0 && !isLoadingModules) {
      createModules();
    }
  }, [modules, isLoadingModules]);

  useEffect(() => {
    const lead: ModuleList[] = [];
    const quote: ModuleList[] = [];
    const bind: ModuleList[] = [];
    const rest: ModuleList[] = [];
    if (!modulesDataObject) return;

    Object.keys(modulesDataObject).forEach((value) => {
      const item = modulesDataObject[value];
      if (item) {
        switch (item.outcomeType) {
          case "Lead":
            lead.push(moduleListPayload(value, item));
            break;
          case "Quote":
            quote.push(moduleListPayload(value, item));
            break;
          case "Bind":
            bind.push(moduleListPayload(value, item));
            break;
          default:
            rest.push(moduleListPayload(value, item));
            break;
        }
      }
    });
    setBlocks([
      {
        id: "Lead",
        name: "Lead",
        parent_id: null,
        type: "container",
        children: sort(lead),
      },
      {
        id: "Quote",
        name: "Quote",
        parent_id: null,
        type: "container",
        children: sort(quote),
      },
      {
        id: "Bind",
        name: "Bind",
        parent_id: null,
        type: "container",
        children: sort(bind),
      },
      ...sort(rest),
    ]);

    const moduleIds: string[] = [];
    const sorted_modules: Module[] = [...modules];

    sorted_modules.sort((a: Module, b: Module) =>
      a.moduleOrderQueue > b.moduleOrderQueue ? 1 : -1
    );
    sorted_modules.map((item: any) => {
      moduleIds.push(item.id);
    });
    setModuleQueue(moduleIds);
  }, [modulesDataObject, enrichedDataObject, thirdPartyDataObject]);

  useEffect(() => {
    if (blocks.length && dragEnded) {
      updateModulesOrder();
      setDragEnded(false);
    }
  }, [blocks, dragEnded]);

  const updateModulesOutcomeType = () => {
    if (blocks.length) {
      blocks.map((item: any) => {
        if (item.children?.length) {
          item.children.map((i: ModuleList) => {
            if (i.outcomeType !== item.id) {
              editModule(i.id, {
                name: i.moduleName,
                description: i.description,
                outcomeType: item.id,
              });
            }
          });
        }
      });
    }
  };

  const updateModulesOrder = () => {
    // eslint-disable-next-line prefer-const
    let moduleIds: string[] = [];
    blocks.map((item: any) => {
      if (item.type !== "container") {
        moduleIds.push(item.id);
      } else {
        item.children.map((i: ModuleList) => {
          moduleIds.push(i.id);
        });
      }
    });
    const payload = {
      orderedModuleIds: moduleIds,
    };
    if (moduleQueue.toString() !== moduleIds.toString()) {
      setModulesOrder(payload, updateModulesOutcomeType);
    } else {
      updateModulesOutcomeType();
    }
  };

  const moduleListPayload = (key: string, item: Module) => {
    return {
      name: key,
      id: item.id,
      position: item.moduleOrderQueue,
      moduleType: item.moduleType,
      outcomeType: item.outcomeType,
      description: item.description,
      moduleName: item.name,
    };
  };

  const sort = (items: ModuleList[]) => {
    return items.sort((a: ModuleList, b: ModuleList) =>
      a.position > b.position ? 1 : -1
    );
  };

  const isLoading: boolean = useMemo(() => {
    return isLoadingModules || isTemplatesLoading;
  }, [isLoadingModules, isTemplatesLoading]);

  const changeOptionHandlerNext = (event: any) => {
    setOutcomeNext(event.target.value);
  };

  const handleEdit = (moduleName: string) => {
    if (modulesDataObject) {
      const module = modulesDataObject[moduleName];
      setModuleToEdit(module);
      setEditModuleModal(true);
    }
  };

  const editModuleAction = (data: ModuleBody) => {
    if (moduleToEdit) {
      editModule(moduleToEdit.id, {
        ...data,
        outcomeType: moduleToEdit.outcomeType,
      });
    }
  };

  const addModuleHandler = () => {
    if (outcomeNext === Modules.FULFILLMENT) {
      setFulfillmentModal(true);
      return;
    }
    createModule(outcomeNext.toLowerCase() as Modules);
  };

  return (
    <Container maxWidth="xl">
      <Title>Outcomes & Services</Title>
      {modules.length === 0 && isLoadingModules && <Spinner />}
      {modules.length > 0 && (
        <OutcomesTableWrapper>
          <Block>
            <SelectModuleWrapper>
              <Select
                options={moduleOptions}
                disabled={!moduleOptions.length}
                id={"select-outcomes"}
                title={""}
                value={outcomeNext}
                hideCreate={true}
                multiple={false}
                onChange={changeOptionHandlerNext}
              />
            </SelectModuleWrapper>
            <Button
              variant={"contained"}
              onClick={addModuleHandler}
              disabled={!moduleOptions.length}
            >
              Add Module
            </Button>
          </Block>
          <div>
            {isLoading && <SkeletonLoader />}
            {!isLoading && modules.length <= 0 && "No Data"}
            {!isLoading && (
              <ModulesList
                blocks={blocks}
                setBlocks={setBlocks}
                handleEdit={handleEdit}
                setDragEnded={setDragEnded}
              />
            )}
          </div>
        </OutcomesTableWrapper>
      )}
      {fulfillmentModal && (
        <AddFulfillment
          open={fulfillmentModal}
          handleClose={() => setFulfillmentModal(false)}
          createModule={createModule}
        />
      )}
      {editModuleModal && (
        <ModuleModal
          title={"Edit Module"}
          open={editModuleModal}
          handleClose={() => setEditModuleModal(false)}
          editModule={editModuleAction}
          module={moduleToEdit}
        />
      )}
    </Container>
  );
};

export default OutcomesAndServices;
