import styled from "styled-components";
import { Button, Typography } from "@mui/material";

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    background-color: var(--secondary);
    border-radius: 100px;
    padding: 16px 168px;
    text-transform: none;
    color: var(--white);
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: 2px;
    display: flex;
    margin: 0 auto;
    :hover {
      background-color: var(--secondary);
    }
  }
`;

export const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    text-align: center;
    margin-top: 28px;
    margin-bottom: 38px;
    font-size: 1.25rem !important;
    font-family: Poppins;
    font-weight: 500;
  }
`;

export const Container = styled.div`
  width: 600px;
  height: 220px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;
