import React, { useContext, useState } from "react";
import { Box } from "@mui/material";
import { RequestWidget } from "features/request-widget-form";

import { Title } from "../../styles";
import { DistributionWidgetTable } from "widgets/DistributionWidgetTable";
import { CreateProductContext } from "pages/CreateProduct";
import { KpiPayload } from "shared/api/kpis/types";
import { useKpis } from "features/kpis/model/hooks/useKpis";
import TablePaper from "shared/ui/TablePaper";

const Engagement = () => {
  const [openCreateRequest, setOpenCreateRequest] = useState(false);
  const openCreateRequestModal = () => {
    setOpenCreateRequest((prev: boolean) => !prev);
  };
  const { currentProduct }: any = useContext(CreateProductContext);
  const { pushKpi } = useKpis();

  const handleKpi = (payload: KpiPayload, callback?: any) => {
    pushKpi(payload, callback);
  };
  return (
    <div>
      <Title>Embedded Hub</Title>
      <Box data-testid="eEmbeddedHub" sx={{ marginTop: "25px" }}>
        <TablePaper>
          <DistributionWidgetTable
            type="edit"
            openCreateRequestModal={openCreateRequestModal}
            handleKpi={handleKpi}
          />
          {openCreateRequest && (
            <RequestWidget
              handleClose={openCreateRequestModal}
              open={openCreateRequest}
              productName={currentProduct?.name}
            />
          )}
        </TablePaper>
      </Box>
    </div>
  );
};

export default Engagement;
