import styled from "styled-components";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    font-family: "Inter";
    font-style: normal;
    text-transform: none;
    background-color: #fff;
    white-space: nowrap;
    color: var(--secondary);
    border: 1px solid #9696a9;
    border-radius: 6px;
  }
`;
