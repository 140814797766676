import { createAction } from "@reduxjs/toolkit";

export const programsActions = {
  GET_PROGRAMS: "GET_PROGRAMS",
  GET_PRODUCT_PROGRAM: "GET_PRODUCT_PROGRAM",
  CREATE_PROGRAM: "CREATE_PROGRAM",
  GET_PROGRAM: "GET_PROGRAM",
  UPDATE_PROGRAM: "UPDATE_PROGRAM",
  DELETE_PROGRAM: "DELETE_PROGRAM",
  GET_PROGRAM_PRODUCTS: "GET_PROGRAM_PRODUCTS",
  LINK_PRODUCTS_TO_PROGRAM: "LINK_PRODUCTS_TO_PROGRAM",
  UNLINK_PRODUCTS_TO_PROGRAM: "UNLINK_PRODUCTS_TO_PROGRAM",
  UNDELETE_PROGRAM: "UNDELETE_PROGRAM",
  CLONE_PROGRAM: "CLONE_PROGRAM",
};

export const unlinkProductFromProgramAction = createAction<{
  programId: string;
  productIds: string[];
  cb?: () => void;
}>("program/unlink");

export const updateProgramStatusAction = createAction<{
  programId: string;
  isActive: boolean;
  cb?: () => void;
}>("program/status/update");
