import styled from "styled-components";
import { Tab, Tabs } from "@mui/material";

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: var(--secondary);
    &.Mui-selected {
      background: #f2f2fc;
      border-radius: 6px;
      color: var(--secondary);
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    height: 0;
  }
  &.MuiTabs-root {
    margin-bottom: 10px;
  }
`;

export const TabContent = styled.div`
  display: flex;
`;
