import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";
import { KpiItemsResponse } from "./types";
import { SuccessResponse } from "api/types";
import qs from "qs";

export default class KpisApi {
  static async getKpiItem(
    params: any
  ): Promise<AxiosResponse<KpiItemsResponse>> {
    return IXPortalApiPrivate.get("/api/Kpi/", {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }

  static async getProductKpis(
    productId: string
  ): Promise<AxiosResponse<KpiItemsResponse>> {
    return IXPortalApiPrivate.get(`/api/Kpi/?ProductId=${productId}`);
  }

  static async createKpi(params: any): Promise<AxiosResponse<SuccessResponse>> {
    return IXPortalApiPrivate.post("/api/Kpi/", params);
  }
}
