import { API_PATH, TENANT_NAME } from "../constants";
import { RequestHeadersKeys } from "./types";
import qs from "qs";

export const defaultConfig = {
  baseURL: API_PATH,
  headers: {
    [RequestHeadersKeys.IXPORTAL_TENANT_HOSTNAME]: TENANT_NAME,
    [RequestHeadersKeys.ACCEPT_LANGUAGE]: "en-US",
    accept: "text/plain",
  },
  paramsSerializer: (params: any) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
};
