import { put, takeEvery } from "redux-saga/effects";
import { addDraftitem } from "entities/draftItems/model/slice/draftItemsSlice";
import { draftItemsActions } from "entities/draftItems";

export function* addDraftItemWorker(payload: any) {
  try {
    yield put(addDraftitem(payload.data));
  } catch (e) {
    yield put({ type: "ADD_DRAFT_ITEM_FAILED" });
  }
}

export function* DraftItemWatcher() {
  yield takeEvery(draftItemsActions.ADD_DRAFT_ITEM, addDraftItemWorker);
}
