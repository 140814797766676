import React, { FC } from "react";

interface Props {
  list?: string[];
}
export const ErrorList: FC<Props> = ({ list }) => {
  return (
    <ul>
      {list?.map((item, index) => (
        <li key={`${item}_${index}`}>{item}</li>
      ))}
    </ul>
  );
};
