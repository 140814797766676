import React, { memo, useMemo } from "react";
import { TableCell, TableRow } from "@mui/material";
import { StyledSkeleton } from "../Skeleton/styles";
import { Column } from "../../config/types/Table";

interface Props {
  lines?: number;
  columns: Column[];
  lineHeight?: string;
}

export const TableSkeleton = memo(function TableSkeleton({
  lines = 3,
  columns,
  lineHeight = "50px",
}: Props) {
  const arrayItems = useMemo(() => {
    return [...Array(lines)].map((_, i) => ({ id: i }));
  }, [lines]);
  return (
    <>
      {arrayItems.map((row) => (
        <TableRow key={row.id}>
          {columns.map((cell) => (
            <TableCell
              component="th"
              scope="row"
              key={cell.id}
              width={cell.minWidth}
              sx={{
                minWidth: cell.minWidth,
                width: cell.minWidth,
                maxWidth: cell.minWidth,
              }}
            >
              <StyledSkeleton
                animation="wave"
                variant="text"
                lineHeight={lineHeight}
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
});
