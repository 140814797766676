import React from "react";
import { useNavigate } from "react-router-dom";
import { useProducts } from "features/associatedProducts/model/hooks/useProducts";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getProduct } from "entities/products/model/selectors";

import { Box } from "@mui/material";
import AccordionSection from "shared/ui/AccordionSection";
import EventsAndTriggersTable from "./EventsAndTriggersTable";

interface Props {
  editable?: boolean;
}
const EventsAndTriggers: React.FC<any> = ({ editable }: Props) => {
  const { updateProductVersion } = useProducts();
  const navigate = useNavigate();
  const product = useAppSelector(getProduct);

  const handleEdit = () => {
    if (product.state === "Published") {
      updateProductVersion(product);
    }
    navigate(`/create_new_product/${product.id}?step=EventsAndTriggers`);
  };

  return (
    <div>
      <AccordionSection
        title="Events & Triggers"
        editable={editable}
        handleEdit={handleEdit}
      >
        <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
          <EventsAndTriggersTable />
        </Box>
      </AccordionSection>
    </div>
  );
};

export default EventsAndTriggers;
