import styled from "styled-components";
import { Collapse, TableRow, TableCell } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

export const StyledCollapse = styled(Collapse).withConfig({
  shouldForwardProp: (prop) => !["stepIndex"].includes(prop),
})<{ stepIndex?: number }>(({ stepIndex }) => {
  return `
  &.MuiCollapse-root {
    width: 100%;
    padding-left: ${stepIndex ? stepIndex * 20 : 0}px;
  }`;
});

export const FilterIcon = styled(FilterListIcon)`
  &.MuiSvgIcon-root {
    font-size: 20px;
  }
`;

export const Connect = styled.div`
  width: 40px;
  height: 40px;
  background: #f2f2fc;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExpandTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #010101;
  white-space: nowrap;
`;

export const GreyText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #383874;
  opacity: 0.5;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #010101;

    border-bottom: none;
    text-align: left;
    padding: 7px 8px;

    border-top: 1px solid #dadae3;
    border-bottom: 1px solid #dadae3;

    &:first-child {
      border-left: 1px solid #dadae3;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-right: 1px solid #dadae3;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &.grey {
    color: var(--grey);
    white-space: nowrap;
  }

  &.noWidth {
    width: 50px;
  }

  &.right {
    text-align: right;
  }
`;

export const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    background-color: #fff;
  }

  &.header {
    text-transform: uppercase;
    .MuiTableCell-head {
      border: none;
      border-radius: 0;
      border-bottom: 5px solid var(--lightPurple);
      padding-bottom: 10px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #383874;
      opacity: 0.5;
    }
  }

  &.transparent {
    background-color: transparent;
    position: relative;
  }
`;
