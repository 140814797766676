import { createSlice } from "@reduxjs/toolkit";
import { FileDetails } from "shared/api/files/types";

interface InitialState {
  uploading: boolean;
  file?: FileDetails;
}
const initialState: InitialState = {
  uploading: false,
  file: undefined,
};

export const filesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    setFileUploading: (state, action) => {
      return {
        ...state,
        moduleLoading: action.payload,
      };
    },
    setFile: (state, action) => {
      return {
        ...state,
        file: action.payload,
      };
    },
  },
});

export const { setFileUploading, setFile } = filesSlice.actions;

export const { reducer: filesReducer } = filesSlice;
