import React, { useEffect, useState } from "react";
import {
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
import { PageTitle } from "shared/ui/pageTitle";
import { DetailsInfo, DetailsStatus } from "shared/ui/DetailsInfoBlock";
import { HeaderWrap, InfoColumn, InfoRow, StyledTitleWrap } from "./styles";
import { Status } from "shared/config/enums/Statuses";
import { ContentCopy, Delete, Edit } from "@mui/icons-material";
import { ControlButtonsWrap } from "components/ProductGeneralInformation/styles";
import { usePrograms } from "features/programs/model/hooks/usePrograms";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { DateFormat } from "shared/config/enums/DateFormat";
import { Spinner } from "shared/ui/Spinner";
import { IXTableRow, TableHeader } from "shared/ui/Table";
import { Column } from "shared/config/types/Table";
import { ActionButton, ActionButtonsWrapper } from "shared/ui/ActionButton";
import { ActionButtonTypes } from "shared/ui/ActionButton/types";
import OutlinedCheckbox from "shared/ui/OutlinedCheckbox";
import { addOrRemove } from "shared/utils/addOrRemove";
import CreateProgram from "components/Modals/CreateProgram";
import TablePaper from "shared/ui/TablePaper";

export const initialColumns: Column[] = [
  {
    id: "Name",
    label: "Product Name",
    minWidth: "100%",
    filtering: false,
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 200,
    filtering: false,
    align: "center",
  },
];
const ProgramDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    getProgram,
    data,
    isProgramLoading,
    products,
    unlinkProduct,
    deleteProgram,
    cloneProgram,
    getKpi,
    kpiItem,
  } = usePrograms();
  const [selected, setSelected] = useState<string[]>([]);
  const [openCreateProgramModal, setOpenCreateProgramModal] = useState(false);

  useEffect(() => {
    if (id) {
      getProgram(id);
      getKpi(id);
    }
  }, [id]);

  const handleViewClick = (id: string) => () => {
    navigate(`/products/list/${id}`);
  };

  const handleUnlink = (productIds: string[], programId: string) => () => {
    unlinkProduct(productIds, programId, () => callback(productIds));
  };

  const callback = (productIds: string[]) => {
    const newData = selected.filter((item) => !productIds.includes(item));
    setSelected(newData);
  };

  const selectAllClick = () => {
    if (selected.length > 0) {
      setSelected([]);
    } else {
      setSelected(products.map((item) => item.productId));
    }
  };

  const selectRow = (id: string) => () => {
    const newSelected = addOrRemove(selected, id);
    setSelected(newSelected);
  };

  const deleteProgramHandler = () => {
    if (id) deleteProgram(id, () => navigate("/programs/list"));
  };

  const cloneProgramHandler = () => {
    if (id) cloneProgram(id);
  };
  return (
    <div data-testid={"program-details"}>
      <PageTitle
        title={"Programs Details"}
        backLink={{
          to: "/programs/list",
          title: "Back to Programs",
        }}
      />
      <TablePaper>
        <HeaderWrap>
          <StyledTitleWrap>General Information</StyledTitleWrap>
          <ControlButtonsWrap>
            <IconButton onClick={cloneProgramHandler}>
              <ContentCopy />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton onClick={() => setOpenCreateProgramModal(true)}>
              <Edit />
            </IconButton>
            <IconButton onClick={deleteProgramHandler}>
              <Delete />
            </IconButton>
          </ControlButtonsWrap>
        </HeaderWrap>
        {isProgramLoading && <Spinner />}
        {!isProgramLoading && (
          <>
            <InfoRow>
              <InfoColumn>
                <DetailsInfo value={data.name} label={"Program name"} />
                <DetailsInfo value={data.id} label={"Program Id"} />
                <DetailsStatus
                  value={data.status}
                  label={"Status"}
                  status={
                    data.status === "Active" ? Status.ACTIVE : Status.INACTIVE
                  }
                />
                <DetailsInfo value={data.description} label={"Description"} />
                <DetailsInfo value={kpiItem.value || ""} label={"KPI"} />
              </InfoColumn>
              <InfoColumn>
                <DetailsInfo value={data.budget} label={"Budget"} />
                <DetailsInfo value={data.budgetType} label={"Budget type"} />
                <DetailsInfo
                  value={data.budgetDuration}
                  label={"Budget duration"}
                />
                <DetailsInfo value={data.currency} label={"Currency"} />
                <DetailsInfo value={data.pacing} label={"Pacing"} />
                <DetailsInfo
                  value={
                    data.startDate
                      ? format(new Date(data.startDate), DateFormat.DEFAULT)
                      : "N/A"
                  }
                  label={"Start date"}
                />
                <DetailsInfo
                  value={
                    data.endDate
                      ? format(new Date(data.endDate), DateFormat.DEFAULT)
                      : "N/A"
                  }
                  label={"End Date"}
                />
              </InfoColumn>
            </InfoRow>
            <StyledTitleWrap>Associated Products</StyledTitleWrap>
            {products.length !== 0 && (
              <ActionButton
                title={ActionButtonTypes.DELETE}
                type={ActionButtonTypes.DELETE}
                onClick={handleUnlink(selected, data.id)}
              />
            )}
            <TableContainer
              sx={{
                height: `calc(100vh - ${"660px"})`,
                position: "relative",
              }}
              data-testid="tableContainer"
            >
              <TableHeader
                hideCheckbox={products.length === 0}
                columns={initialColumns}
                numSelected={selected.length}
                rowCount={products.length}
                onSelectAllClick={selectAllClick}
              ></TableHeader>
              <Table stickyHeader>
                <TableBody data-testid="tableBody">
                  {products.map((row) => {
                    return (
                      <IXTableRow
                        hover
                        tabIndex={-1}
                        key={row.productId}
                        data-testid={`tableRow_${row.productId}`}
                      >
                        <TableCell sx={{ fontWeight: 700 }} padding="checkbox">
                          <OutlinedCheckbox
                            checked={selected.includes(row.productId)}
                            onChange={selectRow(row.productId)}
                          />
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }} width={"100%"}>
                          {row.productName}
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700, minWidth: "200px" }}>
                          <ActionButtonsWrapper>
                            <ActionButton
                              title={ActionButtonTypes.VIEW}
                              type={ActionButtonTypes.VIEW}
                              onClick={handleViewClick(row.productId)}
                            />
                            <ActionButton
                              title={ActionButtonTypes.DELETE}
                              type={ActionButtonTypes.DELETE}
                              onClick={handleUnlink([row.productId], data.id)}
                            />
                          </ActionButtonsWrapper>
                        </TableCell>
                      </IXTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </TablePaper>
      {openCreateProgramModal && (
        <CreateProgram
          open={openCreateProgramModal}
          handleClose={() => setOpenCreateProgramModal(false)}
          edit={true}
        />
      )}
    </div>
  );
};

export default ProgramDetails;
