import React from "react";
import ReplyIcon from "@mui/icons-material/Reply";
import { StatusDiv } from "./styles";

const StatusButton: React.FC<{ status: string; onClick?: () => void }> = ({
  status,
  onClick,
}) => {
  return (
    <StatusDiv status={status}>
      <div>
        {status}
        {status?.toLowerCase() === "pending" ||
          (status?.toLowerCase() === "invited" && (
            <ReplyIcon fontSize={"small"} onClick={onClick} />
          ))}
      </div>
    </StatusDiv>
  );
};

export default StatusButton;
