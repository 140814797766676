import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";
import { SuccessResponse } from "api/types";
import { FileDetails, FileLinks } from "./types";
import qs from "qs";

export default class FilesApi {
  static async uploadFile(
    ReferenceId: string,
    ReferenceType: string,
    file: File
  ): Promise<AxiosResponse<SuccessResponse>> {
    const formData = new FormData();
    formData.append("file", file);
    return IXPortalApiPrivate.post(
      `/api/Files?ReferenceId=${ReferenceId}&ReferenceType=${ReferenceType}`,
      formData
    );
  }

  static async retrieveFile(id: string): Promise<AxiosResponse<FileDetails>> {
    return IXPortalApiPrivate.get(`/api/Files/${id}`);
  }

  static async getFilesByIds(params: any): Promise<AxiosResponse<FileLinks>> {
    return IXPortalApiPrivate.get("/api/Files/links/", {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }
}
