import styled from "styled-components";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)<{ isActive?: boolean }>`
  &.MuiButton-root {
    justify-content: space-between;
    padding: 10px 16px;
    text-transform: none;
    color: ${(props) => (props.isActive ? "var(--secondary)" : "var(--black)")};
    :hover {
      color: ${(props) =>
        props.isActive ? "var(--secondary)" : "var(--black)"};
      background: var(--ghost);
    }
    div {
      display: flex;
      align-items: center;
      > strong {
        margin-right: 5px;
      }
    }
  }
  svg.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    position: relative;
    bottom: 1px;
  }
`;
