import React from "react";
import { StyledCheckbox } from "./styles";
import { ReactComponent as CheckBoxUnchecked } from "assets/icons/checkbox-unchecked.svg";
import { ReactComponent as CheckBoxOutlined } from "assets/icons/checkbox-outlined.svg";

const OutlinedCheckbox: React.FC<{
  checked: boolean;
  onClick?: (event: React.MouseEvent) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  name?: string;
  defaultChecked?: boolean;
}> = ({ checked, onClick, onChange, disabled, ...props }) => {
  return (
    <StyledCheckbox
      onClick={onClick}
      onChange={onChange}
      icon={<CheckBoxUnchecked />}
      checkedIcon={<CheckBoxOutlined />}
      checked={checked}
      disabled={disabled}
      {...props}
    />
  );
};

export default OutlinedCheckbox;
