import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import AccordionSection from "shared/ui/AccordionSection";
import { DistributionTable } from "./DistributionTable";

import { useAppSelector } from "redux/store/hooks/hooks";

import { getProduct } from "entities/products/model/selectors";
import { useProducts } from "features/associatedProducts/model/hooks/useProducts";

interface DistributionProps {
  editable?: boolean;
}

const Distribution = ({ editable }: DistributionProps) => {
  const product = useAppSelector(getProduct);
  const { updateProductVersion } = useProducts();
  const navigate = useNavigate();

  const handleEdit = () => {
    if (product.state === "Published") {
      updateProductVersion(product);
    }
    navigate(`/create_new_product/${product.id}?step=Distribution`);
  };

  return (
    <div>
      <AccordionSection
        title="Distribution"
        editable={editable}
        handleEdit={handleEdit}
      >
        <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
          <DistributionTable />
        </Box>
      </AccordionSection>
    </div>
  );
};

export default Distribution;
