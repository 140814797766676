import styled from "styled-components";
import { Button, Menu, MenuItem } from "@mui/material";

export const Container = styled.div`
  position: absolute;
  left: 100px;
  bottom: -10px;
  right: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 40px;
  height: 84px;
  background: var(--white);
  border-radius: 20px 20px 0px 0px;
  filter: drop-shadow(10px 10px 40px rgba(111, 138, 231, 0.25));
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--headings);
`;

export const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    border-radius: 6px;
    height: 52px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: none;
    letter-spacing: 2px;
    background: var(--white);
    border: 1px solid var(--grey);
    margin-left: 10px;
    color: var(--secondary);
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    margin-top: -20px;
  }
`;

export const DeleteItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    border-top: 1px solid var(--darksilver);
    color: var(--mediumred);
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 20px;
  }
  .MuiSvgIcon-root {
    font-size: 16px;
    margin-right: 16px;
  }
`;
export const StyledItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
    padding: 10px 20px;
  }
  svg {
    margin-right: 16px;
    width: 18px;
    &[data-testid="ReplyIcon"] {
      transform: scaleX(-1);
    }
  }
`;
