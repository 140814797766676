import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/inter";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import historyBr from "./history-br";
import { CustomRouter } from "./CustomRouter";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <CustomRouter history={historyBr}>
        <App />
      </CustomRouter>
    </Provider>
  </MsalProvider>
  // </React.StrictMode>
);

reportWebVitals();
export { WidgetTypes } from "./shared/config/enums/widget/widget";
