import styled from "styled-components";
import { Dialog, DialogActions, DialogTitle, TextField } from "@mui/material";

export const Wrapper = styled.div`
  height: calc(100% - 196px);
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  form {
    padding: 24px;
  }
`;

export const Content = styled.div`
  padding: 12px 32px 32px;
`;

export const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    justify-content: flex-end;
    font-family: "Inter";
  }
  .MuiDialog-paper {
    max-height: 100%;
    height: 100%;
    max-width: 774px;
    width: 100%;
    margin: inherit;
    position: relative;
    overflow-x: hidden;
  }
`;

export const StyledActions = styled(DialogActions)<{ centered: number }>`
  &.MuiDialogActions-root {
    padding: ${(props) =>
      props.centered == 1 ? "20px 20px" : "20px 20px 13px 0px"};
    justify-content: ${(props) => (props.centered == 1 ? "center" : "left")};
    position: absolute;
    bottom: 0;
    width: ${(props) => (props.centered == 1 ? "100%" : "84%")};
    left: ${(props) => (props.centered == 1 ? "0" : "8%")};
    border-top: 1px solid #dadae3;
    background: #ffffff;
  }
  .MuiButton-root {
    width: 50%;
  }
`;

export const StyledTitle = styled(DialogTitle)<{ sidepadding: string }>`
  &.MuiTypography-root {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: var(--headings);
    padding: ${(props) => `20px ${props.sidepadding}px`};
    border-bottom: 1px solid var(--silver);
  }
`;

export const FieldGroup = styled.div`
  position: relative;
`;

export const BackLink = styled.a`
  display: flex;
  align-items: center;
  color: #190a3a;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0.24px;
  cursor: pointer;
`;

export const InputGroup = styled("div")<{ error: any }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  border: 1px solid var(--primary);
  border-radius: 4px;
  padding: 5.3px 14px;
  display: flex;
  flex-wrap: wrap;
  background: ${(props) => (props.error ? "var(--lightred)" : "#ffffff")};
  .MuiChip-root {
    margin: 5px 5px 5px 0;
  }
  .MuiOutlinedInput-root {
    background: ${(props) =>
      props.error ? "var(--lightred) !important" : "#ffffff"};
  }
`;

export const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0",
      lineHeight: "31.99px",
      height: "31.99px",
      margin: "5px 5px 5px 0",
    },
  },
});
