import { Column } from "../Table/types";

export const initialColumns: Column[] = [
  {
    id: "chip",
    label: "",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "name",
    label: "Name",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "provider",
    label: "Provider",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "actualPrice",
    label: "Price",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "products",
    label: "Associated Products",
    minWidth: 250,
    filtering: false,
  },
  {
    id: "createdDateUtc",
    label: "Creation",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "updatedDateUtc",
    label: "Update",
    minWidth: 150,
    filtering: false,
  },
];

export interface DataEnrichmentData {
  id: string;
  status: string;
  name: string;
  provider: string;
  actualPrice: string;
  createdDateUtc: string;
  updatedDateUtc: string;
}

export const mockIncludedData: DataEnrichmentData[] = [
  {
    id: "ascascasc",
    status: "ready",
    name: "REIN",
    provider: "REIN",
    actualPrice: "$8.25",
    createdDateUtc: "2019-08-01",
    updatedDateUtc: "2019-10-02",
  },
  {
    id: "bchwcbahs",
    status: "ready",
    name: "Google",
    provider: "Google",
    actualPrice: "$6.25",
    createdDateUtc: "2020-03-16",
    updatedDateUtc: "2020-05-20",
  },
];

export const mockData: DataEnrichmentData[] = [
  {
    id: "cbhsbdchdsbc",
    status: "ready",
    name: "Confianza",
    provider: "Confianza",
    actualPrice: "$1.25",
    createdDateUtc: "2020-10-01",
    updatedDateUtc: "2020-10-02",
  },
  {
    id: "cbcacachsbdchdsbc",
    status: "ready",
    name: "Fenris",
    provider: "Fenris",
    actualPrice: "$3.25",
    createdDateUtc: "2020-10-01",
    updatedDateUtc: "2020-10-02",
  },
  {
    id: "casccascac",
    status: "ready",
    name: "Confianza-Sales",
    provider: "Confianza-Sales",
    actualPrice: "$1.2",
    createdDateUtc: "2020-10-01",
    updatedDateUtc: "2020-10-02",
  },
];
