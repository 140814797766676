import { AttributesPayload } from "shared/api/products/types";

export const generateAttributeData = (attribute: any) => {
  if (attribute.subAttributes.length) {
    return {
      id: attribute.id,
      attributeName: attribute.attributeName,
      attributeDescription: attribute.attributeDescription,
      fillmentType: attribute.defaultFillmentType ?? attribute.fillmentType,
      valueType: attribute.valueType,
      type: attribute.attributeType,
      systemFieldId: attribute.systemFieldId,
      regexExpression: attribute.regexExpression,
      values: attribute.values,
      defaultValue: attribute.defaultValue,
      format: attribute.format,
      displayName: attribute.displayName,
      isRequired: attribute.isRequired,
      isArray: attribute.isArray,
      subAttributes: attribute.subAttributes.map((item: AttributesPayload) =>
        generateAttributeData(item)
      ),
    };
  } else {
    return {
      id: attribute.id,
      attributeName: attribute.attributeName,
      attributeDescription: attribute.attributeDescription,
      fillmentType: attribute.defaultFillmentType ?? attribute.fillmentType,
      valueType: attribute.valueType,
      type: attribute.attributeType,
      systemFieldId: attribute.systemFieldId,
      regexExpression: attribute.regexExpression,
      values: attribute.values,
      defaultValue: attribute.defaultValue,
      format: attribute.format,
      displayName: attribute.displayName,
      isRequired: attribute.isRequired,
      isArray: attribute.isArray,
      subAttributes: attribute.subAttributes,
    };
  }
};
