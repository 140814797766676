import { createSlice } from "@reduxjs/toolkit";

export const moduleTemplatesSlice = createSlice({
  name: "moduleTemplates",
  initialState: {
    items: [],
    totalCount: 0,
  },
  reducers: {
    loadModuleTemplates: (state, action) => {
      return {
        ...state,
        items: action.payload.items,
        totalCount: action.payload.totalCount,
      };
    },
  },
});

export const { loadModuleTemplates } = moduleTemplatesSlice.actions;
