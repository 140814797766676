import React from "react";

import notFoundIcon from "assets/icons/not-found.svg";
import { Block, Title } from "./styles";

const NoData = () => {
  return (
    <Block>
      <img src={notFoundIcon} alt="not found icon" />
      <Title>No data found</Title>
    </Block>
  );
};

export default NoData;
