import { call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import {
  uploadFileAction,
  uploadFilesAction,
  getFileAction,
  getFilesAction,
} from "../actions";
import { setFileUploading, setFile } from "../slice/filesSlice";
import { Toaster } from "shared/ui/Toast/Toast";
import FilesApi from "shared/api/files/filesApi";
import { requestActions } from "entities/requests";
import { FileDetails, FileLinks } from "shared/api/files/types";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";

export function* uploadFilesSaga(action: ReturnType<typeof uploadFilesAction>) {
  const { ReferenceId, ReferenceType, files } = action.payload;
  yield put(setFileUploading(true));

  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    try {
      yield call(FilesApi.uploadFile, ReferenceId, ReferenceType, file);
      if (index === files.length - 1) {
        if (ReferenceType === "Request") {
          yield put({
            type: requestActions.GET_REQUEST,
            data: ReferenceId,
          });
        }
        yield put(setFileUploading(false));
        Toaster.infoMessage("Files uploaded Successfully");
      }
    } catch (e) {
      sagaErrorBoundary(e);
    }
  }
}

export function* uploadFileSaga(action: ReturnType<typeof uploadFileAction>) {
  const { ReferenceId, ReferenceType, file } = action.payload;
  yield put(setFileUploading(true));
  try {
    yield call(FilesApi.uploadFile, ReferenceId, ReferenceType, file);
    yield put(setFileUploading(false));
    Toaster.infoMessage(`${file.name} uploaded Successfully`);
    if (ReferenceType === "Request") {
      yield put({
        type: requestActions.GET_REQUEST,
        data: ReferenceId,
      });
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
  yield put(setFileUploading(false));
}

export function* retrieveFileSaga(action: ReturnType<typeof getFileAction>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse<FileDetails> = yield call(
      FilesApi.retrieveFile,
      id
    );
    yield put(setFile(response.data));
    if (response.data && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* getFilesSaga(action: ReturnType<typeof getFilesAction>) {
  try {
    const response: AxiosResponse<FileLinks> = yield call(
      FilesApi.getFilesByIds,
      action.payload
    );
    if (response.data && action.payload.callback) {
      action.payload.callback(response.data);
    }
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* FilesWatcher() {
  yield takeLatest(uploadFileAction, uploadFileSaga);
  yield takeLatest(uploadFilesAction, uploadFilesSaga);
  yield takeLatest(getFileAction, retrieveFileSaga);
  yield takeLatest(getFilesAction, getFilesSaga);
}
