import styled from "styled-components";
import { Dialog, DialogActions, DialogTitle, IconButton } from "@mui/material";

export const StyledDialog = styled(Dialog)<{ fullsize?: boolean }>`
  .MuiDialog-container {
    justify-content: flex-end;
    font-family: "Inter";
  }
  .MuiDialog-paper {
    max-height: 100%;
    height: calc(100% - 20px);
    margin: 10px;
    width: 912px;
    max-width: 100%;
    box-shadow: 10px 10px 40px rgba(111, 138, 231, 0.25),
      0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    ${(props) =>
      props.fullsize &&
      `
      width: 100%;
      margin: 40px 60px;
      height: calc(100% - 160px);
    `}
  }
`;
export const StyledActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 20px 50px 40px;
    border-top: 1px solid var(--silver);
    & > :not(:first-of-type) {
      margin-left: 24px;
    }
  }
`;
export const StyledTitle = styled(DialogTitle)`
  &.MuiTypography-root {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: var(--headings);
    padding: 20px 50px;
  }
`;
export const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    top: 25px;
    right: 47px;
  }
`;
