import React from "react";
import { CircularProgress } from "@mui/material";
import { LoaderContainer } from "./styles";

export const Spinner = () => {
  return (
    <LoaderContainer>
      <CircularProgress size={30} />
    </LoaderContainer>
  );
};
