import { Column } from "shared/config/types/Table";

export const initialColumns: Column[] = [
  {
    id: "Name",
    label: "Name",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "kpis-edit",
    label: "ADD KPI's",
    minWidth: 200,
    filtering: false,
  },
];
