import React from "react";
import { Modal } from "shared/ui/Modal";
import Button from "shared/ui/Button";
import { Controller, useForm } from "react-hook-form";
import { InputBlock } from "features/add-rule-form/ui/styles";
import FormGroup from "../../../FormComponents/FormGroup";
import { ModuleBody } from "entities/modules";
import { Module } from "shared/api/modules/types";

interface Props {
  title: string;
  handleClose: () => void;
  isLoading?: boolean;
  open: boolean;
  editModule: (data: ModuleBody) => void;
  module: Module | undefined;
}
const defaultValues: ModuleBody = {
  name: "",
  description: "",
};
const ModuleModal = ({
  title,
  module,
  open,
  editModule,
  isLoading,
  handleClose,
}: Props) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      ...defaultValues,
      name: module?.name ?? defaultValues.name,
      description: module?.description ?? defaultValues.description,
    },
  });
  const onSubmit = () => {
    handleSubmit((data: ModuleBody) => {
      editModule(data);
      handleClose();
    })();
  };
  return (
    <div>
      <Modal
        title={title}
        open={open}
        handleClose={handleClose}
        id="addAttributesModal"
        isLoading={isLoading}
        footer={
          <>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{
                marginRight: "24px",
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={onSubmit}>
              Save
            </Button>
          </>
        }
      >
        <form>
          <InputBlock>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FormGroup
                    id="name"
                    value={value}
                    label="Name"
                    placeholder="Name"
                    danger={true}
                    onChange={onChange}
                    error={!!error}
                  />
                );
              }}
            />
          </InputBlock>
          <InputBlock>
            <Controller
              name="description"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FormGroup
                    id="name"
                    value={value}
                    label="Desription"
                    placeholder="Description"
                    danger={true}
                    onChange={onChange}
                    error={!!error}
                  />
                );
              }}
            />
          </InputBlock>
        </form>
      </Modal>
    </div>
  );
};

export default ModuleModal;
