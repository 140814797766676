import React, { memo, ReactNode, useState } from "react";
import { RuleRow } from "entities/products";
import { Accordion } from "shared/ui/Accordion";
import { RuleButton } from "../../OutcomeConfiguration/ui/ModulesAccordion.styles";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { AddRuleForm } from "features/add-rule-form";
import { EditRuleForm } from "features/edit-rule-form";
import { Rule } from "shared/api/modules/types";
import { ActionButtonTypes } from "components/ActionButton/types";
import { ActionButton } from "shared/ui/ActionButton";
import { useModule } from "features/modules";
import { ModuleTypes } from "entities/modules";

type Props = {
  rules?: Rule[];
  heading: string;
  subHeading?: string;
  icon?: ReactNode;
  moduleId: string;
  moduleType?: ModuleTypes;
  editable: boolean;
  onEdit?: (id: string) => void;
  name: string;
  sortable: boolean;
};

export const RulesCard = memo(function RulesCard({
  rules,
  heading,
  subHeading,
  icon,
  moduleId,
  moduleType,
  editable,
  onEdit,
  name,
  sortable,
}: Props) {
  const [addRuleModal, setAddRuleModal] = useState(false);
  const [editRuleModal, setEditRuleModal] = useState(false);
  const [ruleData, setRuleData] = useState<Rule | null>(null);

  const { deleteModule } = useModule();
  const addRuleHandler = () => {
    setAddRuleModal((prevState) => !prevState);
  };
  const editRuleHandler = (item?: Rule) => {
    setEditRuleModal((prevState) => !prevState);
    setRuleData(item || null);
  };

  const deleteModuleHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    deleteModule(moduleId);
  };
  return (
    <>
      <Accordion
        heading={heading}
        subHeading={subHeading}
        icon={icon}
        sortable={sortable}
        action={
          editable ? (
            <>
              {onEdit && (
                <ActionButton
                  title={ActionButtonTypes.EDIT}
                  type={ActionButtonTypes.EDIT}
                  onClick={() => onEdit(name)}
                />
              )}
              {moduleType !== ModuleTypes.ENRICHMENT &&
                moduleType !== ModuleTypes.INTEGRATION && (
                  <ActionButton
                    title={ActionButtonTypes.DELETE}
                    type={ActionButtonTypes.DELETE}
                    onClick={deleteModuleHandler}
                  />
                )}
            </>
          ) : (
            <></>
          )
        }
      >
        {rules &&
          rules.length > 0 &&
          rules.map((subItem) => {
            return (
              <RuleRow
                data={subItem}
                key={subItem.id}
                name={heading}
                editable={editable}
                moduleId={moduleId}
                editRuleHandler={editRuleHandler}
              />
            );
          })}
        {editable && (
          <RuleButton
            variant="outlined"
            startIcon={<Plus />}
            onClick={addRuleHandler}
          >
            <span>Add Rule</span>
          </RuleButton>
        )}
      </Accordion>
      {addRuleModal && (
        <AddRuleForm
          handleClose={addRuleHandler}
          modelName={heading}
          open={addRuleModal}
          moduleId={moduleId}
          moduleType={moduleType}
        />
      )}
      {editRuleModal && (
        <EditRuleForm
          handleClose={editRuleHandler}
          ruleData={ruleData}
          modelName={heading}
          open={editRuleModal}
          moduleId={moduleId}
        />
      )}
    </>
  );
});
