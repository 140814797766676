import * as React from "react";

import { StyledInput } from "./styles";
import Label from "../Label";

interface InputProps {
  value?: string | number;
  id: string;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  danger?: boolean;
  InputProps?: any;
  rows?: number;
  maxRows?: number;
  multiline?: boolean;
}

const FormGroup = ({
  placeholder,
  value,
  id,
  onChange,
  error,
  label,
  danger,
  disabled,
  type,
  InputProps,
  ...props
}: InputProps) => {
  return (
    <>
      {label && <Label label={label} danger={danger} />}
      <StyledInput
        {...props}
        fullWidth
        type={type ?? "text"}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        id={id}
        onChange={onChange}
        error={error}
        disabled={disabled}
        helperText={error && "This field is required."}
        InputProps={InputProps}
      />
    </>
  );
};

export default FormGroup;
