import React, { useContext } from "react";

import {
  Grid,
  InputAdornment,
  Autocomplete,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import FormGroup from "../../../FormComponents/FormGroup";
import CustomSelect from "../../../FormComponents/Select";
import Label from "../../../FormComponents/Label";
import OutlinedCheckbox from "../../../FormComponents/OutlinedCheckbox";

import { StyledInput } from "../../../FormComponents/FormGroup/styles";
import { Basic, Wrapper, Description } from "./styles";
import { Title } from "../../styles";

import { CreateProductContext } from "pages/CreateProduct";

const ProductSetup: React.FC<any> = () => {
  const {
    name,
    setName,
    categories,
    category,
    setCategory,
    productKpi,
    setProductKpi,
    isFormApprovalRequired,
    setApprovalRequired,
    errors,
    currentProduct,
  }: any = useContext(CreateProductContext);
  return (
    <Wrapper>
      <Title>Product Category</Title>
      <Description>First things first, let’s select a category</Description>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            disabled={!!currentProduct?.categoryId}
            id="category"
            options={categories}
            value={category || null}
            onChange={(e, value: any) => setCategory(value)}
            popupIcon={<KeyboardArrowDownIcon sx={{ color: "#000000" }} />}
            renderInput={(params) => (
              <StyledInput
                {...params}
                error={errors?.category}
                helperText={errors?.category && "This field is required."}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "#000000" }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Basic>
        <Title>Basic Information</Title>
        <Description>First things first, let’s select a category</Description>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormGroup
              placeholder="Enter a name of your product."
              value={name}
              id="productName"
              onChange={(e) => setName(e.target.value)}
              label="Product Name"
              error={errors?.name}
              danger
              disabled={!category}
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup
              value={productKpi || ""}
              id="productkpi"
              onChange={(e) => setProductKpi(e.target.value)}
              label="Add KPI"
              disabled={!currentProduct}
            />
          </Grid>
          <Grid item xs={6}>
            <Label label="Tags" disabled />
            <CustomSelect
              value={[]}
              id="tags"
              options={["Tag 1", "Tag 2", "Tag 3"]}
              title="Tags"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              disabled={!category}
              sx={{
                display: "flex",
                paddingLeft: "3px",
              }}
            >
              <FormControlLabel
                control={
                  <OutlinedCheckbox
                    checked={isFormApprovalRequired}
                    name="isFormApprovalRequired"
                    onChange={() => {
                      setApprovalRequired(!isFormApprovalRequired);
                    }}
                  />
                }
                label="Form Approval Required"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Basic>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormGroup
            value={currentProduct?.id}
            id="productId"
            label="Product ID"
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <FormGroup
            value={currentProduct?.version}
            id="productVersions"
            label="Product Versions"
            disabled={true}
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ProductSetup;
