export const moduleTemplatesActions = {
  GET_MODULE_TEMPLATES: "GET_MODULE_TEMPLATES",
  GET_MODULE_TEMPLATES_SUCCESS: "GET_MODULE_TEMPLATES_SUCCESS",
  GET_MODULE_TEMPLATES_FAILURE: "GET_MODULE_TEMPLATES_FAILURE",
  ADD_MODULE_TEMPLATE: "ADD_MODULE_TEMPLATE",
  ADD_MODULE_TEMPLATE_SUCCESS: "ADD_MODULE_TEMPLATE_SUCCESS",
  ADD_MODULE_TEMPLATE_FAILURE: "ADD_MODULE_TEMPLATE_FAILURE",
  REMOVE_MODULE_TEMPLATE: "REMOVE_MODULE_TEMPLATE",
  REMOVE_MODULE_TEMPLATE_SUCCESS: "REMOVE_MODULE_TEMPLATE_SUCCESS",
  REMOVE_MODULE_TEMPLATE_FAILURE: "REMOVE_MODULE_TEMPLATE_FAILURE",
};
