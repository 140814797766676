import React, { SyntheticEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { Box, SvgIcon } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { ReactComponent as DashboardIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as UserManagementIcon } from "assets/icons/user_managment.svg";
import { ReactComponent as ProductsIcon } from "assets/icons/products.svg";
import { ReactComponent as EmbeddedIcon } from "assets/icons/analytics.svg";
import { ReactComponent as HomeIcon } from "assets/icons/home.svg";
import { ReactComponent as CategoryIcon } from "assets/icons/category.svg";
import { ReactComponent as RequestIcon } from "assets/icons/request.svg";
import { ReactComponent as IntegrationIcon } from "assets/icons/product-item.svg";
import { ReactComponent as NotificationsIcon } from "assets/icons/notifications.svg";
import { ReactComponent as BarChartIcon } from "assets/icons/barChart.svg";
import { ReactComponent as EventsAndTriggersIcon } from "assets/icons/eventsAndTriggers.svg";
import ChevronIcon from "assets/icons/chevron.svg";
import Logo from "assets/icons/logo.svg";
import DemoLogo from "assets/demo-logo.png";
import { useAppSelector } from "redux/store/hooks/hooks";
import { RoutesList } from "shared/routes";

import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledAdminLink,
  StyledDrawer,
  StyledFooterDiv,
  StyledFooterLogoWrap,
  StyledLink,
  StyledLogoDiv,
  StyledLogoText,
  StyledPanelDiv,
  StyledPoliciesWrap,
  StyledSidebarLogo,
  StyledSubLink,
  StyledToggleDiv,
  StyledToggleImg,
} from "./styles";

import { ADMIN_PATH } from "../../constants";
import { countNew } from "entities/requests";
import { getNewNotificationsCount } from "entities/platformNotifications";
import { getCurrentTenantLogo } from "entities/users";

export interface MenuLinks {
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  path: string;
  indexPath?: string;
  subLinks?: MenuLinks[];
}

const menuLinks: MenuLinks[] = [
  {
    label: "Carrier Hub",
    icon: DashboardIcon,
    path: RoutesList.HOME,
    subLinks: [
      {
        label: "Program Configurator",
        icon: ProductsIcon,
        path: "programs",
        indexPath: RoutesList.LIST,
      },
      {
        label: "Product Configurator",
        icon: ProductsIcon,
        path: "products",
        indexPath: RoutesList.LIST,
      },
      {
        label: "User Management",
        icon: UserManagementIcon,
        path: "user-management",
        indexPath: "/users",
      },
    ],
  },
  {
    label: "Analytics Hub",
    icon: EmbeddedIcon,
    path: RoutesList.ANALYTICS,
  },
  {
    label: "Embedded Hub",
    icon: CategoryIcon,
    path: RoutesList.CUSTOMER_ENGAGEMENT,
  },
  {
    label: "Integration Hub",
    icon: IntegrationIcon,
    path: RoutesList.INTEGRATIONS,
  },
  {
    label: "Events & Triggers Hub",
    icon: EventsAndTriggersIcon,
    path: RoutesList.EVENTS_AND_TRIGGERS,
  },
  {
    label: "Requests",
    icon: RequestIcon,
    path: RoutesList.REQUESTS,
  },
  {
    label: "KPI's",
    icon: BarChartIcon,
    path: RoutesList.KPIS,
  },
  {
    label: "Notifications",
    icon: NotificationsIcon,
    path: RoutesList.NOTIFICATIONS,
  },
];

interface Props {
  expanded: boolean;
  sidebarWidth: number;
  rightSidebarPanel: number;
  defaultSpacing: number;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<Props> = ({
  expanded,
  sidebarWidth,
  rightSidebarPanel,
  defaultSpacing,
  toggleSidebar,
}) => {
  const location = useLocation();
  const tenantInfo = useAppSelector(getCurrentTenantLogo);
  const [newCount, setNewCount] = useState(0);
  const count = useAppSelector(countNew);
  const newNotificationCount = useAppSelector(getNewNotificationsCount);
  const [tenant, setTenant] = useState("IX-Platform");
  const { accounts }: any = useMsal();
  const {
    idTokenClaims: { given_name },
  } = accounts[0];

  useEffect(() => {
    if (newCount == 0 && count) {
      setNewCount(count);
    }
    if (newCount !== count) {
      setNewCount(count);
    }
  }, [count]);

  useEffect(() => {
    const tenant_name = localStorage.getItem("tenant-name");
    if (tenant_name) setTenant(tenant_name);
  }, []);

  const tabRequestLabel = (label: string) => {
    if (label === "Requests") {
      if (newCount == 0) {
        return label;
      } else {
        return `Requests (${newCount})`;
      }
    } else if (label === "Notifications") {
      return newNotificationCount
        ? `Notifications (${newNotificationCount})`
        : "Notifications";
    } else {
      return label;
    }
  };

  const setIsActive = (path: string) => {
    const found = location.pathname
      .split("/")
      .find((element: string) => `/${element}` === path);
    return !!found || location.pathname === path;
  };

  const setActiveSubLink = (path: string) => {
    return location.pathname.includes(path);
  };

  const handleBack = () => {
    window.location.assign(ADMIN_PATH);
  };
  const logo = tenant === "IX-Demo 1" ? DemoLogo : Logo;

  const onImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = logo;
  };

  // list of tabs have sublinks
  const list = [
    RoutesList.PRODUCTS_LIST,
    RoutesList.PROGRAMS_LIST,
    RoutesList.USER_MANAGEMENT_USERS,
  ];

  const currentTabExpandable = () => {
    const path = location.pathname;

    const selectedTab = list.filter((e) => path.includes(e));

    if (path === RoutesList.HOME || path === "/signin-oidc" || selectedTab[0]) {
      return true;
    }
    return false;
  };
  return (
    <StyledDrawer variant="permanent" open={expanded} data-testid="sidebar">
      <Box
        sx={{
          width: sidebarWidth,
          overflow: "hidden",
          position: "relative",
          transition: ".1s ease",
          height: "100vh",
          boxSizing: "content-box",
          padding: `0 ${
            defaultSpacing + rightSidebarPanel
          }px 0 ${defaultSpacing}px`,
        }}
      >
        <StyledLogoDiv data-testid="sidebar_logo">
          <StyledSidebarLogo
            src={tenantInfo?.uri || logo}
            onError={onImageError}
            alt="Logo"
          />
          {!tenantInfo?.uri && tenant !== "IX-Demo 1" && (
            <StyledLogoText isFooter={false}>{tenant}</StyledLogoText>
          )}
        </StyledLogoDiv>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "calc(100% - 16rem)",
            overflowY: "auto",
            whiteSpace: "nowrap",
          }}
        >
          {given_name === "Superadmin" && (
            <StyledAdminLink
              onClick={handleBack}
              isAdmin
              data-testid="sidebar_adminLink"
            >
              <SvgIcon component={HomeIcon} />
              {expanded && <p>IXportal Admin</p>}
            </StyledAdminLink>
          )}
          {menuLinks.map((link, index) =>
            !link.subLinks ? (
              <StyledLink
                isActive={setIsActive(link.path)}
                to={
                  link.path === "/user-management"
                    ? `${link.path}/users`
                    : link.path
                }
                key={`${link.label}_${index}`}
                data-testid={`sidebar_${link.label}Link`}
              >
                <SvgIcon component={link.icon} />
                {expanded && <p>{tabRequestLabel(link.label)}</p>}
              </StyledLink>
            ) : (
              <StyledAccordion
                expanded={currentTabExpandable() && expanded}
                key={`${link.label}_${index}`}
              >
                <StyledAccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  sx={{
                    "&.Mui-expanded": {
                      minHeight: "54px",
                    },
                  }}
                >
                  <StyledLink
                    isActive={currentTabExpandable() && expanded}
                    to={
                      link.indexPath
                        ? `${link.path}/${link.indexPath}`
                        : link.path
                    }
                    key={`${link.label}_${index}`}
                    expanded={expanded ? "true" : undefined}
                  >
                    <SvgIcon component={link.icon} />
                    {expanded && (
                      <>
                        <p>{link.label}</p>
                        <ExpandMoreIcon
                          sx={{
                            fontSize: "16px",
                            position: "absolute",
                            right: "10px",
                          }}
                        />
                      </>
                    )}
                  </StyledLink>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  {link.subLinks.map((sublink) => {
                    const combinedPath =
                      link.path + sublink.path + (sublink.indexPath ?? "");
                    return (
                      <StyledSubLink
                        isActive={setActiveSubLink(link.path + sublink.path)}
                        to={combinedPath}
                        key={combinedPath}
                        data-testid={`sidebar_${sublink.label}SubLink`}
                      >
                        {sublink.label}
                      </StyledSubLink>
                    );
                  })}
                </StyledAccordionDetails>
              </StyledAccordion>
            )
          )}
        </Box>
        {expanded ? (
          <StyledFooterDiv data-testid="sidebar_footer">
            <StyledFooterLogoWrap data-testid="sidebar_footerLogo">
              <span>Powered by</span>
              <img src={Logo} alt="logo" />
              <StyledLogoText isFooter={true}>
                IX<span>portal</span>
              </StyledLogoText>
            </StyledFooterLogoWrap>
            <StyledPoliciesWrap data-testid="sidebar_footerPolicies">
              <span>Privacy</span>
              <div></div>
              <span>Policy</span>
              <div></div>
              <span>T&C</span>
            </StyledPoliciesWrap>
          </StyledFooterDiv>
        ) : (
          <StyledFooterDiv>
            <StyledFooterLogoWrap data-testid="sidebar_footerLogo">
              <img src={Logo} alt="logo" />
            </StyledFooterLogoWrap>
            <StyledPoliciesWrap />
          </StyledFooterDiv>
        )}
      </Box>
      <StyledPanelDiv rightSidebarPanel={rightSidebarPanel + 10}>
        <StyledToggleDiv
          onClick={toggleSidebar}
          expanded={expanded}
          data-testid="sidebar_toggleButton"
        >
          <StyledToggleImg expanded={expanded} src={ChevronIcon} />
        </StyledToggleDiv>
      </StyledPanelDiv>
    </StyledDrawer>
  );
};

export default Sidebar;
