import { put, takeEvery } from "redux-saga/effects";
import { showAlert, closeAlert } from "entities/alerts/model/slice/alertsSlice";
import { alertActions } from "entities/alerts";
import { clearupDeleteRequestSlice } from "entities/requests";

export function* showAlertWorker(payload: any) {
  try {
    yield put(showAlert(payload.data));
    if (payload.data.type === "requestDeleted") {
      yield put(clearupDeleteRequestSlice({}));
    }
  } catch (e) {
    yield put({ type: "SHOW_ALERT_FAILED" });
  }
}

export function* closeAlertWorker(payload: any) {
  try {
    yield put(closeAlert(payload.data));
  } catch (e) {
    yield put({ type: "CLOSE_ALERT_FAILED" });
  }
}

export function* AlertsWatcher() {
  yield takeEvery(alertActions.CLOSE_ALERT, closeAlertWorker);
  yield takeEvery(alertActions.SHOW_ALERT, showAlertWorker);
}
