import React, { useState } from "react";
import { useDispatch } from "react-redux";

import Modal from "../StyledModal";
import Button from "shared/ui/Button";

import GeneralInfo from "./GeneralInfo";
import Permissions from "./Permissions";

import { Tab, Box } from "@mui/material";

import { Wrapper, StyledTabs } from "./styles";

import { alertActions } from "entities/alerts";

interface AddNewUserProps {
  open: boolean;
  onClose: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserProfile = ({ open, onClose }: AddNewUserProps) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("Jane");
  const [lastName, setLastName] = useState("Smith");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("user.email@email.com");
  const [phone, setPhone] = useState("+1-202-555-0156");
  const [location, setLocation] = useState("");
  const [value, setValue] = React.useState(0);
  const [role, setRole] = useState<string[]>([]);
  const [group, setGroup] = useState<string[]>([]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onSubmit = () => {
    dispatch({
      type: alertActions.SHOW_ALERT,
      data: {
        type: "editUser",
      },
    });
    onClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, id },
    } = event;
    if (id === "firstName") setFirstName(value);
    if (id === "lastName") setLastName(value);
    if (id === "title") setTitle(value);
    if (id === "email") setEmail(value);
    if (id === "phone") setPhone(value);
    if (id === "location") setLocation(value);
  };

  const selectRole = (event: any) => {
    const {
      target: { value },
    } = event;
    setRole(typeof value === "string" ? value.split(",") : value);
  };

  const selectGroup = (event: any) => {
    const {
      target: { value },
    } = event;
    setGroup(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Modal
      id="editUserModal"
      onClose={onClose}
      open={open}
      title="User Profile"
      submitButton={
        <Button
          variant="contained"
          onClick={onSubmit}
          data-testid="editUserModal_button"
        >
          Save changes
        </Button>
      }
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs
          value={value}
          onChange={handleTabChange}
          aria-label="user profile modal tabs"
          data-testid="editUserModal_tabs"
        >
          <Tab
            label="General Info"
            data-testid="editUserModal_generalInfoTab"
            {...a11yProps(0)}
          />
          <Tab
            label="Permissions"
            data-testid="editUserModal_permissionsTab"
            {...a11yProps(1)}
          />
          <Tab
            label="History"
            data-testid="editUserModal_historyTab"
            {...a11yProps(2)}
            disabled
          />
        </StyledTabs>
      </Box>
      <Wrapper>
        <TabPanel
          value={value}
          index={0}
          data-testid="editUserModal_generalInfo"
        >
          <GeneralInfo
            handleChange={handleChange}
            firstName={firstName}
            lastName={lastName}
            email={email}
            title={title}
            location={location}
            phone={phone}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          data-testid="editUserModal_permissions"
        >
          <Permissions
            role={role}
            group={group}
            selectRole={selectRole}
            selectGroup={selectGroup}
          />
        </TabPanel>
        <TabPanel value={value} index={2} data-testid="editUserModal_history">
          Item Three
        </TabPanel>
      </Wrapper>
    </Modal>
  );
};

export default UserProfile;
