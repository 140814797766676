import React, { lazy, Suspense, useEffect } from "react";
import {
  ModalConfiguration,
  ModalConfigurationHeader,
  ModalConfigurationWrapper,
} from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Slide } from "@mui/material";
import { Spinner } from "shared/ui/Spinner";
import { StyledTitle } from "shared/ui/pageTitle/ui/styles";
import { useWidgetConfig } from "features/widgets";
import { TransitionProps } from "@mui/material/transitions";

const Configuration = lazy(() => import("./ConfigurationPage/index"));
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface Props {
  open: boolean;
  onClose: () => void;
  widgetId?: string | null;
}
const WidgetConfiguration = ({ open, onClose, widgetId }: Props) => {
  const {
    getWidgetById,
    isLoading,
    data,
    getWidgetApplications,
    getWidgetProducts,
    setNeedToRefresh,
  } = useWidgetConfig();
  useEffect(() => {
    setNeedToRefresh(false);
  }, []);
  useEffect(() => {
    if (widgetId) {
      getWidgetById(widgetId);
    }
  }, [widgetId, getWidgetById]);

  useEffect(() => {
    if (data) {
      getWidgetApplications();
      getWidgetProducts();
    }
  }, [data]);

  return (
    <ModalConfiguration
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <ModalConfigurationWrapper>
        <ModalConfigurationHeader>
          <StyledTitle variant={"h5"}>
            {`${widgetId ? "Update the" : "Create new"}`} widget
          </StyledTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 18,
              top: 18,
              color: "var(--black)",
            }}
          >
            <CloseIcon />
          </IconButton>
        </ModalConfigurationHeader>
        <Suspense fallback={<Spinner />}>
          {isLoading && <Spinner />}
          {!isLoading && <Configuration onClose={onClose} data={data} />}
        </Suspense>
      </ModalConfigurationWrapper>
    </ModalConfiguration>
  );
};

export default WidgetConfiguration;
