import styled from "styled-components";

export const OutcomesTableWrapper = styled.div`
  background-color: #ffffff;
  padding: 20px;
  min-height: 500px;
`;

export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  align-items: baseline;
`;

export const List = styled.li`
  list-style-type: none;
  margin-bottom: 10px;
`;

export const StyledBlockWrapper = styled.div`
  position: relative;
  background: white;
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 8px;
  cursor: move;
  overflow: hidden;
`;

export const Title = styled.div`
  background: rgba(98, 102, 234, 0.5);
  padding: 10px 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #010101;
`;
export const BlockContainer = styled.div`
  padding: 20px 20px 10px;
`;
