import React, { memo } from "react";
import { StyledChip } from "shared/ui/Table/styles";
import { useLinkedApps } from "../model/hooks/useApplications";
import { ReactComponent as ActivateIcon } from "assets/icons/add.svg";
import { ReactComponent as DeactivateIcon } from "assets/icons/remove.svg";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getDirectProductIdsByApp } from "entities/applications";
import { ChipWrapper } from "./styles";
import { Spinner } from "shared/ui/Spinner";
import { useDirectActions } from "../model/hooks/useDirectApplications";
import { KpiPayload } from "shared/api/kpis/types";
import { getEntityKPI } from "entities/kpis";

interface Props {
  productId: string;
  appId: string;
  handleKpi?: (payload: KpiPayload, callback?: any) => void;
}

export const ActivateButtonDirect = memo(function ActivateButtonDirect({
  productId,
  appId,
  handleKpi,
}: Props) {
  const { unlinkDirectToProduct, linkDirectToProduct } = useDirectActions();
  const loading = useLinkedApps(appId);
  const activeProductApplication = useAppSelector(
    getDirectProductIdsByApp(appId, productId)
  );
  const entityKpi = useAppSelector(getEntityKPI(appId, productId));

  const handleActivate = (id: string) => () => {
    if (!activeProductApplication) {
      linkDirectToProduct({ id, productId });
    } else if (handleKpi && entityKpi) {
      handleKpi(
        {
          entityId: appId,
          productId,
          kpiType: "Application",
          value: null,
        },
        () => unlinkDirectToProduct({ id, productId })
      );
    } else {
      unlinkDirectToProduct({ id, productId });
    }
  };
  return (
    <ChipWrapper>
      {loading ? <Spinner /> : null}
      <StyledChip
        label={!activeProductApplication ? "Activate" : "Deactivate"}
        chiptype={"activate"}
        icon={!activeProductApplication ? <ActivateIcon /> : <DeactivateIcon />}
        onClick={handleActivate(appId)}
      />
    </ChipWrapper>
  );
});
