import React from "react";

import { StyledButton } from "./styles";

interface SelectAllButtonProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  text?: string;
}
const SelectAllButton: React.FC<any> = ({
  onClick,
  text,
  ...props
}: SelectAllButtonProps) => {
  return (
    <StyledButton variant="outlined" onClick={onClick} {...props}>
      {text ?? "Select All"}
    </StyledButton>
  );
};

export default SelectAllButton;
