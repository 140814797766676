import React from "react";
import { Box } from "@mui/material";
import AccordionSection from "shared/ui/AccordionSection";
import { KpisTable } from "./KpisTable";

const Kpis = () => {
  return (
    <div>
      <AccordionSection title="KPI's" editable={false}>
        <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
          <KpisTable />
        </Box>
      </AccordionSection>
    </div>
  );
};

export default Kpis;
