import React, { memo, useEffect, useState } from "react";

import { Table, TableBody, TableCell } from "@mui/material";
import { initialColumns } from "./types";
import { useApplicationsData } from "features/applications";

import { IXTableRow, TableHeader } from "shared/ui/Table";
import { TableSkeleton } from "shared/ui/TableSkeleton";
import TablePlaceholder from "shared/ui/TablePlaceholder";
import { KpiItem } from "shared/api/kpis/types";
import { getKpiItems, getCurrentKpiItems } from "entities/kpis";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getWidgetsData } from "entities/widgets";
import { getApplicationsData } from "entities/applications";
import { TableContainerWrapper } from "shared/ui/Table/styles";

export const KpisTable = memo(function KpisTable() {
  const { isLoading } = useApplicationsData();
  const productKpis: KpiItem[] = useAppSelector(getKpiItems);
  const programKpi: KpiItem[] = useAppSelector(getCurrentKpiItems);
  const [sortedKpis, setSortedKpis] = useState<KpiItem[]>([]);
  const widgets = useAppSelector(getWidgetsData);
  const applications = useAppSelector(getApplicationsData);

  useEffect(() => {
    if (productKpis.length || programKpi) {
      const data = [];

      programKpi?.map((item: KpiItem) => {
        data.push(item);
      });

      const productKpi = productKpis.filter(
        (item: KpiItem) => item.kpiType === "Product" && item.value
      );
      if (productKpi.length) data.push(productKpi[0]);

      const distributions = productKpis.filter(
        (item: KpiItem) => item.kpiType === "Application" && item.value
      );
      data.push(...distributions);

      const widgets = productKpis.filter(
        (item: KpiItem) => item.kpiType === "Widget" && item.value
      );
      data.push(...widgets);
      setSortedKpis(data);
    }
  }, [productKpis, programKpi]);

  const widgetName = (id: string) => {
    if (widgets.length === 0) return "";
    const widget = widgets.filter((item) => item.id === id);
    if (widget.length) return widget[0].name;
  };

  const appName = (id: string) => {
    if (applications.length === 0) return "";
    const application = applications.filter((item) => item.id === id);
    if (application.length) return application[0].name;
  };

  const kpiName = (id: string, type: string) => {
    const widName = widgetName(id);
    const applicationName = appName(id);
    if (!widName && !applicationName) return type;
    const name = widName || applicationName;

    return name;
  };

  return (
    <TableContainerWrapper data-testid="tableContainer">
      <Table stickyHeader>
        <TableHeader
          hideCheckbox={true}
          columns={initialColumns}
          rowCount={productKpis.length}
          numSelected={0}
        />
        <TableBody data-testid="tableBody">
          {isLoading && <TableSkeleton lines={3} columns={initialColumns} />}
          {!isLoading && sortedKpis.length === 0 && (
            <TablePlaceholder colSpan={initialColumns.length} />
          )}
          {!isLoading && sortedKpis.length
            ? sortedKpis.map((row) => {
                return (
                  <IXTableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    data-testid={`tableRow_${row.entityId}`}
                  >
                    <TableCell sx={{ fontWeight: 700 }}>
                      {kpiName(row.entityId, row.kpiType)}
                    </TableCell>
                    <TableCell>{row.kpiType}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </IXTableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainerWrapper>
  );
});
