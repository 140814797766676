import styled from "styled-components";
export const SortLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const IconWrap = styled.div<{ open: boolean; applied: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: ${(props) =>
    props.open || props.applied ? "var(--secondary)" : "transparent"};
  cursor: pointer;
  margin-left: 5px;

  svg {
    transform: rotateZ(0deg);
    transition: 0.2s;
    opacity: ${(props) => (props.open || props.applied ? "1" : "0.5")};
    path {
      transition: 0.2s;
      fill: ${(props) =>
        props.open || props.applied
          ? "var(--white)"
          : "rgba(56, 56, 116, 0.5)"};
    }
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  min-width: 250px;
  flex-direction: column;
`;
