import { useAppDispatch, useAppSelector } from "redux/store/hooks/hooks";
import { useCallback } from "react";
import { getProduct } from "entities/products";
import {
  getEventsAction,
  getEventItems,
  getEventsLoading,
  createEventAction,
  deleteEventAction,
  editEventAction,
  getEventActionLoading,
  getTotalCount,
  getTriggerDefinitionsAction,
  getEventTriggers,
  cloneEventAction,
} from "entities/events";
import { EventPayload } from "shared/api/events/types";
import { PaginationParams } from "api/types";

const useProductData = () => useAppSelector(getProduct);
const useEventsData = () => useAppSelector(getEventItems);
const useEventsTotalCount = () => useAppSelector(getTotalCount);
const useEventsLoading = () => useAppSelector(getEventsLoading);
const useEventActionLoading = () => useAppSelector(getEventActionLoading);
const useEventTriggers = () => useAppSelector(getEventTriggers);

export const useEvents = () => {
  const dispatch = useAppDispatch();
  const { id } = useProductData();
  const isLoading = useEventsLoading();
  const actionLoading = useEventActionLoading();
  const events = useEventsData();
  const total = useEventsTotalCount();
  const eventTriggers = useEventTriggers();

  const getEvents = useCallback(
    (pagingParams: PaginationParams) => {
      dispatch(getEventsAction({ ProductId: id, ...pagingParams }));
    },
    [dispatch]
  );

  const getAllEvents = useCallback(
    (pagingParams: PaginationParams, id: string) => {
      dispatch(getEventsAction({ ProductId: id ?? "", ...pagingParams }));
    },
    [dispatch]
  );

  const createEvent = useCallback(
    (data: EventPayload, callback: any) => {
      dispatch(createEventAction({ data, productId: id, callback }));
    },
    [dispatch]
  );

  const deleteEvent = useCallback(
    (eventId: string, eventTrigger: string) => {
      dispatch(deleteEventAction({ productId: id, eventId, eventTrigger }));
    },
    [dispatch]
  );

  const editEvent = useCallback(
    (eventId: string, data: EventPayload, callback: any) => {
      dispatch(editEventAction({ productId: id, eventId, data, callback }));
    },
    [dispatch]
  );

  const getTriggerDefinitions = useCallback(() => {
    dispatch(getTriggerDefinitionsAction());
  }, [dispatch]);

  const cloneEvent = useCallback(
    (id: string, callback: any) => {
      dispatch(cloneEventAction({ id, callback }));
    },
    [dispatch]
  );

  return {
    isLoading,
    getEvents,
    events,
    createEvent,
    id,
    deleteEvent,
    editEvent,
    actionLoading,
    total,
    getAllEvents,
    getTriggerDefinitions,
    eventTriggers,
    cloneEvent,
  };
};
