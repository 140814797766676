import React from "react";

import { IconButton } from "@mui/material";
import { Edit, ExpandMore, CheckCircle, Error } from "@mui/icons-material";

import { StyledTitle, Summary, Details, StyledAccordion } from "./styles";

interface AccordionSectionProps {
  children?: React.ReactNode;
  title: string;
  editable?: boolean;
  error?: boolean;
  handleEdit?: () => void;
}

const AccordionSection = ({
  children,
  title,
  editable = true,
  handleEdit,
  error,
}: AccordionSectionProps) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <Summary expandIcon={<ExpandMore />}>
        {error ? (
          <Error sx={{ color: "#FB9A1A", margin: "0 10px" }} />
        ) : (
          <CheckCircle sx={{ color: "#009C10", margin: "0 10px" }} />
        )}
        <StyledTitle>{title}</StyledTitle>
        {editable && (
          <IconButton onClick={handleEdit}>
            <Edit />
          </IconButton>
        )}
      </Summary>
      <Details>{children}</Details>
    </StyledAccordion>
  );
};

export default AccordionSection;
