import styled from "styled-components";
import { Chip, Typography } from "@mui/material";

export const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    font-size: 14px;
    text-decoration: underline;
  }
`;

export const StyledChip = styled(Chip)<{ chiptype: string }>`
  &.MuiChip-root {
    background-color: ${(props) =>
      props.chiptype !== "pending" ? "transparent" : "#ffebd1"};
    color: ${(props) =>
      props.chiptype !== "pending" ? "var(--black)" : "#fb9a1a"};
    padding: ${(props) =>
      props.chiptype === "pending" ? "15px 20px" : "8px 12px 8px 8px"};
    border: ${(props) =>
      props.chiptype === "pending" ? "none" : "1px solid #9696a9"};
    border-radius: ${(props) =>
      props.chiptype === "pending" ? "100px" : "6px"};
  }
`;

export const TableText = styled.p`
  color: var(--headings);
`;
