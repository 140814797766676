import { call, put, takeLatest } from "redux-saga/effects";
import {
  createModuleAction,
  createModuleRuleAction,
  createModulesAction,
  deleteModuleAction,
  editModuleAction,
  deleteModuleRuleAction,
  editModuleRuleAction,
  getAllModulesAction,
  getModulesTemplatesAction,
  updateModulesAttributes,
  setModulesOrderAction,
} from "../actions";
import ModulesApi from "shared/api/modules/modulesApi";
import {
  CreateModulesPayload,
  ModulesCreateResponse,
  ModulesOrderResponse,
  ModulesResponse,
  ModuleTemplatesResponse,
  ModulesOrderPayload,
} from "shared/api/modules/types";
import { AxiosResponse } from "axios";
import {
  setAttributesLoading,
  setModuleLoading,
  setModules,
  setModuleOrderQueue,
  setModulesLoading,
  setNewOrder,
  setResponseAttributesForResult,
  setRuleLoading,
  setTemplates,
} from "../slice/modulesSlice";
import { Toaster } from "shared/ui/Toast/Toast";
import { Modules } from "../types/enum";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";
import { ModulesTypesMap, ModuleType } from "../types/types";

const modules: ModulesTypesMap = {
  [Modules.APPETITE]: {
    name: "Appetite",
    description:
      "Assists entities to make better choices by considering risk more effectively in decision making.",
    moduleType: "Rule",
  },
  [Modules.RESULT]: {
    name: "Result",
    description:
      "Reviews all applications and client-submitted feedback to verify that the candidates meet all the requirements to become a Qualified ",
    moduleType: "Result",
    outcomeType: "Bind",
  },
  [Modules.ELIGIBILITY]: {
    name: "Eligibility",
    description:
      "The decision as to whether an individual qualifies, under financial and nonfinancial requirements, to receive program benefits",
    moduleType: "Rule",
  },
  [Modules.RATING]: {
    name: "Rating",
    description:
      "Penalties imposed on individuals or institutions that do not comply with laws or rules.",
    moduleType: "Rule",
  },
  [Modules.PAYMENT]: {
    name: "Payment",
    description: "Payment options that are accepted for collection of funds",
    moduleType: "Rule",
  },
  [Modules.BIND]: {
    name: "Bind",
    description: "Rules to bind a customer policy",
    moduleType: "Rule",
  },
  [Modules.RULE]: {
    name: "Rule",
    description: "Rule module",
    moduleType: "Rule",
    outcomeType: "Lead",
  },
  [Modules.NOTIFICATION]: {
    name: "Notifications",
    description: "Communication upon success of binding or quoting a customer",
    moduleType: "Rule",
  },
  [Modules.FULFILLMENT]: {
    name: "Fulfillment",
    description: "Fulfillment",
    moduleType: "Fulfillment",
  },
  [Modules.COMPLETION]: {
    name: "Completion",
    description: "Completion",
    moduleType: "Completion",
    outcomeType: "Lead",
  },
};

export function* getModulesSaga(id: string, version: number) {
  const response: AxiosResponse<ModulesResponse> = yield call(
    ModulesApi.getModules,
    id,
    version
  );
  yield put(setModules(response.data));
  yield put(setModuleOrderQueue(response.data));
  yield put(setModulesLoading(false));
}

export function* getModulesTemplatesSaga() {
  try {
    const response: AxiosResponse<ModuleTemplatesResponse> = yield call(
      ModulesApi.getModulesTemplates
    );
    yield put(setTemplates(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* setModulesOrderSaga(
  action: ReturnType<typeof setModulesOrderAction>
) {
  try {
    const { id, version, data, callback } = action.payload;
    const response: AxiosResponse<ModulesOrderResponse> = yield call(
      ModulesApi.setModulesOrder,
      id,
      version,
      data
    );
    if (response.data) {
      yield put(setNewOrder(response.data.moduleQueue));
    }
    if (callback) callback();
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* setModulesOrder(
  id: string,
  version: number,
  data: ModulesOrderPayload
) {
  const response: AxiosResponse<ModulesOrderResponse> = yield call(
    ModulesApi.setModulesOrder,
    id,
    version,
    data
  );
  return response.data.moduleQueue;
}

export function* createModule(
  id: string,
  version: number,
  folder: CreateModulesPayload
) {
  const response: AxiosResponse<ModulesCreateResponse> = yield call(
    ModulesApi.createModule,
    id,
    version,
    folder
  );
  return response.data.id;
}

export function* createModuleSaga(
  action: ReturnType<typeof createModuleAction>
) {
  const { version, id, type, data } = action.payload;
  try {
    let newModuleType: ModuleType = modules[type];
    yield put(setModuleLoading(true));
    if (data) {
      newModuleType = {
        name: data.name,
        description: data.description,
        moduleType: type,
        outcomeType: "Lead",
      };
    }
    yield call(createModule, id, version, newModuleType);
    yield put(setModuleLoading(false));
    yield put(setModulesLoading(true));
    yield call(getModulesSaga, id, version);
  } catch (e) {
    sagaErrorBoundary(e);
  }
  yield put(setModulesLoading(false));
}

export function* createModulesSaga(
  action: ReturnType<typeof createModulesAction>
) {
  const { id, version } = action.payload;
  yield put(setModulesLoading(true));
  try {
    yield call(createModule, id, version, modules[Modules.RESULT]);
    yield call(getModulesSaga, id, version);
  } catch (e) {
    sagaErrorBoundary(e);
  }
  yield put(setModulesLoading(false));
}

export interface ErrorFromBackend {
  errors: {
    Validations: string[];
  };
}
export function* createModuleRuleSaga(
  action: ReturnType<typeof createModuleRuleAction>
) {
  const { data, id, version, moduleId } = action.payload;
  yield put(setRuleLoading(true));
  try {
    yield call(ModulesApi.createModuleRule, id, version, moduleId, data);
    yield put(setRuleLoading(false));
    yield put(setModulesLoading(true));
    yield call(getModulesSaga, id, version);
    Toaster.infoMessage("Rule added Successfully");
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(setRuleLoading(false));
    yield put(setModulesLoading(false));
  }
  yield put(setModulesLoading(false));
}

export function* editModuleSaga(action: ReturnType<typeof editModuleAction>) {
  const { id, version, moduleId, payload } = action.payload;

  try {
    yield call(ModulesApi.editModule, id, version, moduleId, payload);
    yield put(setModulesLoading(true));
    yield call(getModulesSaga, id, version);
    Toaster.infoMessage("Module edit Successfully");
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(setRuleLoading(false));
    yield put(setModulesLoading(false));
  }
  yield put(setModulesLoading(false));
}

export function* deleteModuleSaga(
  action: ReturnType<typeof deleteModuleAction>
) {
  const { id, version, moduleId } = action.payload;
  try {
    yield call(ModulesApi.deleteModule, id, version, moduleId);
    yield put(setModulesLoading(true));
    yield call(getModulesSaga, id, version);
    Toaster.infoMessage("Module deleted Successfully");
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(setRuleLoading(false));
    yield put(setModulesLoading(false));
  }
  yield put(setModulesLoading(false));
}
export function* editModuleRuleSaga(
  action: ReturnType<typeof editModuleRuleAction>
) {
  const { data, id, version, moduleId, ruleId } = action.payload;
  yield put(setRuleLoading(true));
  try {
    yield call(ModulesApi.editModuleRule, id, version, moduleId, ruleId, data);
    yield put(setRuleLoading(false));
    yield put(setModulesLoading(true));
    yield call(getModulesSaga, id, version);
    Toaster.infoMessage("Rule edited Successfully");
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(setRuleLoading(false));
    yield put(setModulesLoading(false));
  }
  yield put(setModulesLoading(false));
}

export function* deleteModuleRuleSaga(
  action: ReturnType<typeof editModuleRuleAction>
) {
  const { id, version, moduleId, ruleId } = action.payload;
  try {
    yield call(ModulesApi.deleteModuleRule, id, version, moduleId, ruleId);
    yield put(setModulesLoading(true));
    yield call(getModulesSaga, id, version);
    Toaster.infoMessage("Rule is deleted");
  } catch (e) {
    sagaErrorBoundary(e);
  }
  yield put(setModulesLoading(false));
}

export function* getAllModulesSaga(
  action: ReturnType<typeof getAllModulesAction>
) {
  const { id, version } = action.payload;
  try {
    yield call(getModulesSaga, id, version);
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* updateModuleAttributesSaga(
  action: ReturnType<typeof updateModulesAttributes>
) {
  const { payload } = action;
  try {
    yield put(setAttributesLoading(true));
    const response: AxiosResponse<{ id: string }> = yield call(
      ModulesApi.updateModuleAttributes,
      payload
    );
    if (response.data) {
      yield put(setResponseAttributesForResult(payload));
      Toaster.infoMessage(
        `Attribute${
          payload.attributes.length > 1 ? "s" : ""
        } added successfully`
      );
    }
  } catch (e) {
    sagaErrorBoundary(e);
  } finally {
    yield put(setAttributesLoading(false));
  }
}
export function* ModulesWatcher() {
  yield takeLatest(setModulesOrderAction, setModulesOrderSaga);
  yield takeLatest(createModulesAction, createModulesSaga);
  yield takeLatest(createModuleRuleAction, createModuleRuleSaga);
  yield takeLatest(editModuleRuleAction, editModuleRuleSaga);
  yield takeLatest(deleteModuleRuleAction, deleteModuleRuleSaga);
  yield takeLatest(createModuleAction, createModuleSaga);
  yield takeLatest(deleteModuleAction, deleteModuleSaga);
  yield takeLatest(editModuleAction, editModuleSaga);
  yield takeLatest(getModulesTemplatesAction, getModulesTemplatesSaga);
  yield takeLatest(getAllModulesAction, getAllModulesSaga);
  yield takeLatest(updateModulesAttributes, updateModuleAttributesSaga);
}
