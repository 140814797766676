import React, { useState, useEffect, useContext } from "react";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";

import { StyledTypography } from "./styles";
import { initialColumns } from "./types";

import TablePaper from "shared/ui/TablePaper";

import { ProductResponse } from "shared/api/products/types";
import { Module, ModuleTemplate } from "api/types";
import { Column } from "components/Table/types";
import { OrderTypes } from "shared/config/types/Sorting";

import { AssociatedProductsContext } from "pages/IntegrationHub";
import DataEnrichTable from "components/Table";
import TableControls from "components/TableControls";
import CreateRequestModal from "components/Modals/CreateRequest";

import { RootState } from "redux/store";
import { moduleTemplatesActions } from "entities/moduleTemplates";
import { productsActions } from "entities/products";

import { searchByValue, sortByOrder } from "utils/array";

import { useProducts } from "features/associatedProducts/model/hooks/useProducts";
import { AssociatedProductsModal } from "features/associatedProducts";
import { getProduct } from "entities/products/model/selectors";
import { useAppSelector } from "redux/store/hooks/hooks";
import { useModule } from "features/modules/model/hooks/useModule";

interface ThirdPartyProps {
  dataType?: string;
  listProduct: boolean;
  product?: ProductResponse;
}

export const ThirdPartyWidgets = ({
  dataType,
  listProduct,
  product,
}: ThirdPartyProps) => {
  const { viewProducts }: any = useContext(AssociatedProductsContext);
  const [openCreateRequest, setOpenCreateRequest] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openListProductModal, setOpenListProductModal] = useState(false);
  const [columns, setColumns] = useState<Column[]>(initialColumns);

  const [includedThirdParties, setIncludedThirdParties] = useState<
    ModuleTemplate[]
  >([]);
  const [currentIncluded, setCurrentIncluded] = useState<ModuleTemplate[]>([]);
  const [currentAddon, setCurrentAddon] = useState<ModuleTemplate[]>([]);
  const [addOnThirdParties, setAddOnThirdParties] = useState<ModuleTemplate[]>(
    []
  );
  const [categoryId, setCategoryId] = useState("");
  const [enrichId, setEnrichId] = useState("");
  const [order, setOrder] = useState<OrderTypes | null>(null);
  const [sortType, setSortType] = useState("");
  const isConfig = location.pathname.includes("create_new_product");
  const currentProduct = useAppSelector(getProduct);

  const moduleTemplates = useSelector(
    (state: RootState) => state.moduleTemplates.items
  );
  const productModules = useSelector(
    (state: RootState) => state.products.modules
  );

  const [searchQuery] = useDebounce(searchValue, 2000);
  const dispatch = useDispatch();
  const { activateModuleTemplate, deactivateModuleTemplate } = useProducts();
  const { moduleOrderQueue } = useModule();

  const handleSearch = (searchQuery: string) => {
    if (searchQuery) {
      const searchOutcome = searchByKey("name");
      const { searchResult, addOnSearchResult } = searchOutcome;
      if (searchResult) {
        setIncludedThirdParties([...searchResult]);
      }
      if (addOnSearchResult) {
        setAddOnThirdParties([...addOnSearchResult]);
      }
      if (!searchResult.length && !addOnSearchResult.length) {
        searchProvider();
      }
    }
  };

  const searchProvider = () => {
    const searchOutcome = searchByKey("provider");
    const { searchResult, addOnSearchResult } = searchOutcome;
    if (searchResult) {
      setIncludedThirdParties([...searchResult]);
    }
    if (addOnSearchResult) {
      setAddOnThirdParties([...addOnSearchResult]);
    }
  };

  const searchByKey = (key: string) => {
    const searchResult = searchByValue(currentIncluded, key, searchQuery);
    const addOnSearchResult = searchByValue(currentAddon, key, searchQuery);
    return { searchResult, addOnSearchResult };
  };

  useEffect(() => {
    if (product) getProductModules();
    if (!moduleTemplates.length) {
      dispatch({ type: moduleTemplatesActions.GET_MODULE_TEMPLATES });
    }
  }, []);

  useEffect(() => {
    if (searchQuery) {
      handleSearch(searchQuery);
    } else {
      if (currentIncluded.length) setIncludedThirdParties([...currentIncluded]);
      if (currentAddon.length) setAddOnThirdParties([...currentAddon]);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (currentProduct?.name) {
      processThirdParties(currentProduct);
    }
  }, [currentProduct]);

  useEffect(() => {
    if (moduleTemplates.length) {
      if (!product && !isConfig) {
        const includedData = getThirdParties([...moduleTemplates]).filter(
          (item: ModuleTemplate) => item.isIncluded === true
        );
        const addOnData = getThirdParties([...moduleTemplates]).filter(
          (item: ModuleTemplate) => item.isIncluded === false
        );
        setCurrentIncluded([...includedData]);
        setIncludedThirdParties([...includedData]);
        setCurrentAddon([...addOnData]);
        setAddOnThirdParties([...addOnData]);
      } else if (product) {
        processThirdParties(product);
      }
    }
  }, [moduleTemplates]);

  const getProductModules = () => {
    dispatch({
      type: productsActions.GET_PRODUCT_MODULES,
      data: {
        productId: product?.id,
        version: product?.version,
      },
    });
  };

  const getThirdParties = (data: ModuleTemplate[]) => {
    return data.filter(
      (item: ModuleTemplate) =>
        item.moduleConfiguration.moduleType === "Integration"
    );
  };

  const processThirdParties = (productToProcess: ProductResponse) => {
    const categoryThirdParties = moduleTemplates.filter(
      (item: ModuleTemplate) =>
        item.categoryId === productToProcess.categoryId &&
        item.moduleConfiguration.moduleType === "Integration"
    );
    const included = productModules.map(
      (item: Module) => item.moduleTemplateId
    );
    const productThirdParties = categoryThirdParties.filter(
      (item: ModuleTemplate) => included.includes(item.id)
    );
    const addOnThirdParties = categoryThirdParties.filter(
      (item: ModuleTemplate) => !included.includes(item.id)
    );
    setIncludedThirdParties([...productThirdParties]);
    setCurrentIncluded([...productThirdParties]);
    setAddOnThirdParties([...addOnThirdParties]);
    setCurrentAddon([...addOnThirdParties]);
  };

  useEffect(() => {
    if (!listProduct) {
      const filteredColumns = [...columns].filter(
        (col: Column) => col.id !== "products"
      );
      setColumns(filteredColumns);
    }
  }, [listProduct]);

  useEffect(() => {
    if (order) {
      const filters = {
        name: order.label,
        sortKey: order.type,
      };
      if (sortType === "addOn") {
        const sorted = sortByOrder([...addOnThirdParties], filters);
        if (sorted.length > 0) setAddOnThirdParties(sorted);
      }
      if (sortType === "included") {
        const sorted = sortByOrder([...includedThirdParties], filters);
        if (sorted.length > 0) setIncludedThirdParties(sorted);
      }
    }
  }, [order, sortType]);

  const hanldeEdit = (record: ModuleTemplate, editName?: string) => {
    if (!product && listProduct) setOpenListProductModal(!openListProductModal);
    setCategoryId(record.categoryId);
    setEnrichId(record.id);
    if (editName === "Deactivate") handleDeactivate(record);
    else handleActivate(record);
  };

  const handleActivate = (record: ModuleTemplate) => {
    if (product) {
      activateModuleTemplate(
        product.version,
        record.id,
        product.id,
        moduleOrderQueue + 1
      );
      const newAddOnThirdParties = [...currentAddon].filter(
        (item: ModuleTemplate) => item.id !== record.id
      );
      const newIncludes = [...currentIncluded, record];
      setCurrentAddon(newAddOnThirdParties);
      setAddOnThirdParties(newAddOnThirdParties);
      setCurrentIncluded(newIncludes);
      setIncludedThirdParties(newIncludes);
    }
  };

  const handleDeactivate = (record: ModuleTemplate) => {
    if (product) {
      deactivateModuleTemplate(product.version, record.id, product.id);
      const newIncludedThirdParties = [...currentIncluded].filter(
        (item: ModuleTemplate) => item.id !== record.id
      );
      const newAddOns = [...currentAddon, record];
      setIncludedThirdParties(newIncludedThirdParties);
      setCurrentIncluded(newIncludedThirdParties);
      setCurrentAddon(newAddOns);
      setAddOnThirdParties(newAddOns);
    }
  };

  const handleViewAll = (record: ModuleTemplate) => {
    viewProducts(record.id);
  };

  const sortData = (order: OrderTypes, type: string) => {
    setOrder(order);
    setSortType(type);
  };

  return (
    <TablePaper>
      <TableControls
        activeTab={"Third Party"}
        handleOpen={() => setOpenCreateRequest(!openCreateRequest)}
        searchValue={searchValue}
        handleSearch={(e: any) => setSearchValue(e.target.value)}
      />
      <StyledTypography>CONFIGURED</StyledTypography>
      <DataEnrichTable
        rows={includedThirdParties}
        columns={columns}
        rowHasChip={true}
        chipIndex={0}
        chipName={listProduct ? "Add to product" : "Deactivate"}
        heightAuto={true}
        onEdit={hanldeEdit}
        onView={handleViewAll}
        hideCheckbox={true}
        setSortOrder={(order: OrderTypes) => sortData(order, "included")}
      />
      <StyledTypography>ADD-ON</StyledTypography>
      <DataEnrichTable
        rows={addOnThirdParties}
        columns={columns}
        rowHasChip={true}
        chipIndex={0}
        chipName={listProduct ? "Add to product" : "Activate"}
        onEdit={hanldeEdit}
        onView={handleViewAll}
        hideCheckbox={true}
        heightAuto={true}
        setSortOrder={(order: OrderTypes) => sortData(order, "addOn")}
      />
      <CreateRequestModal
        open={openCreateRequest}
        handleClose={() => setOpenCreateRequest(!openCreateRequest)}
        requestCategory={dataType}
      />
      <AssociatedProductsModal
        open={openListProductModal}
        handleClose={() => setOpenListProductModal(!openListProductModal)}
        categoryId={categoryId}
        enrichId={enrichId}
        type={"thirdParty"}
      />
    </TablePaper>
  );
};
