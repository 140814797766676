import styled from "styled-components";

export const StyledControlHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const TableControlsItemsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
