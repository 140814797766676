import styled from "styled-components";
import { Button } from "@mui/material";

export const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 560px;
  background: #ffffff;
  box-shadow: 10px 10px 40px rgba(31, 60, 100, 0.16),
    0px 4px 20px rgba(0, 0, 0, 0.16);
`;
export const Header = styled.div`
  padding: 20px 32px;
  border-bottom: 1px solid #dadae3;
  display: flex;
  justify-content: space-between;
`;
export const Footer = styled.div`
  padding: 20px 32px 24px;
  border-top: 1px solid #dadae3;
`;
export const Title = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #010101;
  margin: 0;
`;
export const Content = styled.div`
  padding: 32px;
`;
export const StyledButton = styled(Button)`
  &.MuiButton-root {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 2px;
    border-radius: 100px;
    padding: 16px 38px;
    text-transform: none;
  }
  &.MuiButton-outlined {
    border: 1px solid #9696a9;
    color: var(--secondary);
  }
  &.MuiButton-contained {
    background: var(--secondary);
    color: #ffffff;
  }
`;
export const ScrollWrapper = styled.div`
  overflow: auto;
  max-height: calc(100vh - 338px);
`;
