import styled from "styled-components";
import { DialogContent, Typography } from "@mui/material";

export const Form = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 40px 51px;
    border-bottom: none;
  }
`;
export const StyledTypography = styled(Typography)<{ danger?: boolean }>`
  font-size: 0.75rem !important;

  &.warnings {
    color: ${(props) => (props.danger ? "var(--darkred)" : "var(--grey)")};
  }

  &.top {
    margin-top: 0.75rem !important;
  }

  &.text {
    text-transform: none;
    color: #000;
  }
`;
