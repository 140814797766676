import React, { memo, useEffect, useState } from "react";
import { AssociatedProductsModal } from "features/associatedProducts";
import { useDebounce } from "use-debounce";
import TableControls from "shared/ui/TableControls/TableControls";
import { DirectTable } from "./DirectTable";
import { NetworkTable } from "./NetworkTable";
import { useApplicationActions } from "features/applications";
import { KpiPayload } from "shared/api/kpis/types";
import { useKpis } from "features/kpis/model/hooks/useKpis";

interface Props {
  openCreateRequestModal: () => void;
  type?: string;
}

export const DistributionTable = memo(function DistributionTable({
  openCreateRequestModal,
  type,
}: Props) {
  const { setSearchExpressions } = useApplicationActions();
  const [searchValue, setSearchValue] = useState("");
  const [value] = useDebounce(searchValue, 500);

  const { pushKpi } = useKpis();

  useEffect(() => {
    setSearchExpressions(value);
  }, [setSearchExpressions, value]);

  const [openListProductModal, setOpenListProductModal] = useState(false);
  const handleOpenAssociated = () => {
    setOpenListProductModal((prevState) => !prevState);
  };

  const handleKpi = (payload: KpiPayload, callback?: any) => {
    pushKpi(payload, callback);
  };

  return (
    <>
      <TableControls
        activeTab={"Distributions"}
        buttonLabel={"Create Request"}
        handleOpen={openCreateRequestModal}
        searchValue={searchValue}
        handleSearch={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
      />
      <NetworkTable type={type} handleKpi={handleKpi} />
      <DirectTable type={type} handleKpi={handleKpi} />
      <AssociatedProductsModal
        open={openListProductModal}
        handleClose={handleOpenAssociated}
      />
    </>
  );
});
