import { createAction } from "@reduxjs/toolkit";
import {
  WidgetPayload,
  WidgetPayloadWithId,
  WidgetsPayload,
  WidgetsProductUpdatePayload,
} from "shared/api/widgets/types";

export const getWidgetsAction = createAction<WidgetsPayload | undefined>(
  "widgets/getAll"
);
export const updateWidgetProductsAction =
  createAction<WidgetsProductUpdatePayload>("widgets/products/update");

export const updateWidgetForProductsAction =
  createAction<WidgetsProductUpdatePayload>("widgets/update/product");

/**
 * Creates a widget for specific tenant.
 */
export const createWidgetAction = createAction<{
  widgetData: WidgetPayload;
  cb?: () => void;
}>("widgets/create");

/**
 * Get Widget by Id.
 */
export const getWidgetByIdAction = createAction<string>("widgets/getItem");

/**
 * Update Widget by Id.
 */
export const updateWidgetByIdAction = createAction<{
  widgetData: WidgetPayloadWithId;
  cb?: () => void;
}>("widgets/updateItem");

/**
 * Clone Widget
 */
export const cloneWidgetAction = createAction<{
  item: WidgetPayloadWithId;
  cb?: (id: string) => void;
}>("widgets/clone");

/**
 * Delete Widget
 */
export const deleteWidgetAction = createAction<{
  item: Record<string, string>;
  cb?: () => void;
}>("widgets/delete");
export const getApplicationsByIdsAction = createAction<string>(
  "widgets/applications/getByIds"
);

export const getProductsByIdsAction = createAction<string>(
  "widgets/products/getByIds"
);
