import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";

import { IconButton, Grid, CircularProgress } from "@mui/material";
import FormGroup from "components/FormComponents/FormGroup";
import Select from "shared/ui/Select";
import Button from "shared/ui/Button";
import Label from "components/FormComponents/Label";
import {
  StyledTitle,
  Wrapper,
  StyledActions,
  StyledDialog,
  FieldGroup,
} from "./styles";
import {
  WebhookConfiguration,
  EmailConfiguration,
  ScheduleConfiguration,
  ImmediateConfiguration,
} from "./configurations";
import { TriggerSettings } from "./TriggerSettings";

import { useEvents } from "../events";
import { useTemplates } from "../templates";
import { useProducts } from "../products";

import {
  ModalProps,
  Data,
  conditionTypeData,
  triggerTypeData,
  actionTypeData,
  defaultValues,
  FieldsList,
} from "./type";
import { EventTrigger } from "shared/api/events/types";
import { ProductResponse } from "shared/api/products/types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const AddEventModal = ({ event, handleClose, open }: ModalProps) => {
  const [trigger, setTrigger] = useState<EventTrigger | null>(null);

  const {
    createEvent,
    id,
    editEvent,
    getTriggerDefinitions,
    eventTriggers,
    isLoading,
  } = useEvents();
  const { getTemplates, notificationTemplates } = useTemplates();
  const { products, getProducts, loading } = useProducts();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    getTemplates();
    getTriggerDefinitions();
    getProducts({
      limit: 1000,
      offset: 0,
    });
  }, []);

  const getEmailActionConfiguration = (
    data: any,
    action: "stringify" | "parse"
  ) => {
    return (
      data.actionConfiguration.emailActionConfiguration && {
        ...data.actionConfiguration.emailActionConfiguration,
        templateData:
          data.actionConfiguration.emailActionConfiguration.templateData &&
          JSON[action](
            data.actionConfiguration.emailActionConfiguration.templateData
          ),
      }
    );
  };

  const getArraysConfiguration = (data: any, action: "stringify" | "parse") => {
    return (
      data && {
        ...data,
        headers: JSON[action](data.headers),
        params: JSON[action](data.params),
      }
    );
  };

  const generateData = (data: any, action: "stringify" | "parse") => {
    let emailActionConfiguration, webhookActionConfiguration, tokenEndpoint;
    if (data.actionConfiguration.actionType === "Email") {
      emailActionConfiguration = getEmailActionConfiguration(data, action);
    } else if (data.actionConfiguration.actionType === "WebHook") {
      webhookActionConfiguration = getArraysConfiguration(
        data.actionConfiguration.webhookActionConfiguration,
        action
      );
      tokenEndpoint = getArraysConfiguration(
        data.actionConfiguration.webhookActionConfiguration?.tokenEndpoint,
        action
      );
    }
    return {
      ...data,
      actionConfiguration: {
        ...data.actionConfiguration,
        emailActionConfiguration,
        ...(webhookActionConfiguration && {
          webhookActionConfiguration: {
            ...webhookActionConfiguration,
            tokenEndpoint,
          },
        }),
      },
    };
  };

  useEffect(() => {
    if (open) {
      if (event) {
        const formData: any = generateData(event, "stringify");
        reset({ ...formData });
      } else {
        setValue("productId", id);
      }
    } else {
      setTrigger(null);
      reset(defaultValues);
    }
  }, [open, event]);

  const conditionTypeWatcher = watch(FieldsList.CONDITION_TYPE);
  const triggerTypeWatcher = watch(
    FieldsList.TRIGGER_CONFIGURATION_TRIGGER_TYPE
  );
  const actionTypeWatcher = watch(FieldsList.ACTION_TYPE);
  const recipientEmailsWatcher = watch(FieldsList.RECIPIENT_EMAILS);
  const authTypeWatcher = watch(FieldsList.AUTH_TYPE);

  const templateDataWatcher = watch(FieldsList.TEMPLATE_DATA);
  const webhookHeadersWatcher = watch(FieldsList.WEBHOOK_HEADERS);
  const tokenEndpointHeadersWatcher = watch(FieldsList.TOKEN_ENDPOINT_HEADERS);
  const webhookParamsWatcher = watch(FieldsList.WEBHOOK_PARAMS);
  const tokenEndpointParamsWatcher = watch(FieldsList.TOKEN_ENDPOINT_PARAMS);
  const webhookBodyWatcher = watch(FieldsList.WEBHOOK_BODY_TEMPLATE);
  const tokenEndpointBodyWatcher = watch(FieldsList.TOKEN_ENDPOINT_BODY);

  const ActionTypeConfiguration =
    actionTypeWatcher === "Email" ? EmailConfiguration : WebhookConfiguration;

  useEffect(() => {
    if (actionTypeWatcher === "Email") {
      setValue("actionConfiguration.webhookActionConfiguration", null);
      clearErrors(["actionConfiguration.webhookActionConfiguration"]);
    } else if (actionTypeWatcher === "WebHook") {
      setValue("actionConfiguration.emailActionConfiguration", null);
      clearErrors(["actionConfiguration.emailActionConfiguration"]);
    } else {
      setValue("actionConfiguration.emailActionConfiguration", null);
      setValue("actionConfiguration.webhookActionConfiguration", null);
      clearErrors([
        "actionConfiguration.webhookActionConfiguration",
        "actionConfiguration.emailActionConfiguration",
      ]);
    }
  }, [actionTypeWatcher]);

  const isJsonString = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  const handleError = (valid: boolean, field: any) => {
    if (!valid) {
      setError(field, {
        type: "custom",
        message: "Not valid json",
      });
    } else {
      clearErrors([field]);
    }
  };

  const validateWatcher = (watcher: any) => {
    return watcher ? isJsonString(watcher) : true;
  };

  const onSubmit = () => {
    const templateDataValid = validateWatcher(templateDataWatcher);
    const webhookHeadersValid = validateWatcher(webhookHeadersWatcher);
    const webhookParamsValid = validateWatcher(webhookParamsWatcher);
    const webhookBodyValid = validateWatcher(webhookBodyWatcher);
    const tokenEndpointBodyValid = validateWatcher(tokenEndpointBodyWatcher);
    const tokenEndpointHeadersValid = validateWatcher(
      tokenEndpointHeadersWatcher
    );
    const tokenEndpointParamsValid = validateWatcher(
      tokenEndpointParamsWatcher
    );

    if (
      templateDataValid &&
      webhookHeadersValid &&
      tokenEndpointHeadersValid &&
      webhookParamsValid &&
      tokenEndpointParamsValid &&
      webhookBodyValid &&
      tokenEndpointBodyValid
    ) {
      handleSubmit((data: Data) => {
        const formData: any = generateData(data, "parse");
        if (event) {
          editEvent(event.id, formData, callback);
        } else {
          createEvent(formData, callback);
        }
      })();
    } else {
      handleError(templateDataValid, FieldsList.TEMPLATE_DATA);
      handleError(webhookHeadersValid, FieldsList.WEBHOOK_HEADERS);
      handleError(webhookParamsValid, FieldsList.WEBHOOK_PARAMS);
      handleError(tokenEndpointHeadersValid, FieldsList.TOKEN_ENDPOINT_HEADERS);
      handleError(tokenEndpointParamsValid, FieldsList.TOKEN_ENDPOINT_PARAMS);
      handleError(webhookBodyValid, FieldsList.WEBHOOK_BODY_TEMPLATE);
      handleError(tokenEndpointBodyValid, FieldsList.TOKEN_ENDPOINT_BODY);
    }
  };

  const callback = () => {
    handleClose();
  };

  const showTriggerSettings = (value: string) => {
    const result: EventTrigger = eventTriggers.filter(
      (item) => item.eventTriggerName === value
    )[0];
    setTrigger(result);
  };

  const handleSelectProduct = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValue("productName", ev.target.value);
    const selectedProduct: ProductResponse = products.products.filter(
      (item: any) => item.name === ev.target.value
    )[0];
    setValue("productId", selectedProduct.id);
  };

  const renderIconComponent = (props: any, isLoading: boolean) =>
    isLoading ? (
      <CircularProgress {...props} size="1rem" />
    ) : (
      <KeyboardArrowDownIcon {...props} />
    );

  return (
    <StyledDialog onClose={handleClose} open={open}>
      {trigger ? (
        <TriggerSettings
          handleClose={handleClose}
          goBack={() => setTrigger(null)}
          trigger={trigger}
        />
      ) : (
        <>
          <StyledTitle sx={{ m: 0, p: 2 }} sidepadding={"32"}>
            {event?.id ? "Edit event" : "New event"}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 47,
                top: 25,
                color: "var(--black)",
              }}
            >
              <CloseIcon />
            </IconButton>
          </StyledTitle>
          <Wrapper data-testid="create-event-modal">
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="eventTrigger"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <>
                          <Label label="Trigger Name" danger={true} />
                          <FieldGroup>
                            <Select
                              onChange={onChange}
                              options={eventTriggers.map(
                                (item) => item.eventTriggerName
                              )}
                              id={"eventTrigger"}
                              error={!!error}
                              value={value || ""}
                              multiple={false}
                              disabled={isLoading || !!event?.id}
                              IconComponent={(props: any) =>
                                renderIconComponent(props, isLoading)
                              }
                            />
                            {value && (
                              <IconButton
                                aria-label="toggle visibility"
                                edge="end"
                                sx={{
                                  position: "absolute",
                                  top: "6px",
                                  right: "50px",
                                }}
                                onClick={() => showTriggerSettings(value)}
                              >
                                <SettingsIcon sx={{ color: "#0671E0" }} />
                              </IconButton>
                            )}
                          </FieldGroup>
                        </>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="conditionType"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <>
                          <Label label="Condition Type" danger={true} />
                          <Select
                            onChange={onChange}
                            options={conditionTypeData}
                            id={"conditionType"}
                            error={!!error}
                            value={value || ""}
                            multiple={false}
                          />
                        </>
                      );
                    }}
                  />
                </Grid>
                {id || event?.id ? (
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="productId"
                      control={control}
                      render={({ field: { value } }) => {
                        return (
                          <FormGroup
                            id="productId"
                            value={value}
                            label="Product ID"
                            placeholder="Product ID"
                            disabled
                          />
                        );
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="productName"
                      control={control}
                      render={({ field: { value } }) => {
                        return (
                          <>
                            <Label label="Product Name" />
                            <Select
                              onChange={handleSelectProduct}
                              options={products.products.map(
                                (item: any) => item.name
                              )}
                              id={"productName"}
                              value={value || ""}
                              multiple={false}
                              disabled={loading}
                              IconComponent={(props: any) =>
                                renderIconComponent(props, loading)
                              }
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <Controller
                    name="conditionExpression"
                    control={control}
                    rules={{ required: conditionTypeWatcher !== "None" }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <FormGroup
                          id="conditionExpression"
                          value={value}
                          label="Condition Expression"
                          placeholder="Enter Expression"
                          danger={conditionTypeWatcher !== "None"}
                          disabled={conditionTypeWatcher === "None"}
                          onChange={onChange}
                          error={!!error}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="triggerConfiguration.triggerType"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <>
                          <Label label="Trigger Type" danger={true} />
                          <Select
                            onChange={onChange}
                            options={triggerTypeData}
                            id={"triggerType"}
                            error={!!error}
                            value={value}
                            multiple={false}
                            disabled={!!event?.id}
                          />
                        </>
                      );
                    }}
                  />
                </Grid>
              </Grid>
              {triggerTypeWatcher === "Immediate" ? (
                <ImmediateConfiguration control={control} />
              ) : (
                <ScheduleConfiguration control={control} />
              )}
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="actionConfiguration.actionType"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <>
                          <Label label="Action Type" danger={true} />
                          <Select
                            onChange={onChange}
                            options={actionTypeData}
                            id={"triggerType"}
                            error={!!error}
                            value={value || ""}
                            multiple={false}
                            disabled={!!event?.id}
                          />
                        </>
                      );
                    }}
                  />
                </Grid>
              </Grid>
              {actionTypeWatcher ? (
                <ActionTypeConfiguration
                  control={control}
                  notificationTemplates={notificationTemplates}
                  setValue={setValue}
                  recipientEmailsWatcher={recipientEmailsWatcher}
                  authTypeWatcher={authTypeWatcher}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              ) : null}
            </form>
          </Wrapper>
          <StyledActions centered={1}>
            <Button onClick={handleClose} variant="outlined">
              {"Cancel"}
            </Button>
            <Button variant="contained" onClick={onSubmit}>
              {event?.id ? "Update" : "Create"}
            </Button>
          </StyledActions>
        </>
      )}
    </StyledDialog>
  );
};
