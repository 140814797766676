import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import themeSwitcher from "theme-switcher";
import { ToastContainer } from "react-toastify";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import { loginRequest } from "./authConfig";
import { authActions } from "./entities/auth";

import Home from "./pages/Home";
import UserManagement from "./pages/UserManagement";
import ProductsPage from "./pages/ProductsPage";
import ProductOverviewPage from "./pages/ProductOverviewPage";
import Users from "./components/Users";
import Products from "./components/Products";
import CreateProduct from "./pages/CreateProduct";
import RequestSection from "./components/Requests";
import EmbeddedHub from "./pages/EmbeddedHub/EmbeddedHub";
import IntegrationHub from "./pages/IntegrationHub";
import Requests from "./pages/Requests";
import { CarrierHubPage } from "./pages/CarrierHubPage";
import ProgramsPage from "./pages/ProgramsPage";
import Programs from "./pages/Programs";
import DataEnrichment from "./components/DataEnrichment";
import { DistributionWidgets } from "./pages/DistributionWidgets";
import { ThirdPartyWidgets } from "./pages/ThirdPartyWidgets";
import { AssociatedProducts } from "./pages/AssociatedProducts";
import NotificationsPage from "./pages/NotificationsPage";

import "react-toastify/dist/ReactToastify.css";
import { Distribution } from "./pages/Distribution";

import { useAppSelector } from "redux/store/hooks/hooks";
import { getAccessToken } from "entities/auth/model/selectors";
import KpisPage from "pages/KpisPage";
import ProgramDetails from "./pages/ProgramDetails/ProgramDetails";
import EventsAndTriggers from "./pages/EventsAndTriggers";
import { RoutesList } from "./shared/routes";
import WidgetView from "./pages/WidgetView/WidgetView";
import AnalyticsHub from "./pages/AnalyticsHub";
import { getCurrentUserProfile } from "./entities/users";
import { useUsers } from "./features/users/model/hooks/useUsers";

enum Themes {
  LIGHT = "light",
  DARK = "dark",
}

const App = () => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthData = useAppSelector(getAccessToken);
  const userProfile = useAppSelector(getCurrentUserProfile);
  const isAuth = isAuthData && Object.keys(userProfile).length > 0;
  const [theme, setTheme] = useState(Themes.LIGHT);

  useEffect(() => {
    if (isAuthenticated) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          dispatch({ type: authActions.SIGN_IN, data: response });
        })
        .catch((e) => {
          console.log(e);
          handleLogin(instance);
        });
    }
  }, []);

  const { getUserProfile } = useUsers();
  useEffect(() => {
    isAuthData && getUserProfile();
  }, [isAuthData]);

  useEffect(() => {
    if (!isAuthData && inProgress === InteractionStatus.None) logOut(instance);
  }, [isAuthData]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      handleLogin(instance);
    }
  }, [inProgress]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tenant = urlParams.get("tenant");
    const tenantInStorage = localStorage.getItem("tenant-name");
    if (tenant && tenant !== tenantInStorage) {
      localStorage.setItem("tenant-name", tenant);
    }
  }, [location.search]);

  useEffect(() => {
    switcher({
      theme,
    });
  }, [theme]);

  const { switcher } = themeSwitcher({
    themeMap: {
      dark: "/tenants/dark.css",
      light: "/tenants/light.css",
    },
  });

  const handleLogin = (instance: any) => {
    instance.loginRedirect(loginRequest).catch((e: any) => {
      console.error(e);
    });
  };

  const logOut = (instance: any) => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0]?.homeAccountId),
    };
    instance.logoutRedirect(logoutRequest);
  };
  return (
    <div>
      <AuthenticatedTemplate>
        {isAuth && (
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path={RoutesList.HOME} element={<Home />}>
              <Route
                path={RoutesList.CUSTOMER_ENGAGEMENT}
                element={<EmbeddedHub />}
              >
                <Route
                  path="distribution"
                  element={<Distribution listProduct={true} />}
                />
                <Route
                  path="distribution/associated-products"
                  element={<AssociatedProducts />}
                />
                <Route
                  path={RoutesList.WIDGET}
                  element={<DistributionWidgets />}
                />
                <Route
                  path="widget/associated-products"
                  element={<AssociatedProducts />}
                />
                <Route path="widget/:id" element={<AssociatedProducts />} />
              </Route>
              <Route
                path={RoutesList.INTEGRATIONS}
                element={<IntegrationHub />}
              >
                <Route
                  path="third-party"
                  element={
                    <ThirdPartyWidgets
                      listProduct={true}
                      dataType={"Third Party"}
                    />
                  }
                />
                <Route
                  path="data-enrich"
                  element={
                    <DataEnrichment
                      listProduct={true}
                      dataType={"Data Enrichment"}
                    />
                  }
                />
              </Route>

              <Route
                path={RoutesList.EVENTS_AND_TRIGGERS}
                element={<EventsAndTriggers />}
              />
              <Route index element={<CarrierHubPage />} />
              <Route path="/signin-oidc" element={<CarrierHubPage />} />
              <Route path="user-management" element={<UserManagement />}>
                <Route path="users" element={<Users status="Users" />} />
                <Route
                  path={RoutesList.ARCHIVE}
                  element={<Users status="Archive" />}
                />
              </Route>
              <Route path="products" element={<ProductsPage />}>
                <Route path="list" element={<Products status="New" />} />
                <Route
                  path={RoutesList.ARCHIVE}
                  element={<Products status="Archived" />}
                />
              </Route>
              <Route
                path="products/list/:productId"
                element={<ProductOverviewPage />}
              />
              <Route path="programs" element={<ProgramsPage />}>
                <Route path="list" element={<Programs status="Programs" />} />
                <Route
                  path={RoutesList.ARCHIVE}
                  element={<Programs status="Archive" />}
                />
              </Route>
              <Route path="programs/:id" element={<ProgramDetails />} />
              <Route path={RoutesList.REQUESTS} element={<Requests />}>
                <Route
                  path={RoutesList.NEW}
                  element={<RequestSection status="New" />}
                />
                <Route
                  path={RoutesList.COMPLETED}
                  element={<RequestSection status="Completed" />}
                />
                <Route
                  path={RoutesList.ARCHIVE}
                  element={<RequestSection status="Archived" />}
                />
              </Route>
              <Route path={RoutesList.ANALYTICS} element={<AnalyticsHub />} />
              <Route path={RoutesList.KPIS} element={<KpisPage />} />
              <Route
                path={RoutesList.NOTIFICATIONS}
                element={<NotificationsPage />}
              />
            </Route>
            <Route path="/create_new_product/*" element={<CreateProduct />} />
            <Route path={RoutesList.WIDGET_VIEW} element={<WidgetView />} />
          </Routes>
        )}
      </AuthenticatedTemplate>
      <ToastContainer />
      <UnauthenticatedTemplate>
        <></>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default App;
