import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Tabs from "components/Tabs";
import { PageTitle } from "shared/ui/pageTitle";
import { TabNames } from "../types";
import { RoutesList } from "shared/routes";

const tabList = [
  {
    label: TabNames.NEW,
  },
  {
    label: TabNames.COMPLETED,
  },
  {
    label: TabNames.ARCHIVE,
  },
];

const Requests = () => {
  const [activeTab, setActiveTab] = useState(TabNames.NEW);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname == RoutesList.REQUESTS) {
      setActiveTab(TabNames.NEW);
      navigate("new");
    }
  }, [location]);

  return (
    <Box data-testid="requests">
      <PageTitle title={"Requests"} />
      <Tabs
        setActiveTab={setActiveTab}
        tabList={tabList}
        activeTab={activeTab}
        basePath={RoutesList.REQUESTS}
      >
        <Outlet />
      </Tabs>
    </Box>
  );
};

export default Requests;
