import { useAppDispatch, useAppSelector } from "redux/store/hooks/hooks";
import {
  getApplicationsNetworkDataAction,
  getNetworkData,
  getNetworkLoading,
  getNetworkTotal,
  linkNetworkToProductAction,
  unlinkNetworkToProductAction,
} from "entities/applications";
import { useCallback } from "react";
import {
  ApplicationsLinkPayload,
  ApplicationsPayload,
} from "shared/api/applications/types";

const useNetworkItems = () => useAppSelector(getNetworkData);
const useNetworkLoading = () => useAppSelector(getNetworkLoading);
const useNetworkTotal = () => useAppSelector(getNetworkTotal);

export const useNetworkData = () => {
  const data = useNetworkItems();
  const isLoading = useNetworkLoading();
  const total = useNetworkTotal();

  return {
    isLoading,
    data,
    total,
  };
};

export const useNetworkActions = () => {
  const dispatch = useAppDispatch();
  const getNetworkApplications = useCallback((data: ApplicationsPayload) => {
    dispatch(getApplicationsNetworkDataAction(data));
  }, []);

  const linkNetworkToProduct = useCallback(
    (data: ApplicationsLinkPayload) => {
      dispatch(linkNetworkToProductAction(data));
    },
    [dispatch]
  );

  const unlinkNetworkToProduct = useCallback(
    (data: ApplicationsLinkPayload) => {
      dispatch(unlinkNetworkToProductAction(data));
    },
    [dispatch]
  );
  return {
    getNetworkApplications,
    linkNetworkToProduct,
    unlinkNetworkToProduct,
  };
};
