import {
  CreateModulesPayload,
  ModulesResponse,
  RulePayload,
  RuleResponse,
  ModulesOrderPayload,
  ModulesOrderResponse,
} from "./types";
import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";
import { ModuleTemplatesResponse } from "api/types";
import { ModuleAttributesPayload, ModuleBody } from "entities/modules";

export default class ModulesApi {
  static async createModule(
    id: string,
    version: number,
    data: CreateModulesPayload
  ): Promise<AxiosResponse<ModulesResponse>> {
    return IXPortalApiPrivate.post(
      `/api/Products/${id}/versions/${version}/modules`,
      data
    );
  }

  static async setModulesOrder(
    id: string,
    version: number,
    data: ModulesOrderPayload
  ): Promise<AxiosResponse<ModulesOrderResponse>> {
    return IXPortalApiPrivate.post(
      `/api/Products/${id}/versions/${version}/modulequeue`,
      data
    );
  }

  static async editModule(
    id: string,
    version: number,
    moduleId: string,
    payload: ModuleBody
  ): Promise<AxiosResponse<RuleResponse>> {
    return IXPortalApiPrivate.put(
      `/api/Products/${id}/versions/${version}/modules/${moduleId}`,
      payload
    );
  }

  static async deleteModule(
    id: string,
    version: number,
    moduleId: string
  ): Promise<AxiosResponse<RuleResponse>> {
    return IXPortalApiPrivate.delete(
      `/api/Products/${id}/versions/${version}/modules/${moduleId}`
    );
  }

  static async getModules(
    id: string,
    version: number
  ): Promise<AxiosResponse<ModulesResponse>> {
    return IXPortalApiPrivate.get(
      `/api/Products/${id}/versions/${version}/modules`
    );
  }

  static async updateModuleAttributes({
    id,
    version,
    moduleId,
    attributes,
  }: ModuleAttributesPayload): Promise<AxiosResponse<{ id: string }>> {
    return IXPortalApiPrivate.post(
      `/api/Products/${id}/versions/${version}/modules/${moduleId}/attributes`,
      { attributes }
    );
  }
  static async createModuleRule(
    id: string,
    version: number,
    moduleId: string,
    data: RulePayload
  ): Promise<AxiosResponse<RuleResponse>> {
    return IXPortalApiPrivate.post(
      `/api/Products/${id}/versions/${version}/modules/${moduleId}/rules`,
      data
    );
  }

  static async editModuleRule(
    id: string,
    version: number,
    moduleId: string,
    ruleId: string,
    data: RulePayload
  ): Promise<AxiosResponse<RuleResponse>> {
    return IXPortalApiPrivate.put(
      `/api/Products/${id}/versions/${version}/modules/${moduleId}/rules/${ruleId}`,
      data
    );
  }

  static async deleteModuleRule(
    id: string,
    version: number,
    moduleId: string,
    ruleId: string
  ): Promise<AxiosResponse<RuleResponse>> {
    return IXPortalApiPrivate.delete(
      `/api/Products/${id}/versions/${version}/modules/${moduleId}/rules/${ruleId}`
    );
  }

  static async getModulesTemplates(): Promise<
    AxiosResponse<ModuleTemplatesResponse>
  > {
    return IXPortalApiPrivate.get("/api/ModuleTemplates");
  }
}
