import React from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import Button from "shared/ui/Button";

import { BackLink, PaddingDiv, StyledTitle, Row } from "./styles";

interface PageTitleProps {
  title: string;
  backLink: {
    to: string;
    title: string;
  };
  handleClick?: () => void;
}

const PageTitle: React.FC<any> = ({
  title,
  backLink,
  handleClick,
}: PageTitleProps) => {
  return (
    <PaddingDiv>
      {backLink && (
        <BackLink to={backLink.to}>
          <KeyboardArrowLeftIcon /> {backLink.title}
        </BackLink>
      )}
      <Row>
        <StyledTitle variant={"h5"}>{title}</StyledTitle>
        {handleClick && (
          <Button
            onClick={handleClick}
            variant="outlined"
            data-testid="add_to_program"
          >
            Add to Program
          </Button>
        )}
      </Row>
    </PaddingDiv>
  );
};

export default PageTitle;
