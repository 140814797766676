import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: flex;
  align-items: start;
  position: relative;
  margin: 10px 0;
  flex-wrap: wrap;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const ErrorWrapper = styled.div`
  color: var(--error);
  width: 100%;
  margin: 10px 0;
`;

export const SelectWrapper = styled.div`
  flex: 1 80%;
`;
