import React from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import Select from "@mui/material/Select";
import { AttributesPayloadWithRoot } from "entities/products";
import { TreeMenuItem } from "./styles";

export interface Props {
  data: AttributesPayloadWithRoot[];
  value: AttributesPayloadWithRoot[];
  selected: AttributesPayloadWithRoot[];
  error: boolean;
  deleteHandler: (item: AttributesPayloadWithRoot) => void;
  handleChange: (item: AttributesPayloadWithRoot) => void;
}
const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultipleSelectChip = ({
  handleChange,
  data,
  value,
  deleteHandler,
  error,
  selected,
}: Props) => {
  const renderOptionsTreeView: (
    data: AttributesPayloadWithRoot[],
    stepIndex?: number
  ) => JSX.Element[] | null = (
    data: AttributesPayloadWithRoot[],
    stepIndex = 0
  ) => {
    return data.length > 0
      ? data.map((item) => {
          return item.subAttributes.length > 0 ? (
            <div key={item.displayName}>
              <TreeMenuItem
                key={item.displayName}
                value={item.displayName}
                onClick={() => handleChange(item)}
                index={stepIndex}
              >
                <Checkbox
                  checked={
                    !!selected.find(
                      (attr) =>
                        item.name === attr.name &&
                        item.parentConcat === attr.parentConcat
                    )
                  }
                />
                <ListItemText primary={item.displayName} />
              </TreeMenuItem>
              {renderOptionsTreeView(item.subAttributes, stepIndex + 1)}
            </div>
          ) : (
            <TreeMenuItem
              key={item.displayName}
              value={item.displayName}
              onClick={() => handleChange(item)}
              index={stepIndex}
            >
              <Checkbox
                checked={
                  !!selected.find(
                    (attr) =>
                      item.name === attr.name &&
                      item.parentConcat === attr.parentConcat
                  )
                }
              />
              <ListItemText primary={item.displayName} />
            </TreeMenuItem>
          );
        })
      : null;
  };
  return (
    <FormControl sx={{ width: "100%", backgroundColor: "#fff" }}>
      <InputLabel id="demo-multiple-name-label">Attributes</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        value={value}
        multiple
        error={error}
        placeholder={"Choose"}
        input={<OutlinedInput id="select-multiple-chip" label={"Attributes"} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => {
              return (
                <Chip
                  key={value.name + value.parentConcat}
                  onMouseDown={(event: React.MouseEvent) => {
                    event.stopPropagation();
                  }}
                  label={value.parentRoute || value.displayName}
                  onDelete={() => deleteHandler(value)}
                />
              );
            })}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {renderOptionsTreeView(data)}
      </Select>
    </FormControl>
  );
};
