import styled from "styled-components";
import { Button, Link } from "@mui/material";

export const Header = styled.div`
  display: flex;
  padding: 19px 48px;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  align-items: center;
  border-bottom: 1px solid #d3d3de;
  background: #ffffff;
`;
export const ButtonsGroup = styled.div`
  display: flex;
  align-items: baseline;
  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-right: 32px;
    color: #1f3c64;
  }
`;
export const Title = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
  letter-spacing: 0.01em;
  color: #190a3a;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
export const Nav = styled.div<{ step: number }>`
  padding: 14px;
  display: flex;
  position: relative;
  justify-content: center;
  background: #f9f9f9;
  border-bottom: 4px solid #a9a8b2;
  &:before {
    content: "";
    position: absolute;
    width: 240px;
    height: 4px;
    left: 0px;
    bottom: -4px;
    background: #12ef9d;
    transform: matrix(1, 0, 0, -1, 0, 0);
    width: ${(props) => {
      switch (props.step) {
        case 1:
          return "14.28%";
        case 2:
          return "28.57%";
        case 3:
          return "42.85%";
        case 4:
          return "57.14%";
        case 5:
          return "71.42%";
        case 6:
          return "85.71%";
        default:
          return "100%";
      }
    }};
  }
`;
export const StyledButton = styled(Button)<{ isActive?: boolean }>`
  &.MuiButton-root {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 12px 24px;
    border-radius: 100px;

    &.MuiButton-outlined {
      color: var(--secondary);
      border: 2px solid var(--secondary);
      &.Mui-disabled {
        color: #9b9b9b;
        border-color: #9b9b9b;
      }
    }
    &.MuiButton-contained {
      text-transform: none;
      &.Mui-disabled {
        color: #ffffff;
        background: #9b9b9b;
      }
    }
  }
`;
export const StyledLink = styled(Link)<{ isActive?: boolean }>`
  &.MuiLink-root {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-decoration: none;
    &.active {
      font-weight: 600;
      color: var(--secondary);
    }
    &.visited {
      text-decoration: underline;
      cursor: pointer;
      color: #1f3c64;
    }
  }
`;
