import React, { FC } from "react";
import { CardContentWithIconProps } from "shared/config/types/Cards";
import {
  ContentInfoWrapper,
  ContentIntegrationsWrapper,
  Description,
} from "./styles";

export const CardContentWithIcon: FC<CardContentWithIconProps> = ({
  icon,
  description,
}) => {
  return (
    <ContentIntegrationsWrapper background="transparent">
      <ContentInfoWrapper>
        <>
          {icon}
          <Description>{description}</Description>
        </>
      </ContentInfoWrapper>
    </ContentIntegrationsWrapper>
  );
};
