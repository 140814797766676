import React, { FC, ReactNode } from "react";
import { CarrierCard } from "./styles";

interface Props {
  children: ReactNode;
}

export const HubCard: FC<Props> = ({ children }) => (
  <CarrierCard>{children}</CarrierCard>
);
