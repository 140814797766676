import React from "react";
import { Modal } from "shared/ui/Modal";
import Button from "shared/ui/Button";
import { Controller, useForm } from "react-hook-form";
import { InputBlock } from "features/add-rule-form/ui/styles";
import FormGroup from "../../../FormComponents/FormGroup";
import { ModuleBody, Modules } from "entities/modules";

interface Props {
  handleClose: () => void;
  isLoading?: boolean;
  open: boolean;
  createModule: (type: Modules, data: ModuleBody) => void;
}
const defaultValues: ModuleBody = {
  name: "",
  description: "",
};
const AddFulfillment = ({
  open,
  createModule,
  isLoading,
  handleClose,
}: Props) => {
  const { control, handleSubmit } = useForm({
    defaultValues,
  });
  const onSubmit = () => {
    handleSubmit((data: ModuleBody) => {
      createModule(Modules.FULFILLMENT, data);
      handleClose();
    })();
  };
  return (
    <div>
      <Modal
        title="Add Fulfillment Module"
        open={open}
        handleClose={handleClose}
        id="addAttributesModal"
        isLoading={isLoading}
        footer={
          <>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{
                marginRight: "24px",
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={onSubmit}>
              Add Module
            </Button>
          </>
        }
      >
        <form>
          <InputBlock>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FormGroup
                    id="name"
                    value={value}
                    label="Name"
                    placeholder="Name"
                    danger={true}
                    onChange={onChange}
                    error={!!error}
                  />
                );
              }}
            />
          </InputBlock>
          <InputBlock>
            <Controller
              name="description"
              control={control}
              rules={{ required: true }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <FormGroup
                    id="name"
                    value={value}
                    label="Desription"
                    placeholder="Description"
                    danger={true}
                    onChange={onChange}
                    error={!!error}
                  />
                );
              }}
            />
          </InputBlock>
        </form>
      </Modal>
    </div>
  );
};

export default AddFulfillment;
