import { createAction } from "@reduxjs/toolkit";
import { AnalyticsParams } from "../types/types";

export const getWidgetDeploysAction = createAction<{
  params?: AnalyticsParams;
}>("analytics/widgetDeploys");

export const getWidgetDetailsAction = createAction<{
  params?: AnalyticsParams;
}>("analytics/widgetDetails");

export const getWidgetLocationsAction = createAction<{
  params?: AnalyticsParams;
}>("analytics/widgetLocations");
