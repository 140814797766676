import React from "react";
import { Box } from "@mui/material";

export const HighlightedBlock = ({ children }: any) => {
  return (
    <Box
      sx={{
        my: 3,
        p: 3,
        bgcolor: "#F2F2F5",
        border: "1px solid",
        borderColor: "#D3D3DE",
        borderRadius: "8px",
        ".MuiOutlinedInput-root.Mui-disabled": {
          borderColor: "#D3D3DE",
        },
        ".MuiOutlinedInput-root": {
          background: "#fff",
        },
      }}
    >
      {children}
    </Box>
  );
};
