import React from "react";
import { Typography, styled } from "@mui/material";

export const Subtitle = ({ children }: any) => {
  return (
    <Typography
      sx={{
        color: "#383874",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "20px",
        marginBottom: "8px",
        textTransform: "uppercase",
        letterSpacing: "1px",
        textAlign: "left",
        ".thin": {
          fontWeight: "400",
          fontStyle: "italic",
        },
      }}
    >
      {children}
    </Typography>
  );
};

export const Container = styled("div")`
  border-radius: var(--8, 8px) var(--8, 8px) 0px 0px;
  border: 1px solid var(--Neutral-Silver, #dadae3);
  background: var(--Neutral-Ghost, #fafafb);
  padding: 24px 16px var(--32, 32px) 16px;
`;

export const Footer = styled("div")`
  padding: 14px 16px;
  border-radius: 0px 0px var(--8, 8px) var(--8, 8px);
  border-right: 1px solid var(--Neutral-Silver, #dadae3);
  border-bottom: 1px solid var(--Neutral-Silver, #dadae3);
  border-left: 1px solid var(--Neutral-Silver, #dadae3);
  background: var(--Common-colors-Neutral-White-BG, #fff);
`;
