import React, { useEffect, useMemo, useState } from "react";
import { OutcomesTableWrapper } from "./styles";
import { RulesCard } from "widgets/RulesCard";
import {
  useModule,
  useTemplates,
} from "features/modules/model/hooks/useModule";
import { SkeletonLoader } from "shared/ui/Skeleton";
import { ModuleTypes } from "entities/modules";
import { ResultCard } from "widgets/Outcomes";
import { useAppSelector } from "redux/store/hooks/hooks";

import { useNavigate } from "react-router-dom";
import AccordionSection from "shared/ui/AccordionSection";
import { Box } from "@mui/material";
import { getProduct } from "entities/products/model/selectors";
import { useProducts } from "features/associatedProducts/model/hooks/useProducts";
import { Module } from "shared/api/modules/types";
import { List } from "components/CreateNewProduct/Steps/OutcomesAndServices/styles";
import { FulfillmentCard } from "widgets/Outcomes/ui/FulfillmentCard";

interface OutcomeConfigurationProps {
  editable: boolean;
}

interface ModuleList {
  name: string;
  id: string;
  position: number;
  moduleType: ModuleTypes;
}

const OutcomeConfiguration = ({ editable }: OutcomeConfigurationProps) => {
  const product = useAppSelector(getProduct);
  const { updateProductVersion } = useProducts();
  const navigate = useNavigate();
  const [list, setList] = useState<ModuleList[]>([]);

  const handleEdit = () => {
    if (product.state === "Published") {
      updateProductVersion(product);
    }
    navigate(`/create_new_product/${product.id}?step=ServicesAndRules`);
  };
  const {
    createModules,
    isLoading: isLoadingModules,
    modules,
    getModules,
    modulesDataObject,
  } = useModule();

  const { getTemplates, isLoading: isTemplatesLoading } = useTemplates();

  useEffect(() => {
    getModules();
    getTemplates();
  }, []);

  useEffect(() => {
    const result: ModuleList[] = [];
    if (!modulesDataObject) return;

    Object.keys(modulesDataObject).forEach((value) => {
      const item = modulesDataObject[value];
      if (item) {
        result.push(moduleListPayload(value, item));
      }
    });
    if (result.length) {
      const sorted = sort(result);
      setList(sorted);
    }
  }, [modulesDataObject]);

  const moduleListPayload = (key: string, item: Module) => {
    return {
      name: key,
      id: item.id,
      position: item.moduleOrderQueue,
      moduleType: item.moduleType,
    };
  };

  const sort = (items: ModuleList[]) => {
    return items.sort((a: ModuleList, b: ModuleList) =>
      a.position > b.position ? 1 : -1
    );
  };

  const isLoading: boolean = useMemo(() => {
    return isLoadingModules || isTemplatesLoading;
  }, [isLoadingModules, isTemplatesLoading]);

  useEffect(() => {
    if (modules.length === 0 && !isLoadingModules) {
      createModules();
    }
  }, [modules, isLoadingModules]);

  return (
    <AccordionSection
      title="Outcome Configuration"
      editable={editable}
      handleEdit={handleEdit}
    >
      <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
        {modules.length > 0 && (
          <OutcomesTableWrapper>
            <div>
              {isLoading && <SkeletonLoader />}
              {!isLoading && modules.length <= 0 && "No Data"}
              {!isLoading && (
                <>
                  {list.map((item: ModuleList) => (
                    <List key={item.id}>
                      {item.moduleType !== ModuleTypes.RESULT &&
                        item.moduleType !== ModuleTypes.FULFILLMENT &&
                        modulesDataObject?.[item.name] && (
                          <RulesCard
                            sortable={false}
                            rules={modulesDataObject[item.name].rules}
                            heading={modulesDataObject[item.name].name}
                            subHeading={
                              modulesDataObject[item.name].description
                            }
                            key={modulesDataObject[item.name].id}
                            moduleId={modulesDataObject[item.name].id}
                            editable={false}
                            name={item.name}
                            moduleType={item.moduleType}
                          />
                        )}
                      {item.moduleType === ModuleTypes.FULFILLMENT &&
                        modulesDataObject?.[
                          ModuleTypes.FULFILLMENT.toLowerCase() + "." + item.id
                        ] && (
                          <FulfillmentCard
                            sortable={false}
                            onEdit={handleEdit}
                            name={item.name}
                            editable={false}
                            responseAttributes={
                              modulesDataObject[
                                ModuleTypes.FULFILLMENT.toLowerCase() +
                                  "." +
                                  item.id
                              ].responseAttributes
                            }
                            moduleId={
                              modulesDataObject[
                                ModuleTypes.FULFILLMENT.toLowerCase() +
                                  "." +
                                  item.id
                              ].id
                            }
                            heading={
                              modulesDataObject[
                                ModuleTypes.FULFILLMENT.toLowerCase() +
                                  "." +
                                  item.id
                              ].name
                            }
                            subHeading={
                              modulesDataObject[
                                ModuleTypes.FULFILLMENT.toLowerCase() +
                                  "." +
                                  item.id
                              ].description
                            }
                          />
                        )}
                      {item.moduleType === ModuleTypes.RESULT &&
                        modulesDataObject?.[
                          ModuleTypes.RESULT.toLowerCase() + "." + item.id
                        ] && (
                          <ResultCard
                            sortable={false}
                            rules={
                              modulesDataObject?.[
                                ModuleTypes.RESULT.toLowerCase() + "." + item.id
                              ].rules
                            }
                            heading={
                              modulesDataObject?.[
                                ModuleTypes.RESULT.toLowerCase() + "." + item.id
                              ].name
                            }
                            subHeading={
                              modulesDataObject?.[
                                ModuleTypes.RESULT.toLowerCase() + "." + item.id
                              ].description
                            }
                            key={
                              modulesDataObject?.[
                                ModuleTypes.RESULT.toLowerCase() + "." + item.id
                              ].id
                            }
                            moduleId={
                              modulesDataObject?.[
                                ModuleTypes.RESULT.toLowerCase() + "." + item.id
                              ].id
                            }
                            editable={false}
                            responseAttributes={
                              modulesDataObject?.[
                                ModuleTypes.RESULT.toLowerCase() + "." + item.id
                              ].responseAttributes
                            }
                          />
                        )}
                    </List>
                  ))}
                </>
              )}
            </div>
          </OutcomesTableWrapper>
        )}
      </Box>
    </AccordionSection>
  );
};

export default OutcomeConfiguration;
