import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import AccordionSection from "shared/ui/AccordionSection";
import { DistributionWidgetTable } from "./DistributionWidgetTable";

import { useAppSelector } from "redux/store/hooks/hooks";

import { getProduct } from "entities/products/model/selectors";
import { useProducts } from "features/associatedProducts/model/hooks/useProducts";

interface CustomerEngagementProps {
  editable?: boolean;
}

const CustomerEngagement = ({ editable }: CustomerEngagementProps) => {
  const product = useAppSelector(getProduct);
  const navigate = useNavigate();
  const { updateProductVersion } = useProducts();

  const handleEdit = () => {
    if (product.state === "Published") {
      updateProductVersion(product);
    }
    navigate(`/create_new_product/${product.id}?step=Engagement`);
  };

  return (
    <AccordionSection
      title="Embedded Hub"
      editable={editable}
      handleEdit={handleEdit}
    >
      <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
        <DistributionWidgetTable />
      </Box>
    </AccordionSection>
  );
};

export default CustomerEngagement;
