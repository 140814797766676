import React from "react";
import { StyledTableHead } from "./styles";
import { TableCell, TableRow } from "@mui/material";

import OutlinedCheckbox from "../../FormComponents/OutlinedCheckbox";

import { EnhancedTableProps } from "../types";
import { FilterTableModal } from "features/filter-table-modal";

const TableHeader = (props: EnhancedTableProps) => {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    filterParams,
    setFilterParams,
    columns,
    hideCheckbox,
    setSortOrder,
  } = props;

  return (
    <StyledTableHead data-testid="tableHeader">
      <TableRow>
        {!hideCheckbox && (
          <TableCell padding="checkbox">
            <OutlinedCheckbox
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : "left"}
            sx={{ minWidth: headCell.minWidth, width: headCell.minWidth }}
          >
            {headCell.filtering ? (
              <FilterTableModal
                headCell={headCell}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                setSortOrder={setSortOrder}
              />
            ) : (
              <span>{headCell.label}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};

export default TableHeader;
