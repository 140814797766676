import { TemplatesDefinitionsResponse, TemplatesResponse } from "./types";
import { IXPortalApiPrivate } from "api/IXPortalApi";
import { AxiosResponse } from "axios";

export default class TemplatesApi {
  static async getTemplatesDefinitions(): Promise<
    AxiosResponse<TemplatesDefinitionsResponse>
  > {
    return IXPortalApiPrivate.get("/api/Templates/definitions");
  }
  static async getTemplates(): Promise<AxiosResponse<TemplatesResponse>> {
    return IXPortalApiPrivate.get("/api/Templates");
  }
}
