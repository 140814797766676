import React from "react";
import { OutlinedInput, ListItemText } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  StyledCheckbox,
  StyledMenuItem,
  StyledSelect,
  StyledPlaceholder,
  StyledHelperText,
} from "./styles";

interface SelectsProps {
  options: string[];
  id: string;
  value: string | string[];
  onChange: (e: any) => void;
  multiple?: boolean;
  error?: boolean;
  errorPlaceholder?: string;
  disabled?: boolean;
  sx?: any;
  placeholder?: string;
  IconComponent?: any;
}
const CustomizedSelects = ({
  options,
  id,
  onChange,
  value,
  multiple,
  error,
  errorPlaceholder,
  disabled,
  placeholder,
  IconComponent,
  ...props
}: SelectsProps) => {
  const renderIconComponent = (props: any) => (
    <KeyboardArrowDownIcon {...props} />
  );

  return (
    <>
      <StyledSelect
        multiple={multiple}
        fullWidth
        id={id}
        value={value}
        disabled={disabled}
        onChange={onChange}
        input={<OutlinedInput placeholder="Please select" />}
        renderValue={(selected: any) => {
          if (errorPlaceholder && error) {
            return <StyledPlaceholder>{errorPlaceholder}</StyledPlaceholder>;
          }
          if (placeholder && !selected) {
            return placeholder;
          }
          return Array.isArray(selected) ? selected.join(", ") : selected;
        }}
        displayEmpty={true}
        error={error}
        IconComponent={IconComponent || renderIconComponent}
        {...props}
      >
        {!multiple && (
          <StyledMenuItem value="">
            <ListItemText primary={"Please choose an option"} />
          </StyledMenuItem>
        )}
        {options.map((option) => (
          <StyledMenuItem
            key={option}
            value={option}
            data-testid={`select_${option}Option`}
          >
            <StyledCheckbox
              checked={
                !!(value && value instanceof Array
                  ? value.indexOf(option) > -1
                  : value === option)
              }
            />
            <ListItemText primary={option} />
          </StyledMenuItem>
        ))}
      </StyledSelect>
      {error && <StyledHelperText>This field is required.</StyledHelperText>}
    </>
  );
};

export default CustomizedSelects;
