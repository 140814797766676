import styled from "styled-components";

export const TabButton = styled.button<{
  isActive: boolean;
  disabled: boolean;
}>`
  padding: 16px 32px;
  display: flex;
  height: 100%;
  align-items: center;
  background: ${(props) => (props.isActive ? "var(--white)" : "transparent")};
  color: ${(props) => (props.isActive ? "var(--secondary)" : "var(--primary)")};
  border: none;
  cursor: pointer;
  border-top-right-radius: 24px;
  transition: 0.2s ease;
  letter-spacing: 2px;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const TabsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabButtonWrap = styled.div`
  background: var(--darksilver);
  display: flex;
  align-items: center;
  height: 48px;
  border-top-right-radius: 24px;
  border-top-left-radius: 10px;
  overflow: auto;
`;

export const TabPanelWrap = styled.div`
  min-height: calc(100vh - 425px);
  background: var(--white);
  border-radius: 0px 20px 20px 20px;
  .MuiPaper-root {
    box-shadow: none;
    border-radius: 0px 20px 20px 20px;
  }
`;
