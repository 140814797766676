import React from "react";

import { Box } from "@mui/material";

import AccordionSection from "shared/ui/AccordionSection";
import { RequestsTable } from "widgets/RequestsTable";

import { useAppSelector } from "redux/store/hooks/hooks";
import { getNewRequestList } from "entities/requests";
interface RequestsProps {
  editable?: boolean;
}

const Requests = ({ editable }: RequestsProps) => {
  const newRequests = useAppSelector(getNewRequestList);

  return (
    <AccordionSection
      title="Requests"
      editable={editable}
      error={!!newRequests.length}
    >
      <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
        <RequestsTable />
      </Box>
    </AccordionSection>
  );
};

export default Requests;
