import { styled, Typography } from "@mui/material";

export const Percentages = styled(Typography)`
  position: absolute;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #383874;
  font-size: 60px;
  font-weight: 600;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  span {
    font-size: 25px;
    transform: translateY(15%);
    position: absolute;
  }
`;
