import {
  Event,
  EventTrigger,
  TriggerConfiguration,
} from "shared/api/events/types";

export interface ModalProps {
  event?: Event;
  open: boolean;
  edit?: boolean;
  handleClose: () => void;
}
export interface TriggerSettingsProps {
  handleClose: () => void;
  goBack: () => void;
  trigger: EventTrigger;
}

export interface Data {
  eventTrigger: string;
  conditionType: string;
  conditionExpression: string;
  productId: string;
  actionConfiguration: any;
  triggerConfiguration: TriggerConfiguration;
  productName?: string;
}

export const conditionTypeData = ["None", "ValidationRule"];

export const triggerTypeData = ["Schedule", "Immediate"];

export const actionTypeData = ["Email", "WebHook"];

export const defaultValues: Data = {
  eventTrigger: "",
  conditionType: "",
  conditionExpression: "",
  productId: "",
  actionConfiguration: {
    actionType: "",
    emailActionConfiguration: {
      senderEmail: "",
    },
  },
  triggerConfiguration: {
    triggerType: "Immediate",
    immediateConfiguration: {
      delay: undefined,
      repetition: undefined,
    },
    scheduledConfiguration: {
      triggerTime: null,
    },
  },
};

export enum FieldsList {
  CONDITION_TYPE = "conditionType",
  TRIGGER_CONFIGURATION_TRIGGER_TYPE = "triggerConfiguration.triggerType",
  TOKEN_ENDPOINT_BODY = "actionConfiguration.webhookActionConfiguration.tokenEndpoint.body",
  WEBHOOK_BODY_TEMPLATE = "actionConfiguration.webhookActionConfiguration.bodyTemplate",
  TOKEN_ENDPOINT_PARAMS = "actionConfiguration.webhookActionConfiguration.tokenEndpoint.params",
  WEBHOOK_PARAMS = "actionConfiguration.webhookActionConfiguration.params",
  TOKEN_ENDPOINT_HEADERS = "actionConfiguration.webhookActionConfiguration.tokenEndpoint.headers",
  WEBHOOK_HEADERS = "actionConfiguration.webhookActionConfiguration.headers",
  TEMPLATE_DATA = "actionConfiguration.emailActionConfiguration.templateData",
  AUTH_TYPE = "actionConfiguration.webhookActionConfiguration.authType",
  RECIPIENT_EMAILS = "actionConfiguration.emailActionConfiguration.recipientEmails",
  ACTION_TYPE = "actionConfiguration.actionType",
}
