import { useDispatch } from "react-redux";
import { useCallback } from "react";
import {
  uploadFileAction,
  uploadFilesAction,
  getFileAction,
  getFilesAction,
} from "entities/files";
import { FileLinks } from "shared/api/files/types";

export const useFiles = () => {
  const dispatch = useDispatch();

  const uploadFile = useCallback(
    (id: string, type: string, file: File) => {
      dispatch(
        uploadFileAction({
          ReferenceId: id,
          ReferenceType: type,
          file,
        })
      );
    },
    [dispatch]
  );

  const uploadFiles = useCallback(
    (id: string, type: string, files: File[]) => {
      dispatch(
        uploadFilesAction({
          ReferenceId: id,
          ReferenceType: type,
          files,
        })
      );
    },
    [dispatch]
  );

  const retrieveFile = useCallback(
    (id: string, callback: (data: any) => void) => {
      dispatch(getFileAction({ id, callback }));
    },
    [dispatch]
  );

  const getFiles = useCallback(
    (params: any, callback?: (data: FileLinks) => void) => {
      dispatch(getFilesAction({ FileIds: params.FileIds, callback }));
    },
    [dispatch]
  );

  return { uploadFile, uploadFiles, retrieveFile, getFiles };
};
