import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";

// types
import { initialColumns } from "./types";
import { PaginationParams } from "api/types";
import { ProgramResponse } from "shared/api/programs/types";
import { OrderTypes } from "shared/config/types/Sorting";
import { RootState } from "redux/store";

import TablePaper from "shared/ui/TablePaper";
import TableControls from "components/TableControls";
import FirstimeBox from "components/FirstimeBox";
import CreateProgram from "components/Modals/CreateProgram";
import ProgramsTable from "components/Table";
import RestoreModal from "components/Modals/RestoreModal";

import { ReactComponent as Configurator } from "assets/icons/program-configurator.svg";

// actions
import { modalsActions } from "entities/modals";
import { programActions, programsActions } from "entities/programs";

const initialPagingData = {
  totalCount: 0,
  page: 1,
  limit: 10,
  offset: 0,
};

interface ProgramsProps {
  status: string;
}

const Programs: React.FC<any> = ({ status }: ProgramsProps) => {
  const totalCount = useSelector(
    (state: RootState) => state.programs.programs.totalCount
  );
  const programs = useSelector(
    (state: RootState) => state.programs.programs.items
  );

  const [pagingParams, setPagingParams] =
    useState<PaginationParams>(initialPagingData);
  const [searchValue, setSearchValue] = useState("");
  const [openCreateProgramModal, setOpenCreateProgramModal] = useState(false);
  const [order, setOrder] = useState<OrderTypes | null>(null);
  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const [programToRestore, setProgramToRestore] = useState<ProgramResponse>();
  const [edit, setEdit] = useState(false);

  const dispatch = useDispatch();

  const [searchQuery] = useDebounce(searchValue, 1000);

  useEffect(() => {
    dispatch(programActions.loadProgram({}));
  }, []);

  useEffect(() => {
    setPagingParams(initialPagingData);
    handleSearch(searchQuery);
  }, [searchQuery, order]);

  useEffect(() => {
    getData();
  }, [status, pagingParams]);

  useEffect(() => {
    if (!openCreateProgramModal) setEdit(false);
  }, [openCreateProgramModal]);

  const getData = () => {
    const pagingData = pagingParams;
    dispatch({
      type: programsActions.GET_PROGRAMS,
      data: {
        params: {
          ShowArchived: status === "Archive",
          limit: pagingData.limit,
          offset: pagingData.offset,
        },
      },
    });
  };

  const handleSearch = (searchQuery: string) => {
    const params: any = {
      ShowArchived: status === "Archive",
      limit: pagingParams.limit,
      offset: pagingParams.offset,
      SearchExpression: searchQuery,
    };
    if (order) {
      params["SortingPreferences.Kind"] = order.type;
      params["SortingPreferences.SortingProperty"] = "Name";
    }
    dispatch({
      type: programsActions.GET_PROGRAMS,
      data: { params },
    });
  };

  const handleDelete = (el: ProgramResponse) => {
    dispatch({
      type: modalsActions.OPEN_MODAL,
      data: { delete: { type: "program", isModalOpen: true, data: el } },
    });
  };
  const handleEdit = (el: ProgramResponse) => {
    dispatch({
      type: programsActions.GET_PROGRAM,
      data: el,
    });
    setEdit(true);
    setOpenCreateProgramModal(true);
  };
  const onRestore = (el: ProgramResponse) => {
    setProgramToRestore(el);
    setOpenRestoreModal(true);
  };

  const handleClone = (el: ProgramResponse) => {
    dispatch({
      type: programsActions.CLONE_PROGRAM,
      data: {
        id: el.id,
      },
    });
    setEdit(true);
    setOpenCreateProgramModal(true);
  };

  const setSortOrder = (order: OrderTypes) => {
    setOrder(order);
  };
  const navigateUrl = (id: string) => {
    return `/programs/${id}`;
  };

  const restoreProgram = () => {
    if (programToRestore) {
      dispatch({
        type: programsActions.UNDELETE_PROGRAM,
        data: programToRestore,
      });
    }
    setOpenRestoreModal(!openRestoreModal);
  };
  const handleCloseModal = () => {
    setOpenCreateProgramModal(false);
    dispatch(programActions.loadProgram({}));
    dispatch(programActions.loadProducts({ products: [] }));
  };

  return (
    <TablePaper>
      <TableControls
        activeTab={status}
        handleOpen={() => setOpenCreateProgramModal(!openCreateProgramModal)}
        searchValue={searchValue}
        handleSearch={(e: any) => setSearchValue(e.target.value)}
        hideCreate={totalCount === 0}
      />
      {totalCount > 0 ? (
        <ProgramsTable
          rows={programs}
          columns={initialColumns}
          activeTab={status}
          pagingParams={pagingParams}
          totalCount={totalCount}
          setPagingParams={setPagingParams}
          onDelete={handleDelete}
          onView={handleEdit}
          setSortOrder={setSortOrder}
          onRestore={onRestore}
          onClone={handleClone}
          navigateUrl={navigateUrl}
        />
      ) : (
        <FirstimeBox
          title="Program"
          handleCreate={() =>
            setOpenCreateProgramModal(!openCreateProgramModal)
          }
          icon={<Configurator />}
        />
      )}
      <RestoreModal
        onClose={() => setOpenRestoreModal(false)}
        open={openRestoreModal}
        restoreType={"Program"}
        handleSubmit={restoreProgram}
      />
      {openCreateProgramModal && (
        <CreateProgram
          open={openCreateProgramModal}
          handleClose={handleCloseModal}
          edit={edit}
        />
      )}
    </TablePaper>
  );
};

export default Programs;
