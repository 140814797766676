import React, { memo, useEffect, useMemo, useState } from "react";
import { Table, TableBody, TableCell, TableContainer } from "@mui/material";

import { IXTableRow, TableHeader } from "shared/ui/Table";
import { initialColumns } from "./types";
import { useGetWidgets, useWidgets } from "features/widgets";
import { format } from "date-fns";
import { DateFormat } from "shared/config/enums/DateFormat";
import TablePlaceholder from "shared/ui/TablePlaceholder";
import SortingDefault, {
  SortRenderProps,
} from "shared/ui/Table/SortingDefault/SortingDefault";
import { OrderTypes } from "shared/config/types/Sorting";
import { SORT } from "shared/config/enums/Sorting";
import { TableSkeleton } from "shared/ui/TableSkeleton";
import { DistributionsList } from "entities/applications";
import { useAppSelector } from "redux/store/hooks/hooks";
import { getProduct } from "entities/products";
import { ActionButton, ActionButtonsWrapper } from "shared/ui/ActionButton";
import { WidgetConfig } from "entities/widgets/ui/widget-config/WidgetConfigPopover";
import { WidgetPreview } from "entities/widgets";
import { ActionButtonTypes } from "shared/ui/ActionButton/types";

interface Props {
  type?: string;
}

export const DistributionWidgetTable = memo(function DistributionWidgetTable({
  type,
}: Props) {
  const { isLoading, data } = useWidgets();
  const { getWidgets } = useGetWidgets();

  const { id: productIdEdit } = useAppSelector(getProduct);

  const [order, setOrder] = useState<OrderTypes>({ type: SORT.ASC, label: "" });
  const [activeWidgets, setActiveWidgets] = useState<any[]>([]);

  useEffect(() => {
    getWidgets({
      ...(order.label && { sortPreference: order.label }),
      ...(order.label && { sortKind: order.type }),
    });
  }, [getWidgets, order]);

  useEffect(() => {
    const widgets: Array<any> = [];
    data.map((item: any) => {
      if (item.products.length) {
        item.products.map((product: any) => {
          if (product.productId === productIdEdit) {
            widgets.push(item);
          }
        });
      }
    });
    setActiveWidgets(widgets);
  }, [data]);

  const initialColumnsData = useMemo(() => {
    return type === "edit"
      ? initialColumns.filter((item) => item.id !== "AssociatedProducts")
      : initialColumns;
  }, [initialColumns]);

  return (
    <TableContainer
      sx={{
        height: `calc(100vh - ${"660px"})`,
        position: "relative",
      }}
      data-testid="tableContainer"
    >
      <Table stickyHeader>
        <TableHeader
          hideCheckbox={true}
          columns={initialColumnsData}
          rowCount={data.length}
        >
          {(props: SortRenderProps) => (
            <SortingDefault
              setOrder={(data: OrderTypes) => setOrder(data)}
              order={order}
              {...props}
            />
          )}
        </TableHeader>
        <TableBody data-testid="tableBody">
          {isLoading && (
            <TableSkeleton lines={3} columns={initialColumnsData} />
          )}
          {!isLoading && data.length === 0 && (
            <TablePlaceholder colSpan={initialColumnsData.length} />
          )}
          {!isLoading &&
            activeWidgets.length > 0 &&
            activeWidgets.map((row) => {
              return (
                <IXTableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  data-testid={`tableRow_${row.id}`}
                >
                  <TableCell sx={{ fontWeight: 700 }}>{row.name}</TableCell>
                  <TableCell>
                    <DistributionsList
                      items={row.applications.map((item: any) => item.name)}
                    />
                  </TableCell>
                  <TableCell>{row.widgetType}</TableCell>
                  <TableCell>
                    {format(new Date(row.createdDateUtc), DateFormat.DEFAULT)}
                  </TableCell>
                  <TableCell>
                    {format(new Date(row.updatedDateUtc), DateFormat.DEFAULT)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <ActionButtonsWrapper>
                      <WidgetConfig
                        widgetId={row.id}
                        productId={productIdEdit}
                        name={row.name}
                        distributions={row.applications}
                      >
                        <ActionButton
                          title={ActionButtonTypes.SETTINGS}
                          type={ActionButtonTypes.SETTINGS}
                        />
                      </WidgetConfig>
                      <WidgetPreview
                        popoverTitle="Widget Preview"
                        widgetId={row.id}
                      >
                        <ActionButton
                          title={ActionButtonTypes.VIEW}
                          type={ActionButtonTypes.VIEW}
                        />
                      </WidgetPreview>
                    </ActionButtonsWrapper>
                  </TableCell>
                </IXTableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
});
