import React from "react";
import { Controller } from "react-hook-form";
import Label from "components/FormComponents/Label";
import { Grid } from "@mui/material";

import {
  ErrorText,
  StyledOutlinedInput,
  HighlightedBlock,
  Subtitle,
} from "features/events/ui";

export const ImmediateConfiguration = ({ control }: any) => {
  return (
    <HighlightedBlock>
      <Subtitle>IMMEDIATE CONFIGURATION</Subtitle>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Controller
            name="triggerConfiguration.immediateConfiguration.delay"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^\d*$/i,
                message: "* Invalid Delay format",
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <>
                  <Label label={"Delay"} danger={true} />
                  <StyledOutlinedInput
                    placeholder="Enter delay in seconds"
                    value={value}
                    onChange={onChange}
                    error={error}
                    title="The delay after which action will be executed"
                    id="delay"
                  />
                  {error && <ErrorText error={error} />}
                </>
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="triggerConfiguration.immediateConfiguration.repetition"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^\d*$/i,
                message: "* Invalid Delay format",
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <>
                  <Label label={"Repetition"} danger={true} />
                  <StyledOutlinedInput
                    placeholder="Enter Repetition"
                    value={value}
                    onChange={onChange}
                    error={error}
                    id="repetition"
                    title="Number how many times the event should repeat. Only when the condition is passed."
                  />
                  {error && <ErrorText error={error} />}
                </>
              );
            }}
          />
        </Grid>
      </Grid>
    </HighlightedBlock>
  );
};
