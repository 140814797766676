import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Tabs from "components/Tabs";
import BulkActions from "components/BulkActions";
import { PageTitle } from "shared/ui/pageTitle";
import { TabNames } from "../types";

const tabList = [
  {
    label: TabNames.USERS,
  },
  {
    label: TabNames.ARCHIVE,
  },
];

const UserManagement = () => {
  const [activeTab, setActiveTab] = useState(TabNames.USERS);
  const { key, pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (key === "default") navigate("users");
  }, []);

  useEffect(() => {
    if (pathname === "/user-management/users") {
      if (activeTab === TabNames.ARCHIVE) setActiveTab(TabNames.USERS);
    }
  }, [pathname]);

  return (
    <Box data-testid="userManagment">
      <PageTitle title={"User Managements"} />
      <Tabs
        setActiveTab={setActiveTab}
        basePath={"/user-management"}
        tabList={tabList}
        activeTab={activeTab}
      >
        <Outlet />
      </Tabs>
      <BulkActions activeTab={activeTab} />
    </Box>
  );
};

export default UserManagement;
