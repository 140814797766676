import { useAppDispatch, useAppSelector } from "redux/store/hooks/hooks";
import { useCallback } from "react";
import {
  cloneWidgetAction,
  createWidgetAction,
  deleteWidgetAction,
  getApplicationsByIdsAction,
  getProductsByIdsAction,
  getRowLoader,
  getWidgetByIdAction,
  getWidgetProducts,
  getWidgetsAction,
  getWidgetsActionLoader,
  getWidgetsApplications,
  getWidgetsByIdLoading,
  getWidgetsConfigApps,
  getWidgetsConfigData,
  getWidgetsConfigID,
  getWidgetsData,
  getWidgetsLoading,
  getWidgetsNeedToRefresh,
  getWidgetsTotal,
  updateWidgetByIdAction,
  updateWidgetForProductsAction,
  updateWidgetProductsAction,
  widgetsActions,
} from "entities/widgets";
import {
  Application,
  WidgetPayload,
  WidgetPayloadWithId,
  WidgetsPayload,
  WidgetsProductUpdatePayload,
} from "shared/api/widgets/types";
import { actions } from "entities/applications";

const useWidgetsData = () => useAppSelector(getWidgetsData);
const useGetRowLoader = () => useAppSelector(getRowLoader);
const useWidgetsTotal = () => useAppSelector(getWidgetsTotal);
const useWidgetsLoading = () => useAppSelector(getWidgetsLoading);
const useWidgetsByIdLoading = () => useAppSelector(getWidgetsByIdLoading);
const useWidgetsNeedToRefresh = () => useAppSelector(getWidgetsNeedToRefresh);
const useWidgetsConfigData = () => useAppSelector(getWidgetsConfigData);
const useWidgetsConfigID = () => useAppSelector(getWidgetsConfigID);
const useGetWidgetProducts = () => useAppSelector(getWidgetProducts);
const useGetWidgetApplications = () => useAppSelector(getWidgetsApplications);

const useWidgetsActionLoader = () => useAppSelector(getWidgetsActionLoader);
const useGetWidgetsConfigApps = () => useAppSelector(getWidgetsConfigApps);

export const useWidgets = () => {
  const data = useWidgetsData();
  const isLoading = useWidgetsLoading();
  const total = useWidgetsTotal();

  return { total, isLoading, data };
};

export const useGetWidgets = () => {
  const dispatch = useAppDispatch();
  const needToRefresh = useWidgetsNeedToRefresh();

  const getWidgets = useCallback(
    (data?: WidgetsPayload) => {
      dispatch(getWidgetsAction(data));
    },
    [dispatch]
  );

  return {
    getWidgets,
    needToRefresh,
  };
};

export const useGetWidgetsActions = () => {
  const dispatch = useAppDispatch();

  const updateWidgetProducts = useCallback(
    (data: WidgetsProductUpdatePayload) => {
      dispatch(updateWidgetProductsAction(data));
    },
    [dispatch]
  );

  const updateWidgetForProducts = useCallback(
    (data: WidgetsProductUpdatePayload) => {
      dispatch(updateWidgetForProductsAction(data));
    },
    [dispatch]
  );
  const setLinkedArrayDataMap = useCallback(
    (data: string[]) => {
      dispatch(actions.setLinkedArrayDataMap(data));
    },
    [dispatch]
  );

  return {
    updateWidgetProducts,
    setLinkedArrayDataMap,
    updateWidgetForProducts,
  };
};

export const useWidgetConfig = () => {
  const dispatch = useAppDispatch();
  const isWidgetLoading = useWidgetsByIdLoading();
  const data = useWidgetsConfigData();
  const widgetsConfigID = useWidgetsConfigID();
  const widgetsConfigApps = useGetWidgetsConfigApps();
  const isActionLoading = useWidgetsActionLoader();
  const productItemsById = useGetWidgetProducts();
  const applications = useGetWidgetApplications();
  const isLoading = isWidgetLoading;

  const createWidget = useCallback(
    (data: WidgetPayload, cb?: () => void) => {
      dispatch(createWidgetAction({ widgetData: data, cb }));
    },
    [dispatch]
  );

  const getWidgetApplications = useCallback(() => {
    if (!data || data.applications.length <= 0) return;
    const params = new URLSearchParams();
    data.applications.forEach((app) =>
      params.append("ApplicationsIds", app.applicationId)
    );
    dispatch(getApplicationsByIdsAction(params.toString()));
  }, [dispatch, data]);

  const getWidgetApplicationsByWidgetApps = useCallback(
    (applications: Application[]) => {
      if (applications.length <= 0) return;
      const params = new URLSearchParams();
      applications.forEach((app) =>
        params.append("ApplicationsIds", app.applicationId)
      );
      dispatch(getApplicationsByIdsAction(params.toString()));
    },
    [dispatch, data]
  );

  const getWidgetProducts = useCallback(() => {
    if (!data || data.products.length <= 0) return;
    const params = new URLSearchParams();
    data.products.forEach((product) =>
      params.append("ProductIds", product.productId)
    );
    dispatch(getProductsByIdsAction(params.toString()));
  }, [dispatch, data]);

  const clearWidget = useCallback(
    (cb?: () => void) => {
      dispatch(widgetsActions.clearWidgetItem());
      cb?.();
    },
    [dispatch]
  );
  const clearWidgetItemId = useCallback(() => {
    dispatch(widgetsActions.clearWidgetItemId());
  }, [dispatch]);

  const updateWidget = useCallback(
    (data: WidgetPayloadWithId, cb?: () => void) => {
      dispatch(updateWidgetByIdAction({ widgetData: data, cb }));
    },
    [dispatch]
  );

  const setNeedToRefresh = useCallback(
    (data: boolean) => {
      dispatch(widgetsActions.setNeedToRefreshWidgets(data));
    },
    [dispatch]
  );
  const getWidgetById = useCallback(
    (id: string) => {
      dispatch(getWidgetByIdAction(id));
    },
    [dispatch]
  );
  return {
    createWidget,
    setNeedToRefresh,
    getWidgetById,
    isLoading,
    data,
    clearWidget,
    updateWidget,
    isActionLoading,
    clearWidgetItemId,
    widgetsConfigID,
    productItemsById,
    applications,
    getWidgetApplications,
    widgetsConfigApps,
    getWidgetProducts,
    getWidgetApplicationsByWidgetApps,
  };
};

export const useWidgetsActions = () => {
  const dispatch = useAppDispatch();
  const isLoadingActionID = useGetRowLoader();

  const cloneWidget = useCallback(
    (item: WidgetPayloadWithId, cb?: (id: string) => void) => {
      dispatch(cloneWidgetAction({ item, cb }));
    },
    [dispatch]
  );

  const deleteWidget = useCallback(
    (item: Record<string, string>, cb?: () => void) => {
      dispatch(deleteWidgetAction({ item, cb }));
    },
    [dispatch]
  );

  return {
    cloneWidget,
    isLoadingActionID,
    deleteWidget,
  };
};
