import React, { FC } from "react";
import { TableCell } from "@mui/material";
import NoDataIconSrc from "assets/icons/no-data.svg";
import { NoDataWrapperRelative, StyledTableRow } from "./styles";

interface Props {
  message?: string;
  colSpan?: number;
  heightAuto?: boolean;
}

const TablePlaceholder: FC<Props> = ({
  message = "Nothing to show.",
  colSpan,
  heightAuto,
}) => {
  return (
    <StyledTableRow selected={false} tabIndex={-1}>
      <TableCell
        sx={{
          border: "none",
          height: heightAuto ? "auto" : "calc(100vh - 585px)",
        }}
        colSpan={colSpan}
      >
        <NoDataWrapperRelative data-testid="table_nothingToShow">
          <div>
            <img src={NoDataIconSrc} alt="no data" />
            <p>{message}</p>
          </div>
        </NoDataWrapperRelative>
      </TableCell>
    </StyledTableRow>
  );
};

export default TablePlaceholder;
