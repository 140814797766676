import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { StyledDialog, StyledActions, StyledTitle } from "../styles";
import { IconButton } from "@mui/material";
import Button from "shared/ui/Button";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  submitButton: React.ReactNode;
  title: string;
  copying: boolean;
  hideBottom: boolean;
  sidepadding: string;
  closeIconRightSpacing: number;
}

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  sidepadding: string;
  closeIconRightSpacing: number;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, closeIconRightSpacing, ...other } = props;

  return (
    <StyledTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: closeIconRightSpacing,
            top: 25,
            color: "var(--black)",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyledTitle>
  );
};

const RequestModal = ({
  open,
  onClose,
  submitButton,
  title,
  copying,
  children,
  hideBottom,
  sidepadding,
  closeIconRightSpacing,
}: ModalProps) => {
  return (
    <StyledDialog onClose={onClose} open={open}>
      <BootstrapDialogTitle
        id={"request-title"}
        onClose={onClose}
        sidepadding={sidepadding}
        closeIconRightSpacing={closeIconRightSpacing}
      >
        {title}
      </BootstrapDialogTitle>
      {children}
      {!hideBottom && (
        <StyledActions centered={copying ? 0 : 1}>
          {!copying && (
            <Button onClick={onClose} variant="outlined">
              {"Cancel"}
            </Button>
          )}
          {submitButton}
        </StyledActions>
      )}
    </StyledDialog>
  );
};

export default RequestModal;
