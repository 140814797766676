import styled from "styled-components";
import { Accordion, Button } from "@mui/material";

export const ServicesAccordionContainer = styled(Accordion)`
  &.MuiAccordion-root {
    background: #ffffff;
    border: 1px solid #d3d3de;
    border-radius: 6px;
    transition: 0.3s background-color;
    box-shadow: none;
    margin: 0 0 5px 0;
    ::before {
      position: relative;
    }
  }
  &.Mui-expanded {
    background: #f2f2fc;
  }
`;
export const IconItem = styled.div<{ isExpanded: boolean }>`
  background-color: ${(props) =>
    !props.isExpanded ? "rgba(98, 102, 234, 0.16)" : "#FFFFFF"};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  transition: 0.3s background-color;
`;

export const AccordionHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #010101;
`;

export const SubTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #494a63;
`;

export const RuleButton = styled(Button)`
  &.MuiButton-root {
    width: 100%;
    text-align: left;
    align-items: center;
    padding: 15px 20px;
    justify-content: flex-start;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid var(--secondary);
    background-color: #ffffff;
    border-radius: 10px;
    text-transform: none;
  }
`;
