import styled from "styled-components";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)<{ isActive?: boolean }>`
  &.MuiButton-root {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 32px;

    &.MuiButton-outlined {
      color: var(--secondary);
      border: 2px solid var(--secondary);
      &.Mui-disabled {
        color: #9b9b9b;
        border-color: #9b9b9b;
      }
    }
    &.MuiButton-contained {
      margin-left: 24px;
      &.Mui-disabled {
        color: #ffffff;
        background: #9b9b9b;
      }
    }
  }
`;

export const Content = styled.div`
  padding: 58px 64px;
  height: calc(100vh - 150px);
  overflow-y: auto;
  background: #f2f2fc;
  position: relative;
`;

export const Title = styled.h2`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.01em;
  color: #190a3a;
  margin: 0 0 6px;
`;
