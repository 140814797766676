import { createSlice } from "@reduxjs/toolkit";
import { AlertsState } from "entities/alerts/model/types";

const initialState: AlertsState = [];

export const alertsSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert: (state, action) => {
      return [...state, action.payload];
    },
    closeAlert: (state, action) => {
      const existingAlerts = JSON.parse(JSON.stringify(state));
      existingAlerts.shift();
      return [...existingAlerts];
    },
  },
});

export const { showAlert, closeAlert } = alertsSlice.actions;
