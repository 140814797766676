import { createSlice } from "@reduxjs/toolkit";

export type deleteModalsTypes =
  | "user"
  | "users"
  | "group"
  | "groups"
  | "userFromGroup"
  | "usersFromGroup"
  | "product"
  | "request"
  | "program";
export type DeleteModalProps = {
  isModalOpen?: boolean;
  type?: deleteModalsTypes;
  data?: any;
};
export interface ModalsState {
  delete: DeleteModalProps;
}
const initialState: ModalsState = {
  delete: {},
};
export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    closeModal: (state, action) => {
      return initialState;
    },
  },
});

export const { openModal, closeModal } = modalsSlice.actions;
