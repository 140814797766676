import styled from "styled-components";
import { Typography } from "@mui/material";

export const StyledTextarea = styled.textarea<{ error?: boolean }>`
  padding: 14px;
  width: 100%;
  background: ${(props) => (props.error ? "var(--lightred)" : "var(--white)")};
  border: 1px solid var(--primary);
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--headings);
  outline: none;
  max-width: 100%;
  min-width: 100%;
  min-height: 86px;
  &.has-tooltip {
    padding-right: 35px;
  }
  &:focus {
    border: 1px solid var(--secondary);
    box-shadow: 0px 0px 10px rgba(98, 102, 234, 0.5);
  }
  &::placeholder {
    color: var(--grey);
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 0.8rem !important;
  color: var(--error);
  margin-left: 0.875rem !important;
`;
