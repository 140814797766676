import React from "react";
import { Controller } from "react-hook-form";

import { Grid, FormControl, FormControlLabel } from "@mui/material";
import { HighlightedBlock, Subtitle } from "features/events/ui";
import FormGroup from "components/FormComponents/FormGroup";
import Select from "shared/ui/Select";
import Label from "components/FormComponents/Label";
import OutlinedCheckbox from "components/FormComponents/OutlinedCheckbox";
import { JsonConfiguration } from "../JsonConfiguration";
import { Section } from "./styles";

const authTypes: any = {
  AuthKey: "Auth Key",
  TokenAuth: "Token Auth",
};

export const WebhookConfiguration = ({
  control,
  authTypeWatcher,
  setValue,
}: any) => {
  const onAuthTypeChange = (e: any) => {
    const value = e.target.value.replace(/\s/g, "");
    setValue("actionConfiguration.webhookActionConfiguration.authType", value);
  };
  return (
    <HighlightedBlock>
      <Subtitle>WebHook CONFIGURATION</Subtitle>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Controller
            name="actionConfiguration.webhookActionConfiguration.authType"
            control={control}
            render={({ field: { value } }) => {
              return (
                <>
                  <Label label="Auth type" danger={true} />
                  <Select
                    onChange={(e) => onAuthTypeChange(e)}
                    options={["Auth Key", "Token Auth"]}
                    id="authType"
                    value={authTypes[value] || ""}
                  />
                </>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="actionConfiguration.webhookActionConfiguration.url"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormGroup
                  id="url"
                  value={value}
                  label="URL"
                  placeholder="Enter URL"
                  onChange={onChange}
                  error={!!error}
                  danger
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="actionConfiguration.webhookActionConfiguration.headers"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <JsonConfiguration
                  title={"Headers"}
                  value={value}
                  onChange={onChange}
                  error={error}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="actionConfiguration.webhookActionConfiguration.params"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <JsonConfiguration
                  title={"Params"}
                  value={value}
                  onChange={onChange}
                  error={error}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="actionConfiguration.webhookActionConfiguration.bodyTemplate"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <JsonConfiguration
                  title={"Body Template"}
                  value={value}
                  onChange={onChange}
                  body={true}
                  error={error}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="actionConfiguration.webhookActionConfiguration.httpMethod"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <>
                  <Label label="Http method" danger={true} />
                  <Select
                    onChange={onChange}
                    options={["GET", "POST", "PUT", "PATCH", "DELETE"]}
                    id="httpMethod"
                    error={!!error}
                    value={value || ""}
                  />
                </>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="actionConfiguration.webhookActionConfiguration.keepEmptyProperties"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl
                  fullWidth
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginTop: "40px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <OutlinedCheckbox
                        checked={value}
                        name="isRequired"
                        onChange={onChange}
                      />
                    }
                    label="Keep Empty Properties"
                  />
                </FormControl>
              );
            }}
          />
        </Grid>
        {authTypeWatcher === "Token Auth" && (
          <Grid item xs={12} md={12}>
            <Section>
              <Subtitle>Token Auth CONFIGURATION</Subtitle>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="actionConfiguration.webhookActionConfiguration.tokenEndpoint.url"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <FormGroup
                          id="tokenEndpoint_url"
                          value={value}
                          label="URL"
                          placeholder="Enter URL"
                          onChange={onChange}
                          error={!!error}
                          danger
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="actionConfiguration.webhookActionConfiguration.tokenEndpoint.httpMethod"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <>
                          <Label label="Http method" danger={true} />
                          <Select
                            onChange={onChange}
                            options={["GET", "POST", "PUT", "PATCH", "DELETE"]}
                            id="tokenEndpoint_httpMethod"
                            error={!!error}
                            value={value || ""}
                          />
                        </>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="actionConfiguration.webhookActionConfiguration.tokenEndpoint.headers"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <JsonConfiguration
                          title={"Headers"}
                          value={value}
                          onChange={onChange}
                          error={error}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="actionConfiguration.webhookActionConfiguration.tokenEndpoint.params"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <JsonConfiguration
                          title={"Params"}
                          value={value}
                          onChange={onChange}
                          error={error}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="actionConfiguration.webhookActionConfiguration.tokenEndpoint.body"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <JsonConfiguration
                          title={"Body Template"}
                          value={value}
                          onChange={onChange}
                          body={true}
                          error={error}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="actionConfiguration.webhookActionConfiguration.tokenEndpoint.tokenExpirationInSeconds"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^\d*$/i,
                        message: "* Invalid Token Expiration format",
                      },
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <FormGroup
                          id="tokenExpirationInSeconds"
                          value={value}
                          label="Token Expiration In Seconds"
                          placeholder="Enter expiration in seconds"
                          onChange={onChange}
                          error={!!error}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="actionConfiguration.webhookActionConfiguration.tokenEndpoint.tokenResponseFieldName"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <FormGroup
                          id="tokenResponseFieldName"
                          value={value}
                          label="Token Response Field Name"
                          placeholder="Enter name"
                          onChange={onChange}
                          error={!!error}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Section>
          </Grid>
        )}
      </Grid>
    </HighlightedBlock>
  );
};
