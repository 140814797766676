import React, { useState, useEffect, ChangeEvent } from "react";
import { Grid, Box } from "@mui/material";
import { StyledTypography } from "../styles";
import Label from "../../../FormComponents/Label";
import FormGroup from "../../../FormComponents/FormGroup";
import TextArea from "../../../FormComponents/Textarea";
import CustomSelect from "shared/ui/Select";

interface ErrorsProps {
  requestType?: boolean;
  requestTitle?: boolean;
  description?: boolean;
}

interface CreateSectionProps {
  errors: ErrorsProps;
  description: string;
  requestTitle: string;
  requestType: string;
  handleTypeChange: (event: any) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  requestGroup?: string;
}

const CreateSection = ({
  errors,
  requestTitle,
  requestType,
  description,
  handleTypeChange,
  handleChange,
  requestGroup,
}: CreateSectionProps) => {
  const [defaultRequestType, setDefaultRequestType] = useState("");

  useEffect(() => {
    if (requestGroup) {
      switch (requestGroup) {
        case "Third Party":
          setDefaultRequestType("API Integration");
          break;
        case "Data Enrichment":
          setDefaultRequestType("Data Enrichment");
          break;
        default:
          setDefaultRequestType("");
          break;
      }
    }
  }, [requestGroup]);

  return (
    <>
      <Grid item xs={10}>
        <Label label="Request Type" danger />
        <CustomSelect
          value={defaultRequestType || requestType}
          id="requestType"
          options={[
            "Attribute",
            "Distribution",
            "Data Enrichment",
            "API Integration",
            "Outcomes",
            "Widget",
            "Other",
          ]}
          disabled={requestGroup !== "Request"}
          onChange={(event) => handleTypeChange(event.target.value)}
          error={errors?.requestType}
        />
      </Grid>
      <Grid item xs={10}>
        <FormGroup
          value={requestTitle}
          id="requestTitle"
          onChange={handleChange}
          label="Title"
          error={errors?.requestTitle}
          danger
        />
      </Grid>
      {defaultRequestType && (
        <Grid item xs={10}>
          <StyledTypography className="warnings">
            Please provide us with API documentation/ json file
          </StyledTypography>
          <StyledTypography className="warnings">
            Please provide us with Stage site/Production site or any keys
          </StyledTypography>
          <StyledTypography className="warnings">
            Please upload the required documents related to your API
          </StyledTypography>
        </Grid>
      )}
      <Grid item xs={10}>
        <Label label={"Description"} danger />
        <TextArea
          value={description}
          id="description"
          onChange={handleChange}
          placeholder="Enter a Description for your request"
          minRows={4}
          error={errors?.description}
        />
        <Box sx={{ textAlign: "right", marginTop: "6px" }}>
          <StyledTypography className="warnings">
            {description.length}/250 characters
          </StyledTypography>
        </Box>
      </Grid>
    </>
  );
};

export default CreateSection;
