import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  CloseItem,
  Content,
  ImageItem,
  PopoverContent,
  PopoverHeader,
  WidgetPreviewWrapper,
} from "../styles";
import CloseIcon from "@mui/icons-material/Close";
import { useFiles } from "features/files/model/hooks/useFiles";
import { useWidgets } from "features/widgets";
import { FileLinks, FileUrl } from "shared/api/files/types";
import { FileInfo, Widget } from "shared/api/widgets/types";

interface Props {
  children: ReactElement;
  popoverTitle: string;
  widgetId: string;
}
export const WidgetPreview: FC<Props> = ({
  children,
  popoverTitle,
  widgetId,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { getFiles } = useFiles();
  const { data } = useWidgets();
  const [widgetImages, setWidgetImages] = useState<string[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (open) {
      const widget = data.find((item: Widget) => item.id === widgetId);
      if (widget) {
        const ids = widget.files.map((item: FileInfo) => item.id);
        getFiles({ FileIds: ids }, loadPreviews);
      }
    }
  }, [open]);

  const loadPreviews = (data: FileLinks) => {
    const result = data.fileLinks;
    if (result.length) {
      const imagesUris = result.map((item: FileUrl) => {
        return item.fileUri;
      });
      setWidgetImages(imagesUris);
    }
  };

  return (
    <div>
      <Content onClick={handleClick}>{children}</Content>
      <WidgetPreviewWrapper
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverHeader>
          <div>
            {widgetImages.length ? popoverTitle : "No Preview Available"}
          </div>
          <CloseItem>
            <CloseIcon onClick={handleClose} />
          </CloseItem>
        </PopoverHeader>
        {widgetImages.length ? (
          <PopoverContent>
            {widgetImages.map((image) => (
              <ImageItem key={image}>
                <img src={image} alt="widget" />
              </ImageItem>
            ))}
          </PopoverContent>
        ) : (
          <></>
        )}
      </WidgetPreviewWrapper>
    </div>
  );
};
