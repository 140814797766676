import React from "react";
import Modal from "../CenteredModal";
import Button from "shared/ui/Button";
import {
  Container,
  StyledActions,
  Title,
  QuestionMark,
  Circle,
} from "./styles";

interface RestoreModalProps {
  open: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  restoreType: string;
}

const RestoreModal = ({
  open,
  onClose,
  handleSubmit,
  restoreType,
}: RestoreModalProps) => {
  return (
    <Modal id={"deleteModal-archive"} onClose={onClose} open={open}>
      <Container>
        <Circle />
        <QuestionMark />
      </Container>
      <Title>{`Are you sure you want to restore the ${restoreType}?`}</Title>
      <StyledActions>
        <Button variant="outlined" onClick={onClose}>
          No, Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Yes, Restore
        </Button>
      </StyledActions>
    </Modal>
  );
};

export default RestoreModal;
