export enum TabNames {
  USERS = "Users",
  ARCHIVE = "Archive",
  PRODUCTS = "Products",
  OVERVIEW = "Overview",
  VERSIONS = "Versions",
  NEW = "New",
  COMPLETED = "Completed",
  REQUEST = "Requests",
  PROGRAMS = "Programs",
  THIRDPARTY = "Third Party",
  DATAENRICH = "Data Enrichment",
  DISTRIBUTION = "Distribution",
  WIDGET = "Widget",
  NOTIFICATIONS = "Notifications",
  EVENTS_AND_TRIGGERS = "Events & Triggers",
}
