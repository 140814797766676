import { Column } from "shared/config/types/Table";

export const initialColumns: Column[] = [
  {
    id: "Name",
    label: "Widget Name",
    minWidth: 200,
    filtering: true,
  },
  {
    id: "Distribution",
    label: "Distribution",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "widgetType",
    label: "Widget Type",
    minWidth: 200,
    filtering: false,
  },
  {
    id: "CreatedDateUtc",
    label: "Creation",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "UpdatedDateUtc",
    label: "Update",
    minWidth: 150,
    filtering: false,
  },
  {
    id: "actions",
    label: "actions",
    minWidth: 150,
    filtering: false,
  },
];
