import React from "react";
import { ReactSortable } from "react-sortablejs";

import { List, StyledBlockWrapper, Title, BlockContainer } from "./styles";

import { useModule } from "features/modules/model/hooks/useModule";

import { RulesCard } from "widgets/RulesCard";
import { ResultCard } from "widgets/Outcomes";
import { FulfillmentCard } from "widgets/Outcomes/ui/FulfillmentCard";

import { ModuleTypes } from "entities/modules";

const sortableOptions = {
  animation: 150,
  fallbackOnBody: true,
  swapThreshold: 0.65,
  ghostClass: "ghost",
  group: "shared",
  forceFallback: true,
};

const ModulesList: React.FC<any> = ({
  blocks,
  setBlocks,
  handleEdit,
  setDragEnded,
}) => {
  return (
    <ReactSortable
      list={blocks}
      setList={setBlocks}
      {...sortableOptions}
      disabled
    >
      {blocks.map((block: any, blockIndex: number) => (
        <BlockWrapper
          key={block.id}
          block={block}
          blockIndex={[blockIndex]}
          setBlocks={setBlocks}
          handleEdit={handleEdit}
          setDragEnded={setDragEnded}
        />
      ))}
    </ReactSortable>
  );
};
function Container({
  block,
  blockIndex,
  setBlocks,
  handleEdit,
  setDragEnded,
}: any) {
  return (
    <ReactSortable
      key={block.id}
      list={block.children}
      onEnd={() => setDragEnded(true)}
      setList={(currentList) => {
        setBlocks((sourceList: any) => {
          const tempList = [...sourceList];
          const _blockIndex = [...blockIndex];
          const lastIndex = _blockIndex.pop();
          const lastArr = _blockIndex.reduce(
            (arr, i) => arr[i]["children"],
            tempList
          );
          lastArr[lastIndex]["children"] = currentList;
          return tempList;
        });
      }}
      {...sortableOptions}
    >
      {block.children?.map((childBlock: any, index: number) => {
        return (
          <BlockWrapper
            key={childBlock.id}
            block={childBlock}
            blockIndex={[...blockIndex, index]}
            setBlocks={setBlocks}
            handleEdit={handleEdit}
            setDragEnded={setDragEnded}
          />
        );
      })}
    </ReactSortable>
  );
}
function BlockWrapper({
  block,
  blockIndex,
  setBlocks,
  handleEdit,
  setDragEnded,
}: any) {
  const { modulesDataObject } = useModule();
  if (!block) return null;
  if (block.type === "container") {
    return (
      <StyledBlockWrapper className="block">
        <Title>{block.name}</Title>
        <BlockContainer>
          <Container
            block={block}
            setBlocks={setBlocks}
            blockIndex={blockIndex}
            handleEdit={handleEdit}
            setDragEnded={setDragEnded}
          />
        </BlockContainer>
      </StyledBlockWrapper>
    );
  } else {
    return (
      <List key={block.id}>
        {block.moduleType !== ModuleTypes.RESULT &&
          block.moduleType !== ModuleTypes.FULFILLMENT &&
          modulesDataObject?.[block.name] && (
            <RulesCard
              rules={modulesDataObject[block.name].rules}
              heading={modulesDataObject[block.name].name}
              subHeading={modulesDataObject[block.name].description}
              key={modulesDataObject[block.name].id}
              moduleId={modulesDataObject[block.name].id}
              editable={true}
              sortable={true}
              onEdit={handleEdit}
              name={block.name}
              moduleType={block.moduleType}
            />
          )}
        {block.moduleType === ModuleTypes.FULFILLMENT &&
          modulesDataObject?.[
            ModuleTypes.FULFILLMENT.toLowerCase() + "." + block.id
          ] && (
            <FulfillmentCard
              rules={
                modulesDataObject[
                  ModuleTypes.FULFILLMENT.toLowerCase() + "." + block.id
                ].rules
              }
              sortable={true}
              onEdit={handleEdit}
              name={block.name}
              editable={true}
              moduleType={block.moduleType}
              responseAttributes={
                modulesDataObject[
                  ModuleTypes.FULFILLMENT.toLowerCase() + "." + block.id
                ].responseAttributes
              }
              moduleId={
                modulesDataObject[
                  ModuleTypes.FULFILLMENT.toLowerCase() + "." + block.id
                ].id
              }
              heading={
                modulesDataObject[
                  ModuleTypes.FULFILLMENT.toLowerCase() + "." + block.id
                ].name
              }
              subHeading={
                modulesDataObject[
                  ModuleTypes.FULFILLMENT.toLowerCase() + "." + block.id
                ].description
              }
            />
          )}
        {block.moduleType === ModuleTypes.RESULT &&
          modulesDataObject?.[
            ModuleTypes.RESULT.toLowerCase() + "." + block.id
          ] && (
            <ResultCard
              sortable={true}
              rules={
                modulesDataObject?.[
                  ModuleTypes.RESULT.toLowerCase() + "." + block.id
                ].rules
              }
              heading={
                modulesDataObject?.[
                  ModuleTypes.RESULT.toLowerCase() + "." + block.id
                ].name
              }
              subHeading={
                modulesDataObject?.[
                  ModuleTypes.RESULT.toLowerCase() + "." + block.id
                ].description
              }
              key={
                modulesDataObject?.[
                  ModuleTypes.RESULT.toLowerCase() + "." + block.id
                ].id
              }
              moduleId={
                modulesDataObject?.[
                  ModuleTypes.RESULT.toLowerCase() + "." + block.id
                ].id
              }
              editable={true}
              responseAttributes={
                modulesDataObject?.[
                  ModuleTypes.RESULT.toLowerCase() + "." + block.id
                ].responseAttributes
              }
            />
          )}
      </List>
    );
  }
}

export default ModulesList;
