import { createAction } from "@reduxjs/toolkit";
import { EventsPayload } from "../types/types";
import { EventPayload } from "shared/api/events/types";

export const getEventsAction = createAction<EventsPayload>("events/get");

export const createEventAction = createAction<{
  data: EventPayload;
  productId: string;
  callback: any;
}>("event/create");

export const deleteEventAction = createAction<{
  eventId: string;
  productId: string;
  eventTrigger: string;
}>("event/delete");

export const editEventAction = createAction<{
  data: EventPayload;
  eventId: string;
  productId: string;
  callback: any;
}>("event/edit");

export const getTriggerDefinitionsAction = createAction(
  "events/trigger-definitions"
);

export const cloneEventAction = createAction<{
  id: string;
  callback: any;
}>("event/clone");
