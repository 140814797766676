import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GeneralInfoWrapper,
  StyledTitle,
  InfoBlock,
  InfoValues,
  Tag,
  TagsWrap,
  InfoLabel,
  Row,
  Col,
  State,
  ProductHeaderWrap,
  ControlButtonsWrap,
} from "./styles";

import { usePrograms } from "features/programs/model/hooks/usePrograms";
import { IconButton, Divider } from "@mui/material";
import { Delete, Edit, ContentCopy } from "@mui/icons-material";
import CustomSelect from "../FormComponents/Select";
import { getCurrentKpiItems } from "entities/kpis";
import { modalsActions } from "entities/modals";
import { productsActions, getProductVersions } from "entities/products";

import {
  AttributesPayload,
  ProductVersionsResponse,
} from "shared/api/products/types";
import { CategoryData } from "shared/api/productCategories/types";

import { RootState } from "redux/store";
import { useAppSelector } from "redux/store/hooks/hooks";

import { getCategoriesList } from "entities/productCategories/model/selectors";
import { useProducts } from "features/associatedProducts/model/hooks/useProducts";
import { getProgramsItems, programsActions } from "entities/programs";
import { ProgramResponse } from "shared/api/programs/types";
import { KpiItem } from "shared/api/kpis/types";

interface InfoDataProps {
  leftCol: Array<InfoProps>;
  rightCol: Array<InfoProps>;
}
interface InfoProps {
  label: string;
  id: string;
}
interface GeneralInformationProps {
  editable?: boolean;
}

const GeneralInformation = ({ editable = true }: GeneralInformationProps) => {
  const product: any = useSelector(
    (state: RootState) => state.products.product
  );

  const productCategories: CategoryData[] = useAppSelector(getCategoriesList);
  const programs: ProgramResponse[] = useAppSelector(getProgramsItems);
  const programKpis: KpiItem[] = useAppSelector(getCurrentKpiItems);
  const productVersions: ProductVersionsResponse =
    useAppSelector(getProductVersions);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { updateProductVersion, isPublishedLoading } = useProducts();
  const { getProgramKpis, clearKpiItems } = usePrograms();

  useEffect(() => {
    if (product.id) getPrograms();
  }, [product]);

  useEffect(() => {
    if (programs.length) {
      const ids = programs.map((item: ProgramResponse) => item.id);
      getProgramKpis(ids);
    }
    if (!programs.length && programKpis.length) {
      clearKpiItems();
    }
  }, [programs]);

  const getPrograms = () => {
    dispatch({
      type: programsActions.GET_PROGRAMS,
      data: {
        params: {
          ProductId: product.id,
        },
      },
    });
  };

  const infoData: InfoDataProps = {
    leftCol: [
      {
        label: "Program name",
        id: "program",
      },
      {
        label: "Product Category",
        id: "categoryId",
      },
      {
        label: "Product name",
        id: "name",
      },
      {
        label: "product id",
        id: "id",
      },
      {
        label: "product version",
        id: "version",
      },
    ],
    rightCol: editable
      ? [
          {
            label: "status",
            id: "state",
          },
          {
            label: "Tags",
            id: "tags",
          },
        ]
      : [
          {
            label: "Tags",
            id: "tags",
          },
        ],
  };

  const handleDelete = () => {
    dispatch({
      type: modalsActions.OPEN_MODAL,
      data: { delete: { type: "product", isModalOpen: true, data: product } },
    });
  };

  const handleEdit = () => {
    if (product.state === "Published") {
      updateProductVersion(product);
    } else {
      navigate(`/create_new_product/${product.id}`);
    }
  };

  const handleClone = () => {
    dispatch({
      type: productsActions.CLONE_PRODUCT,
      data: product,
    });
  };

  const getCategoryName = (id: string) => {
    const category = productCategories.find(
      (category: CategoryData) => category.id === id
    );
    if (category) return category.categoryName;
    else return "Unknown";
  };

  const getProgramNames = () => {
    if (programs.length) {
      const result = programs
        .map((item: ProgramResponse) => item.name)
        .join(",");
      return result;
    }
  };

  const renderVersion = () => {
    return (
      <CustomSelect
        value={[product?.version]}
        id="tags"
        options={
          productVersions?.versions?.map((item: any) => item.version) || []
        }
        title="Tags"
        disabled={!editable}
        hideCreate={true}
        fullWidth={false}
        onChange={handleChangeVersion}
      />
    );
  };

  const handleChangeVersion = (e: any) => {
    dispatch({
      type: productsActions.GET_PRODUCT,
      data: {
        ...product,
        version: e.target.value,
      },
    });
  };

  const renderInfoBlock = (item: InfoProps, type?: string) => {
    const versionComponent =
      item.id === "version" ? renderVersion() : <span>{product[item.id]}</span>;
    const categoryIdComponent =
      item.id === "categoryId" ? (
        <span>{getCategoryName(product[item.id])}</span>
      ) : (
        versionComponent
      );
    const stateComponent =
      item.id === "state" ? (
        <State className={product[item.id].toLowerCase()}>
          {product[item.id]}
        </State>
      ) : (
        categoryIdComponent
      );
    return (
      <InfoBlock key={item.id} className={type}>
        <InfoLabel>{item.label}:</InfoLabel>
        {product?.[item.id] && (
          <InfoValues>
            {Array.isArray(product[item.id]) ? (
              <TagsWrap>
                {product[item.id].map((val: AttributesPayload) => (
                  <Tag key={val.id}>{val.displayName}</Tag>
                ))}
              </TagsWrap>
            ) : (
              stateComponent
            )}
          </InfoValues>
        )}
        {item.id === "program" && (
          <InfoValues>
            <span>{getProgramNames()}</span>
          </InfoValues>
        )}
      </InfoBlock>
    );
  };

  return (
    <div>
      <ProductHeaderWrap>
        <StyledTitle>General Information</StyledTitle>
        {editable && (
          <ControlButtonsWrap>
            <IconButton onClick={handleClone}>
              <ContentCopy />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton onClick={handleEdit} disabled={isPublishedLoading}>
              <Edit />
            </IconButton>
            <IconButton onClick={handleDelete}>
              <Delete />
            </IconButton>
          </ControlButtonsWrap>
        )}
      </ProductHeaderWrap>
      <GeneralInfoWrapper>
        <Row>
          <Col>
            {infoData.leftCol.map((item: any) => renderInfoBlock(item))}
          </Col>
          <Col>
            {infoData.rightCol.map((item: any) =>
              renderInfoBlock(item, "column")
            )}
          </Col>
        </Row>
      </GeneralInfoWrapper>
    </div>
  );
};

export default GeneralInformation;
