import React from "react";

import { ResponsiveFunnel } from "@nivo/funnel";
import { Grid, CircularProgress, Box } from "@mui/material";
import {
  AnalyticBlock,
  Title,
  Paragraph,
  useAnalytics,
} from "features/analytics";
import { StepDetail } from "shared/api/analytics/types";

interface FunnelWidgetProps {
  ranges?: string;
}

export const FunnelWidget = ({ ranges }: FunnelWidgetProps) => {
  const { stepDetails, loading } = useAnalytics();
  const newData = stepDetails
    .filter((item) => item.name !== "Displayed")
    .map((item: StepDetail) => {
      return {
        id: item.outcomeType,
        value: item.executionCount,
        label: `Users complete ${item.name} of the Widget`,
      };
    });
  const initialValue = 0;
  const total = stepDetails.reduce(
    (accumulator, currentValue) => accumulator + currentValue.executionCount,
    initialValue
  );

  const calculatePercentages = (value: number) => {
    return (value / (total / 100)).toFixed(0);
  };

  const customSeparators = ({ parts }: any) => {
    return (
      <>
        {parts.map((item: any) => (
          <text
            textAnchor="left"
            dominantBaseline="central"
            x={item.x0 / 2 - 30}
            y={item.y}
            fill="#383874"
            fontSize="14px"
            fontWeight="700"
            key={item.x}
          >
            {item.data.id} {calculatePercentages(item.data.value)}%
          </text>
        ))}
        {parts.map((item: any) => (
          <text
            key={item.x}
            textAnchor="left"
            dominantBaseline="central"
            x={item.x1 + 10}
            y={item.y}
            fill="#383874"
            fontSize="12px"
            fontWeight="400"
          >
            {item.data.label}
          </text>
        ))}
      </>
    );
  };

  const getFunnelChart = () => {
    return newData.length ? (
      <ResponsiveFunnel
        data={newData}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        colors={[
          "#010101",
          "#383874",
          "#6266EA",
          "#95A4FC",
          "#C6C7F8",
          "#A1E3CB",
        ]}
        borderWidth={0}
        borderColor={{ from: "color", modifiers: [] }}
        borderOpacity={1}
        labelColor="#ffffff"
        enableBeforeSeparators={true}
        enableAfterSeparators={true}
        beforeSeparatorLength={80}
        afterSeparatorLength={300}
        currentPartSizeExtension={10}
        currentBorderWidth={20}
        motionConfig="wobbly"
        layers={[customSeparators, "parts", "labels", "annotations"]}
        shapeBlending={0.5}
        spacing={2}
      />
    ) : null;
  };

  return (
    <AnalyticBlock>
      <Grid container spacing={3}>
        <Grid item md={8}>
          <Title>Funnel Widget</Title>
          <Paragraph>{ranges}</Paragraph>
        </Grid>
        <Grid item md={4} sx={{ textAlign: "right" }}>
          <Title>{total}</Title>
          <Paragraph>Total widget interactions</Paragraph>
        </Grid>
        <Grid item md={12} sx={{ height: "500px" }}>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress sx={{ color: "#383874" }} />
            </Box>
          ) : (
            getFunnelChart()
          )}
        </Grid>
      </Grid>
    </AnalyticBlock>
  );
};
