import React, { FC } from "react";
import { CardButton } from "shared/config/types/Cards";
import { ButtonsContainer, FooterButton } from "./styles";
import { useNavigate } from "react-router-dom";

interface Props {
  buttons: CardButton[];
}

export const CardFooter: FC<Props> = ({ buttons }) => {
  const navigate = useNavigate();

  const handleClick = (to: string) => () => {
    navigate(to);
  };

  return (
    <ButtonsContainer>
      {buttons.map((item, i) => {
        return (
          <FooterButton
            onClick={handleClick(item.to)}
            key={item.to}
            variant="outlined"
            buttonActive={i === 0}
          >
            {item.text}
          </FooterButton>
        );
      })}
    </ButtonsContainer>
  );
};
