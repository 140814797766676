import { call, put, takeLatest, select } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import {
  createEventAction,
  deleteEventAction,
  editEventAction,
  getEventsAction,
  getTriggerDefinitionsAction,
  cloneEventAction,
} from "../actions";
import EventsApi from "shared/api/events/eventsApi";
import {
  EventsResponse,
  TriggerDefinitionsResponse,
} from "shared/api/events/types";
import { Toaster } from "shared/ui/Toast/Toast";
import { sagaErrorBoundary } from "shared/utils/sagaErrorBoundary";
import { eventsActions } from "../slice/eventsSlice";
import { getProduct } from "entities/products";
import { ProductResponse } from "shared/api/products/types";

export function* getEventsSaga(action: ReturnType<typeof getEventsAction>) {
  try {
    yield put(eventsActions.setEventsLoading(true));
    const response: AxiosResponse<EventsResponse> = yield call(
      EventsApi.getEvents,
      action.payload
    );
    yield put(eventsActions.setEvents(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* createEventSaga(action: ReturnType<typeof createEventAction>) {
  const { data, productId, callback } = action.payload;
  try {
    yield put(eventsActions.setEventActionLoading(true));
    yield call(EventsApi.createEvent, data);
    yield put(eventsActions.setEventActionLoading(false));
    Toaster.infoMessage("Event added successfully");
    yield put(getEventsAction({ ProductId: productId, limit: 100, offset: 0 }));
    callback();
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(eventsActions.setEventActionLoading(false));
  }
}

export function* deleteEventSaga(action: ReturnType<typeof deleteEventAction>) {
  const { eventId, productId, eventTrigger } = action.payload;
  try {
    yield put(eventsActions.setEventActionLoading(true));
    yield call(EventsApi.deleteEvent, eventId);
    yield put(eventsActions.setEventActionLoading(false));
    Toaster.infoMessage(`Event "${eventTrigger}" deleted successfully`);
    yield put(getEventsAction({ ProductId: productId, limit: 100, offset: 0 }));
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(eventsActions.setEventActionLoading(false));
  }
}

export function* editEventSaga(action: ReturnType<typeof editEventAction>) {
  const { eventId, productId, data, callback } = action.payload;
  try {
    yield put(eventsActions.setEventActionLoading(true));
    yield call(EventsApi.editEvent, eventId, data);
    yield put(eventsActions.setEventActionLoading(false));
    Toaster.infoMessage("Event edited successfully");
    yield put(getEventsAction({ ProductId: productId, limit: 100, offset: 0 }));
    callback();
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(eventsActions.setEventActionLoading(false));
  }
}

export function* getTriggerDefinitionsSaga() {
  try {
    const response: AxiosResponse<TriggerDefinitionsResponse> = yield call(
      EventsApi.getTriggerDefinitions
    );
    yield put(eventsActions.setEventTriggers(response.data));
  } catch (e) {
    sagaErrorBoundary(e);
  }
}

export function* cloneEventSaga(action: ReturnType<typeof cloneEventAction>) {
  const { id, callback } = action.payload;
  try {
    const response: AxiosResponse<any> = yield call(EventsApi.cloneEvent, id);
    if (response.data) {
      const eventResponse: AxiosResponse<any> = yield call(
        EventsApi.getEvent,
        response.data.id
      );
      Toaster.infoMessage(`${eventResponse.data.eventTrigger} has been cloned`);
      callback(eventResponse.data);
    }
    const product: ProductResponse = yield select(getProduct);
    yield put(
      getEventsAction({ ProductId: product.id || "", limit: 100, offset: 0 })
    );
  } catch (e) {
    sagaErrorBoundary(e);
    yield put(eventsActions.setEventActionLoading(false));
  }
}

export function* EventsWatcher() {
  yield takeLatest(getEventsAction, getEventsSaga);
  yield takeLatest(createEventAction, createEventSaga);
  yield takeLatest(deleteEventAction, deleteEventSaga);
  yield takeLatest(editEventAction, editEventSaga);
  yield takeLatest(getTriggerDefinitionsAction, getTriggerDefinitionsSaga);
  yield takeLatest(cloneEventAction, cloneEventSaga);
}
