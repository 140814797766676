import React from "react";
import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import { AttributesPayload } from "shared/api/products/types";
import AttributeTableRow from "./AttributeTableRow";
import AttributeTableRowCollapsed from "./AttributeTableRowCollapsed";

interface CollapseProps {
  item: AttributesPayload;
}

const AttributesTable = ({ item }: CollapseProps) => {
  const createRow = (row: AttributesPayload) => {
    return row.subAttributes.length > 0 ? (
      <AttributeTableRowCollapsed item={row} key={row.id} />
    ) : (
      <AttributeTableRow item={row} key={row.id} />
    );
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        boxShadow: "none",
        marginBottom: "7px",
      }}
    >
      <Table
        aria-label="collapsible table"
        sx={{
          borderCollapse: "none",
          borderSpacing: "0px 8px",
          backgroundColor: "var(--ghost)",
        }}
      >
        <TableBody>{createRow(item)}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default AttributesTable;
