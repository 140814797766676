import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse, TableRow, IconButton, Table } from "@mui/material";
import { InfoLabel } from "../styles";
import { AttrName, StyledTableCell } from "./styles";
import { Tag } from "../../ProductGeneralInformation/styles";
import IconWrap from "../IconWrap";
import { ReactComponent as AttributeIcon } from "assets/icons/attribute.svg";
import { AttributesPayload } from "shared/api/products/types";
import AttributeTableRow from "./AttributeTableRow";

interface CollapseProps {
  item: AttributesPayload;
}

const fillmentTypesLabel: { [key: string]: string } = {
  BaseInformation: "Base Information",
  PostfillmentPrefill: "Postfillment Prefill",
  PostfillmentSensitive: "Postfillment Sensitive",
  Hidden: "Hidden",
  AdditionalInfo: "Additional Info",
  SystemContainer: "System Container",
};

const AttributeTableRowCollapsed = ({ item }: CollapseProps) => {
  const [openning, setOpenning] = React.useState(false);

  const createRow = (row: AttributesPayload) => {
    return row.subAttributes.length > 0 ? (
      <AttributeTableRowCollapsed item={row} key={row.id} />
    ) : (
      <AttributeTableRow item={row} key={row.id} />
    );
  };

  return (
    <>
      <TableRow sx={{ backgroundColor: "var(--white)" }}>
        <StyledTableCell sx={{ width: "5%" }}>
          <IconWrap
            color={item.isRequired ? "primary" : "secondary"}
            fillColor={item.isRequired ? "primary" : "secondary"}
            icon={<AttributeIcon />}
          />
        </StyledTableCell>
        <StyledTableCell sx={{ width: "20%" }}>
          <AttrName>
            <strong>{item.displayName}</strong>
          </AttrName>
        </StyledTableCell>
        <StyledTableCell sx={{ width: "10%" }}>
          <InfoLabel>{item.isRequired ? "Mandatory" : "Optional"}</InfoLabel>
        </StyledTableCell>
        <StyledTableCell>
          <InfoLabel>{item.valueType}</InfoLabel>
        </StyledTableCell>
        <StyledTableCell sx={{ width: "25%" }}>
          {item.attributeDescription}
        </StyledTableCell>
        <StyledTableCell sx={{ width: "13%" }} colSpan={5}>
          <Tag>{fillmentTypesLabel[item.fillmentType]}</Tag>
        </StyledTableCell>
        {item.subAttributes.length > 0 && (
          <StyledTableCell className="right" sx={{ width: "5%" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenning(!openning)}
            >
              {openning ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
        )}
      </TableRow>
      <TableRow>
        <StyledTableCell
          colSpan={12}
          sx={{ padding: "0 !important", border: "none !important" }}
        >
          <Collapse in={openning} timeout="auto" unmountOnExit>
            <Table
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0 8px",
              }}
            >
              {item.subAttributes.map((item: AttributesPayload) =>
                createRow(item)
              )}
            </Table>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

export default AttributeTableRowCollapsed;
