import React from "react";
import { Controller } from "react-hook-form";
import { Grid } from "@mui/material";
import FormGroup from "components/FormComponents/FormGroup";

import { HighlightedBlock, Subtitle } from "features/events/ui";

export const ScheduleConfiguration = ({ control }: any) => {
  return (
    <HighlightedBlock>
      <Subtitle>SCHEDULE CONFIGURATION</Subtitle>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Controller
            name="triggerConfiguration.scheduledConfiguration.triggerTime"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormGroup
                  id="triggerTime"
                  value={value}
                  label="Trigger time"
                  placeholder="Enter trigger time"
                  danger={true}
                  onChange={onChange}
                  error={!!error}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </HighlightedBlock>
  );
};
