import React, { useEffect, useMemo, useState } from "react";
import {
  CloseItem,
  PopoverHeader,
  WidgetConfigContent,
  WidgetName,
} from "../styles";
import CloseIcon from "@mui/icons-material/Close";
import Select from "components/FormComponents/Select";
import { ConfigListItem } from "./ConfigListItem";
import { useAppDispatch } from "redux/store/hooks/hooks";
import { actions, getApplicationByIdAction } from "../../../applications";
import { SelectChangeEvent } from "@mui/material/Select";
import { Application } from "shared/api/widgets/types";
import { useWidgetConfigApplications } from "features/applications";
import { Spinner } from "shared/ui/Spinner";
import { compareStringArrays } from "shared/utils/compareStringArrays";

interface Props {
  widgetId: string;
  productId: string;
  name: string;
  distributions: Application[];
  handleClose: () => void;
}

const schemas = [
  "product_schemas:get",
  "application_forms:create",
  "application_forms:update",
  "application_forms:get",
  "system_glossary:get",
  "workflow_result:get",
];

export const WidgetConfigModal = ({
  widgetId,
  productId,
  distributions,
  name,
  handleClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const { loading, applicationItem } = useWidgetConfigApplications(productId);
  const [application, setApplication] = useState<string>("");

  const distributionsObject = useMemo(() => {
    return distributions.reduce<Record<string, string>>((accum, item) => {
      accum[item.applicationId] = item.name;
      return accum;
    }, {});
  }, [distributions]);

  const isIdenticalSchema = useMemo(() => {
    if (!applicationItem) return false;
    if (schemas.length > applicationItem.scopes.length) return false;
    return compareStringArrays(schemas, applicationItem.scopes);
  }, [applicationItem]);

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = Object.entries(distributionsObject).find(
      ([_, value]) => value === event.target.value
    );
    if (newValue) {
      const [name] = newValue;
      setApplication(name);
      dispatch(getApplicationByIdAction(name));
    }
  };
  useEffect(() => {
    return () => {
      dispatch(actions.setApplicationItem(null));
    };
  }, []);

  const isProductLinked = applicationItem?.productIds.includes(productId);
  return (
    <>
      <PopoverHeader>
        Widget Configuration
        <CloseItem>
          <CloseIcon onClick={handleClose} />
        </CloseItem>
      </PopoverHeader>
      <div style={{ overflowY: "auto", padding: "20px" }}>
        <WidgetName>{name}</WidgetName>
        <Select
          options={distributions.map((item) => item.name)}
          id={"distribution_select"}
          title={"Distributions"}
          value={distributionsObject[application] || ""}
          onChange={handleChange}
          hideCreate={true}
          placeholder="Choose distribution"
        />
        <WidgetConfigContent style={{ margin: "10px 0" }}>
          {loading && <Spinner />}
          {!applicationItem && <p>Select Distribution</p>}
          {applicationItem && !isIdenticalSchema && (
            <p>
              This client application is not allowed to use the specified scope.
            </p>
          )}
          {applicationItem && !isProductLinked && (
            <p>You need to activate the distribution for the current product</p>
          )}
          {applicationItem && isProductLinked && isIdenticalSchema && (
            <ConfigListItem
              widgetId={widgetId}
              productId={productId}
              tenantId={applicationItem.clientId}
            />
          )}
        </WidgetConfigContent>
      </div>
    </>
  );
};

export default WidgetConfigModal;
