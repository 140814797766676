import styled from "styled-components";
import { IconButton, Modal, Popover } from "@mui/material";

export const WidgetPreviewWrapper = styled(Popover)`
  .MuiPopover-paper {
    min-width: 300px;
    width: 300px;
    height: 416px;
    min-height: 416px;
  }
`;
export const WidgetPreviewConfigWrapper = styled(Popover)`
  .MuiPopover-paper {
    min-width: 300px;
    max-width: 950px;
    width: 100%;
    height: 416px;
    min-height: 416px;
  }
`;

export const PopoverContent = styled.div`
  padding: 12px 16px;
  height: 360px;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
`;

export const ImageItem = styled.div`
  height: 360px;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    height: 300px;
  }
`;

export const PopoverHeader = styled.div`
  border-bottom: 1px solid #d9d9d9;
  padding: 12px 16px;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const Content = styled.div`
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
`;

export const CloseItem = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;

export const WidgetConfigWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  &:last-child {
    border-bottom: none;
  }
`;

export const WidgetConfigItem = styled.div`
  position: relative;
  width: 100%;
  p {
    margin-bottom: 10px;
  }
`;

export const WidgetName = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const WidgetConfigContent = styled.div`
  margin: 10px 0;
  p {
    margin-bottom: 10px;
  }
`;
export const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    path {
      stroke: var(--grey);
      fill: var(--grey)
    }

    &:hover {
      background-color: rgba(144, 144, 144, 0.18);
    }
`;

export const SideModal = styled(Modal)`
  &.MuiModal-root {
    z-index: 9;
    right: 0;
    top: 0;
    left: inherit;
    width: 560px;
    background: #ffffff;
    box-shadow: 10px 10px 40px rgba(31, 60, 100, 0.16),
      0px 4px 20px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    left: inherit;
    .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0);
    }
  }
`;
