import React from "react";
import { Box } from "@mui/material";
import { PageTitle } from "shared/ui/pageTitle";
import { KpisTable } from "widgets/Kpis/ui/kpisTable";

const Kpis = () => {
  return (
    <Box data-testid="kpis">
      <PageTitle title={"KPI's"} />
      <KpisTable />
    </Box>
  );
};

export default Kpis;
