import React, { memo, useEffect, useState } from "react";
import { format } from "date-fns";

import { Row } from "./styles";
// components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { IXTableRow, TableHeader } from "shared/ui/Table";
import TablePlaceholder from "shared/ui/TablePlaceholder";
import { Error } from "@mui/icons-material";
import SortingDefault, {
  SortRenderProps,
} from "shared/ui/Table/SortingDefault/SortingDefault";
// types
import { RequestSchema } from "shared/api/requests/types";
import { OrderTypes } from "shared/config/types/Sorting";
import { SORT } from "shared/config/enums/Sorting";
import { DateFormat } from "shared/config/enums/DateFormat";

import { initialColumns } from "./types";
import { useRequests } from "features/requests/model/hooks/useRequests";
import { getNewRequestList, filteredCount } from "entities/requests";
import { useAppSelector } from "redux/store/hooks/hooks";
import { sortByOrder } from "utils/array";
import { PaginationParams } from "api/types";

const initialPagingData = {
  showArchived: false,
  limit: 10,
  offset: 0,
  page: 0,
  SearchExpression: "",
  filter: "Statuses=New&Statuses=Pending&Statuses=InProgress",
};

export const RequestsTable = memo(function RequestsTable() {
  const { getRequests } = useRequests();
  const [order, setOrder] = useState<OrderTypes>({ type: SORT.ASC, label: "" });
  const [renderedItems, setRenderedItems] = useState<RequestSchema[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [pagingParams, setPagingParams] =
    useState<PaginationParams>(initialPagingData);

  const newRequests = useAppSelector(getNewRequestList);
  const total = useAppSelector(filteredCount);

  useEffect(() => {
    getRequests(pagingParams);
  }, [pagingParams]);

  useEffect(() => {
    if (order) {
      const filters = {
        name: order.label,
        sortKey: order.type,
      };
      const sorted = sortByOrder([...renderedItems], filters);
      if (sorted.length > 0) setRenderedItems(sorted);
    }
  }, [order]);

  useEffect(() => {
    setRenderedItems([...newRequests]);
  }, [newRequests]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (setPagingParams) {
      setPagingParams({
        ...pagingParams,
        page: newPage,
        offset: rowsPerPage * newPage,
      });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (setPagingParams) {
      setPagingParams({
        ...pagingParams,
        page: 0,
        limit: Number(event.target.value),
        offset: 0,
      });
    }
  };

  return (
    <>
      <TableContainer
        sx={{
          height: `calc(100vh - ${"660px"})`,
          position: "relative",
        }}
        data-testid="tableContainer"
      >
        <Table stickyHeader>
          <TableHeader
            hideCheckbox={true}
            columns={initialColumns}
            numSelected={0}
          >
            {(props: SortRenderProps) => (
              <SortingDefault
                setOrder={(data: OrderTypes) => setOrder(data)}
                order={order}
                {...props}
              />
            )}
          </TableHeader>
          <TableBody data-testid="tableBody">
            {renderedItems.length === 0 ? (
              <TablePlaceholder colSpan={initialColumns.length} />
            ) : (
              renderedItems.map((row) => {
                return (
                  <IXTableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    data-testid={`tableRow_${row.id}`}
                  >
                    <TableCell sx={{ fontWeight: 700 }}>
                      <Row>
                        <Error sx={{ color: "#FB9A1A", margin: "0 10px" }} />
                        {row.requestTypeName}
                      </Row>
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>
                      {format(new Date(row.createdDateUtc), DateFormat.DEFAULT)}
                    </TableCell>
                    <TableCell>
                      {format(new Date(row.updatedDateUtc), DateFormat.DEFAULT)}
                    </TableCell>
                  </IXTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ marginTop: "30px" }}
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        data-testid="table_pagination"
      />
    </>
  );
});
