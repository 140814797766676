import styled from "styled-components";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormHelperText } from "@mui/material";

export const StyledDatePicker = styled(DatePicker)`
  &.Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--darkred);
    }
    .MuiInputBase-root {
      background: var(--lightred);
      color: var(--headings);
    }
  }
  &.Mui-disabled {
    background: var(--lightsilver);
    border-color: var(--lightsilver);
  }
`;
export const StyledHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    color: #d32f2f;
    margin-left: 14px;
  }
`;
