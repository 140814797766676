import { RootState } from "redux/store";
import { createSelector } from "@reduxjs/toolkit";
import { ProgramsResponse } from "shared/api/programs/types";

export const getProgramsState = (state: RootState) => state.programs;

export const getPrograms = createSelector(getProgramsState, (programs) => {
  return programs.programs;
});

export const getProgramsItems = createSelector(
  getPrograms,
  (programs: ProgramsResponse) => {
    return programs.items;
  }
);

export const getProgramsTotal = createSelector(
  getPrograms,
  (programs: ProgramsResponse) => {
    return programs.totalCount;
  }
);

export const getProgram = createSelector(getProgramsState, (programs) => {
  return programs.program;
});
export const getProgramLoading = createSelector(
  getProgramsState,
  (programs) => {
    return programs.isProgramLoading;
  }
);

export const getProducts = createSelector(getProgramsState, (programs) => {
  return programs.products;
});
