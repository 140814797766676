import { AxiosError } from "axios";
import { ErrorFromBackend } from "entities/modules";
import { Toaster } from "../ui/Toast/Toast";
import { useErrorList } from "../lib/hooks/useErrorList";

export const sagaErrorBoundary = (e: unknown) => {
  const error = e as AxiosError<ErrorFromBackend>;
  const errorMessage = error.response?.data?.errors?.Validations;
  if (!errorMessage) {
    Toaster.error(error.message);
  } else {
    Toaster.error(useErrorList(errorMessage));
  }
};
