import styled from "styled-components";
import { Checkbox } from "@mui/material";

export const StyledCheckbox = styled(Checkbox)`
  &.Mui-disabled {
    path {
      fill: var(--grey);
    }
  }
`;
