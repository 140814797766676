import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { pushKpiAction, getKpisAction, getKpiItemAction } from "entities/kpis";
import { KpiPayload } from "shared/api/kpis/types";

export const useKpis = () => {
  const dispatch = useDispatch();

  const pushKpi = useCallback(
    (params: KpiPayload, callback?: (data: any) => void) => {
      dispatch(
        pushKpiAction({
          productId: params.productId,
          entityId: params.entityId,
          kpiType: params.kpiType,
          value: params.value,
          callback,
        })
      );
    },
    [dispatch]
  );

  const getKpiItem = useCallback(
    (params: any) => {
      dispatch(
        getKpiItemAction({
          EntityIds: params.EntityIds,
          KPIType: params.KPIType,
        })
      );
    },
    [dispatch]
  );

  const getKpisByProduct = useCallback(
    (productId: string) => {
      dispatch(getKpisAction({ productId }));
    },
    [dispatch]
  );

  return { pushKpi, getKpisByProduct, getKpiItem };
};
