import React, { memo } from "react";
import { LoaderContainer, StyledSkeleton } from "./styles";

interface Props {
  lineHeight?: string;
  lines?: number;
}
export const SkeletonLoader = memo(function SkeletonLoader({
  lines = 3,
  lineHeight = "70px",
}: Props) {
  return (
    <LoaderContainer>
      {Array.from(Array(lines).keys()).map((item) => (
        <StyledSkeleton animation="wave" lineHeight={lineHeight} key={item} />
      ))}
    </LoaderContainer>
  );
});
