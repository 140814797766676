import React, { useState } from "react";

import { StyledControlHeader } from "./styles";
import { SearchField } from "shared/ui/searchField";
import Button from "shared/ui/Button";
import ProductConfirmation from "../Modals/ProductConfirmation";

import { useAppSelector } from "redux/store/hooks/hooks";
import { getProgram } from "entities/programs";
import { CreateProgramResponse } from "shared/api/programs/types";
import { getDraftItems } from "entities/draftItems/model/selectors";

// types
import { TableControlsProps } from "../Table/types";

const TableControls: React.FC<TableControlsProps> = ({
  activeTab,
  handleOpen,
  searchValue,
  handleSearch,
  hideCreate,
}) => {
  const [open, setOpen] = useState(false);
  const program: CreateProgramResponse = useAppSelector(getProgram);
  const draftItems = useAppSelector(getDraftItems);

  const createTabName = () => {
    switch (activeTab) {
      case "Widget":
        return "Request Widget";
      case "Products":
        return "Create New Product";
      case "Requests":
      case "Distribution":
      case "Third Party":
      case "Data Enrichment":
        return "Create Request";
      case "Users":
        return "Add New User";
      case "Programs":
        return "Create New Program";
      default:
        return activeTab;
    }
  };
  const isArchived = activeTab === "Archive";

  return (
    <StyledControlHeader data-testid="tableControlHeader">
      <SearchField
        value={searchValue}
        onChange={handleSearch}
        id={`searchField-${activeTab}`}
        placeholder={`Search ${activeTab}`}
        sx={{ width: "384px" }}
      />
      {!hideCreate && !isArchived && (
        <div>
          <Button
            variant={program.id ? "outlined" : "contained"}
            onClick={handleOpen}
            data-testid={`create-${activeTab}-button`}
          >
            {createTabName()}
          </Button>
          {program.id && activeTab === "Products" && (
            <Button
              variant="contained"
              onClick={() => setOpen(true)}
              data-testid={"add-products-button"}
              sx={{ marginLeft: "40px" }}
              disabled={!draftItems.length}
            >
              Add Products
            </Button>
          )}
        </div>
      )}
      {open && (
        <ProductConfirmation open={open} onClose={() => setOpen(false)} />
      )}
    </StyledControlHeader>
  );
};

export default TableControls;
